/*===================== 
    3.8 Rating CSS
==========================*/
.rating {
    @include flex_wrap ($dis: flex, $wrap: nowrap, $gap: 10px);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;

    ul {
        @include flex_wrap ($dis: flex, $wrap: nowrap, $gap: 2px);
        align-items: center;
        color: #E0E0E0;

        li {
            i {
                font-size: 15px;

                &.fill {
                    color: rgba(var(--primary), 1);
                }
            }
        }
    }
}

.rating-2 {
    i {
        color: rgba(var(--yellow), 1);
        font-size: 14px;
    }

    h6 {
        margin: 0;

        span {
            color: rgba(var(--content), 1);
            margin-left: 4px;
        }
    }
}