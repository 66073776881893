/*=====================
    1.2 Authentication CSS
==========================*/
/* ---------- learning authentication ---------- */
.learning-auth {
    .learning-header {
        background-image: url(../images/learning/home-bg.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 168px 0 25px;

        .home-contain {
            color: rgba(var(--white), 1);

            [class="dark"] & {
                color: #fff;
            }

            h2 {
                margin-bottom: 6px;
                font-size: 22px;
            }

            h5 {
                [class="dark"] & {
                    color: rgba(255, 255, 255, 0.62);
                }
            }
        }
    }

    a {
        &:hover {
            color: rgba(var(--content), 1);
        }

        &.forgot-password {
            color: rgba(var(--content), 1);
            margin-top: 11px;
            text-align: right;
            display: block;
        }

        &.create-new {
            display: block;
            text-align: center;
            color: rgba(var(--content), 1);
            margin-top: 11px;
        }
    }

    .continue-media {
        margin-top: 26px;
        position: relative;
        text-align: center;

        &::before {
            @include pos;
            border-top: 1px dashed rgb(202, 202, 202);
            width: 100%;
            @include center(vertical);
            left: 0;
            z-index: -1;

            [class="dark"] & {
                border-top-color: rgba(202, 202, 202, 0.1);
            }
        }

        span {
            background-color: rgba(var(--white), 1);
            padding-inline: 11px;
            font-size: 14px;
            font-weight: 600;
            color: rgba(var(--title), 1);

            [class="dark"] & {
                background-color: #060d17;
            }
        }
    }
}

/* ---------- Chatting authentication ---------- */
.chatting-auth {
    .edit-image {
        .profile-pic {
            color: transparent;
            transition: all 0.3s ease;
            @include flex_common;
            position: relative;
            overflow: hidden;
            padding: 4px;
            @include pseudowh($width: 82px, $height: 82px);
            margin: 0 auto;
            background-color: rgba(var(--white), 0.4);
            border-radius: 100%;

            [class="dark"] & {
                background-color: rgba(31, 41, 55, 0.4);
            }

            input {
                display: none;
            }

            img {
                position: absolute;
                object-fit: cover;
                @include pseudowh($width: 115px, $height: 115px);
                border: 6px solid rgba(var(--white), 1);
                box-shadow: 0 0 10px 0 rgba(var(--black), 0.1);
                border-radius: 100px;
                z-index: 0;
            }

            .-label {
                cursor: pointer;
                @include pseudowh;
                position: absolute;
                top: 0;
                left: 0;
            }

            .camera-icon {
                fill: rgba(var(--title), 1);
                @include pseudowh($width: 30px, $height: 30px);

                [class="dark"] & {
                    fill: #1f2937;
                }
            }

            span {
                font-size: 25px;
            }
        }
    }

    .from-flex {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 13px);
        align-items: center;
        margin-bottom: 28px;
    }

    .chatting-header {
        position: relative;
        padding: 0;

        .background-bg {
            position: absolute;
            bottom: 0;
            left: 0;
            @include pseudowh($width: 100%, $height: 133px);
            background: linear-gradient(0deg, rgba(var(--theme-color), 1) 0%, rgba(205, 233, 221, 0) 82.04%);
        }
    }

    .chatting-content {
        margin-top: 30px;

        h2 {
            font-weight: 500;
            margin-bottom: 9px;
            color: rgba(var(--title), 1);

            [class="dark"] & {
                color: #1f2937;
            }
        }

        h4 {
            font-weight: 400;
            line-height: 1.4;
            color: rgba(132, 143, 143, 1);
            margin-bottom: 19px;
        }
    }

    .header-box {
        display: flex;
        align-items: center;

        .arrow-box {
            font-size: 25px;
            @include pseudowh($width: 30px, $height: 30px);
            @include flex_common;
            margin-right: 4px;
        }

        .add-member {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
            align-items: center;
            margin-left: auto;

            i {
                font-size: 20px;
            }
        }

        .calling-box {
            margin-left: auto;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 15px);
            align-items: center;

            @include mq-max(md) {
                gap: calc(7px + (15 - 7) * ((100vw - 320px) / (600 - 320)));
            }

            i {
                font-size: 23px;
                color: rgba(var(--title), 1);
            }
        }

        .header-profile {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
            align-items: center;

            .profile-image {
                @include pseudowh($width: 52px, $height: 52px);
                overflow: hidden;
                border-radius: 100%;
                @include flex_common;
            }

            .name-contact {
                width: calc(100% - 52px - 3px);
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 3px);
                align-items: center;

                h5,
                h6 {
                    width: 100%;
                    color: rgba(var(--title), 1);
                }

                h6 {
                    font-size: 10px;
                }
            }
        }

        .left-header {
            width: 86px;
            @include flex_common;
        }

        .right-header {
            margin-left: auto;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 7px);
            align-items: center;
            color: rgba(var(--title), 1);

            button {
                padding: 0;
                color: rgba(var(--title), 1);
                line-height: 1;
            }

            i {
                &.ri-search-line {
                    font-size: 23px;
                }

                &.ri-more-2-line {
                    font-size: 20px;
                }
            }
        }
    }

    .onbording-content {
        margin-top: 40px;

        h1 {
            font-weight: 500;
            font-size: 30px;
            color: rgba(var(--title), 1);
            margin-bottom: 10px;

            [class="dark"] & {
                color: #222;
            }
        }

        h4 {
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--title), 0.6);
            margin-bottom: 26px;
            line-height: 1.3;
        }

        button {
            [class="dark"] & {
                color: rgba(255, 255, 255, 1) !important;
            }
        }

        h5 {
            font-weight: 400;
            color: rgba(var(--title), 0.6);
            font-size: 16px;
            margin-top: 13px;

            a {
                color: rgba(132, 143, 143, 1);
            }
        }
    }

    .custom-form-select {

        .form-select,
        .form-label {
            color: #1f2937;
            border-color: rgba(var(--title), 0.6);
        }
    }
}

/* ---------- Food authentication ---------- */
.food-auth {
    .bg-splash {
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/food/splash.png);
        background-position: bottom;
        @include pseudowh($width: 100%, $height: 355px);
        background-repeat: no-repeat;
        background-size: cover;
    }

    .auth-box {
        .auth-content {
            text-align: center;
            margin-top: 65px;
            position: relative;
            z-index: 1;

            img {
                width: 124px;
            }

            h1 {
                font-family: $oleo;
                font-weight: 700;
                font-size: 35px;
                color: rgba(var(--white), 1);
                line-height: 48px;
                margin-top: 5px;
            }
        }

        .auth-image {
            text-align: center;
            position: relative;
            width: 266px;
            margin: 20px auto 0;

            .leaf-1 {
                position: absolute;
                bottom: 59px;
                left: 0;
                animation: mover 2.5s infinite alternate;
            }

            .leaf-2 {
                position: absolute;
                bottom: 25px;
                right: 17px;
                animation: mover 3.5s infinite alternate;
            }
        }
    }

    .auth-start-box {
        margin-top: 37px;

        .start-heading {
            text-align: center;

            h1 {
                color: rgba(var(--title), 1);
                line-height: 116.9%;
            }

            p {
                font-weight: 400;
                font-size: 16px;
                margin: 17px 0 0;
                color: rgba(var(--content), 1);
            }
        }
    }

    .head-arrow {
        font-size: 24px;
        margin-top: 36px;
        color: rgba(var(--title), 1);
        @include pseudowh($width: auto, $height: auto);
        padding: 0;
        line-height: 1;
    }

    .auth-title {
        margin-top: 60px;

        h3 {
            font-weight: 700;
            margin-bottom: 12px;
            color: rgba(var(--title), 1);
        }

        h4 {
            font-weight: 400;
            font-size: 18px;
            color: rgba(var(--content), 1);
            line-height: 1.4;

            @include mq-max(md) {
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
            }
        }
    }

    .location-box {
        @include pseudowh($width: 236px, $height: 236px);
        margin: 0 auto 88px;
    }
}

/* ---------- Hotel Booking authentication ---------- */
.hotel-auth {
    padding-top: 80px;

    &-bg {
        @include pseudowh($width: 100%, $height: 100vh);
        background: linear-gradient(180deg, rgb(3, 99, 218) 0%, rgb(85, 70, 203) 100%);
    }

    .auth-box {
        background-color: rgba(var(--white), 1);
        height: 100vh;
        border-radius: 25px 25px 0px 0px;
        z-index: 0;
        position: relative;

        [class="dark"] & {
            background-color: #101214;
        }

        &::before {
            @include pos;
            top: -13px;
            left: 0;
            @include pseudowh($width: 100%, $height: 100vh);
            background-color: rgba(var(--white), 0.6);
            border-radius: 25px 25px 0px 0px;
            z-index: -1;

            [class="dark"] & {
                background-color: rgba(16, 18, 20, 0.6);
            }
        }
    }

    .auth-title {
        text-align: center;
        margin-top: 43px;
        margin-bottom: 35px;

        h2 {
            font-weight: 500;
            color: rgba(var(--title-color), 1);
        }
    }
}

/* ---------- Ecommerce authentication ---------- */
.ecommerce-auth {
    display: flex;
    height: auto;
    min-height: 100vh;

    .top-title {
        font-weight: 900;
        font-size: 65px;
        color: rgba(var(--light-bg), 0.015);
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        margin: 0;
        padding-top: 67px;

        [class="dark"] & {
            color: rgba(33, 35, 37, 0.5);
        }
    }

    h2 {
        margin-bottom: 23px;
        font-weight: 600;
        font-size: 28px;
        color: rgba(var(--light-bg), 1);

        &.email-title {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
        }
    }

    .para {
        color: rgba(var(--content), 1);
        line-height: 1.6;
        margin-bottom: 23px;
        font-size: 14px;
    }

    .auth-permission {
        h4 {
            a {
                color: rgba(var(--content), 1);
                font-weight: 400;
            }
        }
    }

    .form-style-6 {
        .form-control {
            color: rgba(var(--white), 1);

            [class="dark"] & {
                color: #fff;

                &:focus {
                    border-color: rgba(255, 255, 255, 0.1);
                }

                &::placeholder {
                    color: rgba(132, 148, 160, 1);
                }
            }
        }
    }
}

/* ---------- Grocery Authentication ---------- */
.grocery-authentication {
    background-image: url(../images/grocery/pattern.png);
    background-position: top right;
    background-size: 300px;
    background-repeat: no-repeat;
    padding-top: 126px;

    .logo-content {
        margin-bottom: 36px;

        .logo {
            width: 124px;
            margin-bottom: 15px;
        }

        p {
            font-weight: 400;
            line-height: 1.6;
            font-size: 14px;
            color: rgba(var(--content), 1);
            margin: 0;
        }
    }

    .auth-box {
        .auth-title {
            margin-bottom: 18px;

            h4 {
                font-weight: 600;
                color: rgb(27, 27, 62);

                [class="dark"] & {
                    color: #fff;
                }
            }
        }
    }

    .guest-button {
        position: relative;
        margin-top: 30px;
        text-align: center;

        a {
            text-decoration: underline;
        }
    }
}

/* ---------- Grocery Authentication ---------- */
.financial-authentication {
    position: relative;
    z-index: 0;
    display: grid;
    place-items: center;
    @include pseudowh($width: 100%, $height: 100vh);

    &::after {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh($width: 100%, $height: 190px);
        background: linear-gradient(180deg, rgba(155, 1, 68, 0.52) 0%, rgba(155, 1, 68, 0) 100%);
        z-index: -1;
    }

    .logo-content {
        text-align: center;
        margin-bottom: 40px;

        .logo {
            width: 50px;
            margin-bottom: 16px;
        }

        h2 {
            font-weight: 400;
            color: rgba(var(--white), 1);
            margin-bottom: 7px;
        }

        h5 {
            font-weight: 400;
            color: rgba(var(--theme-gray), 1);
            font-size: 14px;
        }
    }
}

/* ---------- NFT authentication ---------- */
.nft-authentication {
    background-image: url(../images/nft/shape.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;

    .author-image {
        margin: 0 auto;
        padding-top: 43px;
        text-align: center;

        img {
            @include pseudowh($width: 128px, $height: 128px);
        }
    }

    .author-title {
        margin-top: 8px;
        margin-bottom: 22px;

        h3 {
            font-weight: 500;
            font-size: 20px;

            @include mq-max(md) {
                font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (600 - 320)));
            }
        }

        p {
            font-weight: 400;
            font-size: 14px;
            margin-top: 5px;
            color: rgba(var(--title), 0.6);

            @include mq-max(md) {
                font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (600 - 320)));
            }
        }
    }

    .already-author {
        display: block;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        margin-top: 6px;
        color: #a0a0a0;

        a {
            color: #a0a0a0;
            font-size: 15px;
        }
    }

    .author-divider {
        text-align: center;
        margin: 37px 0 24px;
        position: relative;
        z-index: 0;

        &:before {
            @include pos;
            @include center(vertical);
            left: 0;
            @include pseudowh($width: 100%, $height: 1px);
            border-top: 1px dashed #cacaca;
            z-index: -1;
        }

        span {
            font-weight: 500;
            font-size: 14px;
            color: #a3a3a3;
            padding-inline: 14px;
            background-color: rgba(var(--white), 1);

            [class="dark"] & {
                background-color: #151515;
            }
        }
    }
}