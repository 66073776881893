/*===================== 
    5.3 Chatting Application CSS
==========================*/
.story-section {
    margin-top: 19px;

    .story-box {
        .profile-story-box {
            position: relative;

            &.unread-story {
                .profile-image {
                    img {
                        border: 2px solid rgba(var(--content), 1);
                    }
                }
            }

            .profile-image {
                @include pseudowh($width: 52px, $height: 52px);
                position: relative;
                margin: 0 auto 4px;

                img {
                    border-radius: 100%;
                    @include pseudowh($width: 52px, $height: 52px);
                    object-fit: cover;
                }
            }

            .add-story-button {
                @include pseudowh($width: 20px, $height: 20px);
                position: absolute;
                bottom: 4px;
                right: -3px;
                background-color: rgba(var(--title), 1);
                @include flex_common;
                border-radius: 100%;
                color: rgba(var(--white), 1);
                font-size: 16px;
                padding: 0;

                [class="dark"] & {
                    background-color: #374250;
                    color: rgba(var(--content), 1);
                }
            }

            .profile-name {
                font-weight: 400;
                color: rgba(var(--title), 1);
                text-align: center;

                h6 {
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;

                    [class="dark"] & {
                        color: #374250;
                    }
                }
            }
        }
    }
}

/* ----------- Chatting Section ----------- */
.chatting-section {
    margin-top: 12px;
    background-color: rgba(var(--white), 1);
    height: calc(100vh - 57px);
    border-radius: 20px 20px 0px 0px;
    padding: 18px 0;
    overflow: auto;

    [class="dark"] & {
        background-color: #1f2937;
    }

    &.bg-transparent {
        background-color: transparent;
    }

    .chatting-title {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        color: rgba(var(--title), 1);

        i {
            line-height: 1;
            font-size: 22px;
        }
    }

    .chatting-title-2 {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        margin-bottom: 15px;

        h5 {
            font-weight: 400;
        }

        i {
            line-height: 1;
            font-size: 22px;
        }
    }

    .chatting-list-box {
        margin-top: 18px;

        .chatting-list {
            li {
                +li {
                    border-top: 1px solid rgba(var(--content), 0.1);
                    padding-top: 10px;
                    margin-top: 20px;
                }

                .chatting-box {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 10px);
                    align-items: center;

                    &.unread-story {
                        .chatting-image {
                            img {
                                border: 2px solid rgba(var(--content), 1);

                                [class="dark"] & {
                                    border-color: #374250;
                                }
                            }
                        }
                    }

                    &.online {
                        .chatting-image {
                            position: relative;

                            &::after {
                                @include pos;
                                bottom: 5px;
                                right: 2px;
                                @include pseudowh($width: 10px, $height: 10px);
                                background-color: rgba(var(--theme-color), 1);
                                border-radius: 100%;

                                [class="dark"] & {
                                    background-color: #374250;
                                }
                            }
                        }
                    }

                    &.unread-message {
                        .chatting-content {
                            >div {
                                &.chatting-message {
                                    span {
                                        display: flex;

                                        .msg-count {
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.delivered-message {
                        .chatting-content {
                            .chatting-message {
                                span {
                                    display: flex;
                                    justify-content: flex-end;

                                    i {
                                        &.delivered-icon {
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.read-message {
                        .chatting-content {
                            .chatting-message {
                                span {
                                    display: flex;
                                    gap: 5px;

                                    i {
                                        &.read-icon {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .chatting-image {
                        @include pseudowh($width: 52px, $height: 52px);
                        position: relative;

                        img {
                            border-radius: 100%;
                            @include pseudowh($width: 52px, $height: 52px);
                            object-fit: cover;
                        }
                    }

                    .chatting-content {
                        width: calc(100% - 52px - 10px);
                        @include flex_common($dis: flex, $align: center, $justify: space-between);

                        .chatting-name {
                            margin-right: auto;
                            width: 100%;
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 5px);
                            justify-content: flex-end;

                            h5 {
                                color: rgba(var(--title), 1);
                                width: 100%;
                                font-size: 15px;
                            }

                            h6 {
                                width: 100%;
                                font-weight: 400;
                                color: rgba(var(--content), 1);
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                display: -webkit-box;
                                overflow: hidden;
                                font-size: 14px;
                                margin-top: 2px;
                            }
                        }

                        .chatting-message {
                            width: 32%;
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 5px);
                            justify-content: flex-end;
                            text-align: right;

                            [dir="rtl"] & {
                                text-align: left;
                            }

                            h6 {
                                font-weight: 400;
                                color: rgba(var(--content), 1);
                                width: 100%;
                                font-size: 13px;
                            }

                            >span {
                                display: flex;
                                justify-content: flex-end;
                            }

                            span {
                                display: none;
                                width: 100%;

                                .msg-count {
                                    display: none;
                                    @include pseudowh($width: 18px, $height: 18px);
                                    border-radius: 100%;
                                    background-color: rgba(var(--theme-color), 1);
                                    color: rgba(var(--title), 1);
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 11px;
                                    line-height: 1;
                                    margin-left: auto;

                                    [class="dark"] & {
                                        background-color: #374250;
                                    }

                                    [dir="rtl"] & {
                                        margin-left: unset;
                                        margin-right: auto;
                                    }
                                }

                                i {
                                    color: rgba(var(--content), 1);
                                    font-size: 20px;
                                    line-height: 1;

                                    &.delivered-icon,
                                    &.read-icon {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .chatting-video {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 10px);
                    align-items: center;

                    &.video-chatting {
                        .chatting-content {
                            .chatting-message {
                                i {
                                    &.video-icon {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    &.call-chatting {
                        .chatting-content {
                            .chatting-message {
                                i {
                                    &.call-icon {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    &.miss-call {
                        .chatting-content {
                            .chatting-name {
                                h6 {
                                    .left-icon {
                                        display: block;
                                        color: rgba(246, 0, 0, 1);
                                    }
                                }
                            }
                        }
                    }

                    &.incoming-call {
                        .chatting-content {
                            .chatting-name {
                                h6 {
                                    .right-icon {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    .chatting-image {
                        @include pseudowh($width: 52px, $height: 52px);
                        position: relative;

                        img {
                            border-radius: 100%;
                            @include pseudowh($width: 52px, $height: 52px);
                            object-fit: cover;
                        }
                    }

                    .chatting-content {
                        width: calc(100% - 52px - 10px);
                        @include flex_common($dis: flex, $align: center, $justify: space-between);

                        .chatting-name {
                            margin-right: auto;
                            width: 100%;
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 5px);

                            h5 {
                                color: rgba(var(--title), 1);
                                width: 100%;
                                font-size: 15px;
                            }

                            h6 {
                                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 5px);
                                align-items: center;
                                font-weight: 400;
                                color: rgba(var(--content), 1);
                                font-size: 13px;

                                i {
                                    display: none;
                                    font-size: 18px;
                                    line-height: 1;
                                    color: rgba(var(--title), 1);
                                }
                            }
                        }

                        .chatting-message {
                            i {
                                display: none;
                                font-size: 27px;
                                color: rgba(var(--content), 1);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ----------- Account Section ----------- */
.account-setting-section {
    margin-top: 12px;
    background-color: rgba(var(--white), 1);
    height: calc(100vh - 79px);
    border-radius: 20px 20px 0px 0px;
    padding: 25px 0 100px;
    position: relative;
    z-index: 0;

    [class="dark"] & {
        background-color: #1f2937;
    }

    &::after {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh;
        background-image: url(../images/chatting/chatting-bg.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0.02;
        z-index: -1;
    }

    &:before {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh;
        background-image: url(../images/logo/2.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 195px;
        opacity: 0.05;
        z-index: -1;
    }

    .account-setting-list-box {
        .account-setting-list {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 25px);

            li {
                width: 100%;

                .setting-name {
                    color: rgba(var(--title), 1);
                    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 13px);
                    align-items: center;

                    i {
                        font-size: 24px;
                        line-height: 1;
                    }
                }
            }
        }
    }
}

.audio-section {
    .audio-profile-box {
        position: absolute;
        top: 100px;
        @include center(horizontal);

        .audio-profile-image {
            @include pseudowh($width: 158px, $height: 158px);
            border-radius: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
        }

        .profile-name-time {
            margin-top: 18px;
            text-align: center;
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);
            align-items: center;

            h3 {
                width: 100%;
                font-weight: 500;
                font-size: 18px;
                color: rgba(56, 72, 73, 1);
            }

            h2 {
                width: 100%;
                font-weight: 400;
                font-size: 20px;
                color: rgba(132, 143, 143, 1);
            }
        }
    }
}

.video-calling {
    position: absolute;
    top: 0;
    left: 0;
    @include pseudowh;
    z-index: -1;

    .main-image {
        @include pseudowh;
        object-fit: cover;
    }

    .camera-image {
        position: absolute;
        top: 70px;
        right: 16px;
        @include pseudowh($width: 96px, $height: 104px);
    }
}

.message-box {
    margin-top: 12px;
    background-color: rgba(var(--white), 1);
    height: calc(100vh - 79px);
    border-radius: 20px 20px 0px 0px;
    padding: 18px 0 100px;
    overflow: hidden auto;

    [class="dark"] & {
        background-color: #1f2937;
    }

    .msger {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }

    .msger-header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 2px solid #ddd;
        background-color: #eee;
        color: #666;
    }

    .msg-bubble {
        width: calc(100% - 60px);
        padding: 15px;
        position: relative;
        border-radius: 15px;
        background-color: #eeeeee;

        [class="dark"] & {
            background-color: #374151;
        }

        .msg-text {
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--title), 1);
        }
    }

    .msg-info-time {
        position: absolute;
        bottom: -21px;
        right: 0;
        font-weight: 400;
        font-size: 12px;
        color: rgba(var(--title), 1);
        font-size: 0.85em;
    }

    .left-msg,
    .right-msg {
        display: flex;
        align-items: flex-end;
    }

    .left-msg .msg-bubble {
        position: relative;
        border-bottom-left-radius: 0;

        [dir="rtl"] & {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 15px;
        }
    }

    .msg {
        margin-bottom: 32px;
    }

    .msg:last-of-type {
        margin: 0;
    }

    .right-msg {
        flex-direction: row-reverse;

        .msg-text {
            color: rgba(var(--white), 1);

            [dir="rtl"] & {
                text-align: left;
            }

            [class="dark"] & {
                color: #eeeeee;
            }
        }
    }

    .right-msg .msg-bubble {
        background-color: rgba(var(--title), 0.6);
        color: rgba(var(--white), 1);
        border-bottom-right-radius: 0;

        [dir="rtl"] & {
            border-bottom-right-radius: 15px;
            border-bottom-left-radius: 0;
        }

        [class="dark"] & {
            background: #374151;
        }
    }

    .right-msg .msg-img {
        margin: 0 0 0 10px;
    }

    .msger-inputarea {
        backdrop-filter: blur(3.5px);
        position: fixed;
        bottom: 0;
        @include center(horizontal);
        width: 100%;
        padding: 0 15px 15px;
        max-width: 600px;
    }

    .msger-inputarea * {
        border: none;
        border-radius: 3px;
        font-size: 1em;
    }

    .msger-input {
        width: 100%;
        background-color: #eeeeee;
        padding: 16px 54px 16px 48px;
        border-radius: 50px;

        [class="dark"] & {
            background-color: #374151;
            color: #ddd;
        }

        [dir="rtl"] & {
            padding: 16px 48px 16px 54px;
        }
    }

    .msger-send-btn {
        padding: 0;
        border-radius: 100%;
        @include pseudowh($width: 41px, $height: 41px);
        @include flex_common;
        position: absolute;
        @include center(vertical);
        right: 8px;
        background-color: rgba(var(--title), 1);
        color: rgba(var(--white), 1);

        [dir="rtl"] & {
            right: unset;
            left: 8px;
        }

        [class="dark"] & {
            background-color: #1f2a36;
        }

        svg {
            @include pseudowh($width: 20px, $height: 20px);
            stroke: rgba(var(--white), 1);
            fill: none;

            [class="dark"] & {
                stroke: #eeeeee;
            }
        }
    }

    .emoji-button {
        padding: 0;
        @include pseudowh($width: 24px, $height: 24px);
        position: absolute;
        @include center(vertical);
        left: 16px;

        [dir="rtl"] & {
            left: unset;
            right: 16px;
        }

        .emoji-icon {
            stroke: rgba(var(--content), 1);
            fill: transparent;
            @include pseudowh($width: 24px, $height: 24px);
        }
    }
}

/* ----------- User Profile Section ----------- */
.user-profile-section {
    margin-top: 38px;
    background-color: rgba(var(--white), 1);
    height: 100%;
    border-radius: 20px 20px 0px 0px;
    padding: 25px 0 110px;
    position: relative;
    min-height: calc(100vh - 79px);

    [class="dark"] & {
        background-color: #1f2937;
    }

    .user-profile-box {
        text-align: center;

        .user-name {
            margin-top: 10px;

            h4 {
                font-weight: 500;
                font-size: 15px;
                color: rgba(var(--title), 1);
            }

            h5 {
                margin-top: 6px;
                font-weight: 400;
                font-size: 14px;
                color: rgba(var(--content), 1);
            }
        }

        .user-content {
            margin-top: 10px;

            .content-list {
                @include flex_common;
                flex-wrap: wrap;
                gap: 20px;

                li {
                    a {
                        @include pseudowh($width: 34px, $height: 34px);
                        background-color: rgba(var(--theme-color), 1);
                        border-radius: 4px;
                        color: rgba(var(--title), 1);
                        font-size: 16px;
                        @include flex_common;

                        [class="dark"] & {
                            background-color: #374151;
                        }
                    }
                }
            }
        }

        .user-media {
            background-color: rgba(245, 245, 245, 1);
            margin-top: 21px;
            margin-bottom: 19px;
            padding: 14px;
            border-radius: 8px;

            [class="dark"] & {
                background-color: #374151;
            }

            img {
                @include pseudowh;
                object-fit: cover;
                border-radius: 8px;
                background-color: transparent;
                border: none;
            }

            .modal-target {
                width: 300px;
                border-radius: 5px;
                cursor: pointer;
                transition: 0.3s;
            }

            .modal {
                display: none;
                position: fixed;
                z-index: 2;
                left: 0;
                top: 0;
                @include pseudowh;
                overflow: auto;
                background-color: rgba(var(--black), 1);
            }

            .modal-content {
                margin: auto;
                display: block;
                width: 80%;
                opacity: 1;
                max-width: 1200px;
                object-fit: contain;
            }

            .modal-caption {
                margin: auto;
                display: block;
                width: 80%;
                max-width: 1200px;
                text-align: center;
                color: rgba(var(--white), 1);
                font-weight: 700;
                font-size: 1em;
                margin-top: 32px;
            }

            .modal-content,
            .modal-caption {
                animation-name: zoom;
                animation-duration: 0.6s;
            }

            .modal-close {
                position: absolute;
                top: 15px;
                right: 15px;
                color: rgba(var(--white), 1);
                font-size: 20px;
                font-weight: bold;
                @include pseudowh($width: 40px, $height: 40px);
                transition: 0.3s;
                z-index: 1;
                background-color: rgba(var(--title), 1);
                @include flex_common;
                border-radius: 5px;
            }

            .modal-close:hover,
            .modal-close:focus {
                color: #bbb;
                text-decoration: none;
                cursor: pointer;
            }
        }

        .user-setting {
            .setting-list {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 22px);

                li {
                    width: 100%;

                    a {
                        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 6px);
                        align-items: center;
                        color: rgba(var(--title), 1);

                        i {
                            font-size: 15px;
                            line-height: 1;
                        }

                        h4 {
                            font-weight: 400;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

.user-image {
    @include flex_common;
    position: relative;
    @include pseudowh($width: 86px, $height: 86px);
    margin: -50px auto 0px;
    overflow: hidden;
    border-radius: 100%;
}

.media-section {
    margin-top: 12px;
    background-color: rgba(var(--white), 1);
    height: 100vh;
    border-radius: 20px 20px 0px 0px;
    padding: 18px 0;

    [class="dark"] & {
        background-color: #1f2937;
    }
}

.new-chat-section {
    padding-bottom: 18px;

    .new-chat-list {
        margin-top: 18px;

        li {
            +li {
                border-top: 1px solid rgba(var(--content), 0.1);
                padding-top: 10px;
                margin-top: 10px;
            }

            .new-chat {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 10px);
                align-items: center;

                .new-chat-icon {
                    @include pseudowh($width: 32px, $height: 32px);
                    background-color: rgba(var(--theme-color), 1);
                    border-radius: 100%;
                    @include flex_common;
                    font-size: 16px;
                    color: rgba(var(--title), 1);

                    [class="dark"] & {
                        background-color: #374250;
                    }

                    &-2 {
                        font-size: 20px;
                    }
                }

                .new-chat-name {
                    h5 {
                        color: rgba(var(--title), 1);
                        width: 100%;
                    }
                }
            }

            .new-chat-box {
                align-items: center;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 10px);

                &.unread-story {
                    .chatting-image {
                        img {
                            border: 2px solid rgba(var(--content), 1);
                        }
                    }
                }

                &.online {
                    .chatting-image {
                        position: relative;

                        &::after {
                            @include pos;
                            bottom: 5px;
                            right: 2px;
                            @include pseudowh($width: 10px, $height: 10px);
                            background-color: rgba(var(--theme-color), 1);
                            border-radius: 100%;
                        }
                    }
                }

                &.unread-message {
                    .chatting-content {
                        >div {
                            &.chatting-message {
                                span {
                                    display: block;

                                    label {
                                        display: flex;
                                    }
                                }
                            }
                        }
                    }
                }

                &.delivered-message {
                    .chatting-content {
                        >div {
                            &.chatting-message {
                                span {
                                    display: block;

                                    i {
                                        &.delivered-icon {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.read-message {
                    .chatting-content {
                        >div {
                            &.chatting-message {
                                span {
                                    display: block;

                                    i {
                                        &.read-icon {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .chatting-image {
                    @include pseudowh($width: 52px, $height: 52px);
                    position: relative;

                    img {
                        border-radius: 100%;
                        @include pseudowh($width: 52px, $height: 52px);
                        object-fit: cover;
                    }
                }

                .chatting-content {
                    @include flex_common($dis: flex, $align: center, $justify: space-between);
                    width: calc(100% - 52px - 10px);

                    .chatting-name {
                        margin-right: auto;
                        width: 100%;
                        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 5px);
                        justify-content: flex-end;

                        h5 {
                            color: rgba(var(--title), 1);
                            width: 100%;
                            font-size: 14px;
                        }

                        h6 {
                            width: 100%;
                            font-weight: 400;
                            color: rgba(var(--content), 1);
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            overflow: hidden;
                            font-size: 13px;
                        }
                    }

                    .chatting-message {
                        width: 32%;
                        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 5px);
                        justify-content: flex-end;
                        text-align: right;

                        h6 {
                            font-weight: 400;
                            color: rgba(var(--content), 1);
                            width: 100%;
                        }

                        span {
                            display: none;
                            width: 100%;

                            label {
                                display: none;
                                @include pseudowh($width: 18px, $height: 18px);
                                border-radius: 100%;
                                background-color: rgba(var(--theme-color), 1);
                                color: rgba(var(--title), 1);
                                align-items: center;
                                justify-content: center;
                                font-size: 11px;
                                line-height: 1;
                                margin-left: auto;
                            }

                            i {
                                color: rgba(var(--content), 1);
                                font-size: 20px;
                                line-height: 1;

                                &.delivered-icon,
                                &.read-icon {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .chatting-video {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 10px);
                align-items: center;

                &.video-chatting {
                    .chatting-content {
                        .chatting-message {
                            i {
                                &.video-icon {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &.call-chatting {
                    .chatting-content {
                        .chatting-message {
                            i {
                                &.call-icon {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &.miss-call {
                    .chatting-content {
                        .chatting-name {
                            h6 {
                                .left-icon {
                                    display: block;
                                    color: rgba(246, 0, 0, 1);
                                }
                            }
                        }
                    }
                }

                &.incoming-call {
                    .chatting-content {
                        .chatting-name {
                            h6 {
                                .right-icon {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                .chatting-image {
                    @include pseudowh($width: 52px, $height: 52px);
                    position: relative;

                    img {
                        border-radius: 100%;
                        @include pseudowh($width: 52px, $height: 52px);
                        object-fit: cover;
                    }
                }

                .chatting-content {
                    width: calc(100% - 52px - 10px);
                    @include flex_common($dis: flex, $align: center, $justify: space-between);

                    .chatting-name {
                        margin-right: auto;
                        width: 100%;
                        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 5px);

                        h5 {
                            color: rgba(var(--title), 1);
                            width: 100%;
                        }

                        h6 {
                            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 5px);
                            align-items: center;
                            font-weight: 400;
                            color: rgba(var(--content), 1);

                            i {
                                display: none;
                                font-size: 18px;
                                line-height: 1;
                                color: rgba(var(--title), 1);
                            }
                        }
                    }

                    .chatting-message {
                        i {
                            display: none;
                            font-size: 27px;
                            color: rgba(var(--content), 1);
                        }
                    }
                }
            }
        }
    }
}

/* ----------- Chat setting ----------- */
.chat-setting-section {
    margin-top: 12px;
    background-color: rgba(var(--white), 1);
    height: 100%;
    border-radius: 20px 20px 0px 0px;
    padding: 18px 0 18px;
    min-height: calc(100vh - 46px);

    [class="dark"] & {
        background-color: #1f2a36;
    }

    .chat-setting-list {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 100%;

            +li {
                border-top: 1px solid rgba(var(--border-color), 1);
                margin-top: 12px;
                padding-top: 12px;

                [class="dark"] & {
                    border-color: rgba(var(--content), 0.1);
                }
            }

            .chat-box {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 15px);
                align-items: center;

                .chat-icon {
                    font-size: 17px;
                    color: rgba(var(--title), 1);
                    line-height: 1;
                }

                .chat-name {
                    color: rgba(var(--title), 1);

                    h5 {
                        color: rgba(var(--title), 1);
                        font-size: 15px;
                    }

                    h6 {
                        color: rgba(var(--content), 1);
                        font-weight: 400;
                        margin-top: 4px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.display-setting-section {
    .checkbox-list {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 17px);

        li {
            width: 100%;

            &:last-child {
                a {
                    h5 {
                        font-size: 14px;
                        color: rgba(var(--title), 1);
                        margin-bottom: 3px;
                    }

                    h6 {
                        color: rgba(var(--content), 1);
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

.chat-history-section {
    .chat-history-box {
        margin-top: 10px;

        .chat-list {
            margin-top: 30px;

            li {
                width: 100%;

                +li {
                    margin-top: 16px;
                    padding-top: 16px;
                    border-top: 1px solid rgba(var(--content), 0.1);
                }

                a {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                    color: rgba(var(--title), 1);
                    align-items: center;

                    h5 {
                        color: rgba(var(--title), 1);
                        font-weight: 400;
                        line-height: 1;
                        font-size: 16px;
                    }

                    i {
                        line-height: 1;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.chat-backup-section {
    margin-top: 12px;
    background-color: rgba(var(--white), 1);
    height: calc(100vh - 57px);
    border-radius: 20px 20px 0px 0px;
    padding: 18px 0;
    overflow: auto;

    [class="dark"] & {
        background-color: #1f2937;
    }

    .backup-box {
        display: flex;
        align-items: flex-start;
        gap: 13px;

        .backup-icon {
            font-size: 15px;
            color: rgba(var(--title), 1);
        }

        .backup-content {
            color: rgba(var(--content), 1);

            h5 {
                color: rgba(var(--title), 1);
                font-size: 16px;
                margin-top: 3px;
            }

            p {
                margin-top: 7px;
                margin-bottom: 0;
                line-height: 1.5;
                font-size: 14px;
            }
        }
    }

    .back-list {
        margin-left: 43px;
        margin-top: 13px;
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 4px);

        [dir="rtl"] & {
            margin-left: unset;
            margin-right: 43px;
        }

        li {
            width: 100%;
            font-size: 14px;
            color: rgba(var(--title), 1);
        }
    }

    .backup-button {
        background-color: rgba(var(--title), 1);
        width: auto;
        font-size: 14px;
        padding: 8px 14px;
        margin-top: 15px;
        margin-left: 43px;
        color: #fff;

        [class="dark"] & {
            background-color: #374151;
        }

        [dir="rtl"] & {
            margin-left: unset;
            margin-right: 43px;
        }
    }

    .back-box-list {
        margin-left: 43px;
        margin-top: 15px;
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 12px);

        [dir="rtl"] & {
            margin-left: unset;
            margin-right: 43px;
        }

        li {
            width: 100%;

            a {
                color: rgba(var(--title), 1);

                h5 {
                    font-size: 15px;
                }

                h6 {
                    color: rgba(var(--content), 1);
                    margin-top: 9px;
                    font-weight: 400;
                    font-size: 15px;
                }
            }
        }
    }
}

.chat-backup-form {
    .form-check {
        margin: 0;
        padding: 0;
        min-height: auto;
        display: flex;
        align-items: flex-start;
        gap: 10px;

        +.form-check {
            margin-top: 15px;
        }

        .form-check-label {
            margin-top: 3px;
            width: calc(100% - 19px - 10px);
        }

        .form-check-input {
            background-color: transparent;
            @include pseudowh($width: 19px, $height: 19px);
            float: unset;
            margin: 0;
            border: none;

            &:after {
                @include pseudowh($width: 19px, $height: 19px);
                top: 0;
                left: 0;
            }

            &::before {
                @include pseudowh($width: 8px, $height: 5px);
                left: 6px;
                top: 6px;

                [dir="rtl"] & {
                    left: 6px;
                }
            }

            &:checked {
                &:after {
                    background-color: rgba(var(--title), 1);
                }
            }
        }

        .form-check-label {
            .first {
                display: block;
                font-size: 14px;
                color: rgba(var(--title), 1);
                font-weight: 500;
                line-height: 1;
                margin-bottom: 5px;
            }

            .second {
                font-size: 14px;
                color: rgba(var(--content), 1);
                font-weight: 400;
                line-height: 1.4;
            }
        }
    }
}

/* ----------- Help Section ----------- */
.help-section {
    margin-top: 12px;
    background-color: rgba(var(--white), 1);
    height: 100vh;
    border-radius: 20px 20px 0px 0px;
    padding: 18px 0 100px;

    [class="dark"] & {
        background-color: #1f2a36;
    }

    .help-list {
        margin-top: 20px;

        li {
            width: 100%;

            +li {
                margin-top: 14px;
                padding-top: 14px;
                border-top: 1px solid rgba(var(--content), 0.1);
            }

            a {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                color: rgba(var(--title), 1);
                align-items: center;

                h5 {
                    color: rgba(var(--title), 1);
                    font-weight: 400;
                    line-height: 1;
                    font-size: 15px;
                }

                i {
                    line-height: 1;
                    font-size: 15px;
                }
            }
        }
    }
}

/* ----------- Story slide ----------- */
.story-slide {
    @include pseudowh($width: 100vw, $height: 100vh);
    overflow: hidden;
    position: fixed;
    @include center(horizontal);
    top: 0;
    z-index: 9;
    display: none;
    max-width: 600px;

    &.show {
        display: block;
    }

    .slide {
        display: grid;
    }

    .close-btn {
        position: absolute;
        bottom: 15px;
        left: 15px;
        z-index: 3;
        width: calc(100% - 30px);
    }

    .slide-items {
        position: relative;
        grid-area: 1/1;
        overflow: hidden;

        img {
            object-fit: cover;
            @include pseudowh($width: 100%, $height: 100vh);
        }

        >* {
            position: absolute;
            top: 0;
            opacity: 0;
            pointer-events: none;
        }

        >.active {
            position: relative;
            opacity: 1;
            poiter-events: initial;
        }
    }

    .slide-nav {
        grid-area: 1/1;
        z-index: 2;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;

        button {
            -webkit-appearance: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            opacity: 0;
        }
    }

    .slide-thumb {
        display: flex;
        grid-column: 1/3;
        padding: 0 15px;

        >span {
            flex: 1;
            display: block;
            height: 2px;
            background: #afafaf;
            margin: 3px;
            margin-top: 20px;
            border-radius: 3px;
            overflow: hidden;
        }

        >span.done {
            &:after {
                content: "";
                display: block;
                height: inherit;
                background: rgba(255, 255, 255, 0.9);
                border-radius: 3px;
            }
        }

        >span.active {
            &:after {
                content: "";
                display: block;
                height: inherit;
                background: rgba(255, 255, 255, 0.9);
                border-radius: 3px;
                transform: translateX(-100%);
                animation: thumb 5s forwards linear;
            }
        }
    }

    @keyframes thumb {
        to {
            transform: initial;
        }
    }
}