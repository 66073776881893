/* =====================
    1.1 Animation SCSS
 ========================== */
@keyframes flash {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes scale {
    0% {
        transform: scale(.8);
    }

    100% {
        transform: scale(1.3);
    }
}

@keyframes circle {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes wave-animation {
    0% {
        transform: rotate(0.0deg)
    }

    10% {
        transform: rotate(14.0deg)
    }

    20% {
        transform: rotate(-8.0deg)
    }

    30% {
        transform: rotate(14.0deg)
    }

    40% {
        transform: rotate(-4.0deg)
    }

    50% {
        transform: rotate(10.0deg)
    }

    60% {
        transform: rotate(0.0deg)
    }

    100% {
        transform: rotate(0.0deg)
    }
}

@keyframes zoom {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes radio-button {
    0% {
        transform: translateY(-50%) scale(0);
        box-shadow: 0 0 0px rgba(var(--black), 0.2);
    }

    80% {
        transform: translateY(-50%) scale(1.2);
    }

    100% {
        transform: translateY(-50%) scale(1);
        box-shadow: 0 0 3px rgba(var(--black), 0.2);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-10px);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes flicker {
    0% {
        background: transparent;
    }

    50% {
        background: white;
    }

    100% {
        background: transparent;
    }
}

@keyframes neon {
    0% {
        text-shadow: none;
    }

    50% {
        text-shadow: rgba(255, 255, 255, 0.8) 0 0 8px;
    }

    100% {
        text-shadow: none;
    }
}