/*===================== 
    3.9 Slider CSS
==========================*/
.reading-slider {
    padding-bottom: 5px;

    .swiper-wrapper {
        .swiper-slide {
            width: 81%;

            &.swiper-slide-active {
                margin-left: 0;
            }
        }
    }
}

.card-swiper {
    .swiper-slide {
        width: 88%;
    }

    .swiper-pagination {
        position: relative;
        bottom: 0;
        margin-top: 12px;

        .swiper-pagination-bullet {
            &-active {
                width: 40px;
                border-radius: 50px;
                background: var(--gradient);
            }
        }
    }
}

.white-dots {
    .swiper-pagination {
        position: relative;
        bottom: 0;
        margin-top: 15px;

        .swiper-pagination-bullet {
            transition: all 0.5s ease;
            @include pseudowh($width: 7px, $height: 7px);
            display: inline-block;
            border-radius: 50px;
            background: linear-gradient(270deg, #ffa000 -5.38%, #ffd54f 100%);
            opacity: 1;
            box-shadow: $shadow3;
            opacity: 0.5;

            [class="dark"] & {
                background-color: #171717;
                box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
            }

            &-active {
                opacity: 1;
                @include pseudowh($width: 42px, $height: 7px);
            }
        }
    }
}

.financial-slider {
    padding-top: 50px;

    .swiper-pagination {
        position: relative;
        border: unset;
        left: unset;
        margin-top: 44px;

        @include mq-max(md) {
            margin-top: calc(27px + (44 - 27) * ((100vw - 320px) / (600 - 320)));
        }

        .swiper-pagination-bullet {
            border: 0.8px solid rgba(var(--title), 1);
            @include pseudowh($width: 6px, $height: 6px);
            background-color: transparent;
            transition: all 0.5s ease;
            border-radius: 100px;
            opacity: 1;
            margin: 0;
            margin-inline: 3px;

            &:first-child {
                margin-left: unset;
            }

            &:last-child {
                margin-right: unset;
            }

            &-active {
                width: 25px;
                background-color: rgba(var(--title), 1);
            }
        }
    }
}

.onboarding-content-slider {
    .swiper-pagination {
        position: relative;
        bottom: unset;
        left: unset;
        text-align: left;
        margin-top: 38px;

        [dir="rtl"] & {
            text-align: right;
        }

        .swiper-pagination-bullet {
            z-index: -1;
            @include pseudowh($width: 19px, $height: 5px);
            border-radius: 100px;
            background-color: #C0C0C0;
            opacity: 1;
            transition: 0.3s;
            margin: 0;
            margin-inline: 5px;

            &:first-child {
                margin-left: unset;
            }

            &:last-child {
                margin-right: unset;
            }

            &-active {
                width: 60px;
                background: rgba(var(--title), 1);
            }
        }
    }
}