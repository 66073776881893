/*===================== 
   5.10 Inner pages CSS
==========================*/
/* ---------- Term & Condition CSS ---------- */
.term-condition-section {
    .term-condition-box {
        .term-content {
            p {
                color: rgba(var(--content), 1);
                line-height: 1.5;
                font-size: 14px;
                margin: 0;

                a {
                    [class="dark"] & {
                        color: #ddd;
                    }

                    &:hover {
                        color: rgba(var(--theme-color), 1);
                    }
                }
            }

            .social-media-list {
                margin: 9px 0;
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 3px);
                padding-left: 30px;

                [dir="rtl"] & {
                    padding-left: unset;
                    padding-right: 30px;
                }

                li {
                    width: 100%;
                    list-style: disc;

                    a {
                        color: rgba(var(--theme-color2), 1);

                        [class="dark"] & {
                            color: #ddd;
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Language selection CSS ---------- */
.language-section {
    .language-setting-list {
        .setting-list {
            background-color: rgba(var(--white), 1);
            border-radius: 6px;
            padding-block: 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            [class="dark"] & {
                background-color: #171717;
                box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
            }

            li {
                width: 100%;

                &.active {
                    i {
                        display: block;
                    }

                    h4 {
                        [class="dark"] & {
                            color: #f1f1f1;
                        }
                    }
                }

                +li {
                    padding-top: 15px;
                    margin-top: 13px;
                    border-top: 1px solid #f9f9f9;

                    [class="dark"] & {
                        border-top-color: rgba(249, 249, 249, 0.04);
                    }
                }

                a {
                    @include flex_common($dis: flex, $align: center, $justify: space-between);
                    color: rgba(var(--title), 1);
                    width: 100%;
                    padding-inline: 11px;

                    h4 {
                        color: rgba(var(--content), 1);
                    }

                    i {
                        display: none;
                        font-size: 18px;
                        line-height: 1;
                        color: rgba(var(--primary), 1);
                    }
                }
            }
        }
    }
}

.language-section {
    .language-form {
        .language-box {
            @include flex_common ($dis: flex, $align: center, $justify: space-between);

            +.language-box {
                margin-top: 8px;
                padding-top: 8px;
                border-top: 1px solid #f1f1f1;

                [class="dark"] & {
                    border-color: rgba(119, 119, 119, 0.35);
                }
            }

            .form-check-input {
                margin: 0;
                cursor: pointer;
                position: relative;
                @include pseudowh($width: 18px, $height: 18px);
                border: none;
                vertical-align: unset;
                background: none;

                &:before {
                    @include pos;
                    @include pseudowh($width: 12px, $height: 8px);
                    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                    transform: rotate(-45deg) scale(0, 0);
                    left: 3px;
                    top: 2px;
                    z-index: 1;
                    border: 2px solid rgba(var(--title), 1);
                    border-top-style: none;
                    border-right-style: none;
                }

                &:focus {
                    box-shadow: none;
                }

                &:active {
                    filter: none;
                }

                &:checked {
                    background-color: transparent;
                    border-color: transparent;

                    &:before {
                        transform: rotate(-45deg) scale(1, 1);
                    }
                }
            }

            .form-check-label {
                position: relative;
                padding-left: 21px;
                width: 100%;

                [dir="rtl"] & {
                    padding-left: unset;
                    padding-right: 21px;
                }

                &::before {
                    @include pos;
                    top: 50%;
                    left: 7px;
                    @include pseudowh($width: 4px, $height: 4px);
                    border-radius: 100%;
                    background-color: rgba(var(--title), 1);

                    [dir="rtl"] & {
                        left: unset;
                        right: 0;
                    }

                    [class="dark"] & {
                        background-color: rgba(119, 119, 119, 0.35);
                    }
                }
            }
        }
    }
}

.language-list {
    padding-left: 22px;

    [dir="rtl"] & {
        padding-left: unset;
        padding-right: 22px;
    }

    li {
        list-style-type: disc;
        font-size: 14px;
        font-weight: 400;
        color: rgba(var(--title), 1);

        +li {
            margin-top: 8px;
            padding-top: 8px;
            border-top: 1px solid #f1f1f1;

            [class="dark"] & {
                border-top: 1px solid rgba(119, 119, 119, 0.2);
            }
        }
    }
}

.rating-sec {
    i {
        font-size: 20px;
        color: rgba(var(--theme-color), 1);
    }
}

.coupon-listing {
    margin-top: 22px;
    background-color: rgba(var(--white), 1);
    padding: 15px;
    box-shadow: 2px 3px 6px rgba(213, 213, 213, 0.19);
    border-radius: 10px;

    [class="dark"] & {
        background-color: #171717;
        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
    }

    [dir="rtl"] & {
        padding-right: 15px;
    }

    li {
        display: block;

        .coupon-box {
            .top-bar {
                display: flex;
                align-items: center;

                h4 {
                    text-transform: uppercase;
                    font-weight: 700;
                    margin-right: 10px;

                    [dir="rtl"] & {
                        margin-right: unset;
                        margin-left: 10px;
                    }
                }

                span {
                    background-color: #ebebeb;
                    padding: 1px 8px;
                    border-radius: 2px;

                    [class="dark"] & {
                        background-color: rgba(235, 235, 235, 0.188);
                    }
                }

                a {
                    text-transform: uppercase;
                    margin-left: auto;
                    font-size: 14px;
                    font-weight: 700;
                    color: rgba(var(--primary), 1);

                    [dir="rtl"] & {
                        margin-right: auto;
                        margin-left: 0;
                    }
                }
            }

            p {
                color: rgba(var(--content), 1);
                font-size: 14px;
                margin-bottom: 6px;
                margin-top: 9px;
                line-height: 1.45;
            }

            h6 {
                font-size: 12px;
            }
        }

        +li {
            padding-top: 20px;
            margin-top: 20px;
            border-top: 1px solid #eee;

            [class="dark"] & {
                border-top-color: rgba(235, 235, 235, 0.188);
            }
        }
    }
}