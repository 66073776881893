/**=====================
   4.1 Header CSS
==========================**/
/* ---------- Landing Header ---------- */
.landing-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    @include flex_common($dis: flex, $align: center, $justify: space-between);
    padding: 16px;

    .header-left {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 9px);
        align-items: center;

        a {
            border: none;
            font-size: 24px;
            line-height: 0;
            padding: 0;
            display: block;

            i {
                line-height: 1;
            }
        }
    }

    .header-right {
        .notification-box {
            position: relative;

            &::before {
                @include pos;
                animation: flash 2s linear infinite;
                background-color: rgba(var(--title), 1);
                border-radius: 100%;
                @include pseudowh($width: 5px, $height: 5px);
                right: 4px;
                top: 3px;
                z-index: 1;
            }

            a {
                border: none;
                font-size: 24px;
                line-height: 0;
                padding: 0;
                display: block;
                color: rgba(var(--title), 1);

                i {
                    line-height: 1;
                }
            }
        }
    }
}

/*=====================
    Learning Header CSS
==========================*/
header {
    &.learning-header {
        background-color: transparent;
        display: block;
        padding: 0;
        background-image: url(../images/learning/header-bg.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &.bg-image-none {
            background: none;
        }

        .top-header {
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 16px;

            .header-right {
                .notification-box {
                    &::before {
                        content: none;
                    }

                    &::after {
                        content: none;
                    }
                }
            }
        }

        .header-bottom {
            padding-top: 65px;

            &-2 {
                margin: 0;
                padding-top: 60px;
            }

            .customer-name {
                color: rgba(var(--white), 1);
                display: flex;
                align-items: center;

                h2 {
                    font-size: 24px;
                    font-weight: 700;

                    [class="dark"] & {
                        color: #fff;
                    }
                }

                img {
                    @include pseudowh($width: 20px, $height: 20px);
                    margin-left: 7px;
                    object-fit: contain;
                    transform-origin: 70% 70%;
                    animation-name: wave-animation;
                    animation-duration: 2.5s;
                    animation-iteration-count: infinite;

                    [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: 7px;
                    }
                }
            }

            h5 {
                font-weight: 500;
                color: rgba(var(--white), 1);
                margin-top: 5px;

                [class="dark"] & {
                    color: #fff;
                }
            }
        }

        .header-bottom-account {
            align-items: flex-end;
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 13px);
            height: 120px;
            padding: 15px;

            .profile-image {
                @include pseudowh($width: 80px, $height: 80px);
                border-radius: 8px;
                overflow: hidden;
                margin-bottom: -34px;
            }

            .profile-name {
                h4 {
                    margin-bottom: 4px;
                }

                h6 {
                    font-weight: 400;
                    margin: 0;
                }
            }
        }

        .search-box {
            position: relative;
            margin-top: 21px;
            margin-bottom: 32px;

            &-2 {
                margin-top: 68px;
            }

            &.index-search {
                padding-bottom: 32px;
                margin-bottom: 0;
            }

            .form-control {
                border-radius: 50px;
                border: none;
                padding: 8px 8px 8px 41px;
                font-size: 16px;

                @include mq-max(md) {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                }

                [class="dark"] & {
                    background-color: #121924;
                    color: #fff;
                }

                [dir="rtl"] & {
                    padding: 8px 41px 8px 8px;
                }
            }

            i {
                position: absolute;
                @include center(vertical);
                left: 15px;
                z-index: 0;
                font-size: 18px;
                color: rgba(var(--content), 1);

                [dir="rtl"] & {
                    left: unset;
                    right: 15px;
                }
            }
        }
    }

    &.locked-header {
        height: 216px;
        position: relative;

        .top-header {
            z-index: 1;
        }

        .video-header {
            .bg-overlay {
                position: absolute;
                top: 0;
                left: 0;
                @include pseudowh;
                background-color: rgba(var(--black), 0.6);

                [class="dark"] & {
                    background-color: rgba(34, 34, 34, 0.6);
                }
            }

            img {
                width: 100%;
            }

            .video-icon {
                @include center(both);
                @include pseudowh($width: 37px, $height: 37px);
                @include flex_common;
                position: absolute;
                border-radius: 100%;
                background-color: rgba(var(--white), 1);
                font-size: 20px;

                [class="dark"] & {
                    background-color: #fff;
                    color: #222;
                }
            }
        }
    }
}

/*=====================
    Chatting Header CSS
==========================*/
.chatting-header {
    padding-top: 15px;

    .header-box {
        display: flex;
        align-items: center;

        .arrow-box {
            font-size: 25px;
            @include pseudowh($width: 30px, $height: 30px);
            @include flex_common;
            margin-right: 4px;
            color: rgba(var(--title), 1);

            [class="dark"] & {
                color: #1f2937 !important;
            }

            [dir="rtl"] & {
                margin-right: unset;
                margin-left: 4px;
            }
        }

        .add-member {
            align-items: center;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
            margin-left: auto;
            color: rgba(var(--title), 1);

            [class="dark"] & {
                color: #1f2937 !important;
            }

            [dir="rtl"] & {
                margin-left: unset;
                margin-right: auto;
            }

            i {
                font-size: 20px;
            }
        }

        .calling-box {
            margin-left: auto;
            align-items: center;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 15px);

            [dir="rtl"] & {
                margin-left: unset;
                margin-right: auto;
            }

            @media (max-width: 600px) {
                gap: calc(7px + (15 - 7) * ((100vw - 320px) / (600 - 320)));
            }

            i {
                font-size: 23px;
                color: rgba(var(--title), 1);

                [class="dark"] & {
                    color: #1f2a36;
                }
            }
        }

        .header-profile {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
            align-items: center;
            color: rgba(var(--title), 1);

            [class="dark"] & {
                color: #1f2a36;
            }

            .profile-image {
                @include pseudowh($width: 52px, $height: 52px);
                overflow: hidden;
                border-radius: 100%;
                @include flex_common;
            }

            .name-contact {
                width: calc(100% - 52px - 3px);
                align-items: center;
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 3px);

                h5,
                h6 {
                    width: 100%;
                    color: rgba(var(--title), 1);
                }

                h6 {
                    font-size: 10px;
                }
            }

            .name-content {
                h5 {
                    margin-bottom: 4px;
                    font-size: 15px;
                }

                h6 {
                    font-weight: 400;
                    color: rgba(var(--content), 1);
                    font-size: 13px;

                    [class="dark"] & {
                        color: #545454;
                    }
                }
            }
        }

        .left-header {
            width: 86px;
            @include flex_common;
        }

        .right-header {
            margin-left: auto;
            align-items: center;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 7px);
            color: rgba(var(--title), 1);

            [dir="rtl"] & {
                margin-left: unset;
                margin-right: auto;
            }

            button {
                padding: 0;
                color: rgba(var(--title), 1);
                line-height: 1;

                [class="dark"] & {
                    color: #1f2a36;
                }
            }

            i {
                &.ri-search-line {
                    font-size: 23px;
                }

                &.ri-more-2-line {
                    font-size: 20px;
                }
            }

            .edit-option {
                .dropdown-menu {
                    border: none;

                    [class="dark"] & {
                        background-color: #1f2a36;
                    }

                    li {
                        .dropdown-item {

                            &:hover,
                            &:active,
                            &:focus {
                                background-color: transparent;
                            }

                            [class="dark"] & {
                                color: #fafafa;
                            }
                        }
                    }
                }
            }
        }

        .search-box {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            opacity: 0;
            visibility: hidden;
            transform: scale(0);
            transition: all 0.5s ease;
            padding: 10px 15px;

            &.show {
                opacity: 1;
                visibility: visible;
                transform: scale(1);
            }

            .close-search {
                position: absolute;
                right: 30px;
                @include center(vertical);
                font-size: 20px;
                cursor: pointer;

                [dir="rtl"] & {
                    right: unset;
                    left: 20px;
                }
            }

            .form-control {
                border: none;
                padding-right: 45px;
                padding-block: 9px;

                [class="dark"] & {
                    background-color: #1f2937;
                    color: #fafafa;
                }

                [dir="rtl"] & {
                    padding-right: inherit;
                    padding-left: 45px;
                }
            }
        }
    }
}

/*=====================
    Cab Header CSS
==========================*/
.cab-header {
    .log-box {
        margin-top: 42px;
        text-align: center;
    }
}

.cab-header-2 {
    position: absolute;
    top: 17px;
    left: 0;
    width: 100%;
    z-index: 1;

    .header-box {
        align-items: center;
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 10px);

        .header-left {
            @include pseudowh($width: 50px, $height: 50px);
            background-color: rgba(var(--white), 1);
            box-shadow: $shadow2;
            border-radius: 12px;
            font-size: 24px;
            @include flex_common;
            color: rgba(var(--title), 1);
            border: none;
            padding: 0;

            [class="dark"] & {
                background-color: #272d34;
            }

            @include mq-max(md) {
                @include pseudowh($width: calc(38px + (50 - 38) * ((100vw - 320px) / (600 - 320))),
                    $height: calc(38px + (50 - 38) * ((100vw - 320px) / (600 - 320))));
                border-radius: calc(8px + (12 - 8) * ((100vw - 320px) / (600 - 320)));
                font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (600 - 320)));
            }
        }

        .header-search-box {
            width: calc(100% - 50px);
            position: relative;

            form {
                .form-control {
                    box-shadow: $shadow2;
                    border-radius: 16px;
                    border: none;
                    padding: 13px 20px 13px 48px;
                    color: rgba(var(--title), 1);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 1;

                    @include mq-max(md) {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                    }

                    [class="dark"] & {
                        background-color: #272d33;
                    }

                    [dir="rtl"] & {
                        padding: 13px 48px 13px 20px;
                        text-align: right;
                    }

                    &::placeholder {
                        color: rgba(var(--title), 1);
                        font-weight: 400;
                        font-size: 16px;
                    }
                }

                i {
                    position: absolute;
                    @include center(vertical);
                    left: 18px;
                    font-size: 20px;
                    color: rgba(var(--content), 1);

                    [dir="rtl"] & {
                        left: unset;
                        right: 18px;
                    }
                }
            }
        }

        .header-title {
            width: calc(100% - 50px - 10px);
            margin-left: 10px;
        }
    }
}

/*=====================
    Food Header CSS
==========================*/
.header-style-3 {
    padding: 27px 0 0;

    &.js-header {
        &.is-active {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 2;

            .header-company-box {
                display: none;
            }
        }
    }

    .header-box {
        display: flex;
        margin-bottom: 17px;

        .left-box {
            align-items: center;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 5px);

            .header-icon {
                @include pseudowh($width: 28px, $height: 28px);
                @include flex_common;
                transform: scaleX(-1);

                i {
                    background: linear-gradient(270deg, #ffa000 -5.38%, #ffd54f 100%);
                    font-size: 20px;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .header-content {
                h4 {
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    color: rgba(var(--title), 1);
                    margin-bottom: 5px;

                    i {
                        line-height: 1;
                    }
                }

                h6 {
                    font-weight: 400;
                    font-size: 13px;
                    color: rgba(var(--content), 1);
                }
            }
        }

        .right-box {
            margin-left: auto;
            font-size: 18px;

            [dir="rtl"] & {
                margin-left: unset;
                margin-right: auto;
            }

            .header-icon {
                color: rgba(var(--title), 1);
            }
        }
    }

    &.header-bg {
        background-image: url(../svg/header-shape.svg);
        height: 70px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        padding-top: 23px;

        @include mq-max(md) {
            height: calc(63px + (70 - 63) * ((100vw - 320px) / (600 - 320)));
            padding-top: calc(14px + (23 - 14) * ((100vw - 320px) / (600 - 320)));
        }
    }

    .header-company-box {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
        margin-top: 8px;
        align-items: center;

        .company-image {
            @include pseudowh($width: 75px, $height: 75px);
            @include flex_common;
            background-color: rgba(var(--white), 1);
            padding: 7px;
            border-radius: 6px;

            [class="dark"] & {
                background-color: #fff;
            }

            img {
                @include pseudowh;
                object-fit: contain;
            }
        }

        .company-detail {
            width: calc(100% - 75px - 8px);
            color: rgba(var(--white), 1);

            [class="dark"] & {
                color: #fff;
            }

            h4 {
                font-weight: 600;
                margin-bottom: 5px;
            }

            p {
                font-weight: 400;
                color: #fff4d9;
                width: 70%;
                margin-bottom: 10px;
            }

            .dollar-rating {
                align-items: center;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);

                @include mq-max(md) {
                    gap: calc(5px + (8 - 5) * ((100vw - 320px) / (600 - 320)));
                }

                li {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 4px);
                    align-items: center;
                    font-weight: 400;
                    font-size: 13px;

                    [class="dark"] & {
                        color: #fff;
                    }

                    i {
                        font-size: 14px;
                    }

                    &.dots {
                        @include pseudowh($width: 4px, $height: 4px);
                        background-color: rgba(var(--white), 1);
                        border-radius: 100%;

                        [class="dark"] & {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }

    .header-box-2 {
        align-items: center;
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);

        .header-social {
            align-items: center;
            display: flex;
            gap: 10px;

            li {
                a {
                    font-size: 18px;
                    color: rgba(var(--white), 1);

                    [class="dark"] & {
                        color: #fff;
                    }
                }
            }
        }

        .header-icon {
            @include pseudowh($width: 30px, $height: 30px);
            @include flex_common;
            font-size: 16px;
            color: rgba(var(--white), 1);

            [class="dark"] & {
                color: #fff;
            }

            i {
                font-size: 20px;
            }
        }

        .header-title {
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            width: calc(100% - 30px - 11px);

            h3 {
                font-weight: 600;
                font-size: 18px;
                color: rgba(var(--white), 1);

                [class="dark"] & {
                    color: #fff;
                }
            }

            i {
                font-size: 18px;
                color: rgba(var(--white), 1);
                line-height: 1;

                [class="dark"] & {
                    color: #fff;
                }
            }
        }
    }
}

.audio-video-menu-section {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    padding: 0 15px 22px;
    max-width: 600px;
    z-index: 9;

    .mobile-list {
        background-color: rgba(var(--grey), 1);
        border-radius: 50px;
        padding: 9px calc(39px + (54 - 39) * ((100vw - 320px) / (600 - 320)));
        @include flex_common($dis: flex, $align: center, $justify: space-between);

        [class="dark"] & {
            background-color: #374151;
        }

        li {
            a {
                text-align: center;
                color: rgba(var(--title), 1);
                display: block;

                &.end-call {
                    @include pseudowh($width: 44px, $height: 44px);
                    background-color: #a72c2c;
                    border-radius: 100%;
                    @include flex_common;
                    color: #ffffff;

                    i {
                        transform: rotate(135.84deg);
                    }
                }

                i {
                    font-size: 24px;
                    line-height: 1;
                    @include pseudowh($width: 24px, $height: 24px);
                    @include flex_common;
                    margin: 0 auto;

                    &.active-icon {
                        display: none;
                    }
                }

                h5 {
                    font-weight: 400;
                    font-size: 14px;
                    margin-top: 4px;
                }
            }
        }
    }
}

/* ---------- Header Style 4 ---------- */
.header-style-4 {
    padding-top: 16px;
    padding-bottom: 16px;
    z-index: 2;
    position: relative;

    .header-left {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);
        align-items: center;

        button {
            color: rgba(var(--title), 1);
            line-height: 1;

            &:active {
                border-color: transparent;
            }
        }

        i {
            font-size: 19px;
        }
    }

    .header-right {
        text-align: right;
        display: block;
        color: rgba(var(--title), 1);
        line-height: 1;

        [dir="rtl"] & {
            text-align: left;
        }

        i {
            font-size: 18px;
            position: relative;

            &::after {
                @include pos;
                top: 0px;
                right: 3px;
                @include pseudowh($width: 5px, $height: 5px);
                background-color: rgba(var(--danger-color), 1);
                border-radius: 100%;
                animation: flash 2s linear infinite;
            }
        }
    }

    .top-header {
        background: var(--gradient-color);
        box-shadow: 0px 4px 9px rgba(var(--black), 0.1);
        border-radius: 0px 0px 15px 15px;
        color: rgba(var(--white), 1);
        padding: 31px 0 11px;
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        position: fixed;
        top: 0;
        @include center(horizontal);
        width: 100%;
        max-width: 600px;

        [class="dark"] & {
            color: #ffffff;
        }

        .left-header {
            align-items: center;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);

            @include mq-max(md) {
                gap: calc(6px + (9 - 6) * ((100vw - 320px) / (600 - 320)));
            }

            a {
                line-height: 1;

                i {
                    font-size: 28px;
                    line-height: 1;
                    color: rgba(var(--white), 1);

                    @include mq-max(md) {
                        font-size: calc(23px + (28 - 23) * ((100vw - 320px) / (600 - 320)));
                    }

                    [class="dark"] & {
                        color: #fff;
                    }
                }
            }

            .name-date {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);
                align-items: center;

                @include mq-max(md) {
                    font-size: calc(6px + (10 - 6) * ((100vw - 320px) / (600 - 320)));
                }

                [class="dark"] & {
                    color: #fff;
                }

                li {
                    font-weight: 500;
                    font-size: 16px;

                    @include mq-max(md) {
                        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
                    }

                    &.dots {
                        @include pseudowh($width: 4px, $height: 4px);
                        background-color: rgba(var(--white), 1);
                        border-radius: 100%;

                        [class="dark"] & {
                            background-color: #fff;
                        }

                        [class="dark"] & {
                            color: #fff;
                        }
                    }
                }
            }

            &-2 {
                >div {
                    h4 {
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .name-date {
                        li {
                            font-weight: 400;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .right-header {
            align-items: center;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 6px);
            color: rgba(var(--white), 1);

            [class="dark"] & {
                color: #fff;
            }

            i {
                font-size: 15px;
            }

            h5 {
                font-weight: 400;
                font-size: 15px;
            }
        }
    }

    .bottom-header {
        background-color: rgba(var(--white), 1);
        box-shadow: 0px 2px 7px rgba(var(--black), 0.04);
        border-radius: 0px 0px 15px 15px;
        padding-top: 85px;
        padding-bottom: 12px;

        [class="dark"] & {
            background-color: #1f222b;
        }

        .filter-list {
            display: flex;
            align-items: center;

            li {
                width: 100%;
                text-align: center;

                +li {
                    border-left: 1px solid rgba(var(--content), 0.6);

                    [dir="rtl"] & {
                        border-right: 1px solid rgba(var(--content), 0.6);
                        border-left: unset;
                    }

                    [class="dark"] & {
                        border-left-color: rgba(var(--content), 0.2);
                    }
                }

                a {
                    @include flex_common;
                    gap: 5px;
                    color: rgba(var(--title), 1);

                    h4 {
                        margin-top: 3px;

                        @include mq-max(md) {
                            font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
                        }
                    }

                    i {
                        font-size: 19px;
                        line-height: 1;

                        @include mq-max(md) {
                            font-size: calc(16px + (19 - 16) * ((100vw - 320px) / (600 - 320)));
                        }
                    }
                }
            }
        }

        .search-form {
            position: relative;

            i {
                position: absolute;
                @include center(vertical);
                font-size: 16px;
                line-height: 1;
                color: rgba(var(--title), 1);

                &.target-icon {
                    color: rgba(var(--content), 1);
                    right: 0;

                    [dir="rtl"] & {
                        right: unset;
                        left: 0;
                    }
                }

                &.search-icon {
                    margin-top: -1px;
                }
            }

            .form-control {
                border: none;
                padding-inline: 25px;

                [dir="rtl"] & {
                    text-align: right;
                }
            }
        }
    }
}

.header-style-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding-top: 25px;

    .custom-container {
        @include flex_common($dis: flex, $align: center, $justify: space-between);

        .left-header {
            a {
                @include pseudowh($width: 28px, $height: 28px);
                background-color: rgba(var(--white), 1);
                box-shadow: 0px 0px 4px rgba(83, 83, 83, 0.25);
                border-radius: 100%;
                @include flex_common;
                font-size: 17px;
                color: rgba(var(--title), 1);

                [class="dark"] & {
                    background-color: #1f222a;
                }
            }
        }

        .right-right {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);
            align-items: center;

            li {
                a {
                    @include pseudowh($width: 28px, $height: 28px);
                    background-color: rgba(var(--white), 1);
                    box-shadow: 0px 0px 4px rgba(83, 83, 83, 0.25);
                    border-radius: 100%;
                    @include flex_common;
                    font-size: 17px;
                    color: rgba(var(--title), 1);

                    [class="dark"] & {
                        background-color: #1f222a;
                    }

                    i {
                        line-height: 1;

                        &.ri-heart-3-fill {
                            color: #ff3200;
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Header Style 5 ---------- */
.header-style-5 {
    position: relative;
    padding: 16px 15px;
    @include flex_common($dis: flex, $align: center, $justify: space-between);

    .header-left {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);
        align-items: center;

        button {
            padding: 0;
            @include pseudowh($width: unset, $height: unset);
            line-height: 1;
            margin: 0;
            border: none;
            color: rgba(var(--title), 1);

            &:active {
                border-color: transparent;
            }
        }

        .header-title {
            font-weight: 600;
            color: rgba(var(--title), 1);
        }

        i {
            font-size: 19px;
        }
    }

    .header-right {
        @include flex_common($dis: flex, $align: center, $justify: flex-end);
        gap: 15px;

        a {
            font-size: 20px;
            color: rgba(var(--title), 1);

            &.notification {
                position: relative;

                &::after {
                    @include pos;
                    top: 5px;
                    right: 3px;
                    @include pseudowh($width: 6px, $height: 6px);
                    background-color: rgba(var(--theme-color), 1);
                    border-radius: 100%;
                    animation: flash 2s linear infinite;
                }

                &::before {
                    @include pos;
                    top: 4px;
                    right: 3px;
                    @include pseudowh($width: 6px, $height: 6px);
                    background-color: rgba(var(--white), 1);
                    border-radius: 100%;

                    [class="dark"] & {
                        background-color: #14141b;
                    }
                }
            }
        }
    }
}

/* ---------- Header Style 6 ---------- */
.header-style-6 {
    padding: 17px 15px 13px;
    @include flex_common ($dis: flex, $align: center, $justify: space-between);

    .right-icon {
        color: rgba(var(--title), 1);
        font-size: 20px;
        line-height: 1;
    }

    &.header-absolute {
        position: absolute;
        top: 0;
        left: 0;
        width: -webkit-fill-available;
        padding: 19px 15px 0;
        z-index: 1;

        .arrow-box {
            @include pseudowh($width: 28px, $height: 28px);
            background-color: rgba(var(--white), 1);
            border-radius: 100%;
            display: grid;
            place-items: center;
            box-shadow: 0px -1px 17px rgba(var(--black), 0.05);
            color: rgba(var(--title), 1);

            i {
                line-height: 1;
                font-size: 16px;
            }
        }

        .right-icon-list {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .header-title-name {
        h3 {
            font-weight: 600;
        }
    }

    .header-title {
        display: flex;
        align-items: center;

        a {
            line-height: 1;
            color: rgba(var(--title), 1);

            i {
                font-size: 22px;
                line-height: 1;
            }
        }
    }

    .icon-arrow {
        font-size: 20px;
        line-height: 1;
        color: rgba(var(--title), 1);
        display: inherit;

        i {
            line-height: 1;
        }
    }

    .left-header {
        display: flex;
        align-items: center;
        gap: 12px;

        .menu-btn {
            padding: 0;
            border: none;
            line-height: 1;
            display: flex;
            color: rgba(var(--title), 1);
            background-color: transparent;
            width: auto;

            i {
                font-size: 20px;
                line-height: 1;
            }
        }

        a {
            @include pseudowh($width: auto, $height: 30px);
            display: flex;
            align-items: center;

            @include mq-max(md) {
                height: calc(27px + (30 - 27) * ((100vw - 320px) / (600 - 320)));
            }

            img {
                @include pseudowh;
            }
        }
    }

    .right-header {
        display: flex;
        align-items: center;
        gap: 11px;

        .location-btn {
            padding: 0;
            width: auto;
            border: none;
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--content), 1);
            gap: 6px;

            @include mq-max(md) {
                gap: calc(3px + (6 - 3) * ((100vw - 320px) / (600 - 320)));
                font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
            }
        }

        .profile-image {
            @include pseudowh($width: 38px, $height: 38px);
            border-radius: 100%;
            overflow: hidden;
        }
    }

    &.search-header {
        gap: 15px;
        padding: 19px 15px 0;
        margin: 0;
        position: relative;
        inset: unset;
        width: 100%;

        .arrow-box {
            box-shadow: none;
            width: calc(12% - 15px);

            [class="dark"] & {
                background-color: transparent;
                color: #fff;
            }
        }

        .search-input {
            position: relative;
            width: 88%;

            input {
                background: rgba(var(--white), 1);
                box-shadow: 0px -1px 17px rgba(0, 0, 0, 0.05);
                border: none;
                border-radius: 5px;
                padding: 12px 35px;

                @include mq-max(md) {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                }
            }

            .search-icon {
                position: absolute;
                left: 10px;
                @include center(vertical);
                font-size: 20px;
                color: rgba(var(--content-dark), 1);

                @media (max-width: 1012px) {
                    display: none;
                }
            }

            .mic-icon {
                position: absolute;
                right: 10px;
                @include center(vertical);
                font-size: 20px;
                color: rgba(var(--content-dark), 1);
            }
        }
    }
}

/* ---------- Header Style 7 ---------- */
.header-style-7 {
    margin-bottom: 28px;

    .header-box {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        padding-top: 23px;

        .left-header {
            h2 {
                font-weight: 500;
                color: #ffffff;
                margin-bottom: 6px;

                span {
                    font-size: 19px;
                    line-height: 1;
                    transform-origin: 70% 70%;
                    animation-name: wave-animation;
                    animation-duration: 2.5s;
                    animation-iteration-count: infinite;
                }
            }

            h5 {
                font-weight: 400;
                color: #c4c4c4;
            }
        }

        .right-header {
            a {
                @include pseudowh($width: 46px, $height: 46px);
                border-radius: 100%;
                overflow: hidden;
                display: block;
            }
        }
    }
}

.profile-header {
    position: relative;
    padding: 40px 0;
    overflow: hidden;
    border-bottom: 1px solid #636363;
    border-radius: 0 0 10px 10px;

    &::after {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh;
        background-image: url(../images/financial/bg.gif);
        background-repeat: no-repeat;
        z-index: -2;
        opacity: 0.9;
    }

    &:before {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh;
        z-index: -1;
        background-color: rgba(var(--theme-maroon), 0.9);
    }

    .header-profile-box {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);

        .header-left {
            display: flex;
            align-items: center;
            gap: 11px;

            .profile-image {
                @include pseudowh($width: 60px, $height: 60px);
                border-radius: 100%;
                overflow: hidden;
            }

            .profile-name {
                h4 {
                    font-size: 15px;
                    color: rgba(var(--white), 1);
                }

                h5 {
                    font-weight: 400;
                    font-size: 13px;
                    margin: 7px 0;
                    color: #c4c4c4;
                }

                h6 {
                    font-weight: 400;
                    font-size: 13px;
                    color: #c4c4c4;
                }
            }
        }

        .header-right {
            text-align: center;
            color: rgba(var(--white), 1);

            i {
                font-size: 28px;
            }

            h6 {
                font-weight: 400;
                font-size: 10px;
                color: rgba(var(--white), 1);
                margin-top: 6px;
            }
        }
    }
}

.scanner-header {
    padding: 15px;
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    a {
        color: #c4c4c4;
        line-height: 1;

        &.close-icon {
            font-size: 30px;
        }
    }

    .camera {
        padding: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        font-size: 24px;
        color: #c4c4c4;
    }

    .flash-light {
        padding: 0;
        line-height: 1;
        border: none;
        background-color: transparent;
        font-size: 24px;
        color: #c4c4c4;
    }

    .right-box {
        display: flex;
        align-items: center;
        gap: 8px;

        a {
            font-size: 24px;
        }
    }
}

.financial-profile-header {
    padding: 12px 0;
    border-radius: 0px 0px 9px 9px;
    position: relative;
    overflow: hidden;

    &::after {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh;
        background-image: url(../images/financial/bg.gif);
        background-repeat: no-repeat;
        z-index: -2;
        opacity: 0.9;
    }

    &:before {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh;
        z-index: -1;
        background-color: rgba(var(--theme-maroon), 0.9);
    }

    .header-box {
        display: flex;
        align-items: center;
        gap: 7px;

        .arrow-box {
            font-size: 24px;
            @include flex_common;

            i {
                color: #ffffff;
            }
        }

        .header-profile {
            display: flex;
            gap: 11px;
            align-items: center;

            .profile-image {
                @include pseudowh($width: 42px, $height: 42px);
                overflow: hidden;
                border-radius: 100%;
                @include flex_common;
            }

            .name-content {
                h5 {
                    color: rgba(var(--white), 1);
                    margin-bottom: 4px;
                    font-size: 15px;
                }

                h6 {
                    font-weight: 400;
                    color: #c4c4c4;
                }
            }
        }
    }

    &.financial-small-header {
        padding: 19px 0;

        .header-box {
            gap: 9px;

            .arrow-box {
                font-size: 18px;
            }
        }
    }
}

/* ---------- Header Style 9 ---------- */
.header-style-9 {
    background-image: url(../images/fitness/header-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px 0px 20px 20px;
    height: 166px;
    color: #fff;
    padding: 20px 15px 0;
    position: relative;
    z-index: 0;
    margin-bottom: 32px;

    &::before {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh($width: 100%, $height: 104px);
        background: linear-gradient(180deg, #2f2f2f 0%, rgba(78, 78, 78, 0) 100%);
        z-index: -1;
    }

    .left-header {
        display: flex;
        gap: 10px;

        i {
            font-size: 22px;
            line-height: 1;

            @include mq-max(md) {
                font-size: calc(19px + (22 - 19) * ((100vw - 320px) / (600 - 320)));
            }
        }

        .header-contain {
            h4 {
                font-weight: 500;
                font-size: 18px;

                @include mq-max(md) {
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
                }
            }

            h6 {
                margin-top: 4px;
                font-size: 15px;

                @include mq-max(md) {
                    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }
    }
}

.exercise-video-header {
    background: linear-gradient(180deg, #000000 0%, #000000 0.01%, rgba(0, 0, 0, 0) 100%);
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .exercise-header-box {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        padding: 22px 15px 31px;

        a {
            font-size: 22px;
            line-height: 1;
            color: #fff;
        }

        h4 {
            font-weight: 600;
        }

        .volume-icon {
            font-size: 24px;
            line-height: 1;
        }
    }
}

.fitness-fix-header {
    position: fixed;
    top: 0;
    @include center(horizontal);
    width: 100%;
    max-width: 600px;
    background: linear-gradient(180deg, #000000 0%, #000000 0.01%, rgba(0, 0, 0, 0) 100%);
    padding: 26px 15px;
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    color: rgba(var(--white), 1);

    [class="dark"] & {
        color: #fff;
    }

    a {
        font-size: 22px;
        color: rgba(var(--white), 1);
        line-height: 1;

        [class="dark"] & {
            color: #fff;
        }

        i {
            line-height: 1;
        }
    }

    h4 {
        font-weight: 600;
        line-height: 1;
    }
}

/* ---------- Header Style 10 ---------- */
.header-style-10 {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 18px;
    padding-inline: 15px;

    .arrow-box {
        font-size: 26px;
        color: rgba(var(--title), 1);
        line-height: 1;

        i {
            line-height: 1;
        }
    }

    .search-input {
        position: relative;
        width: calc(100% - 8px - 26px);

        .form-control {
            background-color: #fafafa;
            border-radius: 9px;
            width: 100%;
            border: none;
            padding-block: 17px;
            padding-inline: 41px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--title), 1);

            [class="dark"] & {
                background-color: #212325;
            }

            &::placeholder {
                color: rgba(var(--content), 1);
            }
        }

        i {
            font-size: 20px;
            position: absolute;
            @include center(vertical);
            color: #919191;

            &.search-icon {
                left: 15px;
            }

            &.mic-icon {
                right: 15px;
            }
        }
    }
}