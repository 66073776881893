/*=====================
   3.10 Social authentication CSS
==========================*/
/* ---------- Social style 1 ---------- */
.social-style-1 {
    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
    align-items: center;
    margin-top: 15px;

    a {
        display: block;
        padding: 11px;
        position: relative;
        background-color: transparent;
        color: rgba(var(--title), 1);
        border-radius: 5px;
        overflow: hidden;
        z-index: 0;

        &:hover {
            color: rgba(var(--title), 1);
        }

        &::before {
            @include pos;
            @include pseudowh;
            top: 0;
            left: 0;
            background: var(--gradient);
            opacity: 0.05;
            z-index: -1;

            [class="dark"] & {
                background: #fff;
            }
        }

        img {
            @include pseudowh($width: 20px, $height: 20px);
            object-fit: contain;
        }

        span {
            font-size: 14px;
            font-weight: 600;
            display: block;
            margin-top: 9px;
            line-height: 1;
        }
    }
}

/* ---------- Social style 2 ---------- */
.social-style-2 {
    margin-top: 18px;
    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 14px);

    li {
        width: 100%;

        a {
            @include flex_common;
            flex-wrap: nowrap;
            gap: 11px;
            background-color: #fafafa;
            border-radius: 14px;
            padding: 17px;
            color: rgba(var(--title), 1);

            img {
                @include pseudowh($width: 20px, $height: 20px);
            }

            h4 {
                font-weight: 400;
                font-size: 17px;

                @include mq-max(md) {
                    font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }
    }
}

/* ---------- Social style 3 ---------- */
.social-style-3 {
    margin-bottom: 145px;

    h3 {
        color: rgba(var(--title), 1);
        margin-bottom: 22px;
    }

    ul {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
        justify-content: center;

        li {
            &:first-child {
                a {
                    &.dark-box {
                        img {
                            filter: contrast(0.5);
                        }
                    }
                }
            }

            a {
                @include pseudowh($width: 54px, $height: 54px);
                background-color: rgba(var(--white), 1);
                @include flex_common;
                box-shadow: $shadow3;
                border-radius: 14px;

                &.dark-box {
                    background: #151515;
                    border: 1px solid #222222;
                    box-shadow: none;
                }
            }
        }
    }
}

/* ---------- Social style 4 ---------- */
.social-style-4 {
    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 14px);

    li {
        width: 100%;

        a {
            @include flex_common;
            flex-wrap: nowrap;
            gap: 11px;
            padding: 17px;
            color: rgba(var(--content), 1);
            border: 1px solid rgba(var(--title), 1);
            border-radius: 6px;
            background-color: transparent;

            img {
                @include pseudowh($width: 20px, $height: 20px);
            }

            h4 {
                font-weight: 400;
                font-size: 17px;

                @include mq-max(md) {
                    font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }
    }
}

/* ---------- Social style 5 ---------- */
.social-style-5 {
    display: flex;
    gap: 15px;
    flex-wrap: nowrap;

    li {
        width: 100%;

        a {
            border-radius: 5px;
            padding: 13px;
            color: rgba(var(--white), 1);
            @include flex_common;
            gap: 4px;

            [class="dark"] & {
                color: #fff;
            }

            &.google-bg {
                background-color: rgba(var(--google-color), 1);
            }

            &.fb-bg {
                background-color: rgba(var(--facebook-color), 1);
            }

            img {
                filter: invert(1) brightness(100);
                @include pseudowh($width: 18px, $height: 18px);
            }
        }
    }
}

/* ---------- Social style 6 ---------- */
.social-style-6 {
    ul {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 22px);
        justify-content: center;

        li {
            &:first-child {
                a {
                    img {
                        filter: contrast(0.5);
                    }
                }
            }

            a {
                @include pseudowh($width: 54px, $height: 54px);
                background-color: rgba(var(--white), 1);
                @include flex_common;
                box-shadow: none;
                border-radius: 14px;
                background: #151515;
                border: 1px solid #222222;
            }
        }
    }
}