/*===================== 
    5.4 Ecommerce Application CSS
==========================*/
.add-icon {
    background-color: rgba(var(--dark-bg), 0.8);
    @include pseudowh($width: 30px, $height: 30px);
    position: absolute;
    bottom: 8px;
    right: 8px;
    @include flex_common;
    border-radius: 100%;
    font-size: 16px;
    color: rgba(var(--white), 1);

    [dir="rtl"] & {
        right: unset;
        left: 8px;
    }

    [class="dark"] & {
        background-color: rgba(33, 35, 37, 0.8);
        color: #fff;
    }

    @include mq-max(md) {
        @include pseudowh($width: calc(26px + (30 - 26) * ((100vw - 320px) / (600 - 320))), $height: calc(26px + (30 - 26) * ((100vw - 320px) / (600 - 320))));
    }

    .wishlist-button {
        @include pseudowh($width: 16px, $height: 16px);
        margin: 0;
        position: relative;
        background: none;
        border: none;
        cursor: pointer;
        @include flex_common;

        &:focus {
            box-shadow: none;
        }

        &:active {
            filter: none;
        }

        &:before {
            @include pos($pos: relative, $content: "\ee0f");
            @include font;
            color: rgba(var(--white), 1);

            [class="dark"] & {
                color: #fff;
            }
        }

        &:checked[type=checkbox] {
            background: unset;
        }

        &:checked {
            &::before {
                content: "\ee0e";
            }
        }
    }
}

.add-icon-list {
    position: absolute;
    bottom: 9px;
    right: 9px;

    [dir="rtl"] & {
        right: unset;
        left: 9px;
    }

    li {
        +li {
            margin-top: 5px;
        }

        a {
            @include pseudowh($width: 24px, $height: 24px);
            border-radius: 100%;
            background-color: rgba(var(--dark-bg), 0.8);
            @include flex_common;
            color: rgba(var(--white), 1);
            line-height: 1;

            [class="dark"] & {
                background-color: rgba(33, 35, 37, 0.8);
                color: #fff;
            }
        }
    }
}

/* ---------- Home Banner CSS ---------- */
.ecommerce-banner {
    .banner-box {
        border-radius: 10px;
        overflow: hidden;
    }
}

/* ---------- Category CSS ---------- */
.cloth-category-section {
    .category-list {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        flex-wrap: wrap;
        gap: 10px;

        li {
            .category-box {
                display: block;
                text-align: center;

                img {
                    @include pseudowh($width: 58px, $height: 58px);
                    border-radius: 100%;
                    margin-bottom: 10px;

                    @include mq-max(md) {
                        @include pseudowh($width: calc(49px + (58 - 49) * ((100vw - 320px) / (600 - 320))), $height: calc(49px + (58 - 49) * ((100vw - 320px) / (600 - 320))));
                    }
                }

                h5 {
                    font-weight: 500;
                    font-size: 13px;
                    color: rgba(var(--content), 1);

                    @include mq-max(md) {
                        font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }
        }
    }
}

/* ---------- Product CSS ---------- */
.product-title {
    font-weight: 400;
    color: rgba(var(--content), 1);
    margin-bottom: 16px;
    font-weight: 400;
    color: rgba(var(--content), 1);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    a {
        @include pseudowh($width: 30px, $height: 30px);
        border-radius: 5px;
        background-color: #f7f7f7;
        font-size: 16px;
        @include flex_common;
        color: rgba(var(--title), 0.8);
        margin-left: auto;

        [class="dark"] & {
            background-color: #212325;
        }

        [dir="rtl"] & {
            margin-left: unset;
            margin-right: auto;
        }
    }
}

.product-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;

    .product-box {
        .product-image {
            border-radius: 7px;
            overflow: hidden;
            margin-bottom: 12px;
            position: relative;
        }

        .product-content {
            h5 {
                font-weight: 600;
                color: rgba(var(--title), 1);
            }

            ul {
                display: flex;
                align-items: center;
                gap: 4px;
                margin-top: 7px;
                margin-bottom: 14px;

                @include mq-max(md) {
                    margin-top: calc(2px + (7 - 2) * ((100vw - 320px) / (600 - 320)));
                    margin-bottom: calc(6px + (14 - 6) * ((100vw - 320px) / (600 - 320)));
                }

                li {
                    line-height: 1;

                    i {
                        color: rgb(237, 239, 244);

                        [class="dark"] & {
                            color: rgb(54, 57, 60);
                        }

                        &.fill {
                            color: rgba(var(--theme-color), 1);
                        }
                    }
                }
            }

            h6 {
                font-weight: 500;
                font-size: 14px;
                color: rgba(var(--title), 1);

                span {
                    color: rgba(var(--content), 1);
                    margin-left: 5px;
                }
            }
        }
    }
}

/* ---------- Brand CSS ---------- */
.brand-image-box {
    transition-timing-function: linear;

    [class="dark"] & {
        filter: brightness(0) invert(1);
    }

    .brand-image {
        text-align: center;
        padding: 5px 8px;
        background-color: #f8f8f8;
    }
}

/* ---------- Service CSS ---------- */
.service-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: 15px;

    .service-box {
        text-align: center;

        img {
            @include pseudowh($width: 58px, $height: 45px);
            object-fit: contain;
            background-color: #f9f9f9;
            padding: 5px;
            border-radius: 10px;

            [class="dark"] & {
                filter: brightness(0) invert(1);
            }
        }

        h6 {
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--title), 1);
            margin-top: 8px;
            line-height: 1.5;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
        }
    }
}

/* ---------- Category 2 CSS ---------- */
.category-list-2 {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 15px;

    li {
        .category-box {
            overflow: unset;
            display: block;
            text-align: center;
            padding: 0;

            img {
                @include pseudowh($width: 96px, $height: 96px);
                border-radius: 100%;
                margin-bottom: 10px;

                @include mq-max(md) {
                    @include pseudowh($width: calc(80px + (96 - 80) * ((100vw - 320px) / (600 - 320))), $height: calc(80px + (96 - 80) * ((100vw - 320px) / (600 - 320))));
                }
            }

            h5 {
                font-weight: 500;
                font-size: 14px;
                color: rgba(var(--content), 1);

                @include mq-max(md) {
                    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }
    }
}

/* ---------- Search List CSS ---------- */
.search-list {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;

    li {
        width: 100%;

        a {
            @include flex_common ($dis: flex, $align: center, $justify: space-between);
            color: rgba(var(--content), 1);
            font-size: 14px;
            font-weight: 400;

            h5 {
                font-size: 14px;
            }

            i {
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    &.search-bg-list {
        background-color: #f6f6f6;
        border-radius: 6px;
        padding: 15px;
        gap: 13px;

        li {
            a {
                color: rgba(var(--title), 1);

                h5 {
                    font-weight: 400;

                    span {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

/* ---------- Address Section CSS ---------- */
.ecommerce-address-section {
    .address-list {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        li {
            width: 100%;

            &.active {
                .address-box {
                    background-color: rgba(255, 186, 70, 0.05);
                    border: 1px solid rgba(var(--theme-color), 1);

                    [class="dark"] & {
                        background-color: rgba(255, 186, 70, 0.05);
                        border: 1px solid rgba(var(--theme-color), 1);
                    }

                    .address-name {
                        .address-icon {
                            background-color: rgba(var(--theme-color), 1);

                            [class="dark"] & {
                                background-color: rgba(var(--theme-color), 1);
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .address-box {
                padding: 16px 14px;
                border: 1px solid rgba(var(--light-bg), 0.6);
                border-radius: 5px;
                background-color: rgba(var(--light-bg), 0.6);

                [class="dark"] & {
                    border: 1px solid rgba(237, 239, 244, 0.05);
                    background-color: #212325;
                }

                .address-name {
                    position: relative;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    gap: 9px;
                    margin-bottom: 8px;

                    .address-icon {
                        background-color: rgba(var(--title), 0.5);
                        border-radius: 4px;
                        @include pseudowh($width: 28px, $height: 28px);
                        @include flex_common;
                        color: rgba(var(--white), 1);
                        font-size: 18px;

                        [class="dark"] & {
                            background-color: rgba(237, 239, 244, 0.05);
                            color: #eee;
                        }
                    }

                    h5 {
                        color: rgba(var(--content), 1);
                        font-size: 14px;
                    }

                    .edit-option {
                        position: absolute;
                        top: 0;
                        right: 0;

                        [dir="rtl"] & {
                            right: unset;
                            left: 0;
                        }

                        .dropdown-button {
                            padding: 0;

                            [class="dark"] & {
                                color: #fff;
                            }

                            &:active,
                            &.show {
                                border-color: transparent;
                            }
                        }

                        .dropdown-menu {
                            padding: 12px;
                            border: none;
                            box-shadow: 2px 2px 8px rgba(var(--black), 0.05);

                            [class="dark"] & {
                                background-color: #2b2d2f;
                            }

                            li {
                                +li {
                                    padding-top: 6px;
                                    margin-top: 6px;
                                    border-top: 1px solid rgba(237, 239, 244, 0.6);

                                    [class="dark"] & {
                                        border-color: rgba(237, 239, 244, 0.05);
                                    }
                                }

                                .dropdown-item {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    color: rgba(var(--title), 1);
                                    padding: 0;

                                    &:hover {
                                        background-color: transparent;
                                    }

                                    i {
                                        line-height: 1;
                                    }

                                    span {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }
                }

                .address-detail {
                    h5 {
                        font-weight: 500;
                        color: rgba(var(--title), 1);
                        font-size: 14px;
                    }

                    p {
                        font-weight: 400;
                        color: rgba(var(--content), 1);
                        line-height: 1.5;
                        margin-top: 6px;
                        margin-bottom: 8px;
                        font-size: 14px;
                    }

                    h6 {
                        font-weight: 400;
                        color: rgba(var(--content), 1);
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

/* ---------- Process To Next Step CSS ---------- */
.next-step {
    background-color: rgba(var(--dark-bg), 1);
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    max-width: 600px;
    width: 100%;
    padding: 12px 23px;
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    z-index: 1;

    [class="dark"] & {
        background-color: #212325;
    }

    @include mq-max(md) {
        padding-inline: calc(15px + (23 - 15) * ((100vw - 320px) / (600 - 320)));
    }

    >div {
        width: auto;
    }

    .left-box {
        h4 {
            color: rgba(var(--white), 0.6);
            margin-bottom: 3px;

            [class="dark"] & {
                color: #fff;
            }

            @include mq-max(md) {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
            }
        }

        h6 {
            a {
                color: rgba(var(--content), 1);
                text-decoration: underline;
            }
        }
    }

    .continue-button {
        padding: 0;
        font-size: 16px;
        color: rgba(var(--white), 1);

        @include mq-max(md) {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
        }
    }
}

/* ---------- Product Image CSS ---------- */
.product-image-slider {
    .swiper-pagination {
        background-color: rgba(var(--white), 1);
        border-radius: 13px;
        @include pseudowh($width: auto, $height: 12px);
        @include center(horizontal);
        @include flex_common;
        width: 52px;

        [class="dark"] & {
            background-color: #14141b;
        }

        .swiper-pagination-bullet {
            background-color: rgba(var(--white), 1);
            border-radius: 100px;
            @include pseudowh($width: 6px, $height: 6px);
            margin-inline: 2px;
            border: 1px solid rgba(var(--title), 1);
            opacity: 1;
            transition: all 0.5s ease;

            [class="dark"] & {
                background-color: #212325;
                border-color: #212325;
            }

            &-active {
                background: var(--theme-gradient-color);
                border-radius: 100px;
                @include pseudowh($width: 25px, $height: 6px);
                margin-inline: 2px;
                border-color: transparent;
            }
        }
    }

    &-bg {
        border-radius: 8px;
        overflow: hidden;

        .swiper-pagination {
            .swiper-pagination-bullet {
                &-active {
                    background-color: rgba(var(--theme-color), 1);
                }
            }
        }
    }
}

/* ---------- Product Details CSS ---------- */
.product-detail-section {
    z-index: 0;
    position: relative;
}

.product-name-box {
    display: flex;
    justify-content: space-between;

    .product-name {
        h4 {
            font-weight: 600;
            color: rgba(var(--title), 1);
            text-transform: capitalize;
            margin-bottom: 6px;
            line-height: 1.5;
        }

        .rating-box {
            display: flex;
            align-items: center;

            .rating {
                gap: 4px;

                li {
                    line-height: 1;

                    i {
                        font-size: 15px;
                        color: rgb(237, 239, 244);

                        [class="dark"] & {
                            color: #212325;
                        }

                        &.fill-color {
                            color: rgba(var(--theme-color), 1);
                        }
                    }
                }
            }

            span {
                font-weight: 400;
                font-size: 12px;
                color: rgba(var(--content), 1);
                margin-left: 7px;

                [dir="rtl"] & {
                    margin-left: unset;
                    margin-right: 7px;
                }
            }
        }
    }

    .product-price {
        text-align: right;

        [dir="rtl"] & {
            text-align: left;
        }

        h3 {
            font-weight: 600;
            color: rgba(var(--theme-color), 1);
            text-transform: capitalize;
            margin-bottom: 2px;
        }

        h6 {
            font-weight: 400;
            text-decoration-line: line-through;
            text-transform: capitalize;
            color: rgba(var(--content), 1);
        }
    }
}

.product-color {
    margin-top: 23px;
    display: flex;
    align-items: center;

    h4 {
        margin-right: 11px;
        color: rgba(var(--title), 1);

        [dir="rtl"] & {
            margin-right: unset;
            margin-left: 11px;
        }

        @include mq-max(md) {
            font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (600 - 320)));
        }
    }

    .thumbs-image {
        width: -webkit-fill-available;
        margin: 0;

        .swiper-wrapper {
            [dir="rtl"] & {
                justify-content: flex-end;
            }

            .swiper-slide {
                width: auto !important;

                img {
                    @include pseudowh($width: 75px, $height: 75px);
                    border: 2px solid transparent;
                    border-radius: 5px;
                    object-fit: cover;
                    opacity: 0.5;
                    transition: 0.3s ease;

                    @include mq-max(md) {
                        @include pseudowh($width: calc(55px + (75 - 55) * ((100vw - 320px) / (600 - 320))), $height: calc(55px + (75 - 55) * ((100vw - 320px) / (600 - 320))));
                    }
                }

                &.swiper-slide-thumb-active {
                    img {
                        border-color: rgba(255, 186, 70, 1);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.product-size {
    margin-top: 17px;
    display: flex;
    align-items: center;

    h4 {
        color: rgba(var(--title), 1);
        margin-right: 22px;

        [dir="rtl"] & {
            margin-right: unset;
            margin-left: 22px;
        }

        @include mq-max(md) {
            font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (600 - 320)));
        }
    }

    .size-list {
        display: flex;
        gap: 10px;
        align-items: center;

        li {
            &.active {
                a {
                    background: var(--theme-gradient-color);
                    color: rgba(var(--white), 1);
                }
            }

            a {
                @include pseudowh($width: 40px, $height: 40px);
                background-color: rgba(var(--light-bg), 1);
                border-radius: 5px;
                @include flex_common;
                font-size: 15px;
                color: rgba(var(--title), 1);
                font-weight: 500;

                [class="dark"] & {
                    background-color: #212325;
                }

                @include mq-max(md) {
                    @include pseudowh($width: calc(33px + (40 - 33) * ((100vw - 320px) / (600 - 320))), $height: calc(33px + (40 - 33) * ((100vw - 320px) / (600 - 320))));
                    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }
    }
}

/* ---------- Cart CSS ---------- */
.cart-list {
    li {
        +li {
            margin-top: 15px;
        }

        .cart-box {
            background-color: rgba(var(--light-bg), 0.6);
            padding: 8px 10px;
            display: flex;
            align-items: center;
            gap: 8px;

            [class="dark"] & {
                background-color: #212325;
            }

            .product-image {
                @include pseudowh($width: 80px, $height: 80px);
                border-radius: 5px;
                overflow: hidden;
            }

            .product-content {
                width: calc(100% - 80px - 8px);
                @include flex_common ($dis: flex, $align: stretch, $justify: space-between);
                gap: 14px;

                @include mq-max(md) {
                    gap: calc(6px + (14 - 6) * ((100vw - 320px) / (600 - 320)));
                }

                h5 {
                    @include mq-max(md) {
                        font-size: 14px;
                    }

                    &.name {
                        color: rgba(var(--title), 1);
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                    }

                    &.size {
                        font-weight: 400;
                        color: rgba(var(--content), 1);
                        margin-top: 6px;
                        margin-bottom: 13px;

                        @include mq-max(md) {
                            margin-bottom: calc(9px + (13 - 9) * ((100vw - 320px) / (600 - 320)));
                        }
                    }

                    &.qty {
                        color: rgba(var(--content), 1);
                        font-weight: 400;
                    }

                    &.price {
                        color: rgba(var(--title), 1);
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 11px;

                        @include mq-max(md) {
                            gap: calc(5px + (11 - 5) * ((100vw - 320px) / (600 - 320)));
                        }

                        a {
                            text-decoration-line: underline;
                            font-size: 13px;

                            @include mq-max(md) {
                                font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (600 - 320)));
                            }
                        }
                    }
                }

                .option {
                    @include flex_common ($dis: flex, $align: center, $justify: flex-end);
                    gap: 5px;

                    li {
                        line-height: 1;

                        +li {
                            margin: 0;
                        }

                        a {
                            font-size: 16px;
                            color: rgba(var(--title), 1);
                            display: block;
                        }
                    }
                }

                h4 {
                    margin-top: 32px;
                    font-weight: 600;
                    color: rgba(var(--theme-color), 1);
                }

                .product-label {
                    font-size: 10px;
                    color: rgba(var(--title), 1);
                    padding: 5px 13px;
                    background-color: rgba(var(--white), 1);
                    border-radius: 3px;

                    [class="dark"] & {
                        background-color: rgba(237, 239, 244, 0.05);
                    }

                    @include mq-max(md) {
                        padding-inline: calc(8px + (13 - 8) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }
        }
    }
}

/* ---------- Product Price CSS ---------- */
.order-detail-box {
    margin-top: 17px;
    padding-top: 17px;
    border-top: 1px solid rgba(var(--light-bg), 1);

    [class="dark"] & {
        border-top-color: rgba(var(--light-bg), 0.05);
    }

    &-2 {
        background-color: rgba(var(--light-bg), 0.6);
        border-radius: 8px;
        padding: 13px 15px;
        margin: 0;
        border: none;

        [class="dark"] & {
            background-color: #212325;
        }
    }

    .product-title {
        margin-bottom: 10px;

        h4 {
            color: rgba(var(--title), 1);
        }
    }

    .order-price-list {
        display: flex;
        flex-wrap: wrap;
        gap: 13px;

        @include mq-max(md) {
            gap: calc(8px + (13 - 8) * ((100vw - 320px) / (600 - 320)));
        }

        li {
            width: 100%;

            &.total-price {
                .order-price-box {
                    .name {
                        font-weight: 500;
                        color: rgba(var(--title), 1);

                        [class="dark"] & {
                            color: #fff;
                        }
                    }

                    .price {
                        font-weight: 600;
                        color: rgba(var(--theme-color), 1);
                    }
                }
            }

            .order-price-box {
                @include flex_common ($dis: flex, $align: center, $justify: space-between);

                h4 {
                    font-weight: 400;
                    font-size: 15px;

                    &.name {
                        color: rgba(var(--content), 1);
                    }

                    &.price {
                        color: rgba(var(--title), 1);

                        &.success {
                            color: rgba(var(--success), 1);
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Order Success CSS ---------- */
.ecommerce-order-success-section {
    padding-top: 60px;
    text-align: center;

    .order-success-image {
        @include pseudowh($width: 106px, $height: 106px);
        margin: 0 auto 16px;
    }

    .success-content {
        h2 {
            font-weight: 600;
            color: rgba(var(--title), 1);
            margin-bottom: 7px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--content), 1);
            margin: 0;
        }
    }
}

/* ---------- Order Details CSS ---------- */
.order-detail-list {
    li {
        +li {
            margin-top: 18px;
        }

        h4 {
            margin-bottom: 8px;
            color: rgba(var(--title), 1);
        }

        p {
            font-weight: 400;
            color: rgba(var(--content), 1);
            line-height: 1.5;
            margin: 0;
            font-size: 14px;

            [class="dark"] & {
                border-top-color: rgba(237, 239, 244, 0.05);
            }
        }
    }
}

.order-summary-box {
    padding-top: 17px;
    margin-top: 26px;
    border-top: 1px solid #f7f7f7;

    [class="dark"] & {
        border-top-color: rgba(237, 239, 244, 0.05);
    }

    .order-summary-list {
        li {
            +li {
                margin-top: 16px;
            }

            .summary-product {
                display: flex;
                align-items: center;
                gap: 8px;

                .product-image {
                    @include pseudowh($width: 80px, $height: 80px);
                    border-radius: 5px;
                    overflow: hidden;
                }

                .product-content {
                    width: calc(100% - 80px - 8px);

                    .name {
                        color: rgba(var(--title), 1);
                        font-size: 14px;
                    }

                    .qty {
                        font-weight: 400;
                        color: #919191;
                        margin-top: 6px;
                        margin-bottom: 13px;
                        font-size: 14px;
                    }

                    h4 {
                        font-weight: 600;
                        color: #ffba46;
                    }
                }
            }
        }
    }
}

/* ---------- Banner CSS ---------- */
.setting-box {
    .profile-image {
        padding: 0;

        .sidebar-profile {
            width: 100%;
            margin-bottom: 30px;

            .profile-image {
                @include pseudowh($width: 92px, $height: 92px);
                border-radius: 100%;
                overflow: hidden;
                margin-inline: auto;
                margin-bottom: 11px;
            }
        }

        .profile-name {
            text-align: center;
            color: rgba(var(--title), 1);

            h4 {
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 7px;
            }

            h5 {
                font-weight: 400;
            }
        }
    }

    .sidebar-list {
        li {
            +li {
                margin-top: 18px;
                padding-top: 18px;
                border-top: 1px solid rgba(237, 239, 244, 0.6);

                [class="dark"] & {
                    border-top-color: rgba(237, 239, 244, 0.05);
                }

                @include mq-max(md) {
                    margin-top: calc(9px + (18 - 9) * ((100vw - 320px) / (600 - 320)));
                    padding-top: calc(9px + (18 - 9) * ((100vw - 320px) / (600 - 320)));
                }
            }

            a {
                color: rgba(var(--title), 1);
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                gap: 14px;

                h5 {
                    font-size: 16px;
                }

                i {
                    font-size: 20px;
                }
            }
        }
    }
}

/* ---------- Order Tracking CSS ---------- */
.order-tracking-list {
    margin-left: 17px;

    @include mq-max(md) {
        margin-left: calc(4px + (17 - 4) * ((100vw - 320px) / (600 - 320)));
    }

    li {
        &:last-child {
            .order-box {
                height: auto;
                align-items: center;
                opacity: 0.6;

                .left-icon {
                    &:after {
                        content: none;
                    }
                }

                .right-content {
                    padding: 0;
                }
            }
        }

        .order-box {
            display: flex;
            flex-wrap: wrap;
            gap: 17px;
            height: 80px;
            position: relative;

            @include mq-max(md) {
                gap: calc(10px + (17 - 10) * ((100vw - 320px) / (600 - 320)));
            }

            .left-icon {
                @include pseudowh($width: 38px, $height: 38px);
                background-color: rgba(var(--theme-color), 1);
                border-radius: 100%;
                @include flex_common;

                @include mq-max(md) {
                    @include pseudowh($width: calc(31px + (38 - 31) * ((100vw - 320px) / (600 - 320))), $height: calc(31px + (38 - 31) * ((100vw - 320px) / (600 - 320))));
                }

                &:after {
                    @include pos;
                    top: 0;
                    @include pseudowh($width: 1px, $height: 100%);
                    border-left: 1px dashed rgba(var(--content), 1);
                    z-index: -1;
                }

                i {
                    font-size: 22px;
                    color: rgba(var(--white), 1);

                    @include mq-max(md) {
                        font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }

            .right-content {
                width: calc(100% - calc(31px + (38 - 31) * ((100vw - 320px) / (600 - 320))) - calc(10px + (17 - 10) * ((100vw - 320px) / (600 - 320))));
                padding-top: 3px;

                h4 {
                    color: rgba(var(--title), 1);
                    margin-bottom: 4px;

                    @include mq-max(md) {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                    }
                }

                h6 {
                    font-weight: 400;
                    color: rgba(var(--content), 1);
                    font-size: 14px;
                    margin-top: 3px;
                    display: inline-block;
                }
            }
        }
    }
}

/* ---------- Footer CSS ---------- */
footer {
    background-color: rgb(249, 249, 249);
    padding-top: 23px;
    padding-bottom: 23px;
    text-align: center;
    margin-bottom: 52px;

    [class="dark"] & {
        background-color: #212325;
    }

    .social-media-list {
        @include flex_common;
        gap: 25px;
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: unset;

        @include mq-max(md) {
            gap: calc(17px + (25 - 17) * ((100vw - 320px) / (600 - 320)));
        }

        li {
            a {
                @include flex_common;
                color: rgba(var(--title), 1);
                font-size: 20px;
                @include pseudowh($width: 22px, $height: 22px);
            }
        }
    }

    .support-contact {
        padding-bottom: 20px;
        margin-block: 23px;
        border-bottom: 1px solid rgba(237, 239, 244, 0.6);

        [class="dark"] & {
            border-bottom-color: rgba(237, 239, 244, 0.05);
        }

        @include mq-max(md) {
            gap: calc(17px + (25 - 17) * ((100vw - 320px) / (600 - 320)));
            padding-bottom: calc(13px + (20 - 13) * ((100vw - 320px) / (600 - 320)));
            margin-block: calc(15px + (23 - 15) * ((100vw - 320px) / (600 - 320)));
        }

        li {
            +li {
                margin-top: 7px;
            }

            a {
                color: rgba(var(--title), 1);
                font-weight: 400;
                font-size: 15px;

                @include mq-max(md) {
                    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }
    }

    .page-list {
        @include flex_common;
        gap: 25px;

        @include mq-max(md) {
            gap: calc(17px + (25 - 17) * ((100vw - 320px) / (600 - 320)));
        }

        li {
            a {
                color: rgba(var(--title), 1);
                font-weight: 500;
                font-size: 16px;

                @include mq-max(md) {
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }
    }
}

/* ---------- Bottom Panel CSS ---------- */
.bottom-panel-box {
    position: fixed;
    bottom: 0;
    max-width: 600px;
    width: 100%;
    background-color: rgba(var(--dark-bg), 1);
    padding: 16px 26px;
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    z-index: 1;

    [class="dark"] & {
        background-color: #212325;
    }

    @include mq-max(md) {
        padding-inline: calc(15px + (26 - 15) * ((100vw - 320px) / (600 - 320)));
    }

    a {
        @include flex_common;
        font-weight: 500;
        font-size: 16px;
        gap: 8px;
        line-height: 1;
        color: rgba(var(--content), 1);

        @include mq-max(md) {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
            gap: calc(5px + (8 - 5) * ((100vw - 320px) / (600 - 320)));
        }

        i {
            font-size: 20px;
            line-height: 1;

            @include mq-max(md) {
                font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (600 - 320)));
            }
        }
    }

    >div {
        width: 100%;
    }

    .left-panel {
        border-right: 1px solid rgba(var(--title), 1);

        [dir="rtl"] & {
            border-left: 1px solid rgba(var(--title), 1);
            border-right: unset;
        }

        [class="dark"] & {
            border-color: rgba(var(--title), 0.1);
        }
    }
}

/* ---------- Banner CSS ---------- */
.shop-list-list-2 {
    li {
        +li {
            margin-top: 18px;
        }

        .summary-product {
            display: flex;
            gap: 8px;

            .product-image {
                @include pseudowh($width: 100px, $height: 133px);
                border-radius: 5px;
                overflow: hidden;
                position: relative;

                img {
                    @include pseudowh;
                    object-fit: cover;
                }

                .add-icon {
                    background-color: rgba(var(--dark-bg), 0.8);
                    @include pseudowh($width: 30px, $height: 30px);
                    position: absolute;
                    bottom: 8px;
                    right: 8px;
                    @include flex_common;
                    border-radius: 100%;
                    font-size: 16px;
                    color: rgba(var(--white), 1);

                    @include mq-max(md) {
                        @include pseudowh($width: calc(26px + (30 - 26) * ((100vw - 320px) / (600 - 320))), $height: calc(26px + (30 - 26) * ((100vw - 320px) / (600 - 320))));
                    }

                    [dir="rtl"] & {
                        right: unset;
                        left: 8px;
                    }

                    [class="dark"] & {
                        background-color: rgba(33, 35, 37, 0.8);
                        color: #fff;
                    }

                    .wishlist-button {
                        @include pseudowh($width: 16px, $height: 16px);
                        margin: 0;
                        position: relative;
                        background: none;
                        border: none;
                        cursor: pointer;
                        @include flex_common;

                        &:focus {
                            box-shadow: none;
                        }

                        &:active {
                            filter: none;
                        }

                        &:before {
                            @include pos($pos: relative, $content: "\ee0f");
                            @include font;
                            color: rgba(var(--white), 1);

                            [class="dark"] & {
                                color: #fff;
                            }
                        }

                        &:checked {
                            &::before {
                                content: "\ee0e";
                            }
                        }
                    }
                }
            }

            .product-content {
                width: calc(100% - 100px - 8px);

                .name {
                    color: rgba(var(--title), 1);
                    font-size: 15px;
                }

                .content {
                    font-weight: 400;
                    font-size: 13px;
                    margin-top: 2px;
                    color: rgba(var(--content), 1);
                    margin-bottom: 7px;
                }

                .qty {
                    font-weight: 400;
                    color: rgba(var(--content), 1);
                    margin-top: 6px;
                    margin-bottom: 13px;
                }

                h4 {
                    font-weight: 600;
                    color: rgba(var(--theme-color), 1);
                    font-size: 15px;
                }

                .product-rating {
                    margin-top: 7px;
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    i {
                        color: rgba(var(--theme-color), 1);
                        font-size: 16px;
                    }

                    span {
                        color: rgba(var(--title), 1);
                    }
                }

                .size {
                    display: flex;
                    align-items: center;
                    margin-top: 7px;

                    .size-list {
                        align-items: center;
                        display: flex;
                        margin-left: 5px;

                        li {
                            +li {
                                margin-top: 0;
                                margin-left: 5px;
                            }

                            a {
                                @include pseudowh($width: 24px, $height: 24px);
                                border-radius: 100%;
                                @include flex_common;
                                border: 1px solid rgba(var(--border-color), 1);
                                font-size: 11px;
                                font-weight: 500;
                                color: rgba(var(--title), 1);

                                [class="dark"] & {
                                    border-color: #212325;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Empty Box CSS ---------- */
.empty-box {
    @include pseudowh($width: 100%, $height: calc(100vh - 50px - 65px));
    @include flex_common;
    text-align: center;
    padding-inline: 15px;
    color: rgba(var(--title), 1);

    img {
        width: 70px;
        margin-bottom: 10px;

        [class="dark"] & {
            filter: invert(1) brightness(100);
        }
    }

    h4 {
        line-height: 1.5;
    }
}

.ecommerce-next-step {
    background-color: rgba(var(--dark-bg), 1);
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    max-width: 600px;
    width: 100%;
    padding: 12px 23px;
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    z-index: 1;

    [class="dark"] & {
        background-color: #2b2e31;
    }

    >div {
        width: auto;
    }

    .continue-button {
        [class="dark"] & {
            color: #fff;
        }
    }

    .left-box {
        h4 {
            color: rgba(var(--white), 0.6);
            margin-bottom: 3px;

            [class="dark"] & {
                color: #fff;
            }

            @include mq-max(md) {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
            }
        }

        h6 {
            a {
                color: rgba(var(--content), 1);
                text-decoration: underline;
            }
        }
    }

    .continue-button {
        padding: 0;
        font-size: 16px;
        color: rgba(var(--white), 1);

        @include mq-max(md) {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
        }
    }
}

/* ---------- Ecommerce Setting CSS ---------- */
.ecommerce-setting-section {
    .ecommerce-form {
        .form-control {
            background-color: rgb(246, 246, 246);
            padding: 14px 22px;
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--title), 1);
            border: none;
            border-radius: 8px;
            margin-bottom: 21px;
            transition: all 0.3s ease-in-out;

            [class="dark"] & {
                background-color: #212325;
            }

            @include mq-max(md) {
                border-radius: calc(4px + (8 - 4) * ((100vw - 320px) / (600 - 320)));
                margin-bottom: calc(10px + (21 - 10) * ((100vw - 320px) / (600 - 320)));
                padding: calc(10px + (14 - 10) * ((100vw - 320px) / (600 - 320))) calc(16px + (22 - 16) * ((100vw - 320px) / (600 - 320)));
                font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
            }
        }
    }
}

.ecommerce-about-section {
    .ecommerce-about-list {
        padding-left: 17px;

        [dir="rtl"] & {
            padding-left: unset;
            padding-right: 17px;
        }

        li {
            list-style: auto;

            +li {
                margin-top: 16px;
            }

            &::marker {
                font-weight: 600;
            }

            .ecommerce-about-box {
                h4 {
                    font-weight: 600;
                    font-size: 17px;
                    margin-bottom: 4px;

                    @include mq-max(md) {
                        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (600 - 320)));
                    }
                }

                p {
                    margin: 0;
                    font-size: 13px;
                    line-height: 1.6;
                    color: rgba(var(--content), 1);
                }
            }
        }
    }
}