/*=====================
   4.4 Log In CSS
==========================*/
.learning-login-form {
    margin-top: 35px;

    a {
        &:hover {
            color: #9dacb1;
        }

        &.forgot-password {
            color: #9dacb1;
            margin-top: 11px;
            text-align: right;
            display: block;
        }

        &.create-new {
            display: block;
            text-align: center;
            color: #9dacb1;
            margin-top: 11px;
        }
    }

    .continue-media {
        margin-top: 26px;
        position: relative;
        text-align: center;

        &::before {
            @include pos;
            border-top: 1px dashed #cacaca;
            width: 100%;
            @include center(vertical);
            left: 0;
            z-index: -1;
        }

        span {
            background-color: rgba(var(--white), 1);
            padding-inline: 11px;
            font-size: 14px;
            font-weight: 600;
            color: #164150;
        }
    }

    .social-button {
        align-items: center;
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
        margin-top: 15px;

        a {
            @include flex_common;
            flex-wrap: nowrap;
            gap: 8px;
            padding: 11px;
            position: relative;
            background-color: transparent;
            color: #164150;
            border-radius: 5px;
            overflow: hidden;
            z-index: 0;

            &::before {
                @include pos;
                top: 0;
                left: 0;
                @include pseudowh;
                background: var(--gradient);
                opacity: 0.05;
                z-index: -1;
            }

            img {
                @include pseudowh($width: 20px, $height: 20px);
                object-fit: contain;
            }

            span {
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}

/*=====================
    Chatting log-in Css
==========================*/
.chatting-log-in {
    margin-top: 30px;

    h2 {
        font-weight: 500;
        margin-bottom: 9px;
        color: rgba(var(--title), 1);
    }

    h4 {
        font-weight: 400;
        line-height: 1.4;
        color: rgba(var(--content), 1);
        margin-bottom: 19px;
    }
}

.log-in-section {
    margin-top: 34px;
}

.add-profile-section {
    margin-top: 19px;
    margin-bottom: 17px;
}

.sign-up-section {
    .chatting-form-control {
        .from-control-box {
            position: relative;

            i {
                position: absolute;
                @include center(vertical);
                right: 7px;
                font-size: 17px;
                cursor: pointer;
                color: rgba(var(--title), 1);

                [dir="rtl"] & {
                    right: unset;
                    left: 7px;
                }

                [class="dark"] & {
                    color: #1f2937;
                }
            }
        }
    }
}

.otp-section {
    .otp-box {
        align-items: center;
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 13px);
        margin-bottom: 28px;
    }
}

/*=====================
    Cab-booking log-in Css
==========================*/
.cab-booking-login-section {
    margin-top: 24px;
}

.number-section {
    padding-top: 32px;

    .number-title {
        h2 {
            font-weight: 500;
            font-size: 20px;
            color: rgba(var(--title), 1);
            margin-bottom: 13px;
        }

        h4 {
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--content), 1);
            margin-bottom: 15px;
            line-height: 1.5;
        }
    }
}

.resend-box {
    margin-top: 16px;
    text-align: center;
    @include flex_common;
    flex-wrap: nowrap;
    gap: 5px;

    &-2 {
        h6 {
            color: #4b4b4b !important;
        }

        .time {
            ul {
                li {
                    color: #4b4b4b !important;
                }
            }
        }
    }

    h6 {
        font-weight: 400;
        font-size: 14px;
        color: rgba(var(--content), 1);
    }

    .time {
        ul {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 3px);
        }
    }
}