/*===================== 
    4.3 Loader CSS
==========================*/
/* ---------- Food Loader css ---------- */
.min-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    @include pseudowh;
    z-index: 1000;
    @include flex_common;

    .loader {
        display: block;
        position: relative;
        @include pseudowh($width: 120px, $height: 120px);
        z-index: 1001;
        object-fit: contain;

        @include mq-max(md) {
            @include pseudowh($width: calc(95px + (120 - 95) * ((100vw - 320px) / (600 - 320))), $height: calc(95px + (120 - 95) * ((100vw - 320px) / (600 - 320))));
        }
    }

    .loader-section {
        position: fixed;
        top: 0;
        background-color: #fff9ec;
        @include pseudowh($width: 51%, $height: 100%);
        z-index: 1000;

        &.cab-color {
            background: var(--gradient2);
        }

        &.blog-color {
            background-color: #fbfbfb;
        }

        &.chatting-color {
            background-color: #ceeadd;
        }

        &.fitness-color {
            background-color: #fff;
        }

        &.ecommerce-color {
            background-color: #373737;
        }

        &.nft-color {
            background-color: #ff9c42;
        }

        &.financial-color {
            background-color: #030303;

            &:after {
                @include pos;
                top: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(155, 1, 68, 0.52) 0%, rgba(155, 1, 68, 0) 100%);
                @include pseudowh($width: 100%, $height: 190px);
            }
        }

        &.grocery-color {
            background-color: #F5F9FA;
        }

        [class="dark"] & {
            background-color: #1b1b1b;
        }
    }

    .loader-section.section-left {
        left: 0;
    }

    .loader-section.section-right {
        right: 0;
    }
}

/* Loaded Styles */
.loaded {
    .min-loader-wrapper {
        visibility: hidden;
        transform: translateY(-100%);
        transition: all 0.3s 1s ease-out;

        .loader-section {
            &.section-left {
                transform: translateX(-100%);
                transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            &.section-right {
                transform: translateX(100%);
                transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }
    }

    .loader {
        opacity: 0;
        transition: all 0.3s ease-out;
    }
}

/* ---------- Hotel Loader css ---------- */
.hotel-loader-wrapper {
    &.min-loader-wrapper {
        background-color: rgba(var(--light-bg), 1);
        position: fixed;
        top: 0;
        left: 0;
        @include pseudowh;
        @include flex_common;
        z-index: 10;

        .hotel-booking {
            background: linear-gradient(180deg, #0363da, #5546cb);
            border-radius: 4px;
            box-shadow: inset 0 0 20px rgba(43, 43, 43, 0.1);
            @include pseudowh($width: 88px, $height: 152px);
            padding: 8px;
            position: relative;

            .door {
                background: rgba(var(--white), 1);
                position: absolute;
                bottom: 0;
                @include pseudowh($width: 16px, $height: 24px);
                left: 50%;
                margin-left: -8px;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;

            }

            .hotel-sign {
                animation: neon 3s ease infinite;
                background: linear-gradient(180deg, #0363da 0%, #5546cb 100%);
                border-radius: 4px;
                box-shadow: inset 0 0 10px rgba(43, 43, 43, 0.1);
                @include pos;
                font-weight: 700;
                padding: 4px 0;
                right: -24px;
                text-align: center;
                width: 21px;
                color: #ddd;


                span {
                    line-height: 1;
                }
            }

            .window {
                background-color: rgba(var(--white), 1);
                @include pseudowh($width: 8px, $height: 16px);
                float: left;
                margin: 0 8px 8px 0;
                border-radius: 2px;
                animation: flicker 1s infinite ease;

                &:nth-of-type(5n) {
                    margin: 0 0 8px 0;
                }

                &:nth-child(1) {
                    animation-delay: 0.5s;
                    animation-duration: 0.5s;
                }

                &:nth-child(2) {
                    animation-delay: 1s;
                    animation-duration: 1s;
                }

                &:nth-child(3) {
                    animation-delay: 1.5s;
                    animation-duration: 1.5s;
                }

                &:nth-child(4) {
                    animation-delay: 2s;
                    animation-duration: 2s;
                }

                &:nth-child(5) {
                    animation-delay: 2.5s;
                    animation-duration: 2.5s;
                }

                &:nth-child(5) {
                    animation-delay: 1.25s;
                    animation-duration: 1.25s;
                }

                &:nth-child(5) {
                    animation-delay: 1.25s;
                    animation-duration: 1.25s;
                }

                &:nth-child(7) {
                    animation-delay: 1.75s;
                    animation-duration: 1.75s;
                }

                &:nth-child(8) {
                    animation-delay: 2s;
                    animation-duration: 2s;
                }

                &:nth-child(9) {
                    animation-delay: 2.25s;
                    animation-duration: 2.25s;
                }

                &:nth-child(10) {
                    animation-delay: 2.5s;
                    animation-duration: 2.5s;
                }

                &:nth-child(10) {
                    animation-delay: 1s;
                    animation-duration: 1s;
                }

                &:nth-child(11) {
                    animation-delay: 1.1s;
                    animation-duration: 1.1s;
                }

                &:nth-child(12) {
                    animation-delay: 1.2s;
                    animation-duration: 1.2s;
                }

                &:nth-child(13) {
                    animation-delay: 1.3s;
                    animation-duration: 1.3s;
                }

                &:nth-child(14) {
                    animation-delay: 1.4s;
                    animation-duration: 1.4s;
                }

                &:nth-child(15) {
                    animation-delay: 1.5s;
                    animation-duration: 1.5s;
                }

                &:nth-child(16) {
                    animation-delay: 1.6s;
                    animation-duration: 1.6s;
                }

                &:nth-child(17) {
                    animation-delay: 1.7s;
                    animation-duration: 1.7s;
                }

                &:nth-child(18) {
                    animation-delay: 1.8s;
                    animation-duration: 1.8s;
                }

                &:nth-child(19) {
                    animation-delay: 1.9s;
                    animation-duration: 1.9s;
                }

                &:nth-child(20) {
                    animation-delay: 2s;
                    animation-duration: 2s;
                }

                &:nth-child(20) {
                    animation-delay: 1.33333s;
                    animation-duration: 1.66667s;
                }

                &:nth-child(21) {
                    animation-delay: 1.4s;
                    animation-duration: 1.75s;
                }

                &:nth-child(22) {
                    animation-delay: 1.46667s;
                    animation-duration: 1.83333s;
                }

                &:nth-child(23) {
                    animation-delay: 1.53333s;
                    animation-duration: 1.91667s;
                }

                &:nth-child(24) {
                    animation-delay: 1.6s;
                    animation-duration: 2s;
                }

                &:nth-child(25) {
                    animation-delay: 1.66667s;
                    animation-duration: 2.08333s;
                }

                &:nth-child(26) {
                    animation-delay: 1.73333s;
                    animation-duration: 2.16667s;
                }

                &:nth-child(27) {
                    animation-delay: 1.8s;
                    animation-duration: 2.25s;
                }

                &:nth-child(28) {
                    animation-delay: 1.86667s;
                    animation-duration: 2.33333s;
                }

                &:nth-child(29) {
                    animation-delay: 1.93333s;
                    animation-duration: 2.41667s;
                }

                &:nth-child(30) {
                    animation-delay: 2s;
                    animation-duration: 2.5s;
                }
            }
        }
    }
}