/*===================== 
    5.5 Financial Application CSS
==========================*/
/* ---------- Add Card Css ---------- */
.add-card-box {
    padding: 19px 16px;
    background-color: rgba(var(--theme-maroon), 1);
    border: 1px solid rgb(99, 99, 99);
    border-radius: 10px;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &::after {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh;
        background-image: url(../images/financial/bg.gif);
        background-repeat: no-repeat;
        z-index: -2;
        opacity: 0.9;
    }

    &::before {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh;
        z-index: -1;
        background-color: rgba(var(--theme-maroon), 0.9);
    }

    .top-card-name {
        margin-bottom: 51px;

        .name {
            font-size: 15px;
            color: rgba(var(--white), 1);
            line-height: 1;
        }

        .pay {
            font-size: 15px;
            color: rgba(var(--white), 1);
            margin-top: 7px;
            line-height: 1;
        }
    }

    .bottom-card-detail {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);

        .available {
            font-size: 15px;
            color: rgba(var(--white), 1);
            line-height: 1;
        }

        .add-card-button {
            border: none;
            padding: 8px 18px;
            display: inline-block;
            width: auto;
            border-radius: 4px;
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--dark-gray), 1);
            line-height: 1;
            background-color: rgba(var(--white), 1);
        }
    }
}

/* ---------- Add Card Css ---------- */
.pay-option-list {
    margin-top: 23px;
    display: flex;
    align-items: center;
    gap: 14px;
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 3px;
    padding-right: 2px;

    [dir="rtl"] & {
        padding-right: unset;
        padding-left: 2px;
    }

    li {
        width: 100%;

        .pay-box {
            text-align: center;
            padding: 12px 24px;
            background-color: rgba(var(--title), 1);
            border-radius: 6px;
            width: 100%;
            display: block;
            color: rgba(var(--white), 1);

            &.send {
                box-shadow: 2px 2px 0px #001aff;

                [dir="rtl"] & {
                    box-shadow: -2px 2px 0px #001aff;
                }
            }

            &.receive {
                box-shadow: 2px 2px 0px #2629af;

                [dir="rtl"] & {
                    box-shadow: -2px 2px 0px #2629af;
                }
            }

            &.exchange {
                box-shadow: 2px 2px 0px #57addd;

                [dir="rtl"] & {
                    box-shadow: -2px 2px 0px #57addd;
                }
            }

            &.scan {
                box-shadow: 2px 2px 0px #61c58b;

                [dir="rtl"] & {
                    box-shadow: -2px 2px 0px #61c58b;
                }
            }

            i {
                font-size: 28px;
            }

            h6 {
                font-weight: 400;
                font-size: 12px;
                width: 60px;
                margin: 0 auto;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

/* ---------- Bills & Recharge Css ---------- */
.bill-recharge-list {
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    gap: 29px;
    overflow: auto;

    li {
        .recharge-box {
            width: min-content;
            display: block;
            text-align: center;

            .recharge-icon {
                background-color: rgba(var(--dark-gray), 1);
                border: 1px solid rgba(var(--title), 1);
                box-shadow: 0px 0px 13px rgba(var(--black), 0.04);
                border-radius: 6px;
                @include pseudowh($width: 60px, $height: 60px);
                font-size: 30px;
                display: grid;
                place-items: center;
                color: rgba(var(--content), 1);
                margin-bottom: 9px;

                i {
                    line-height: 1;
                }
            }

            h6 {
                font-weight: 400;
                line-height: 1.5;
                font-size: 13px;
                color: rgba(var(--theme-light-gray), 1);
            }
        }
    }
}

/* ---------- People Css ---------- */
.people-list-box {
    @include flex_common ($dis: flex, $align: center, $justify: space-between);
    gap: 14px;
    overflow: auto;
    padding-bottom: 7px;

    li {
        .people-box {
            text-align: center;

            .people-image {
                @include pseudowh($width: 58px, $height: 58px);
                border-radius: 100%;
                overflow: hidden;
                filter: grayscale(1);
            }

            h6 {
                margin-top: 10px;
                color: rgba(var(--theme-light-gray), 1);
                font-weight: 400;
                font-size: 13px;
            }
        }
    }
}

/* ---------- Recent Transactions Css ---------- */
.transactions-list {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    &.transactions-list-2 {
        gap: 19px;
    }

    li {
        width: 100%;

        .transactions-box {
            background-color: rgba(var(--dark-gray), 1);
            box-shadow: 0px 0px 13px rgba(var(--black), 1);
            border-radius: 5px;
            padding: 11px;
            display: flex;
            align-items: center;
            gap: 14px;

            &.transactions-box-2 {
                border-radius: 10px;
            }

            .transactions-image {
                @include pseudowh($width: 43px, $height: 46px);
                background-color: rgb(87, 173, 221);
                border-radius: 10px;
                color: rgba(var(--white), 1);
                display: grid;
                place-items: center;
                overflow: hidden;

                img {
                    @include pseudowh;
                    object-fit: cover;
                }

                h2 {
                    font-weight: 700;
                    font-size: 22px;
                }
            }

            .transactions-contain {
                @include flex_common ($dis: flex, $align: center, $justify: space-between);
                width: calc(100% - 43px - 14px);
                color: rgba(var(--theme-light-gray), 1);

                h5 {
                    font-weight: 500;
                    margin-bottom: 6px;
                    font-size: 15px;
                }

                h6 {
                    font-weight: 400;
                    color: rgba(var(--content), 1);
                    font-size: 13px;
                }
            }
        }
    }
}

/* ---------- Card Css ---------- */
.card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;

    li {
        width: 100%;

        &:nth-child(odd) {
            .card-details-box {
                &::before {
                    background-color: rgba(var(--theme-maroon), 0.9);
                }
            }
        }

        &:nth-child(even) {
            .card-details-box {
                &::before {
                    background-color: rgba(34, 36, 157, 0.9);
                }
            }
        }

        .card-details-box {
            padding: calc(15px + (19 - 15) * ((100vw - 320px) / (1920 - 320))) calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)));
            background-color: rgba(var(--theme-maroon), 1);
            border-radius: 10px;
            position: relative;
            z-index: 0;
            overflow: hidden;

            &::after {
                @include pos;
                top: 0;
                left: 0;
                @include pseudowh;
                background-image: url(../images/financial/bg.gif);
                background-repeat: no-repeat;
                z-index: -2;
                opacity: 0.9;
            }

            &::before {
                @include pos;
                top: 0;
                left: 0;
                @include pseudowh;
                z-index: -1;
            }

            .card-name {
                margin-bottom: 61px;
                @include flex_common ($dis: flex, $align: flex-start, $justify: space-between);

                .name {
                    font-size: 15px;
                    color: rgba(var(--white), 1);
                    line-height: 1;
                }

                .pay {
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(var(--white), 0.8);
                    margin-top: 8px;
                    line-height: 1;
                }

                .pay-image {
                    width: 35px;
                }
            }

            .card-bank-detail {
                @include flex_common ($dis: flex, $align: center, $justify: space-between);

                .bank-name {
                    font-weight: 400;
                    color: rgba(var(--white), 0.8);
                    margin-bottom: 5px;
                }

                .available {
                    font-size: 16px;
                    color: rgba(var(--white), 1);
                    line-height: 1;
                }

                .add-card-button {
                    border: none;
                    padding: 8px 18px;
                    display: inline-block;
                    width: auto;
                    border-radius: 4px;
                    font-weight: 400;
                    font-size: 14px;
                    color: rgba(var(--dark-gray), 1);
                    line-height: 1;
                }
            }
        }

        .add-card-button {
            padding: 14px;
            border: 1px solid rgba(251, 251, 251, 0.26);
            border-radius: 9px;
            line-height: 1;
            font-size: 15px;
        }
    }
}

/* ---------- Your Bills Css ---------- */
.your-bills-section {
    .bill-box {
        background: rgba(var(--border-color), 1);
        box-shadow: 0px 0px 13px rgba(var(--black), 0.04);
        border-radius: 10px;
        padding: 13px 12px 19px;

        .bill-name {
            margin-bottom: 29px;

            h5 {
                font-weight: 400;
                font-size: 16px;
                color: rgba(var(--theme-gray), 1);
            }

            h6 {
                font-weight: 400;
                font-size: 12px;
                margin-top: 5px;
                color: rgba(var(--content), 1);
            }
        }

        .bill-total {
            margin-bottom: 10px;

            .total {
                font-weight: 400;
                font-size: 14px;
                color: rgba(var(--content), 1);
                margin-bottom: 3px;
            }

            .pay {
                font-weight: 400;
                font-size: 13px;
                color: rgba(var(--theme-gray), 1);
                display: flex;
                align-items: center;
                gap: 5px;

                .success {
                    font-size: 11px;
                    color: #3fb567;
                }

                .paid {
                    font-weight: 400;
                    font-size: 8px;
                    padding: 3px 8px;
                    background-color: rgba(var(--white), 1);
                    border-radius: 100px;
                    color: rgba(var(--dark), 1);
                    line-height: 1;
                }
            }
        }

        .bill-btn {
            border: 1px solid rgb(38, 41, 175);
            border-radius: 6px;
            padding: 10px 7px;
            font-weight: 500;
            font-size: 12px;
            color: #2629af;
            line-height: 1;
            background-color: transparent;
        }
    }

    .bill-slider {
        .swiper-slide {
            &:nth-child(odd) {
                .bill-box {
                    background-color: rgba(var(--border-color), 1);
                }
            }

            &:nth-child(2n + 2) {
                .bill-box {
                    background-color: #2629af;

                    .bill-name {
                        h5 {
                            color: rgba(var(--white), 1);
                        }

                        h6 {
                            color: rgba(var(--theme-light-gray), 1);
                        }
                    }

                    .bill-total {
                        .total {
                            color: rgba(var(--theme-light-gray), 1);
                        }

                        .pay {
                            color: rgba(var(--white), 1);
                        }
                    }

                    .bill-btn {
                        border: 1px solid rgba(var(--white), 1);
                        color: rgba(var(--white), 1);
                    }
                }
            }

            &:nth-child(2n + 3) {
                .bill-box {
                    background-color: #61c58b;

                    .bill-name {
                        h5 {
                            color: rgba(var(--white), 1);
                        }

                        h6 {
                            color: rgba(var(--white), 0.7);
                        }
                    }

                    .bill-total {
                        .total {
                            color: rgba(var(--white), 0.7);
                        }

                        .pay {
                            color: rgba(var(--white), 1);

                            .success {
                                color: #323232;
                            }
                        }
                    }

                    .bill-btn {
                        border: 1px solid rgba(var(--white), 1);
                        color: rgba(var(--white), 1);
                    }
                }
            }
        }
    }
}

/* ---------- Activity Search Box CSS ---------- */
.activity-search-box {
    .form-box {
        .form-control {
            padding: 12px 22px;
            border-radius: 100px;
            background: rgba(var(--dark-gray), 1);
            border: 1px solid rgba(var(--title), 1);
            box-shadow: 0px 0px 13px rgba(var(--black), 0.04);
            margin-top: 17px;
            margin-bottom: 25px;
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
            color: rgba(var(--theme-light-gray), 1);

            &::placeholder {
                color: rgba(var(--theme-gray), 1);
            }
        }
    }
}

/* ---------- Payment Method Css ---------- */
.payment-method-box {
    margin-top: 20px;
    padding: 12px 17px;
    background: rgba(var(--title), 1);
    box-shadow: 0px 4px 4px rgba(var(--black), 1);
    border-radius: 8px;
    @include flex_common ($dis: flex, $align: center, $justify: space-between);

    .payment-name {
        h5 {
            font-weight: 400;
            color: rgba(var(--white), 1);
            margin-bottom: 8px;
        }

        h6 {
            font-weight: 400;
            color: rgba(var(--theme-light-gray), 1);
        }
    }

    .add-bank-btn {
        @include pseudowh($width: 30px, $height: 30px);
        font-size: 17px;
        color: rgba(var(--white), 1);
        background: rgba(var(--theme-maroon), 1);
        box-shadow: 0px 4px 4px rgba(var(--black), 0.25);
        border-radius: 4px;
        @include flex_common;

        i {
            line-height: 1;
        }
    }
}

/* ---------- Payment Method Css ---------- */
.financial-profile-section {
    .profile-menu-list {
        display: flex;
        flex-wrap: wrap;
        gap: 28px;

        li {
            width: 100%;

            .profile-menu-box {
                display: flex;
                align-items: center;
                gap: 15px;
                color: rgba(var(--theme-light-gray), 1);

                i {
                    font-size: 18px;
                    line-height: 1;
                }

                img {
                    @include pseudowh($width: 18px, $height: 18px);
                    object-fit: contain;
                }

                h4 {
                    font-weight: 400;
                    line-height: 1;
                }
            }
        }
    }
}

/* ---------- QR Code Scan box Css ---------- */
.qr-code-scan-box {
    @include pseudowh($width: 100%, $height: 100vh);
    @include flex_common;

    svg {
        fill: transparent;
        @include pseudowh($width: 230px, $height: 224px);
    }

    .camera-box {
        @include pseudowh;
        position: absolute;
        top: 0;
        left: 0;

        #cam {
            position: absolute;
            top: 0;
            left: 0;
            @include pseudowh;
        }

        #photo {
            @include pseudowh;
        }
    }
}

/* ---------- Chatting Css ---------- */
.pay-chatting-box {
    margin-top: 13px;
    @include pseudowh($width: 100%, $height: calc(100vh - 13px - 66px));
    padding-bottom: 84px;
    overflow: auto;

    .msger {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
    }

    .msger-header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: rgba(var(--border-color), 1);
        background-color: rgba(var(--border-color), 1);
        color: rgba(var(--content), 1);
    }

    .msg-bubble {
        width: calc(100% - 60px);
        padding: 13px 17px;
        position: relative;
        border-radius: 15px;

        @include mq-max(md) {
            width: 100%;
            border-radius: calc(11px + (15 - 11) * ((100vw - 320px) / (600 - 320)));
        }

        .msg-text {
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--theme-light-gray), 1);
        }
    }

    .msg-info-time {
        position: absolute;
        bottom: -21px;
        right: 0;
        font-weight: 400;
        font-size: 12px;
        color: rgba(var(--title), 1);
        font-size: 0.85em;
    }

    .left-msg,
    .right-msg {
        display: flex;
        align-items: flex-end;
    }

    .left-msg .msg-bubble {
        position: relative;
        background: rgba(var(--dark-gray), 1);
        box-shadow: 0px 0px 13px rgba(var(--black), 0.04);
        font-weight: 400;
        font-size: 14px;
        color: rgba(var(--theme-light-gray), 1);
        border: 1px solid rgba(var(--title), 1);
    }

    .msg {
        margin-bottom: 32px;

        @include mq-max(md) {
            margin-bottom: calc(18px + (32 - 18) * ((100vw - 320px) / (600 - 320)));
        }
    }

    .msg:last-of-type {
        margin: 0;
    }

    .msg-time {
        font-weight: 400;
        color: rgba(var(--content), 1);
        font-size: 12px;
        margin-top: 6px;
    }

    .chatting-box {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        gap: 13px;

        .msg-pay {
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--theme-light-gray), 1);
        }
    }

    .right-msg {
        flex-direction: row-reverse;

        .msg-text {
            color: rgba(var(--white), 1);
        }

        .msg-bubble {
            color: rgba(var(--white), 1);
            background-color: rgba(var(--title), 1);
            box-shadow: 0px 0px 13px rgba(var(--black), 0.04);
            border-radius: 10px;
            border: 1px solid rgba(var(--title), 1);
        }

        .msg-img {
            margin: 0 0 0 10px;
        }
    }

    .msger-inputarea {
        position: fixed;
        bottom: 0;
        @include center(horizontal);
        width: 100%;
        padding: 0 15px 25px;
        max-width: 600px;

        .msger-inputarea-box {
            display: flex;
            align-items: center;
            gap: 11px;
        }
    }

    .msger-inputarea * {
        border: none;
        border-radius: 3px;
        font-size: 1em;
    }

    .msger-input {
        width: 100%;
        background-color: rgba(var(--dark), 0.6);
        border: 1px solid rgba(var(--title), 1);
        backdrop-filter: blur(14px);
        font-weight: 400;
        font-size: 16px;
        padding: 14px 18px;
        border-radius: 13px;
        line-height: 1;
        color: rgba(var(--border-color), 1);

        &::placeholder {
            color: rgba(var(--theme-gray), 1);
        }
    }

    .msger-send-btn {
        padding: 13px 39px;
        border-radius: 9px;
        @include flex_common;
        background-color: rgba(var(--theme-maroon), 1);
        color: rgba(var(--white), 1);

        @include mq-max(md) {
            padding: 13px calc(22px + (39 - 22) * ((100vw - 320px) / (600 - 320)));
            border-radius: calc(5px + (9 - 5) * ((100vw - 320px) / (600 - 320)));
        }

        h4 {
            font-weight: 400;
            font-size: 17px;
            color: rgba(var(--theme-light-gray), 1);
            line-height: 21px;
        }
    }

    .emoji-button {
        padding: 0;
        @include pseudowh($width: 24px, $height: 24px);
        position: absolute;
        @include center(vertical);
        left: 16px;

        .emoji-icon {
            stroke: rgba(var(--content), 1);
            fill: transparent;
            @include pseudowh($width: 24px, $height: 24px);
        }
    }
}

/* ---------- Recharge Box Css ---------- */
.recharge-form-box {
    .input-group-box {
        display: flex;
        align-items: center;
        gap: 12px;

        @include mq-max(md) {
            gap: calc(8px + (12 - 8) * ((100vw - 320px) / (600 - 320)));
        }

        .form-control {
            background-color: rgba(var(--dark), 0.6);
            border: 1px solid rgba(var(--title), 1);
            backdrop-filter: blur(14.5px);
            border-radius: 16px;
            padding: 16px 20px;
            font-weight: 400;
            font-size: 18px;
            line-height: 1;
            color: rgba(var(--theme-gray), 1);

            @include mq-max(md) {
                border-radius: calc(9px + (16 - 9) * ((100vw - 320px) / (600 - 320)));
                padding: calc(13px + (16 - 13) * ((100vw - 320px) / (600 - 320))) calc(15px + (20 - 15) * ((100vw - 320px) / (600 - 320)));
                font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (600 - 320)));
            }

            &.number {
                width: 72px;
            }

            &.input-number {
                width: calc(100% - calc(8px + (12 - 8) * ((100vw - 320px) / (600 - 320))) - 72px);

                [dir="rtl"] & {
                    text-align: right;
                }
            }
        }
    }
}

/* ---------- Recharge Box Css ---------- */
.recharge-people-section {
    margin-top: 24px;

    .recharge-title {
        margin-bottom: 19px;

        h5 {
            font-weight: 400;
        }
    }
}

/* ---------- Invite Friend Css ---------- */
.invite-friend-list-2 {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    li {
        width: 100%;

        .invite-friend-box {
            background-color: rgba(var(--dark-gray), 1);
            padding: 11px;
            display: flex;
            align-items: center;
            gap: 13px;

            .friend-image {
                border-radius: 10px;
                @include pseudowh($width: 43px, $height: 43px);
                overflow: hidden;
            }

            .friend-content {
                @include flex_common ($dis: flex, $align: center, $justify: space-between);
                width: calc(100% - 13px - 43px);

                h5 {
                    font-weight: 400;
                    margin-bottom: 6px;
                    color: rgba(var(--theme-light-gray), 1);
                    font-size: 14px;
                }

                h6 {
                    color: rgba(var(--content), 1);
                    font-weight: 400;
                    font-size: 13px;
                    font-weight: 600;
                }

                .invite-btn {
                    width: auto;
                    font-weight: 400;
                    font-size: 14px;
                    padding: 8px 19px;
                    background-color: rgba(147, 3, 84, 0.3);
                    border: 1px solid rgba(147, 3, 84, 0.6);
                    border-radius: 100px;
                    line-height: 1;
                }
            }
        }
    }
}

/* ---------- Personal Profile Css ---------- */
.financial-edit-profile-section {
    .edit-image {
        .profile-pic {
            color: transparent;
            transition: all 0.3s ease;
            @include flex_common;
            position: relative;
            overflow: hidden;
            padding: 4px;
            width: 100px;
            margin: 0 auto;

            input {
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                @include pseudowh;
                cursor: pointer;
                opacity: 0;
            }

            img {
                object-fit: cover;
                @include pseudowh($width: 100px, $height: 100px);
                border: 3px solid rgba(var(--theme-maroon), 1);
                z-index: 0;
                box-shadow: 0px 4px 4px rgba(var(--black), 0.25);
                border-radius: 100%;
            }

            .-label {
                cursor: pointer;
                @include pseudowh($width: 100px, $height: 100px);
            }

            span {
                font-size: 25px;
            }

            .edit {
                position: absolute;
                bottom: 5px;
                @include center(horizontal);
                z-index: 1;
                color: rgba(var(--theme-gray), 1);
                @include flex_common;
                padding: 3px 7px;
                border-radius: 4px;
                gap: 6px;
                background-color: rgba(var(--white), 1);
                box-shadow: 0 0 8px rgb(221, 221, 221);

                i {
                    font-size: 13px;
                }

                span {
                    font-size: 12px;
                }
            }
        }
    }
}

/* ---------- Personal Profile Css ---------- */
.edit-profile-form {
    .form-box {
        margin-bottom: 20px;

        &.form-flex-box {
            display: flex;
            gap: 16px;

            >div {
                width: 100%;
            }
        }

        .form-label {
            font-weight: 400;
            font-size: 15px;
            line-height: 1;
        }

        .form-control {
            background-color: rgba(var(--dark), 0.6);
            border: 1px solid rgba(var(--title), 1);
            padding: 14px 18px;
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--border-color), 1);
            border-radius: 13px;

            &::placeholder {
                color: rgba(var(--theme-gray), 1);
            }

            [dir="rtl"] & {
                text-align: right;
            }

            &[type="date"] {
                position: relative;

                &::after {
                    @include pos($pos: absolute, $content: "\EB27");
                    @include font;
                    color: rgba(var(--border-color), 1);
                    padding: 0 5px;
                    @include center(vertical);
                    right: 20px;

                    [dir="rtl"] & {
                        right: unset;
                        left: 20px;
                    }
                }

                &::-webkit-calendar-picker-indicator {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    @include pseudowh($width: auto, $height: auto);
                    color: transparent;
                    background: transparent;
                }
            }
        }
    }
}

/* ---------- App Setting Css ---------- */
.app-setting-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    li {
        width: 100%;

        .app-setting-box {
            padding: 0;
            margin: 0;
            min-height: auto;
            @include flex_common ($dis: flex, $align: center, $justify: space-between);

            .form-check-label {
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5;
            }

            .form-check-input {
                float: unset;
                margin: 0;
                border-radius: 100px;
                @include pseudowh($width: 42px, $height: 21px);
                padding: 0;
                background: unset;
                background-color: rgba(var(--title), 1);
                position: relative;
                transition: all 0.3s ease-in-out;

                &::before {
                    @include pos;
                    @include center(vertical);
                    left: 1px;
                    @include pseudowh($width: 15px, $height: 15px);
                    border-radius: 100%;
                    background-color: rgba(var(--white), 1);
                    transition: transform 0.3s ease-in-out;
                }

                &:checked {
                    background-color: rgba(var(--theme-maroon), 1);
                    border-color: rgba(var(--theme-maroon), 1);

                    &::before {
                        transform: translateY(-50%) translateX(22px);
                    }
                }

                &:focus {
                    box-shadow: none;
                    border-color: unset;
                }
            }
        }
    }
}

/* ---------- Help Css ---------- */
.help-nav-pills {
    flex-wrap: nowrap;
    margin-bottom: 19px;

    .nav-item {
        width: 100%;
        border-bottom: 1px solid rgba(var(--content), 0.3);

        .nav-link {
            width: 100%;
            padding: 0 0 7px;
            border-radius: 0;
            border-bottom: 1px solid transparent;
            color: rgba(var(--content), 1);
            font-size: 15px;
            font-weight: 400;

            &.active {
                background-color: transparent;
                color: rgba(var(--theme-maroon), 1);
                font-weight: 600;
                border-bottom: 1px solid rgba(var(--theme-maroon), 1);
            }
        }
    }
}

.financial-help-section {
    .financial-tab {
        .nav-item {
            .nav-link {
                color: rgba(var(--border-color), 0.7);

                &.active {
                    color: rgba(var(--border-color), 1);
                }
            }
        }
    }

    .faq-content {
        margin-top: 12px;

        .accordion {
            .accordion-item {
                background-color: transparent;
                border: 1px solid rgba(var(--title), 1);
                border-radius: 13px;
                padding: 16px;

                +.accordion-item {
                    margin-top: 15px;
                }

                .accordion-header {
                    .accordion-button {
                        background-color: transparent;
                        padding: 0;
                        color: rgba(var(--border-color), 1);
                        box-shadow: none;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.5;

                        &:not(.collapsed) {
                            &::after {
                                transform: rotate(-90deg);
                            }
                        }

                        &::after {
                            content: "\EA6E";
                            @include font;
                            background: none;
                            font-size: 21px;
                            @include pseudowh($width: auto, $height: auto);
                            line-height: 1;
                        }
                    }
                }

                .accordion-collapse {
                    .accordion-body {
                        margin-top: 9px;
                        padding: 11px 0 0;
                        border-top: 1px solid rgba(var(--content), 0.3);
                        color: rgba(var(--theme-gray), 1);

                        p {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1.5;
                            margin: 0;
                            color: rgba(var(--border-color), 0.5);
                        }
                    }
                }
            }
        }
    }
}

.financial-color {
    .offcanvas {
        &.theme-bottom-offcanvas {
            .offcanvas-header {
                background-color: rgba(var(--dark-bg), 1);
                color: rgba(var(--content), 1);
            }
        }
    }

    .offcanvas-body {
        background-color: rgba(var(--dark-gray), 1);
    }
}