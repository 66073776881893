/*=====================
    4.2 Home CSS
==========================*/
.learning-home-section {
    background-image: url(../images/learning/home-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 168px 0 25px;

    .home-contain {
        color: rgba(var(--white), 1);

        h2 {
            margin-bottom: 6px;
            font-size: 22px;
        }
    }
}