/*=====================
    3.5 Form CSS
==========================*/
/* ---------- Common Style ---------- */
.checkbox_animated {
    cursor: pointer;
    position: relative;
    margin-right: 16px;
    height: 100%;

    &:before {
        @include pos;
        @include pseudowh($width: 10px, $height: 6px);
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: rotate(-45deg) scale(0, 0);
        left: 3px;
        top: 2px;
        z-index: 1;
        border: 2px solid rgba(var(--primary), 1);
        border-top-style: none;
        border-right-style: none;
    }

    &:after {
        @include pos;
        @include pseudowh($width: 20px, $height: 20px);
        top: -3px;
        left: -1px;
        background-color: rgba(var(--white), 1);
        border: 1px solid rgb(236, 236, 236);
        cursor: pointer;

        [dir="rtl"] & {
            left: unset;
            right: 0;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &:checked {
        background-color: transparent;
        border-color: transparent;

        &:before {
            transform: rotate(-45deg) scale(1, 1);
        }
    }
}

.checkbox_animated_sm {
    cursor: pointer;
    position: relative;
    margin-right: 16px;
    height: 100%;

    &:before {
        @include pos;
        @include pseudowh($width: 8px, $height: 5px);
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        transform: rotate(-45deg) scale(0, 0);
        left: 4px;
        top: 2px;
        z-index: 1;
        border: 2px solid rgba(var(--primary), 1);
        border-top-style: none;
        border-right-style: none;

        [dir="rtl"] & {
            left: 1px;
        }
    }

    &:after {
        @include pos;
        @include pseudowh($width: 16px, $height: 16px);
        top: -2px;
        left: 0;
        background-color: rgba(var(--white), 1);
        border: 1px solid rgba(var(--content), 1);
        cursor: pointer;
        border-radius: 3px;

        [dir="rtl"] & {
            left: unset;
            right: 0;
        }
    }

    &:checked {
        &:before {
            transform: rotate(-45deg) scale(1, 1);
        }
    }
}

.theme-radio-box {
    .form-check {
        cursor: pointer;
        transition: background 0.2s ease;
        margin: 0;
        min-height: auto;
        padding-left: unset;
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 6px);
        align-items: center;

        [dir="rtl"] & {
            padding-right: unset;
        }

        .form-check-input {
            vertical-align: middle;
            @include pseudowh($width: 16px, $height: 16px);
            border-radius: 10px;
            background-color: none;
            border: 0;
            box-shadow: inset 0 0 0 1px rgb(159, 159, 159);
            appearance: none;
            padding: 0;
            margin: 0;
            transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
            pointer-events: none;

            &:checked {
                box-shadow: inset 0 0 0 5px rgba(var(--theme-color), 1);
            }
        }
    }
}

/* ---------- Custom Radio Button ---------- */
.custom-radio-box {
    cursor: pointer;
    transition: background 0.2s ease;
    margin: 0;
    min-height: auto;
    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 6px);
    align-items: center;

    input {
        vertical-align: middle;
        @include pseudowh($width: 16px, $height: 16px);
        border-radius: 10px;
        background-color: none;
        border: 0;
        box-shadow: inset 0 0 0 1px rgb(159, 159, 159);
        appearance: none;
        padding: 0;
        margin: 0;
        transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
        pointer-events: none;

        &:focus {
            box-shadow: inset 0 0 0 1px #9f9f9f;
        }

        &:active {
            box-shadow: inset 0 0 0 1px #9f9f9f;
            filter: none;
        }

        &:checked {
            box-shadow: inset 0 0 0 5px rgba(var(--theme-color), 1);
        }
    }
}

/* ---------- form style 1 ---------- */
.form-style-1 {
    &.learning-search-form {
        position: relative;

        .search-icon {
            position: absolute;
            @include center(vertical);
            font-size: 18px;
            left: 10px;
            color: rgba(var(--content), 1);

            [dir="rtl"] & {
                left: unset;
                right: 10px;
            }

            i {
                font-size: 20px;
            }
        }

        .form-control {
            padding-inline: 41px;

            [dir="rtl"] & {
                padding-inline: 41px;
            }
        }

        .filter-button {
            padding: 0;
            font-size: 17px;
            border: none;
            background-color: transparent;
            top: 0;
            right: 0;
            position: absolute;
            color: rgba(var(--title), 1);
            line-height: 1;
            @include pseudowh($width: 46px, $height: 100%);
            border-radius: 0;
            @include flex_common;

            [dir="rtl"] & {
                right: unset;
                left: 0;
            }
        }
    }

    +.learning-theme-form {
        margin-top: 23px;
    }

    .form-label {
        font-weight: 500;
        color: $dark-green;
        font-size: 16px;

        [class="dark"] & {
            color: #fff;
        }

        @include mq-max(md) {
            font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
        }
    }

    .form-control {
        background-color: #f5f7f7;
        border: none;
        padding: 13px 17px;
        box-shadow: 5px 6px 0px $border-color;
        font-size: 16px;
        color: rgba(var(--title), 1);

        [class="dark"] & {
            box-shadow: 5px 6px 0px rgba(239, 239, 239, 0.08);
            background-color: #121924 !important;
        }

        [dir="rtl"] & {
            text-align: right;
        }

        @include mq-max(md) {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
        }

        &::placeholder {
            color: #9dacb1;
        }
    }

    .form-select {
        background-color: #f5f7f7;
        border: none;
        padding: 13px 17px;
        box-shadow: 5px 6px 0px $border-color;
        color: #9dacb1;
        font-size: 16px;

        [class="dark"] & {
            box-shadow: 5px 6px 0px rgba(239, 239, 239, 0.08);
            background-color: #121924;
        }

        [dir="rtl"] & {
            padding: 13px 17px;
            text-align: right;
        }

        @include mq-max(md) {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
        }

        option {
            color: rgba(var(--title), 1);
            background-color: #f5f7f7;
            border: none;
            padding: 13px 17px;
            box-shadow: 5px 6px 0px #efefef;
            font-size: 16px;
        }
    }

    .form-group {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 10px);
        flex-wrap: nowrap;
        justify-content: space-between;

        .form-control {
            padding: 0;
            @include pseudowh($width: 62px, $height: 52px);

            @include mq-max(md) {
                @include pseudowh($width: calc(49px + (62 - 49) * ((100vw - 320px) / (600 - 320))), $height: calc(46px + (52 - 46) * ((100vw - 320px) / (600 - 320))));
            }
        }
    }

    .learning-theme-dropdown {
        .dropdown-toggle {
            background-color: #f5f7f7;
            border-radius: 4px;
            color: rgba(var(--content), 1);
            text-align: left;
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            font-size: 14px;
            font-weight: 400;
            padding: 13px 17px;
            position: relative;

            [class="dark"] & {
                background-color: rgba(255, 255, 255, 0.05);
            }

            &::before {
                @include pos;
                bottom: -5px;
                right: -5px;
                @include pseudowh;
                background: var(--gradient);
                opacity: 0.08;
                border-radius: 4px;
                z-index: -1;
            }

            &:active {
                background-color: #f5f7f7;
                border-color: transparent;
                color: rgba(var(--content), 1);

                [class="dark"] & {
                    background-color: #121924;
                }
            }

            i {
                font-size: 22px;
                line-height: 1;
            }

            &::after {
                content: none;
            }
        }

        .dropdown-menu {
            width: 100%;

            [class="dark"] & {
                background-color: #161d27;
            }

            li {
                .dropdown-item {
                    @include mq-max(md) {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                    }

                    &:active,
                    &.active {
                        background-color: #f5f7f7;
                        color: rgba(var(--title), 1);
                    }

                    &:hover,
                    &:focus {
                        background-color: transparent;
                    }

                    [class="dark"] & {
                        color: #fff;
                    }
                }
            }
        }
    }
}

/* ---------- form style 2 ---------- */
.form-style-2 {
    .custom-form-select {
        .form-label {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--content), 1);
            margin-bottom: 0;
        }

        .form-select {
            background-color: transparent;
            border: none;
            padding: 8px 25px 11px 0;
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--title), 1);
            background-position: right 5px center;
            border-bottom: 1px solid rgba(var(--content), 1);
            border-radius: 0;

            @include mq-max(md) {
                font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
            }

            [class="dark"] & {
                color: #1f2937;
            }

            &:focus {
                box-shadow: none;
            }

            option {
                [class="dark"] & {
                    background-color: #1f2937;
                    color: #fff;
                }
            }
        }
    }

    .chatting-form-control {
        .form-label {
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--content), 1);
            margin-bottom: 0;
        }

        .form-control {
            background-color: transparent;
            border: none;
            padding: 8px 0 11px;
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--title), 1);
            background-position: right 5px center;
            border-bottom: 1px solid rgba(var(--content), 1);
            border-radius: 0;

            @include mq-max(md) {
                font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
            }

            [class="dark"] & {
                color: #1f2937;
            }

            &:focus {
                box-shadow: none;
            }

            &-border {
                border-bottom-color: rgba(var(--border-color), 1);

                [class="dark"] & {
                    border-color: rgba(var(--border-color), 0.2);
                }
            }

            [dir="rtl"] & {
                text-align: right;
            }
        }

        .delete-account-number {
            border-radius: 100px;
            border: 1px solid #EEE;
            background-color: #eee;
            padding: 13px 32px;
            display: inline-block;
            width: auto;
        }
    }
}

/* ---------- form style 3 ---------- */
.form-style-3 {
    >div {
        position: relative;
    }

    .form-label {
        display: none;
    }

    .form-control {
        background-color: transparent;
        border: 1px solid rgba(var(--border-color), 1);
        font-weight: 400;
        font-size: 18px;
        padding: 18px 59px 18px 26px;
        border-radius: 22px;
        color: rgba(var(--title), 1);
        margin-bottom: 20px;

        [dir="rtl"] & {
            padding: 18px 26px 18px 59px;
            text-align: right;
        }

        [class="dark"] & {
            background-color: #272d34;
            border-color: #272d34;
        }

        @include mq-max(md) {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
            padding: calc(15px + (18 - 15) * ((100vw - 320px) / (600 - 320))) calc(20px + (26 - 20) * ((100vw - 320px) / (600 - 320)));
            padding-right: 59px;

            [dir="rtl"] & {
                padding: calc(15px + (18 - 15) * ((100vw - 320px) / (600 - 320))) calc(20px + (26 - 20) * ((100vw - 320px) / (600 - 320))) calc(15px + (18 - 15) * ((100vw - 320px) / (600 - 320))) 59px;
                text-align: right;
            }
        }

        &.wo-icon {
            padding-right: 26px;

            @include mq-max(md) {
                padding-inline: calc(20px + (26 - 20) * ((100vw - 320px) / (600 - 320)));

                [dir="rtl"] & {
                    text-align: right;
                }
            }
        }

        &::placeholder {
            color: rgba(var(--content), 1);
            font-weight: 400;
        }
    }

    textarea {
        padding: 15px 21px;
        margin-bottom: 0;
    }

    i {
        position: absolute;
        @include center(vertical);
        right: 25px;
        color: rgba(var(--content), 1);
        font-size: 24px;

        [dir="rtl"] & {
            right: unset;
            left: 25px;
        }

        @include mq-max(md) {
            font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (600 - 320)));
        }
    }

    .form-control-flex {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        flex-wrap: nowrap;
        gap: 10px;

        .form-control {
            background-color: #F4F4F4;
            font-weight: 400;
            font-size: 18px;
            color: rgba(var(--title), 1);
            margin-bottom: 0;
            @include pseudowh($width: 60px, $height: 58px);
            padding: 0;
            border-radius: 15px;

            @include mq-max(md) {
                @include pseudowh($width: 60px, $height: calc(49px + (58 - 49) * ((100vw - 320px) / (600 - 320))));
                font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (600 - 320)));
            }

            &:focus {
                background: var(--gradient);
                color: rgba(var(--white), 1);
                border: none;

                &::placeholder {
                    color: rgba(var(--white), 1);
                }
            }
        }
    }

    .onboarding-verify-box {
        margin-bottom: 12px;

        h3 {
            color: rgba(var(--title), 1);
            display: flex;
            align-items: center;
            gap: 9px;

            i {
                position: relative;
                inset: unset;
                transform: unset;
                color: rgba(var(--title), 1);
            }
        }
    }

    .form-control-bg {
        img {
            @include pseudowh($width: 31px, $height: 21px);
            border-radius: 6px;
            position: absolute;
            @include center(vertical);
            left: 25px;

            [dir="rtl"] & {
                left: unset;
                right: 25px;
            }
        }

        .form-control {
            padding-left: 69px;
            padding-right: 54px;
            background-color: #f8f8f8;
            border: none;

            [dir="rtl"] & {
                padding-left: 54px;
                padding-right: 69px;
            }
        }
    }

    .style-3-dropdown {
        .dropdown-link {
            font-weight: 400;
            font-size: 18px;
            color: rgba(var(--content), 1);
            align-items: center;
            padding: 18px 25px;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 13px);
            background-color: transparent;
            border: 1px solid rgba(var(--border), 1);
            border-radius: 22px;
            margin-bottom: 20px;
            position: relative;

            &::before {
                @include pos($pos: absolute, $content: "\EA4E");
                @include center(vertical);
                right: 25px;
                @include font;
                font-size: 22px;
            }

            img {
                @include pseudowh($width: 31px, $height: 21px);
                border-radius: 6px;
            }
        }

        .dropdown-menu {
            width: 100%;
            border-radius: 22px;
            border: 1px solid rgba(var(--border), 1);
            padding: 8px 16px;
            flex-wrap: wrap;
            gap: 12px;

            &.show {
                display: flex;
            }

            li {
                width: 100%;
                position: relative;

                +li {
                    &::before {
                        @include pos;
                        top: -7px;
                        left: 0;
                        @include pseudowh($width: 100%, $height: 1px);
                        background-color: rgba(var(--content), 0.3);
                        z-index: -1;
                    }
                }

                .dropdown-item {
                    font-weight: 400;
                    font-size: 18px;
                    color: rgba(var(--content), 1);
                    align-items: center;
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 13px);
                    background-color: transparent;
                    padding: 0;

                    img {
                        @include pseudowh($width: 31px, $height: 21px);
                        border-radius: 6px;
                    }
                }
            }
        }
    }

    .form-check {
        .form-check-input {
            cursor: pointer;
            position: relative;
            margin-right: 16px;
            height: 100%;
            border: none;

            &:active {
                filter: none;
            }

            &:before {
                @include pos;
                @include pseudowh($width: 10px, $height: 6px);
                transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                transform: rotate(-45deg) scale(0, 0);
                left: 4px;
                top: 2px;
                z-index: 1;
                border: 2px solid rgba(var(--white), 1);
                border-top-style: none;
                border-right-style: none;

                [class="dark"] & {
                    border-color: #fff;
                }
            }

            &:after {
                @include pos;
                @include pseudowh($width: 22px, $height: 22px);
                top: -5px;
                left: -2px;
                border: 1px solid #ececec;
                cursor: pointer;
                background: rgba(var(--white), 1);
                border-radius: 6px;
                transition: all 0.3s ease-in-out;

                [class="dark"] & {
                    background-color: #272d34;
                    border-color: #272d34;
                }

                [dir="rtl"] & {
                    left: unset;
                    right: 0;
                }
            }

            &:focus {
                box-shadow: none;
            }

            &:checked {
                background-color: transparent;
                border-color: transparent;

                &:after {
                    background: var(--gradient2);
                }

                &:before {
                    transform: rotate(-45deg) scale(1, 1);
                }
            }
        }
    }
}

/* ---------- form style 4 ---------- */
.form-style-4 {
    .form-label {
        font-weight: 600;
        font-size: 18px;
        color: rgba(var(--title), 1);
        margin-bottom: 12px;

        @media (max-width: 767px) {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
            margin-bottom: calc(5px + (12 - 5) * ((100vw - 320px) / (600 - 320)));
        }
    }

    .form-control,
    .form-select {
        background-color: rgba(var(--white), 1);
        border: none;
        padding: 16px 18px;
        box-shadow: $shadow3;
        font-weight: 400;
        font-size: 15px;
        color: rgba(var(--title), 1);
        border-radius: 11px;

        @include mq-max(md) {
            padding: calc(13px + (16 - 13) * ((100vw - 320px) / (600 - 320))) calc(13px + (18 - 13) * ((100vw - 320px) / (600 - 320)));
        }

        [dir="rtl"] & {
            text-align: right;
        }

        [class="dark"] & {
            background-color: #171717;
        }

        &::placeholder {
            color: rgba(var(--content), 1);
        }
    }

    .form-position {
        position: relative;

        i {
            position: absolute;
            @include center(vertical);
            right: 20px;
            font-size: 20px;
            color: rgba(var(--content), 1);

            [dir="rtl"] & {
                right: unset;
                left: 20px;
            }
        }

        .form-control {
            padding-right: 50px;

            [dir="rtl"] & {
                padding-right: 18px;
                padding-left: 50px;
                text-align: right;
            }
        }
    }

    .form-control {
        &[type="date"] {
            &::-webkit-calendar-picker-indicator {
                color: rgba(var(--black), 0);
                display: block;
                background: url(../svg/calendar.svg) no-repeat;
                @include pseudowh($width: 20px, $height: 20px);
            }
        }
    }

    .form-flex {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        gap: 10px;

        @include mq-max(md) {
            gap: calc(5px + (10 - 5) * ((100vw - 320px) / (600 - 320)));
        }

        .form-control {
            padding: 0;
            height: 60px;

            @include mq-max(md) {
                height: calc(50px + (60 - 50) * ((100vw - 320px) / (600 - 320)));
            }
        }
    }
}

/* ---------- form style 5 ---------- */
.form-style-5 {
    .form-floating {
        .form-select {
            padding: 23px 15px 10px;
            background-color: rgba(var(--light-bg), 1);
            border-radius: 6px;
            border: none;
            font-weight: 500;
            font-size: 15px;
            color: rgba(var(--title), 1);

            &:focus {
                box-shadow: none;
            }
        }

        .form-control {
            padding: 23px 15px 10px;
            background-color: rgba(var(--light-bg), 1);
            border-radius: 6px;
            border: none;
            font-weight: 500;
            font-size: 15px;

            [dir="rtl"] & {
                text-align: right;
            }

            &::-webkit-calendar-picker-indicator {
                display: none;
            }

            &:focus {
                font-size: 14px;
            }

            >.form-control {
                &:focus~label {
                    opacity: 0.65;
                    transform: scale(0.87) translateY(-0.7rem) translateX(0.15rem);
                }

                &:not(:placeholder-shown)~label {
                    opacity: 0.65;
                    transform: scale(0.87) translateY(-0.7rem) translateX(0.15rem);
                }
            }
        }

        >label {
            padding: 15px 10px;
            display: flex;
            align-items: center;
            border: none;
        }
    }

    .sign-divider {
        margin-bottom: 24px;
    }
}

/* ---------- form style 6 ---------- */
.form-style-6 {
    .form-label {
        font-weight: 500;
        font-size: 16px;
        color: rgba(var(--content), 1);
        margin-bottom: 8px;

        @include mq-max(md) {
            font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
            margin-bottom: calc(4px + (8 - 4) * ((100vw - 320px) / (600 - 320)));
        }
    }

    .form-control,
    .form-select {
        background-color: rgba(var(--white), 1);
        padding: 14px 22px;
        font-weight: 400;
        font-size: 16px;
        color: rgba(var(--title), 1);
        border: 1px solid rgba(var(--title), 1);
        border-radius: 8px;
        background-color: transparent;
        margin-bottom: 21px;
        transition: all 0.3s ease-in-out;

        &::placeholder {
            color: red;
        }

        [class="dark"] & {
            border-color: #212325;
        }

        [dir="rtl"] & {
            text-align: right;
        }

        @include mq-max(md) {
            border-radius: calc(4px + (8 - 4) * ((100vw - 320px) / (600 - 320)));
            margin-bottom: calc(10px + (21 - 10) * ((100vw - 320px) / (600 - 320)));
            padding: calc(10px + (14 - 10) * ((100vw - 320px) / (600 - 320))) calc(16px + (22 - 16) * ((100vw - 320px) / (600 - 320)));
            font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
        }

        &:focus {
            border-color: rgba(var(--white), 0.1);
            background-color: rgba(var(--white), 0.05);
        }

        &::placeholder {
            color: rgba(var(--title), 1);
        }

        &-2 {
            border-color: rgba(var(--title), 0.2);

            &:focus {
                border-color: rgba(var(--title), 0.1);
                background-color: rgba(var(--white), 0.1);
            }
        }
    }

    .auth-divider {
        margin-top: 26px;
        margin-bottom: 26px;
        text-align: center;
        position: relative;
        z-index: 0;

        &::before {
            @include pos;
            @include center(vertical);
            @include pseudowh($width: 100%, $height: 1px);
            background-color: rgba(var(--title), 1);
            left: 0;
            z-index: -1;

            [class="dark"] & {
                background-color: #212325;
            }
        }

        span {
            font-weight: 500;
            font-size: 14px;
            color: rgba(var(--content), 1);
            background-color: rgba(var(--dark-bg), 1);
            padding-inline: 10px;

            [class="dark"] & {
                background-color: #14141b;
            }
        }
    }

    .auth-flex {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);

        .form-control {
            @include pseudowh($width: 63px, $height: 63px);
            margin: 0;
            padding: 0;

            @include mq-max(md) {
                @include pseudowh($width: calc(51px + (63 - 51) * ((100vw - 320px) / (600 - 320))), $height: calc(51px + (63 - 51) * ((100vw - 320px) / (600 - 320))));
            }
        }
    }

    .search-box {
        position: relative;

        .form-control {
            font-weight: 500;
            color: rgba(var(--title), 1);
            background-color: rgba(var(--light-bg), 1);
            border-radius: 8px;
            border: none;
            padding: 10px 15px;
            padding-left: 47px;
            margin-bottom: 14px;

            [dir="rtl"] & {
                padding-left: unset;
                padding-right: 47px;
            }

            [class="dark"] & {
                background-color: #212325;
            }

            &-2 {
                padding-left: 15px;

                [dir="rtl"] & {
                    padding-left: unset;
                    padding-right: 15px;
                }
            }

            &::placeholder {
                color: rgba(var(--content), 1);
            }

            &:focus {
                ~i {
                    color: rgba(var(--title), 1);
                }
            }
        }

        i {
            position: absolute;
            @include center(vertical);
            left: 15px;
            font-size: 20px;
            color: rgba(var(--content), 1);

            [dir="rtl"] & {
                right: 15px;
                left: unset;
            }
        }
    }

    .form-style-flex {
        display: flex;
        align-items: center;
        gap: 15px;

        .search-box {
            width: calc(100% - 45px - 15px);

            .form-control {
                margin: 0;
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }

        .filter-button {
            background-color: rgba(var(--theme-color), 1);
            font-size: 24px;
            border-radius: 8px;
            padding: 0;
            color: rgba(var(--white), 1);
            border: none;
            @include flex_common;
            @include pseudowh($width: 45px, $height: 45px);
        }
    }

    &.light-version {
        .form-label {
            color: rgba(var(--title), 1);
        }

        .form-control {
            border: 1px solid #eee;
        }

        .form-select {
            border: 1px solid #eee;

            &:focus {
                box-shadow: none;
                border: 1px solid #eee;
            }
        }
    }
}

/* ---------- form style 7 ---------- */
.form-style-7 {
    .mb-19 {
        margin-bottom: 19px;
    }

    h5 {
        font-size: 14px;
    }

    .form-box {
        position: relative;
    }

    &.search-box {
        .mic-icon {
            right: 17px;
            left: unset;

            [dir="rtl"] & {
                right: unset;
                left: 17px;
            }
        }

        .form-control {
            padding-right: 47px;
        }
    }

    .form-box-2 {
        i {
            top: 26px;
            line-height: 1;
        }
    }

    .search-box {
        .form-control {
            border-radius: 100px;
            box-shadow: 0px -1px 17px rgba(var(--black), 0.05);
            border: none;
            font-size: 14px;
            font-weight: 400;
            color: rgba(var(--title), 1);

            &::placeholder {
                color: rgba(var(--content), 1);
            }

            &:focus {
                background: linear-gradient(180deg, rgba(var(--theme-color), 0.05) 61.46%, rgba(var(--theme-color), 0) 100%);
            }
        }

        .mic-icon {
            left: unset;
            right: 17px;

            [dir="rtl"] & {
                right: unset;
                left: 17px;
            }
        }
    }

    &.wo-icon {
        .form-control {
            padding: 14px !important;
        }
    }

    .form-control {
        border: 1px solid rgba(var(--border-color), 1);
        border-radius: 11px;
        padding: 14px;
        padding-left: 47px;
        font-weight: 400;
        font-size: 14px;

        [class="dark"] & {
            background-color: #141416;
            color: #fff;
            border: 1px solid rgba(119, 119, 119, 0.35);
        }

        [dir="rtl"] & {
            padding-left: 16px;
            padding-right: 47px;
            text-align: right;
        }
    }

    i {
        position: absolute;
        @include center(vertical);
        left: 16px;
        font-size: 19px;
        color: rgba(var(--content), 1);

        [dir="rtl"] & {
            left: unset;
            right: 16px;
        }
    }

    .auth-divider {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 24px;
        position: relative;
        z-index: 0;

        &::after {
            @include pos;
            @include center(vertical);
            left: 0;
            @include pseudowh($width: 100%, $height: 1px);
            border-top: 1px dashed rgba(var(--white), 1);
            z-index: -1;
        }

        label {
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--theme-color), 1);
            background-color: rgba(var(--white), 1);
            padding-inline: 14px;
        }
    }
}

/* ---------- form style 8 ---------- */
.form-style-8 {
    .form-box {
        position: relative;

        +.form-box {
            .form-control {
                border-top-width: 1px;
            }
        }

        &.one-form {

            &:first-child,
            &:last-child {
                .form-control {
                    border: 1px solid rgba(var(--title), 1);
                    border-radius: 22px;

                    @include mq-max(md) {
                        border-radius: calc(14px + (22 - 14) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }
        }

        .form-control {
            border-width: 0 1px;
            border-color: rgba(var(--title), 1);
            background-color: rgba(var(--title), 0.6);
            backdrop-filter: blur(14px);
            padding: 21px 59px 21px 18px;
            font-weight: 400;
            font-size: 18px;
            border-radius: 0;
            color: rgba(var(--theme-light-gray), 1);

            @include mq-max(md) {
                padding: 21px calc(41px + (59 - 41) * ((100vw - 320px) / (600 - 320))) 21px 18px;
            }

            [dir="rtl"] & {
                padding: 21px 18px 21px 59px;
                text-align: right;

                @include mq-max(md) {
                    padding: 21px 18px 21px calc(41px + (59 - 41) * ((100vw - 320px) / (600 - 320)));
                }
            }

            @include mq-max(md) {
                padding: calc(14px + (21 - 14) * ((100vw - 320px) / (600 - 320))) calc(48px + (59 - 48) * ((100vw - 320px) / (600 - 320))) calc(13px + (21 - 13) * ((100vw - 320px) / (600 - 320))) calc(13px + (18 - 13) * ((100vw - 320px) / (600 - 320)));
                font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (600 - 320)));
            }

            &[type="date"] {
                position: relative;

                &::-webkit-datetime-edit {
                    color: rgba(var(--theme-gray), 1);
                }

                &::-webkit-calendar-picker-indicator {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    @include pseudowh($width: auto, $height: auto);
                    color: transparent;
                    background: transparent;
                }

                &::after {
                    @include pos($pos: absolute, $content: "\EB27");
                    @include font;
                    padding: 0 5px;
                    @include center(vertical);
                    right: 20px;
                    color: rgba(var(--theme-gray), 1);

                    [dir="rtl"] & {
                        right: unset;
                        left: 20px;
                    }
                }
            }

            &::placeholder {
                color: rgba(var(--theme-gray), 1);
            }
        }

        i {
            position: absolute;
            @include center(vertical);
            right: 21px;
            color: #4b4b4b;
            font-size: 24px;

            [dir="rtl"] & {
                right: unset;
                left: 21px;
            }

            @include mq-max(md) {
                font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (600 - 320)));
                right: calc(14px + (21 - 14) * ((100vw - 320px) / (600 - 320)));

                [dir="rtl"] & {
                    right: unset;
                    left: calc(14px + (21 - 14) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }

        &:first-child {
            .form-control {
                border-width: 1px 1px 0px 1px;
                border-style: solid;
                border-color: rgba(var(--title), 1);
                border-radius: 22px 22px 0px 0px;

                @include mq-max(md) {
                    border-radius: calc(14px + (22 - 14) * ((100vw - 320px) / (600 - 320))) calc(14px + (22 - 14) * ((100vw - 320px) / (600 - 320))) 0 0;
                }
            }
        }

        &:last-child {
            .form-control {
                border-style: solid;
                border-width: 1px;
                border-radius: 0 0 22px 22px;

                @include mq-max(md) {
                    border-radius: 0 0 calc(14px + (22 - 14) * ((100vw - 320px) / (600 - 320))) calc(14px + (22 - 14) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }
    }

    .form-flex-box {
        display: flex;

        .form-control {
            &:first-child {
                border-bottom-right-radius: 0;

                [dir="rtl"] & {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 22px;
                }
            }

            &:last-child {
                border-bottom-left-radius: 0;
                border-left-width: 0;

                [dir="rtl"] & {
                    border-bottom-left-radius: 22px;
                    border-bottom-right-radius: 0;
                    border-right-width: 0;
                    border-left-width: 1px;
                }
            }
        }
    }

    .forgot-text {
        margin-top: 10px;
        margin-bottom: 38px;
        text-align: right;
        display: block;

        a {
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--theme-gray), 1);
        }
    }

    .auth-divider {
        text-align: center;
        position: relative;
        margin-bottom: 36px;
        z-index: 0;
        display: block;

        &::after {
            @include pos;
            z-index: -1;
            @include center(vertical);
            left: 0;
            @include pseudowh($width: 100%, $height: 1px);
            background-color: rgba(19, 19, 19, 1);
        }

        span {
            font-weight: 400;
            font-size: 14px;
            color: rgba(var(--theme-gray), 1);
            background-color: rgba(var(--dark-bg), 1);
            padding-inline: 15px;
        }
    }
}

/* ---------- form style 9 ---------- */
.author-box-9 {
    height: 537px;
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    background: linear-gradient(0.73deg, rgba(var(--black), 0.4) 0.28%, rgba(var(--white), 0) 99.04%);
    width: 100%;
    max-width: 600px;
    @include flex_common ($dis: flex, $align: flex-end, $justify: center);
    padding-inline: 15px;

    h2 {
        font-weight: 600;
        font-size: 25px;
        color: rgba(var(--white), 1);
        margin-bottom: 22px;
    }
}

.form-style-9 {
    .form-box {
        position: relative;

        +.form-box {
            margin-top: 20px;
        }

        .form-control {
            background-color: rgba(var(--white), 1);
            color: rgba(var(--title), 1);
            border: 1px solid rgba(var(--border-color), 1);
            font-weight: 400;
            font-size: 18px;
            padding: 18px 26px;
            border-radius: 22px;

            [dir="rtl"] & {
                text-align: right;
            }

            &:focus {
                ~i {
                    color: rgba(var(--title), 0.75);
                }
            }

            &::placeholder {
                color: rgba(var(--content-dark), 1);
            }

            @include mq-max(md) {
                border-radius: calc(15px + (22 - 15) * ((100vw - 320px) / (600 - 320)));
                font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
                padding: calc(10px + (18 - 10) * ((100vw - 320px) / (600 - 320))) calc(14px + (26 - 14) * ((100vw - 320px) / (600 - 320)));
            }

            &::placeholder {
                color: rgba(var(--content-dark), 1);
                font-weight: 400;
            }
        }

        i {
            position: absolute;
            @include center(vertical);
            right: 25px;
            color: rgba(var(--content-dark), 1);
            font-size: 24px;

            @include mq-max(md) {
                font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (600 - 320)));
                right: calc(15px + (25 - 15) * ((100vw - 320px) / (600 - 320)));
            }

            [dir="rtl"] & {
                right: unset;
                left: 25px;

                @include mq-max(md) {
                    left: calc(15px + (25 - 15) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }

        &.with-icon {
            .form-control {
                padding-right: 59px;

                @include mq-max(md) {
                    padding-right: calc(45px + (59 - 45) * ((100vw - 320px) / (600 - 320)));
                }

                [dir="rtl"] & {
                    padding-right: calc(20px + (26 - 20) * ((100vw - 320px) / (600 - 320)));
                    padding-left: 59px;

                    @include mq-max(md) {
                        padding-left: calc(45px + (59 - 45) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }
        }
    }

    .mt-29 {
        margin-top: 29px;
    }

    .other-author {
        font-weight: 500;
        font-size: 14px;
        color: rgba(var(--white), 1);
        margin: 19px 0 26px;
        display: block;
        text-align: center;
    }

    .forgot-text {
        font-weight: 500;
        font-size: 14px;
        margin: 11px 0 31px;
        display: block;
        text-align: right;
        color: rgba(var(--white), 1);
    }
}

/* ---------- form style 10 ---------- */
.form-box-10 {
    font-family: $nunito;

    .form-box {
        +.form-box {
            margin-top: 15px;
        }

        .form-control {
            background-color: rgba(var(--white), 0.05);
            position: relative;
            box-shadow: 0px 0px 0px #f5d106;
            border-radius: 6px;
            border: none;
            font-weight: 400;
            font-size: 16px;
            padding: 14px;
            z-index: 0;
            color: rgba(var(--white), 1);

            [class="dark"] & {
                color: #efefef;
                background-color: rgba(255, 255, 255, 0.05);
            }

            &::placeholder {
                color: rgba(var(--white), 0.5);

                [class="dark"] & {
                    color: #efefef;
                }
            }

            [dir="rtl"] & {
                text-align: right;
            }

            &::before {
                @include pos;
                top: 0;
                left: 0;
                @include pseudowh;
                filter: blur(0.5px);
                z-index: -1;
            }
        }
    }

    .forgot-text {
        font-weight: 400;
        font-size: 14px;
        display: block;
        text-align: right;
        color: rgba(var(--white), 0.5);
        margin: 8px 0 21px;
    }
}

/* ---------- form style 11 ---------- */
.form-box-11 {
    font-family: $nunito;

    .form-box {
        +.form-box {
            margin-top: 15px;
        }

        >.form-control {
            padding: 25px 15px 12px 50px;

            &:focus {
                ~label {
                    transform: translateY(-50%);
                    opacity: 1;
                }
            }

            &:not(:placeholder-shown) {
                ~label {
                    transform: scale(0.8) translateY(-32px) translateX(-4px);
                }
            }
        }

        .form-control {
            background-color: #fafafa;
            position: relative;
            border-radius: 6px;
            border: none;
            font-weight: 400;
            font-size: 15px;
            padding: 25px 15px 12px 50px;
            z-index: 0;
            color: rgba(var(--title), 1);

            [class="dark"] & {
                background-color: #212325;
            }

            [dir="rtl"] & {
                text-align: right;
            }
        }

        >label {
            left: 50px;
            @include pseudowh($width: auto, $height: auto);
            padding: 0;
            @include center(vertical);
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--content), 1);

            [dir="rtl"] & {
                left: unset;
                right: 15px;
            }
        }

        i {
            font-size: 24px;
            position: absolute;
            @include center(vertical);
            left: 15px;
            color: #777777;
            line-height: 1;
        }
    }

    .check-box {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 30px;

        .checkbox-animated {
            cursor: pointer;
            position: relative;
            margin-right: 16px;
            @include pseudowh($width: 28px, $height: 28px);
            margin: 0;
            border: none;

            [class="dark"] & {
                background-color: #151515;
            }

            &:before {
                @include pos;
                @include pseudowh($width: 12px, $height: 7px);
                transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
                transform: rotate(-45deg) scale(0, 0);
                left: 7px;
                top: 6px;
                z-index: 1;
                border: 2px solid rgba(var(--title), 1);
                border-top-style: none;
                border-right-style: none;
            }

            &:after {
                @include pos;
                @include pseudowh($width: 28px, $height: 28px);
                top: -3px;
                left: -1px;
                cursor: pointer;
                background-color: #f7f7f7;
                border-radius: 4px;

                [dir="rtl"] & {
                    left: unset;
                    right: 0;
                }

                [class="dark"] & {
                    background-color: #212325;
                }
            }

            &:focus {
                box-shadow: none;
            }

            &:checked {
                background-color: transparent;
                border-color: transparent;

                &:before {
                    transform: rotate(-45deg) scale(1, 1);
                }
            }
        }

        .form-check-label {
            font-weight: 400;
            font-size: 14px;
            transform: none;
            width: calc(100% - 12px - 28px);
            color: #777777;

            a {
                color: #777777;
                text-decoration: underline;
            }
        }
    }

    .forgot-text {
        font-weight: 400;
        font-size: 14px;
        display: block;
        text-align: right;
        color: rgba(var(--white), 0.5);
        margin: 8px 0 21px;
    }
}

.mbsc-windows {
    &.mbsc-textfield {
        border: 1px solid rgba(var(--border-color), 1);
        font-weight: 400;
        font-size: 18px;
        padding: 18px 26px;
        border-radius: 22px;
        height: auto;
        color: rgba(var(--title), 1);

        [class="dark"] & {
            background-color: #272d34;
            border-color: rgba(255, 255, 255, 0.05);
        }

        &.mbsc-hover,
        &.mbsc-focus {
            border-color: rgba(var(--border-color), 1);

            [class="dark"] & {
                border-color: rgba(255, 255, 255, 0.05);
            }
        }
    }

    &.mbsc-scroller-wheel-item {
        &.mbsc-hover {
            background-color: rgba(var(--theme-color), 1);
        }
    }

    &.mbsc-popup {
        background-color: rgba(var(--white), 1);

        [class="dark"] & {
            background-color: #22272c;
            border-color: rgba(255, 255, 255, 0.05);
        }
    }

    &.mbsc-popup-buttons {
        [class="dark"] & {
            border-color: rgba(255, 255, 255, 0.05);

            .mbsc-popup-button {
                color: #9f9f9f;

                &.mbsc-active {
                    background-color: #22272c;
                    border-color: transparent;
                }
            }
        }
    }

    &.mbsc-popup-arrow-bottom {
        [class="dark"] & {
            background-color: #22272c;
            border-color: rgba(255, 255, 255, 0.05);
        }
    }
}

.md-country-picker-item {
    color: rgba(var(--title), 1);
}

.mbsc-selected {
    background-color: rgba(var(--theme-color), 1);
    color: rgba(var(--title), 1);
}