/*=====================
   4.6 Onboarding CSS
==========================*/
/* ---------- learning onboarding ---------- */
.learning-onboarding-bg {
    background-image: url(../images/learning/bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    .onboarding-top-contain {
        text-align: center;
        padding-top: 78px;
        color: rgba(var(--white), 1);

        [class="dark"] & {
            color: #fff;
        }

        .onboarding-contain {
            h2 {
                font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (600 - 320)));
                font-weight: 600;
                line-height: 1.4;
            }

            h6 {
                font-size: 14px;
                font-weight: 300;
                margin-bottom: 6px;
            }
        }
    }

    .onboarding-images {
        margin-top: 74px;
        position: relative;

        .circle-1,
        .circle-2,
        .plus-1,
        .plus-2 {
            position: absolute;
        }

        .circle-1 {
            top: 39px;
            left: 52px;
            animation: scale 2s alternate infinite ease-in;
        }

        .circle-2 {
            top: 120px;
            left: 166px;
            @include pseudowh($width: 6px, $height: 6px);
            animation: scale 2s alternate infinite ease-in;
            animation-delay: 1.1s;
        }

        .plus-1 {
            bottom: 64px;
            left: 88px;
            animation: circle 5s linear infinite;
        }

        .plus-2 {
            top: 102px;
            right: 34px;
            animation: circle 5s linear infinite;
            animation-delay: 1.1s;
        }
    }

    .onboarding-footer {
        margin-top: 70px;

        .footer-contain {
            p {
                color: $light-gray;
                margin-top: 21px;
                line-height: 1.5;
            }
        }
    }
}

/* ---------- Chatting onboarding ---------- */
.chatting-onboarding-bg {
    background-color: rgba(var(--theme-color), 1);

    .onboarding-top {
        position: relative;

        .background-bg {
            position: absolute;
            bottom: 0;
            left: 0;
            @include pseudowh($width: 100%, $height: 133px);
            background: linear-gradient(0deg, rgba(var(--theme-color), 1) 0%, rgba(205, 233, 221, 0) 82.04%);
        }
    }
}

/* ---------- cab onboarding ---------- */
.cab-onboarding-section {
    .cab-onboarding {
        .auto-play-progress {
            position: absolute;
            right: 16px;
            bottom: 16px;
            z-index: 10;
            @include pseudowh($width: 48px, $height: 48px);
            @include flex_common;
            font-weight: bold;
            color: var(--swiper-theme-color);

            svg {
                --progress: 0;
                position: absolute;
                left: 0;
                top: 0px;
                z-index: 10;
                @include pseudowh;
                stroke-width: 4px;
                stroke: var(--swiper-theme-color);
                fill: none;
                stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
                stroke-dasharray: 125.6;
                transform: rotate(-90deg);
            }
        }

        .cab-onboarding-box {
            margin-top: 61px;

            .onboarding-image {
                padding-inline: 17px;
                margin-bottom: 33px;
                height: 32vh;

                img {
                    @include pseudowh;
                    object-fit: contain;
                }
            }

            .onboarding-content {
                text-align: center;

                h2 {
                    font-weight: 600;
                    font-size: 22px;
                    color: rgba(var(--title), 1);
                    margin-bottom: 10px;
                }

                h4 {
                    font-weight: 400;
                    font-size: 16px;
                    color: rgba(var(--content), 1);
                    line-height: 1.5;
                    margin-bottom: 50px;

                    @include mq-max(md) {
                        margin-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (600 - 320)));
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }
        }

        .swiper-pagination {
            position: relative;
            inset: unset;

            .swiper-pagination-bullet {
                @include pseudowh($width: 55px, $height: 6px);
                border-radius: 100px;
                background-color: #c4c4c4;
                opacity: 1;

                @include mq-max(md) {
                    @include pseudowh($width: calc(45px + (55 - 45) * ((100vw - 320px) / (600 - 320))), $height: calc(4px + (6 - 4) * ((100vw - 320px) / (600 - 320))));
                }

                &-active {
                    background: linear-gradient(180deg, #232526 0%, #414345 100%);
                }
            }
        }
    }

    .onboarding-button {
        position: relative;
        inset: unset;
        margin: 50px auto 0;
        color: rgba(var(--white), 1);
        @include pseudowh($width: 66px, $height: 66px);
        border-radius: 100%;
        border: none;
        background: var(--gradient2);
        font-size: 33px;
        @include flex_common;

        [class="dark"] & {
            color: #fff;
        }

        @include mq-max(md) {
            margin: calc(26px + (50 - 26) * ((100vw - 320px) / (600 - 320))) auto 0;
            font-size: calc(28px + (33 - 28) * ((100vw - 320px) / (600 - 320)));
            @include pseudowh($width: calc(49px + (66 - 49) * ((100vw - 320px) / (600 - 320))), $height: calc(49px + (66 - 49) * ((100vw - 320px) / (600 - 320))));
        }
    }
}

/* ---------- Hotel Booking onboarding ---------- */
.hotel-onboarding {
    padding-top: 67px;

    .hotel-slider {
        .hotel-box {
            margin-bottom: 64px;

            .hotel-image {
                @include pseudowh($width: 306px, $height: 100%);
                box-shadow: 0px 7px 6px rgba(var(--black), 0.22);
                border-radius: 15px;
                overflow: hidden;
                margin: 0 auto;

                @media (max-width: 370px) {
                    width: auto;
                    margin-inline: 15px;
                }

                img {
                    @include pseudowh;
                    object-fit: contain;
                }
            }

            .hotel-content {
                margin-top: 31px;
                text-align: center;

                h2 {
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 112.97%;
                    margin-bottom: 12px;
                    background: var(--gradient-color);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                h4 {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: rgba(var(--content), 1);
                }
            }
        }

        .swiper-pagination {
            bottom: 0;

            .swiper-pagination-bullet {
                @include pseudowh($width: 6px, $height: 6px);
                border-radius: 100px;
                border: 2px solid rgba(74, 74, 74, 1);
                background-color: transparent;
                transition: all 0.5s ease;

                &-active {
                    width: 25px;
                    background-color: rgba(74, 74, 74, 1);
                }
            }
        }
    }

    .button-group {
        margin-top: 23px;
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);

        a {
            color: rgba(var(--title), 1);
            padding: 14px;
            font-weight: 500;
            font-size: 16px;

            &.next-button {
                background: var(--gradient-color);
                color: rgba(var(--white), 1);
                position: relative;
                transform: unset;
                left: unset;
                bottom: unset;

                [class="dark"] & {
                    color: #fff;
                }
            }
        }
    }
}

/* ---------- Ecommerce onboarding ---------- */
.ecommerce-onboarding {
    position: absolute;
    top: 0;
    left: 0;
    @include pseudowh;
}

.ecommerce-bg-image-onboarding {
    img {
        @include pseudowh($width: 100%, $height: 100vh);
        object-fit: cover;
    }
}

.onboarding-button {
    position: absolute;
    top: 0;
    left: 0;
    @include pseudowh;
    background-color: rgba(55, 55, 55, 0.5);
    z-index: 1;
    text-align: center;

    .onboarding-button-group {
        position: fixed;
        bottom: 50px;
        width: 100%;
        padding-inline: 15px;
        @include center(horizontal);
        display: flex;
        flex-wrap: wrap;
        gap: 17px;
        max-width: 600px;

        p {
            color: rgba(var(--white), 0.7);
            margin: 0;
            font-size: 14px;
            line-height: 1.5;
        }
    }
}

/* ---------- Grocery Onboarding ---------- */
.grocery-onboarding {
    background-image: url(../images/grocery/onbording/1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include pseudowh($width: 100%, $height: 100vh);

    .onbording-container {
        position: fixed;
        bottom: 0;
        width: 100%;
        @include center(horizontal);
        max-width: 600px;
        padding: 15px;
        background: linear-gradient(180deg, rgba(234, 234, 234, 0.12) 0%, rgba(230, 230, 230, 1) 100%);

        .onborder-content {
            text-align: center;

            .onbording-logo {
                width: 124px;
                margin-bottom: 6px;
            }

            h2 {
                color: rgba(var(--content), 1);
                font-weight: 500;
                line-height: 1.4;
                width: 50%;
                margin: 0 auto 23px;

                [class="dark"] & {
                    color: #777777;
                }

                @media (max-width: 420px) {
                    width: 80%;
                }
            }

            .onbording-button-group {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                gap: 15px;
            }
        }
    }
}

/* ---------- Financial Onboarding ---------- */
.financial-onboarding {
    position: relative;
    z-index: 0;
    @include pseudowh($width: 100%, $height: 100vh);
    @include flex_common;
    text-align: center;
    flex-wrap: wrap;

    &::after {
        @include pos;
        top: 0;
        left: 0;
        @include pseudowh($width: 100%, $height: 189px);
        background: linear-gradient(180deg,
                rgba(155, 1, 68, 0.28) 0%,
                rgba(155, 1, 68, 0.28) 0.01%,
                rgba(155, 1, 68, 0) 100%);
        z-index: -1;
    }

    .card-image {
        position: relative;
        margin-bottom: 64px;

        .shape-image {
            position: absolute;
            @include center(center);
            width: 100%;
            z-index: -1;
        }

        .card-1,
        .card-2 {
            width: 80%;
        }

        .card-1 {
            transform: rotate(-19.58deg);
        }

        .card-2 {
            transform: rotate(11.07deg);
            margin-top: -40px;
        }
    }

    .onborder-content {
        h2 {
            font-weight: 400;
            font-size: 24px;
            color: rgba(var(--theme-light-gray), 1);
            margin-bottom: 13px;

            @include mq-max(md) {
                font-size: calc(21px + (24 - 21) * ((100vw - 320px) / (600 - 320)));
                margin-bottom: calc(7px + (13 - 7) * ((100vw - 320px) / (600 - 320)));
            }
        }

        p {
            font-weight: 400;
            font-size: 16px;
            margin: 0;
            color: rgba(var(--theme-gray), 1);

            @include mq-max(md) {
                font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
            }
        }
    }

    .onboarding-button-group {
        width: 100%;
        padding-inline: 15px;
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
    }
}

/* ---------- Blog Onboarding ---------- */
.blogging-onboarding {
    .onboarding-image {
        @include pseudowh($width: 100%, $height: 100vh);
        overflow: hidden;

        img {
            @include pseudowh;
            object-fit: cover;
        }
    }

    .onbording-content {
        position: fixed;
        bottom: 0;
        @include center(horizontal);
        @include pseudowh($width: 100%, $height: 430px);
        max-width: 600px;
        padding-inline: 15px;
        background: linear-gradient(0.73deg, rgba(var(--white), 1) 0.28%, rgba(var(--white), 0) 105.04%);
        display: flex;
        align-items: flex-end;

        .slider-content {
            margin-bottom: 67px;
            width: 100%;

            .onboarding-content-box {
                [dir="rtl"] & {
                    text-align: right;
                }

                h3 {
                    font-weight: 500;
                    font-size: 20px;
                    color: rgba(var(--title), 1);
                }

                p {
                    font-weight: 400;
                    font-size: 16px;
                    margin: 8px 0 0;
                    color: rgba(var(--content-dark), 1);
                }
            }
        }

        .next-button {
            @include pseudowh($width: 54px, $height: 54px);
            @include flex_common;
            border-radius: 100%;
            background-color: rgba(var(--title), 1);
            font-size: 28px;
            color: rgba(var(--white), 1);
            position: absolute;
            bottom: 44px;
            right: 15px;
            z-index: 1;

            [dir="rtl"] & {
                right: unset;
                left: 15px;
            }
        }
    }

    .author-box-9 {
        .form-style-9 {
            .forgot-text {
                [class="dark"] & {
                    color: #232526;
                }
            }
        }
    }
}

/* ---------- Fitness Onboarding ---------- */
.fitness-onboarding-box {
    background-image: url(../images/fitness/onboarding/1.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include pseudowh($width: 100%, $height: 100vh);
    position: relative;

    .forgot-text {
        color: rgba(255, 255, 255, 0.5);
    }

    .onboarding-content-box {
        position: fixed;
        bottom: 0;
        @include center(horizontal);
        @include pseudowh($width: 100%, $height: 418px);
        background: linear-gradient(0.41deg, #0b0b0b 0.17%, rgba(16, 19, 24, 0.604167) 69.47%, rgba(24, 31, 44, 0) 99.46%);
        display: flex;
        align-items: flex-end;
        padding-bottom: 53px;
        max-width: 600px;

        &.onboarding-small-space {
            padding-bottom: 34px;
        }

        .main-title-box {
            font-family: $rowdies;
            border-left: 6px solid rgba(var(--theme-color), 1);
            padding-left: 18px;
            margin-bottom: 30px;

            [dir="rtl"] & {
                border-right: 6px solid rgba(var(--theme-color), 1);
                border-left: unset;
                padding-right: 18px;
                padding-left: unset;
            }

            h1 {
                font-weight: 300;
                font-size: 35px;
                margin-bottom: 11px;
                color: rgba(var(--white), 1);
                line-height: 1;

                [class="dark"] & {
                    color: #efefef;
                }
            }

            h2 {
                font-weight: 300;
                font-size: 25px;
                color: #b1b1b1;
                line-height: 1;
            }
        }

        .fitness-button-group {
            width: 100%;
            font-family: "Nunito";
        }

        span {
            margin-top: 34px;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            display: block;
            color: rgba(255, 255, 255, 0.6);

            a {
                color: rgba(255, 255, 255, 1);
            }
        }
    }
}

/* ---------- NFT Onboarding ---------- */
.nft-onboarding-box {
    margin-top: 37px;
    padding-bottom: 20px;

    h2 {
        font-weight: 600;
        font-size: 25px;
        margin-bottom: 15px;
    }

    p {
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 26px;
    }
}