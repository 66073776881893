/**=====================
    4.5 Mobile Menu CSS
==========================**/
/* ---------- Mobile Style 1 ---------- */
.mobile-style-1 {
    position: fixed;
    @include center(horizontal);
    bottom: -1px;
    width: 100%;
    max-width: 600px;
    z-index: 2;
    background-color: rgba(var(--white), 1);
    padding: 10px 19px 19px;
    box-shadow: 0px 0px 4px rgba(var(--title), 0.1);

    @include mq-max(md) {
        padding: 10px calc(8px + (19 - 8) * ((100vw - 320px) / (600 - 320))) calc(13px + (19 - 13) * ((100vw - 320px) / (600 - 320)));
    }

    [class="dark"] & {
        background-color: #060d18;
        box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.1);
    }

    ul {
        @include flex_common($dis: flex, $align: center, $justify: space-between);

        li {
            .mobile-box {
                text-align: center;
                color: rgba(var(--theme-color2), 1);

                [class="dark"] & {
                    color: #ddd;
                }

                .mobile-icon {
                    font-size: 21px;
                    line-height: 1;
                    margin-bottom: 2px;
                    @include pseudowh($width: 45px, $height: 25px);
                    margin: 0 auto;

                    i {
                        font-size: 20px;
                    }
                }

                .mobile-name {
                    font-size: 13px;

                    h5 {
                        font-size: 11px;
                        font-weight: 400;
                    }
                }
            }

            &.active {
                .mobile-icon {
                    @include pseudowh($width: 50px, $height: 50px);
                    margin-top: -30px;
                    position: relative;
                    z-index: 0;

                    &::before {
                        @include pos;
                        @include center(both);
                        @include pseudowh($width: 120%, $height: 120%);
                        background: linear-gradient(0deg, rgba(var(--black), 0) 50%, rgba(255, 255, 255, 1) 50%);
                        z-index: -1;
                        border-radius: 100%;
                        box-shadow: 0px -1px 4px rgba(var(--black), 0.08);

                        [class="dark"] & {
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 50%, #060d18 50%);
                        }
                    }

                    i {
                        @include flex_common;
                        background: var(--gradient);
                        @include pseudowh($width: 50px, $height: 50px);
                        color: rgba(var(--white), 1);
                        border-radius: 100%;

                        [class="dark"] & {
                            background: #121924;
                            color: #fff;
                        }
                    }
                }

                .mobile-name {
                    margin-top: 10px;

                    h5 {
                        font-weight: 700;
                        color: rgba(var(--theme-color), 1);
                        font-size: 13px;

                        [class="dark"] & {
                            color: #fff;
                        }
                    }
                }

                .mobile-box {
                    color: rgba(var(--theme-color), 1);
                }
            }
        }
    }
}

/* ---------- Mobile Style 2 ---------- */
.mobile-style-2 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    padding: 20px 15px;
    max-width: 600px;
    z-index: 1;
    background-color: rgba(var(--white), 1);

    @include mq-max(md) {
        padding: calc(15px + (20 - 15) * ((100vw - 320px) / (600 - 320))) 15px;
    }

    [class="dark"] & {
        background-color: #1f2a36;
    }

    .mobile-list {
        background-color: rgba(var(--grey), 1);
        border-radius: 50px;
        padding: 9px 54px;
        @include flex_common($dis: flex, $align: center, $justify: space-between);

        [class="dark"] & {
            background-color: #374151;
        }

        @include mq-max(md) {
            padding: 9px calc(27px + (54 - 27) * ((100vw - 320px) / (600 - 320)));
        }

        li {
            &.active {
                a {
                    i {
                        &.unactive-icon {
                            display: none;
                        }

                        &.active-icon {
                            display: flex;
                        }
                    }
                }
            }

            a {
                text-align: center;
                color: rgba(var(--title), 1);
                display: block;

                i {
                    font-size: 24px;
                    line-height: 1;
                    @include pseudowh($width: 24px, $height: 24px);
                    @include flex_common;
                    margin: 0 auto;

                    &.active-icon {
                        display: none;
                    }
                }

                h5 {
                    font-weight: 400;
                    font-size: 14px;
                    margin-top: 4px;
                }
            }
        }
    }
}

/* ---------- Mobile Style 3 ---------- */
.mobile-style-3 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    padding: 0 20px 15px;
    max-width: 600px;
    background-color: rgba(var(--light-bg-color), 0.57);
    z-index: 9;
    backdrop-filter: blur(3.5px);

    [class="dark"] & {
        background-color: rgba(27, 27, 27, 0.57);
    }

    @include mq-max(md) {
        padding: 0 calc(11px + (20 - 11) * ((100vw - 320px) / (600 - 320))) calc(8px + (15 - 8) * ((100vw - 320px) / (600 - 320)));

        [dir="rtl"] & {
            padding: 0 calc(11px + (20 - 11) * ((100vw - 320px) / (600 - 320))) calc(8px + (15 - 8) * ((100vw - 320px) / (600 - 320)));
        }
    }

    ul {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        background-color: rgba(var(--white), 1);
        box-shadow: $shadow3;
        border-radius: 18px;
        padding: 10px 29px;

        [class="dark"] & {
            background-color: #171717;
            box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
        }

        @include mq-max(md) {
            padding-inline: calc(19px + (29 - 19) * ((100vw - 320px) / (600 - 320)));

            [dir="rtl"] & {
                padding-inline: calc(19px + (29 - 19) * ((100vw - 320px) / (600 - 320)));
            }
        }

        li {
            &.active {
                a {
                    color: rgba(var(--title), 1);

                    &::before {
                        opacity: 1;
                    }

                    svg {
                        stroke: rgba(var(--title), 1);
                    }

                    h5 {
                        font-weight: 600;
                    }
                }
            }

            a {
                text-align: center;
                display: block;
                color: rgba(var(--content), 1);
                position: relative;
                width: 100%;

                &::before {
                    @include pos;
                    top: -10px;
                    left: 0;
                    @include pseudowh($width: 100%, $height: 2px);
                    background-color: rgba(var(--primary), 1);
                    opacity: 0;
                }

                i {
                    line-height: 1;
                    font-size: 22px;
                }

                svg {
                    @include pseudowh($width: 20px, $height: 20px);
                    fill: transparent;
                    stroke: rgba(var(--content), 1);
                    margin-bottom: 5px;
                }
            }
        }
    }
}

/* ---------- Mobile Style 4 ---------- */
.mobile-style-4 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    max-width: 600px;
    background-color: rgba(var(--white), 1);
    z-index: 9;
    box-shadow: 0px -1px 4px rgba(var(--black), 0.04);

    [class="dark"] & {
        background-color: #282d30;
    }

    ul {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        padding: 13px 17px;

        [dir="rtl"] & {
            padding: 13px 17px;
        }

        li {
            text-align: center;

            &.active {
                .mobile-box {
                    i {
                        &::after {
                            @include pos;
                            top: 0;
                            left: 0;
                            @include pseudowh($width: 12px, $height: 12px);
                            border-radius: 100%;
                            background: var(--gradient-color);
                            opacity: 0.5;
                            z-index: -1;
                        }
                    }
                }
            }

            .mobile-box {
                display: block;
                line-height: 1;

                i {
                    font-size: 20px;
                    color: rgba(var(--title), 1);
                    position: relative;
                }
            }
        }
    }
}

/* ---------- Mobile Style 5 ---------- */
.mobile-style-5 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    max-width: 600px;
    background-color: rgba(var(--dark-bg), 1);
    z-index: 9;
    box-shadow: 0px -1px 4px rgba(var(--black), 0.04);
    width: calc(100% + 1px);

    [class="dark"] & {
        background-color: rgb(55, 55, 55);
    }

    ul {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        padding: 17px 37px;

        [dir="rtl"] & {
            padding: 17px 37px;
        }

        @include mq-max(md) {
            padding: calc(14px + (17 - 14) * ((100vw - 320px) / (600 - 320))) calc(23px + (37 - 23) * ((100vw - 320px) / (600 - 320)));

            [dir="rtl"] & {
                padding: calc(14px + (17 - 14) * ((100vw - 320px) / (600 - 320))) calc(23px + (37 - 23) * ((100vw - 320px) / (600 - 320)));
            }
        }

        li {
            text-align: center;

            &.active {
                .mobile-box {
                    color: rgba(var(--content), 1);

                    [class="dark"] & {
                        color: #9f9f9f;
                    }

                    h6 {
                        display: block;
                    }
                }
            }

            .mobile-box {
                color: rgb(147, 147, 147);
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                gap: 6px;

                i {
                    font-size: 20px;
                    position: relative;
                    line-height: 1;
                }

                h6 {
                    padding-top: 3px;
                    display: none;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
    }
}

/* ---------- Mobile Style 6 ---------- */
.mobile-style-6 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    background-color: rgba(var(--theme-color), 1);
    width: 100%;
    max-width: 600px;
    padding: 12px 20px;
    z-index: 2;

    ul {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);

        li {
            &.active {
                a {
                    color: rgba(var(--white), 1);

                    [class="dark"] & {
                        color: #fff;
                    }
                }
            }

            a {
                color: rgba(var(--white), 0.5);
                display: block;
                text-align: center;

                [class="dark"] & {
                    color: rgba(255, 255, 255, 0.5);
                }

                i {
                    font-size: 22px;
                }

                h6 {
                    font-weight: 600;
                    font-size: 12px;
                }
            }
        }
    }
}

/* ---------- Mobile Style 7 ---------- */
.mobile-style-7 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    background-color: rgb(3, 3, 3);
    border: 1px solid rgb(21, 21, 21);
    width: 100%;
    max-width: 600px;
    z-index: 2;

    ul {
        padding: 9px 21px 12px;
        @include flex_common ($dis: flex, $align: center, $justify: space-between);

        [dir="rtl"] & {
            padding: 9px 21px 12px;
        }

        li {
            &.active {
                a {
                    color: rgba(var(--theme-maroon), 1);

                    &::after {
                        opacity: 1;
                    }
                }
            }

            a {
                position: relative;
                color: rgba(var(--white), 0.5);
                display: block;
                text-align: center;

                &::after {
                    @include pos;
                    top: -10px;
                    left: 0;
                    @include pseudowh($width: 100%, $height: 2px);
                    background-color: rgba(var(--theme-maroon), 1);
                    opacity: 0;
                }

                i {
                    font-size: 22px;
                }

                h6 {
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }

    .qr-code-scanner-btn {
        display: flex;
        align-items: center;
        position: absolute;
        top: -66px;
        @include center(horizontal);
        padding: 13px 22px;
        background-color: rgba(var(--theme-maroon), 0.3);
        border: 1px solid rgba(var(--theme-maroon), 0.6);
        border-radius: 100px;
        color: rgba(var(--white), 1);
        gap: 14px;

        i {
            line-height: 1;
            font-size: 15px;
        }

        h5 {
            white-space: nowrap;
            line-height: 1;
        }
    }
}

/* ---------- Mobile Style 8 ---------- */
.mobile-style-8 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    background-color: rgba(var(--white), 1);
    width: 100%;
    max-width: 600px;
    z-index: 2;
    border-top: 1px solid rgba(42, 42, 114, 0.13);

    [class="dark"] & {
        background-color: #18181a;
        border-color: rgba(255, 255, 255, 0.1);
    }

    ul {
        padding: 9px 21px 12px;
        @include flex_common ($dis: flex, $align: center, $justify: space-between);

        @include mq-max(md) {
            padding-inline: calc(15px + (21 - 15) * ((100vw - 320px) / (600 - 320)));
        }

        [dir="rtl"] & {
            padding: 9px 21px 12px;
        }

        li {
            &.active {
                a {
                    color: rgba(var(--theme-color), 1);

                    &::after {
                        opacity: 1;
                    }

                    i {
                        text-shadow: 0px 4px 4px rgba(var(--black), 0.17);
                    }

                    h6 {
                        font-weight: 600;
                    }
                }
            }

            a {
                position: relative;
                color: #919191;
                display: block;
                text-align: center;

                &::after {
                    @include pos;
                    top: -10px;
                    left: 0;
                    @include pseudowh($width: 100%, $height: 2px);
                    background-color: rgba(var(--theme-color), 1);
                    opacity: 0;
                }

                i {
                    font-size: 24px;

                    @include mq-max(md) {
                        font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (600 - 320)));
                    }
                }

                h6 {
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }

    .qr-code-scanner-btn {
        display: flex;
        align-items: center;
        position: absolute;
        top: -66px;
        @include center(horizontal);
        padding: 13px 22px;
        background-color: rgba(var(--theme-maroon), 0.3);
        border: 1px solid rgba(var(--theme-maroon), 0.6);
        border-radius: 100px;
        color: rgba(var(--white), 1);
        gap: 14px;

        i {
            line-height: 1;
            font-size: 15px;
        }

        h5 {
            line-height: 1;
        }
    }
}

/* ---------- Mobile Style 9 ---------- */
.mobile-style-9 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    background-color: rgba(var(--white), 1);
    width: 100%;
    max-width: 600px;
    z-index: 2;
    box-shadow: 0px 0px 13px rgba(var(--black), 0.04);

    ul {
        padding: 10px 13px;
        @include flex_common ($dis: flex, $align: center, $justify: space-between);

        [dir="rtl"] & {
            padding: 10px 13px;
        }

        li {
            &.active {
                a {
                    color: rgba(var(--title), 1);
                }
            }

            a {
                position: relative;
                color: rgba(var(--content), 1);
                display: block;
                text-align: center;

                i {
                    font-size: 24px;
                }

                h6 {
                    font-weight: 500;
                    font-size: 13px;
                }
            }

            &.cart-button {
                a {
                    @include pseudowh($width: 41px, $height: 41px);
                    background-color: rgba(var(--theme-color), 1);
                    color: rgba(var(--white), 1);
                    border-radius: 100%;
                    @include flex_common;

                    i {
                        font-size: 21px;
                        line-height: 1;
                    }
                }
            }
        }
    }
}

/* ---------- Mobile Style 10 ---------- */
.mobile-style-10 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    background-color: rgba(var(--white), 1);
    width: 100%;
    max-width: 600px;
    z-index: 2;
    box-shadow: 0px 0px 8px rgba(var(--black), 0.14);

    [class="dark"] & {
        background-color: #212332;
    }

    ul {
        padding: 22px 34px;
        @include flex_common ($dis: flex, $align: center, $justify: space-between);

        [dir="rtl"] & {
            padding: 22px 34px;
        }

        li {
            &.active {
                a {
                    &:before {
                        opacity: 1;
                    }

                    svg {
                        stroke: rgba(var(--theme-color), 1);
                    }
                }
            }

            a {
                position: relative;

                &:before {
                    @include pos;
                    top: -7px;
                    @include center(horizontal);
                    @include pseudowh($width: 4px, $height: 4px);
                    background: var(--gradient-color);
                    border-radius: 100%;
                    opacity: 0;
                }

                svg {
                    fill: transparent;
                    stroke: #a0a0a0;
                    @include pseudowh($width: 24px, $height: 24px);
                }

                h6 {
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }
}