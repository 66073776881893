/*=====================
   5.12 Learning Application CSS
==========================*/
/* ---------- Continue Reading CSS ---------- */
.continue-reading-section {
    .reading-list {
        position: relative;

        &::after {
            @include pos;
            bottom: -5px;
            right: -5px;
            @include pseudowh;
            background: var(--gradient);
            z-index: -1;
            opacity: 0.1;
            border-radius: 10px;
        }

        .reading-box {
            padding: 12px;
            box-shadow: 0px 0px 4px rgba(var(--title), 0.09);
            border-radius: 10px;
            position: relative;
            background-color: rgba(var(--white), 1);
            z-index: 0;

            [dir="rtl"] & {
                direction: rtl;
            }

            [class="dark"] & {
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.09);
                background-color: #060d18;
            }

            .reading-detail {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
                align-items: center;

                .reading-image {
                    @include pseudowh($width: 54px, $height: 52px);
                    border-radius: 5px;
                    overflow: hidden;

                    img {
                        @include pseudowh;
                        object-fit: cover;
                    }
                }

                .reading-name {
                    width: calc(100% - 54px - 12px);

                    h5 {
                        color: rgba(var(--title), 1);
                        line-height: 1.3;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        font-size: 14px;
                    }
                }
            }

            .reading-view {
                margin-top: 12px;

                .progress {
                    height: 3px;
                    border-radius: 50px;

                    [class="dark"] & {
                        background-color: rgba(255, 255, 255, 0.08);
                    }

                    .progress-bar {
                        width: 50%;
                        background-color: rgba(var(--theme-color), 1);
                    }
                }

                h6 {
                    margin-top: 6px;
                    font-size: 13px;

                    [class="dark"] & {
                        color: #ddd;
                    }
                }
            }
        }
    }
}

/* ---------- Category CSS ---------- */
.category-section {
    .category-box {
        display: block;
        padding-block: 3px;

        >li {
            +li {
                margin-top: 11px;
            }

            .category-list {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 10px);
                align-items: center;

                li {
                    border-radius: 50px;

                    a {
                        white-space: nowrap;
                        padding: 10px 21px;
                        border-radius: 100px;
                        background-color: rgba(var(--white), 1);
                        box-shadow: 0px 0px 6px rgba(var(--black), 0.06);
                        position: relative;
                        color: rgba(var(--title), 1);
                        display: block;

                        [class="dark"] & {
                            background-color: #060d18;
                            box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.08);
                        }

                        @include mq-max(md) {
                            padding: calc(7px + (10 - 7) * ((100vw - 320px) / (600 - 320))) calc(13px + (21 - 13) * ((100vw - 320px) / (600 - 320)));
                            font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (600 - 320)));
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Feature Course CSS ---------- */
.feature-course-section {
    .feature-course-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        @include mq-max(md) {
            gap: calc(9px + (16 - 9) * ((100vw - 320px) / (600 - 320)));
        }

        li {
            .feature-box {
                position: relative;
                border-radius: 9px;
                padding: 9px;
                z-index: 0;
                overflow: hidden;
                display: block;

                &::before {
                    @include pos;
                    top: 0;
                    left: 0;
                    @include pseudowh;
                    background: var(--gradient);
                    z-index: -1;
                    opacity: 0.05;

                    [class="dark"] & {
                        background: #fff;
                    }
                }

                .feature-image {
                    display: block;
                    border-radius: 6px;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }
                }

                .feature-detail {
                    margin-top: 6px;

                    h6 {
                        font-size: 13px;
                        color: rgba(var(--title), 1);
                        line-height: 1.4;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        font-weight: 600;
                    }

                    .rate-course {
                        @include flex_common($dis: flex, $align: center, $justify: space-between);
                        margin-top: 8px;

                        li {
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 3px);
                            align-items: center;
                            color: rgba(var(--title), 1);
                            font-size: 13px;

                            i {
                                color: rgba(var(--yellow), 1);
                            }
                        }
                    }
                }

                .remove-button {
                    position: absolute;
                    top: 16px;
                    right: 16px;
                    background-color: rgba(var(--white), 1);
                    padding: 0;
                    line-height: 1;
                    @include pseudowh($width: 26px, $height: 26px);
                    font-size: 16px;
                    border-radius: 100%;
                    @include flex_common;

                    @include mq-max(md) {
                        @include pseudowh($width: calc(22px + (26 - 22) * ((100vw - 320px) / (600 - 320))), $height: calc(22px + (26 - 22) * ((100vw - 320px) / (600 - 320))));
                        top: calc(12px + (16 - 12) * ((100vw - 320px) / (600 - 320)));
                        right: calc(12px + (16 - 12) * ((100vw - 320px) / (600 - 320)));
                        font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }
        }
    }
}

/* ---------- Popular Course CSS ---------- */
.popular-course-section {
    .popular-box {
        padding: 9px;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 0px 5px rgba(var(--black), 0.08);
        border-radius: 9px;
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
        align-items: center;
        position: relative;
        z-index: 0;

        &::before {
            @include pos;
            top: 0;
            left: 0;
            @include pseudowh;
            background: var(--gradient);
            opacity: 0.05;
            z-index: -1;

            [class="dark"] & {
                background: #fff;
            }
        }

        .popular-image {
            border-radius: 8px;
            overflow: hidden;
            @include pseudowh($width: 117px, $height: 84px);

            @include mq-max(md) {
                width: calc(105px + (117 - 105) * ((100vw - 320px) / (600 - 320)));
            }

            img {
                @include pseudowh;
                object-fit: cover;
            }
        }

        .popular-detail {
            width: calc(100% - 117px - 9px);

            @include mq-max(md) {
                width: calc(100% - calc(105px + (117 - 105) * ((100vw - 320px) / (600 - 320))) - 9px);
            }

            h5 {
                color: rgba(var(--title), 1);
                line-height: 1.4;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }

            h6 {
                font-size: 13px;
            }

            .video-play {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 3px);
                margin: 4px 0 10px;
                color: rgba(var(--content), 1);
                align-items: center;

                i,
                span {
                    line-height: 1;
                    font-size: 13px;
                }

                span {
                    font-size: 13px;
                }
            }

            .rating-box {
                @include flex_common($dis: flex, $align: center, $justify: space-between);

                >li {
                    line-height: 1;
                    font-size: 13px;

                    [class="dark"] & {
                        color: #ddd;
                    }

                    .rating {
                        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 3px);
                        align-items: center;

                        >li {
                            line-height: 1;
                            font-size: 12px;

                            i {
                                color: rgba(var(--yellow), 1);
                            }
                        }
                    }
                }
            }
        }
    }

    .popular-course-list {
        li {
            +li {
                margin-top: 16px;
            }

            .popular-box-2 {
                padding: 9px;
                border-radius: 5px;
                overflow: hidden;
                box-shadow: 0px 0px 5px rgba(var(--black), 0.08);
                border-radius: 9px;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                align-items: center;
                position: relative;
                z-index: 0;

                &::before {
                    @include pos;
                    top: 0;
                    left: 0;
                    @include pseudowh;
                    background: var(--gradient);
                    opacity: 0.05;
                    z-index: -1;
                }

                .popular-image {
                    border-radius: 8px;
                    overflow: hidden;
                    @include pseudowh($width: 96px, $height: 70px);
                }

                .popular-detail {
                    width: calc(100% - 96px - 9px);

                    a {
                        h5 {
                            font-size: 15px;
                            font-weight: 600;
                            width: 90%;
                        }
                    }

                    h5 {
                        font-size: 13px;
                        color: rgba(var(--title), 1);
                        line-height: 1.4;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                    }
                }

                .remove-button {
                    line-height: 1;
                    @include pseudowh($width: 18px, $height: 18px);
                    background-color: rgba(var(--white), 1);
                    box-shadow: 0px 0px 2px rgba(var(--black), 0.08);
                    border-radius: 3px;
                    color: rgba(var(--title), 1);
                    position: absolute;
                    top: 5px;
                    right: 5px;

                    [class="dark"] & {
                        background-color: #121924;
                    }

                    [dir="rtl"] & {
                        right: unset;
                        left: 5px;
                    }
                }
            }
        }
    }
}

/* ---------- Top instructors CSS ---------- */
.top-instructors-section {
    .instructors-box {
        .instructors-list {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);
            align-items: center;
            white-space: nowrap;
            overflow: auto hidden;
            flex-wrap: nowrap;
            padding-bottom: 8px;

            li {
                .instructors-contain {
                    text-align: center;

                    .instructors-image {
                        @include pseudowh($width: 64px, $height: 64px);
                        border-radius: 100%;
                        overflow: hidden;
                        margin: 0 auto;
                    }

                    .instructors-detail {
                        margin-top: 5px;

                        h5 {
                            color: rgba(var(--title), 1);
                            font-size: 14px;
                        }

                        h6 {
                            margin-top: 5px;
                            color: rgba(var(--content), 1);
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Coupon CSS ---------- */
.coupon-section {
    .coupon-box {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);
        align-items: center;
        padding: 15px;
        background-color: #f5f7f7;
        border-radius: 4px;
        color: rgba(var(--title), 1);
        position: relative;

        [class="dark"] & {
            background-color: #121924;
        }

        &::before {
            @include pos;
            bottom: -5px;
            right: -5px;
            @include pseudowh;
            background: var(--gradient);
            opacity: 0.08;
            border-radius: 4px;
            z-index: -1;
        }

        svg {
            fill: rgba(var(--title), 1);
            @include pseudowh($width: 18px, $height: 18px);
            @include flex_common;
        }

        span {
            font-weight: 400;
            font-size: 14px;
        }

        i {
            margin-left: auto;
            font-size: 19px;
            line-height: 1;

            [dir="rtl"] & {
                margin-left: unset;
                margin-right: auto;
            }
        }
    }
}

/* ---------- Summary CSS ---------- */
.summary-section {
    .summary-table {
        tbody {
            tr {
                &:first-child {
                    td {
                        padding-inline: 0;
                        padding-top: 0;

                        &:last-child {
                            padding-inline: 0;
                            text-align: right;
                            padding-top: 0;

                            [dir="rtl"] & {
                                text-align: left;
                            }
                        }
                    }
                }

                td {
                    color: rgba(var(--title), 1);
                    padding: 6px 0;
                    border: none;
                    font-size: 15px;

                    &:last-child {
                        text-align: right;

                        [dir="rtl"] & {
                            text-align: left;
                        }
                    }

                    &.text-danger {
                        color: rgba(var(--danger), 1);
                    }
                }

                &:last-child {
                    td {
                        border-bottom: 1px solid rgba(157, 172, 177, 0.2);
                        padding-bottom: 14px;
                    }
                }
            }
        }

        tfoot {
            tr {
                td {
                    padding: 12px 0 0;
                    border: none;
                    color: rgba(var(--title), 1);
                    font-weight: 500;
                    font-size: 16px;

                    &:last-child {
                        text-align: right;

                        [dir="rtl"] & {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Total Box CSS ---------- */
.total-box {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    padding: 15px 18px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0px 0px 4px rgba(var(--black), 0.09);
    background-color: rgba(var(--white), 1);
    z-index: 9;

    [class="dark"] & {
        background-color: #121924;
    }

    a {
        background: var(--gradient);
        box-shadow: 0px 0px 4px rgba(var(--black), 0.09);
        border-radius: 10px;
        color: rgba(var(--white), 1);
        padding: 10px 17px;
        display: flex;
        align-items: center;

        [class="dark"] & {
            color: #fff;
        }

        .total-left {
            padding-right: calc(7px + (21 - 7) * ((100vw - 320px) / (1920 - 320)));
            border-right: 1px solid rgba(var(--content), 0.2);

            [dir="rtl"] & {
                padding-left: calc(7px + (21 - 7) * ((100vw - 320px) / (1920 - 320)));
                padding-right: unset;
                border-left: 1px solid rgba(var(--content), 0.2);
                border-right: unset;
            }

            h5 {
                color: rgba(var(--content), 1);
                margin-bottom: 2px;
                font-size: 13px;
            }

            h4 {
                font-size: 15px;
            }
        }

        .total-right {
            width: 100%;
            @include flex_common;

            h4 {
                line-height: 1;
            }

            i {
                font-size: 23px;
                line-height: 1;

                &:nth-child(2) {
                    [dir="rtl"] & {
                        margin-left: -16px;
                    }
                }

                &:last-child {
                    margin-left: -16px;

                    [dir="rtl"] & {
                        margin-left: unset;
                    }
                }
            }
        }
    }
}

.total-box-2 {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    padding: 15px 18px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0px 0px 4px rgba(var(--black), 0.09);
    background-color: rgba(var(--white), 1);
    z-index: 9;

    [class="dark"] & {
        background-color: #121924;
    }

    a {
        background: var(--gradient);
        box-shadow: 0px 0px 4px rgba(var(--black), 0.09);
        border-radius: 10px;
        color: rgba(var(--white), 1);
        padding: 10px 17px;
        display: flex;
        align-items: center;

        [class="dark"] & {
            color: #fff;
        }

        .total-left {
            padding-right: 18px;
            border-right: 1px solid rgba(var(--content), 0.2);

            [dir="rtl"] & {
                padding-right: unset;
                padding-left: 18px;
                border-right: unset;
                border-left: 1px solid rgba(var(--content), 0.2);
            }

            h5 {
                color: rgba(var(--white), 1);
                font-size: 18px;
                margin: 0;
                line-height: 1;
                @include pseudowh($width: 24px, $height: 24px);
                @include flex_common;

                @include mq-max(md) {
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
                }

                [class="dark"] & {
                    color: #fff;
                }
            }

            h4 {
                font-size: 15px;

                @include mq-max(md) {
                    font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }

        .total-right {
            width: 100%;
            flex-wrap: wrap;
            gap: 17px;
            @include flex_common;

            @include mq-max(md) {
                gap: calc(8px + (17 - 8) * ((100vw - 320px) / (600 - 320)));
            }

            i {
                font-size: 19px;
                line-height: 1;
            }

            h4 {
                line-height: 1;
            }
        }
    }
}

/* ---------- Payment CSS ---------- */
.payment-section {
    .payment-accordion {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 9px);

        .accordion-item {
            border: none;
            width: 100%;
            background-color: transparent;

            .accordion-header {
                .accordion-button {
                    display: block;
                    border: 1px solid rgba(var(--content), 0.2);
                    background-color: transparent;
                    box-shadow: none;
                    padding: 12px;
                    border-radius: calc(0.375rem - 1px);
                    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 9px);

                    &::after {
                        @include pos($pos: absolute, $content: "\ea4e");
                        @include font;
                        right: 11px;
                        background: unset;
                        color: rgba(var(--title), 1);
                        font-size: 21px;
                        @include pseudowh($width: unset, $height: unset);
                    }

                    @include mq-max(md) {
                        padding: calc(8px + (12 - 8) * ((100vw - 320px) / (600 - 320)));
                    }

                    .payment-box {
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
                        align-items: center;

                        .payment-image {
                            @include flex_common;
                            @include pseudowh($width: 39px, $height: 39px);
                            background-color: rgba(var(--content), 0.2);
                            box-shadow: 2px 1px 4px rgba(var(--black), 0.05);
                            border-radius: 4px;
                            position: relative;

                            [class="dark"] & {
                                background-color: #060d17;
                                box-shadow: 2px 1px 4px rgba(255, 255, 255, 0.02);
                            }
                        }

                        h5 {
                            font-size: 14px;
                            color: rgba(var(--title), 1);
                            width: calc(100% - 39px - 11px);
                        }
                    }
                }
            }

            .accordion-body {
                @include mq-max(md) {
                    padding: calc(12px + (16 - 12) * ((100vw - 320px) / (600 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (600 - 320)));
                }

                .net-banking-list {
                    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 6px);
                    align-items: center;

                    li {
                        display: block;
                        width: 100%;

                        .form-check {
                            padding: 0;

                            .form-check-input {
                                margin: 0;
                                padding: 0;
                                float: unset;
                                background-image: unset;
                                @include pseudowh($width: 18px, $height: 18px);
                                position: relative;
                                background-color: rgba(var(--content), 0.2);
                                box-shadow: none;
                                border: 1px solid transparent;
                                transition: all 0.3s ease-in-out;

                                &::after {
                                    @include pos;
                                    @include pseudowh($width: 10px, $height: 10px);
                                    background-color: rgba(var(--theme-color), 1);
                                    border-radius: 100%;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%) scale(0);
                                    transition: all 0.3s ease-in-out;

                                    [class="dark"] & {
                                        background-color: #c5c5c5;
                                    }
                                }

                                &:checked {
                                    border-color: rgba(var(--theme-color), 1);
                                    background-color: rgba(var(--white), 1);

                                    &::after {
                                        transform: translate(-50%, -50%) scale(1);
                                    }
                                }
                            }

                            .form-check-label {
                                margin-left: 10px;

                                [dir="rtl"] & {
                                    margin-left: unset;
                                    margin-right: 10px;
                                }

                                @include mq-max(md) {
                                    margin-left: calc(6px + (10 - 6) * ((100vw - 320px) / (600 - 320)));

                                    [dir="rtl"] & {
                                        margin-left: unset;
                                        margin-right: calc(6px + (10 - 6) * ((100vw - 320px) / (600 - 320)));
                                    }
                                }

                                [class="dark"] & {
                                    color: #f5f7f7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Video CSS ---------- */
.video-name-section {
    .name-title {
        h5 {
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 4px;
            color: rgba(var(--orange), 1);
        }

        h4 {
            font-weight: 600;
            font-size: 16px;
            color: rgba(var(--title), 1);
            margin-bottom: 5px;

            @include mq-max(md) {
                font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (600 - 320)));
            }
        }

        .course-number {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 25px);
            align-items: center;

            li {
                font-weight: 400;
                font-size: 14px;
                color: rgba(var(--content), 1);
                position: relative;

                @include mq-max(md) {
                    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (600 - 320)));
                }

                +li {
                    &::before {
                        @include pos;
                        @include center(vertical);
                        left: -14px;
                        @include pseudowh($width: 4px, $height: 4px);
                        background-color: rgba(var(--content), 1);
                        border-radius: 100%;

                        [dir="rtl"] & {
                            left: unset;
                            right: -14px;
                        }
                    }
                }
            }
        }
    }

    .name-box {
        align-items: flex-end;
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 14px);

        .course-price {
            padding: 11px 8px;
            background-color: rgba(var(--content), 0.1);
            box-shadow: 0px 0px 0px rgba(var(--black), 0.25);
            border-radius: 6px;
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 3px);
            justify-content: center;
        }
    }
}

/* ---------- About Course CSS ---------- */
.about-course-section {
    .about-detail {
        position: relative;
        padding-bottom: 28px;

        p {
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            color: rgba(var(--content), 1);
            line-height: 1.5;

            &.more {
                display: none;
            }
        }

        .hide-show-button {
            font-size: 22px;
            background-color: rgba(var(--white), 1);
            background: linear-gradient(0deg, rgba(var(--white), 1) 0%, rgba(237, 237, 237, 0) 100%);
            position: absolute;
            bottom: 0;
            left: 0;
            color: rgba(var(--title), 1);
            border: none;
            padding: 0;

            [class="dark"] & {
                background: transparent;
            }
        }
    }
}

/* ---------- Instructors Section CSS ---------- */
.instructors-section {
    .instructors-box {
        align-items: center;
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);

        .instructors-image {
            @include pseudowh($width: 104px, $height: 104px);
            border-radius: 6px;
            overflow: hidden;
        }

        .instructors-content {
            width: calc(100% - 104px - 12px);

            h3 {
                font-weight: 500;
                font-size: 17px;
                color: rgba(var(--title), 1);
                margin-bottom: 7px;
            }

            .review-list {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 2px 8px);
                align-items: center;
                margin-bottom: 10px;

                @media (max-width: 349px) {
                    gap: 1px;
                }

                li {
                    font-weight: 400;
                    font-size: 13px;
                    color: rgba(var(--content), 1);

                    span {
                        color: rgba(var(--title), 1);
                    }
                }
            }

            .social-media {
                align-items: center;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 13px);

                li {
                    font-size: 18px;
                    line-height: 1;

                    a {
                        color: rgba(var(--title), 1);
                    }
                }
            }
        }
    }
}

/* ---------- Account Security CSS ---------- */
.account-security-section {
    .account-email-box {
        .email-box {
            position: relative;

            &.with-icon {
                .form-control {
                    padding-right: 63px;

                    [dir="rtl"] & {
                        padding-right: 17px;
                        padding-left: 63px;
                    }
                }
            }

            .email-button {
                position: absolute;
                top: 0;
                right: 0;
                @include pseudowh($width: 50px, $height: 100%);
                font-size: 20px;
                color: rgba(var(--title), 1);
                @include flex_common;
                background-color: transparent;

                &:active {
                    border-color: transparent;
                }

                [dir="rtl"] & {
                    right: unset;
                    left: 0;
                }
            }
        }
    }
}

/* ---------- App Settings CSS ---------- */
.app-setting-section {
    .sidebar-menu {
        li {
            +li {
                border-top: 1px solid rgba(var(--title), 0.1);
                padding-top: 9px;
                margin-top: 9px;
            }

            .menu-list {
                @include flex_common($dis: flex, $align: center, $justify: space-between);

                .list-icon {
                    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 9px);
                    align-items: center;

                    i {
                        font-size: 20px;
                        color: rgba(var(--title), 1);
                        position: relative;
                    }

                    h4 {
                        font-weight: 600;
                    }
                }

                .form-switch {
                    padding: 0;
                    min-height: unset;
                    margin: 0;

                    .form-check-input {
                        @include pseudowh($width: 56px, $height: 28px);
                        background-color: rgba(var(--content), 1);
                        border: none;
                        background-image: unset;
                        position: relative;
                        margin: 0;
                        float: unset;
                        border-radius: 100px;
                        transition: 0.4s;

                        @include mq-max(md) {
                            @include pseudowh($width: calc(45px + (56 - 45) * ((100vw - 320px) / (600 - 320))), $height: calc(22px + (28 - 22) * ((100vw - 320px) / (600 - 320))));
                        }

                        &:active {
                            filter: unset;
                        }

                        &:focus {
                            box-shadow: unset;
                        }

                        &::after {
                            @include pos;
                            background-color: rgba(var(--white), 1);
                            @include center(vertical);
                            @include pseudowh($width: 23px, $height: 23px);
                            left: 3px;
                            border-radius: 100%;
                            transition: 0.4s;

                            [dir="rtl"] & {
                                background-color: rgba(221, 221, 221, 1);
                            }

                            @include mq-max(md) {
                                @include pseudowh($width: calc(19px + (23 - 19) * ((100vw - 320px) / (600 - 320))), $height: calc(19px + (23 - 19) * ((100vw - 320px) / (600 - 320))));
                                left: calc(2px + (3 - 2) * ((100vw - 320px) / (600 - 320)));
                            }
                        }

                        &:checked {
                            background-color: rgba(var(--theme-color), 1);

                            &::after {
                                transform: translateX(27px) translateY(-50%);

                                [dir="rtl"] & {
                                    background-color: rgba(221, 221, 221, 1);
                                }

                                @include mq-max(md) {
                                    transform: translateX(calc(22px + (27 - 22) * ((100vw - 320px) / (600 - 320)))) translateY(-50%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Personal Settings CSS ---------- */
.edit-profile-section {
    .edit-image {
        .profile-pic {
            color: transparent;
            transition: all 0.3s ease;
            @include flex_common;
            position: relative;
            overflow: hidden;
            padding: 4px;
            width: 115px;
            margin: 0 auto;

            input {
                z-index: 2;
                position: absolute;
                top: 0;
                left: 0;
                @include pseudowh;
                cursor: pointer;
                opacity: 0;
            }

            img {
                object-fit: cover;
                @include pseudowh($width: 115px, $height: 115px);
                border: 6px solid rgba(var(--white), 1);
                box-shadow: 0 0 10px 0 rgba(var(--black), 0.1);
                border-radius: 100px;
                z-index: 0;

                [class="dark"] & {
                    border-color: #121924;
                }
            }

            .-label {
                cursor: pointer;
                @include pseudowh($width: 115px, $height: 115px);
            }

            span {
                font-size: 25px;
            }

            .edit {
                position: absolute;
                bottom: 5px;
                @include center(horizontal);
                z-index: 1;
                color: #4a4a4a;
                @include flex_common;
                padding: 3px 7px;
                border-radius: 4px;
                gap: 6px;
                background-color: #fff;
                box-shadow: 0 0 8px #ddd;

                i {
                    font-size: 13px;
                }

                span {
                    font-size: 12px;
                }
            }
        }
    }
}

/* ---------- Account name CSS ---------- */
.account-name-section {
    .account-form-box {
        form {
            .select-gender {
                .gender-list {
                    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 11px);
                    align-items: center;

                    li {
                        .form-check {
                            margin: 0;
                            align-items: center;
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 4px);
                            min-height: auto;

                            .form-check-label {
                                font-size: 15px;
                                font-weight: 400;
                                color: rgba(var(--title), 1);
                                line-height: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .gender-list {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 5px);

        li {
            width: 100%;
        }
    }
}

/* ---------- Learning setting CSS ---------- */
.setting-style-1 {
    .menu-setting-list {
        padding: 0 23px 0 15px;

        li {
            +li {
                margin-top: 15px;
            }

            .menu-setting-box {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
                align-items: center;
                color: rgba(var(--title), 1);

                .setting-icon {
                    @include pseudowh($width: 30px, $height: 30px);
                    @include flex_common;
                    background-color: rgba(var(--white), 1);
                    box-shadow: 2px 1px 4px rgba(var(--black), 0.05);
                    border-radius: 4px;

                    [class="dark"] & {
                        background-color: #121924;
                    }

                    i {
                        font-size: 16px;
                    }
                }

                .setting-name {
                    @include flex_common ($dis: flex, $align: center, $justify: space-between);
                    width: calc(100% - 30px - 11px);

                    h4 {
                        font-weight: 400;
                    }

                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

/* ---------- Poster CSS ---------- */
.poster-section {
    .poster-box {
        position: relative;

        .poster-detail {
            position: absolute;
            bottom: 0;
            right: 0;
            @include pseudowh($width: 66%, $height: calc(100% - 18px));
            padding: 0 30px;

            >div {
                h3 {
                    font-weight: 600;
                    font-size: 17px;

                    @include mq-max(md) {
                        font-size: calc(15px + (17 - 15) * ((100vw - 320px) / (600 - 320)));
                    }
                }

                button {
                    display: inline-block;
                    width: auto;
                    margin-top: 12px;

                    [class="dark"] & {
                        background-color: #121924;
                        border-color: transparent;

                        &:hover {
                            border-color: transparent;
                            color: #fff;
                            background-color: #121924;
                        }
                    }
                }
            }
        }
    }
}