/*=====================
    3.6 Modal CSS
==========================*/
.offcanvas {
    [class="dark"] & {
        background-color: #171717;
        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
    }

    &.addtohome-popup {
        height: auto;
        border: none;

        [class="dark"] & {
            background-color: #363636;
        }

        .btn-close {
            position: absolute;
            right: 20px;
            top: 20px;
            padding: 0;
            background: none;
            font-size: 20px;
            @include pseudowh($width: auto, $height: auto);
            line-height: 1;
            display: flex;

            [dir="rtl"] & {
                left: 20px;
                right: unset;
                text-align: right;
            }

            i {
                line-height: 1;
            }
        }

        .offcanvas-body {
            padding: 24px 15px;

            .app-info {
                display: block;
                margin-bottom: 30px;
                text-align: left;

                [dir="rtl"] & {
                    text-align: right;
                }

                .content {
                    h3 {
                        font-weight: 600;
                        color: rgba(var(--title), 1);

                        [class="dark"] & {
                            color: #fff;
                        }
                    }

                    a {
                        color: rgba(var(--content), 1);

                        [class="dark"] & {
                            color: #ddd;
                        }
                    }
                }
            }

            .home-screen-btn {
                display: inline-block;
            }
        }
    }

    &.theme-offcanvas {
        width: 320px;

        @include mq-max(md) {
            width: calc(300px + (320 - 300) * ((100vw - 320px) / (600 - 320)));
        }
    }

    &.theme-bottom-offcanvas {
        border: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
        height: auto;

        [class="dark"] & {
            background-color: #060d18;
        }

        .offcanvas-header {
            display: block;
            padding: 20px 15px;
            background-color: rgba(var(--light-bg), 1);

            [class="dark"] & {
                background-color: #121924;
            }

            h5 {
                margin-block: -3px;
                font-size: 15px;
                font-weight: 600;
            }

            .btn-close {
                display: none;
            }
        }
    }

    &.sidemenu-offcanvas {
        .offcanvas-header {
            .profile-box {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);
                align-items: center;

                .profile-image {
                    width: 40px;
                }

                .profile-name {
                    h6 {
                        margin-bottom: 2px;
                        color: $main-content;
                    }

                    h5 {
                        color: rgba(var(--theme-color), 1);
                        margin-bottom: 2px;
                        font-weight: 600;
                    }
                }
            }

            .btn-close {
                background-image: none;
                margin: 0;
                font-size: 21px;
                padding: 0;
                background-color: unset;
                display: flex;
                align-items: center;
                opacity: 0.8;
            }
        }

        .offcanvas-body {
            .sidebar-menu {
                li {
                    +li {
                        border-top: 1px solid rgba(var(--title), 0.1);
                        padding-top: 9px;
                        margin-top: 9px;
                    }

                    .menu-list {
                        @include flex_common($dis: flex, $align: center, $justify: space-between);

                        .list-icon {
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 9px);
                            align-items: center;

                            i {
                                font-size: 20px;
                                color: rgba(var(--title), 1);
                                position: relative;
                            }

                            h4 {
                                font-weight: 600;
                            }
                        }

                        .list-option {
                            .grid-item {
                                .switch {
                                    display: inline-block;
                                    position: relative;
                                    @include pseudowh($width: 56px, $height: 28px);

                                    input {
                                        opacity: 0;
                                        @include pseudowh($width: 0, $height: 0);

                                        &:checked+.slider {
                                            background-color: rgba(var(--theme-color), 1);

                                            &:before {
                                                transform: translateX(27px);
                                            }
                                        }
                                    }

                                    .slider {
                                        position: absolute;
                                        cursor: pointer;
                                        top: 0;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        background-color: $main-content;
                                        transition: 0.4s;

                                        &::before {
                                            background-color: rgba(var(--white), 1);
                                            bottom: 3px;
                                            @include pos;
                                            @include pseudowh($width: 23px, $height: 23px);
                                            left: 3px;
                                            transition: 0.4s;
                                        }

                                        &.round {
                                            border-radius: 34px;

                                            &:before {
                                                border-radius: 50%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.learning-offcanvas {
        .offcanvas-header {
            background-image: url(../images/learning/header-bg.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: 120px;
            display: flex;
            align-items: flex-end;
            position: relative;

            .profile-box {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 13px);
                align-items: flex-end;
                text-align: left;
                margin: 0;

                [dir="rtl"] & {
                    text-align: right;
                }

                .profile-image {
                    @include pseudowh($width: 80px, $height: 80px);
                    border-radius: 8px;
                    overflow: hidden;
                    margin-bottom: -34px;
                }

                .profile-name {
                    h4 {
                        margin-bottom: 4px;
                    }

                    h6 {
                        font-weight: 400;
                        margin: 0;
                    }
                }
            }
        }

        .offcanvas-body {
            padding: 45px 0 0;

            .menu-setting-list {
                padding: 0 23px 0 15px;

                [dir="rtl"] & {
                    padding-right: 15px;
                    padding-left: 23px;
                }

                li {
                    +li {
                        margin-top: 15px;
                    }

                    .menu-setting-box {
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
                        align-items: center;
                        color: rgba(var(--title), 1);

                        .setting-icon {
                            @include pseudowh($width: 30px, $height: 30px);
                            background-color: rgba(var(--white), 1);
                            box-shadow: 2px 1px 4px rgba(var(--black), 0.05);
                            border-radius: 4px;
                            @include flex_common;

                            [class="dark"] & {
                                background-color: #121924;
                            }

                            i {
                                font-size: 16px;
                            }
                        }

                        .setting-name {
                            @include flex_common ($dis: flex, $align: center, $justify: space-between);
                            width: calc(100% - 30px + 11px);

                            h4 {
                                font-weight: 400;
                            }

                            i {
                                font-size: 20px;
                                line-height: 1;
                            }
                        }

                        .setting-arrow {
                            margin-left: auto;

                            [dir="rtl"] & {
                                margin-left: unset;
                                margin-right: auto;
                            }

                            i {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.filter-button {
        .offcanvas-body {
            .filter-box-list {
                .filter-box {
                    background-color: rgba(var(--white), 1);
                    border-radius: 6px;
                    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);
                    align-items: center;
                    position: relative;
                    overflow: hidden;

                    +.filter-box {
                        margin-top: 13px;
                    }

                    .form-check-input {
                        @include pseudowh($width: 21px, $height: 21px);
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin: 0;
                        border-radius: 0 6px 0 0;
                        border: none;
                        border-top: 1px solid rgba(var(--border-color), 1);
                        border-right: 1px solid rgba(var(--border-color), 1);
                        @include flex_common;

                        [dir="rtl"] & {
                            border-left: 1px solid rgba(var(--border-color), 1);
                            border-right: unset;
                            right: unset;
                            left: 0;
                            border-radius: 6px 0 0 0;
                        }

                        [class="dark"] & {
                            border-color: #363636;
                            background-color: #363636;
                        }

                        &::before {
                            content: none;
                            @include font;
                            line-height: 1;
                        }

                        &:active {
                            filter: unset;
                        }

                        &:focus {
                            box-shadow: none;
                        }

                        &:checked {
                            background-image: none;
                            border-top-color: rgba(var(--theme-color), 1);
                            border-right-color: rgba(var(--theme-color), 1);
                            background-color: rgba(var(--theme-color), 1);

                            [dir="rtl"] & {
                                border-right-color: transparent;
                                border-left-color: rgba(var(--theme-color), 1);
                            }

                            &:before {
                                content: "\eb7b";
                                color: rgba(var(--white), 1);

                                [class="dark"] & {
                                    color: #fff;
                                }
                            }

                            ~.form-check-label {
                                border: 1px solid rgba(var(--theme-color), 1);

                                span,
                                svg,
                                i {
                                    color: rgba(var(--theme-color), 1);
                                    stroke: rgba(var(--theme-color), 1);
                                }
                            }
                        }
                    }

                    .form-check-label {
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        border: 1px solid rgba(var(--border-color), 1);
                        padding: 15px;
                        width: 100%;
                        border-radius: 6px;

                        [class="dark"] & {
                            border-color: #363636;
                        }

                        i {
                            font-size: 16px;
                            color: rgba(var(--content), 1);
                        }

                        svg {
                            @include pseudowh($width: 21px, $height: 21px);
                            fill: none;
                            stroke: rgba(var(--content), 1);
                        }

                        span {
                            font-weight: 400;
                            font-size: 15px;
                            color: rgba(var(--content), 1);
                        }
                    }
                }

                .apply-button {
                    margin-top: 25px;

                    &-2 {
                        background: var(--gradient-color);
                        color: rgba(var(--white), 1);
                    }

                    &-3 {
                        background-color: rgba(var(--theme-color), 1);
                        color: rgba(var(--white), 1);
                        border-radius: 6px;
                    }
                }
            }
        }
    }

    &.apply-coupon-offcanvas {
        .offcanvas-body {
            .content-modal-name {
                color: rgba(var(--content), 1);
                font-size: 12px;
            }

            .search-box {
                position: relative;

                .form-control {
                    padding-left: 46px;
                }

                svg {
                    fill: rgba(var(--title), 1);
                    @include pseudowh($width: 18px, $height: 18px);
                    @include flex_common;
                    position: absolute;
                    @include center(vertical);
                    left: 15px;
                }
            }

            .coupon-list-box {
                margin-top: 23px;

                li {
                    +li {
                        border-top: 1px solid rgba(var(--content), 0.2);
                        margin-top: 11px;
                        padding-top: 24px;
                    }

                    .coupon-box {
                        .top-bar {
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);
                            align-items: center;

                            h5 {
                                text-transform: uppercase;
                                font-weight: 700;
                            }

                            span {
                                background-color: rgba(var(--content), 0.1);
                                padding: 3px 8px;
                                font-size: 12px;
                                border-radius: 2px;
                            }

                            a {
                                text-transform: uppercase;
                                margin-left: auto;
                                font-size: 14px;
                                font-weight: 700;
                                color: rgba(var(--title), 1);
                            }
                        }

                        p {
                            font-size: 12px;
                            margin-bottom: 6px;
                            margin-top: 2px;
                            line-height: 1.45;
                            color: rgba(var(--content), 1);
                        }

                        a {
                            color: #198754;
                        }
                    }
                }
            }
        }
    }

    &.share-modal {
        .offcanvas-body {
            .product-detail-box {
                .share-detail {
                    .share-title {
                        padding-bottom: 10px;

                        h3 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 5px;
                        }

                        p {
                            line-height: 1.5;
                            color: rgba(var(--content), 1);
                            font-size: 12px;
                            margin-bottom: 0;
                        }
                    }

                    .copy-link {
                        margin-top: 7px;

                        .social-media-share {
                            padding-bottom: 9px;
                            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
                            align-items: center;
                            overflow: auto;

                            li {
                                text-align: center;

                                a {
                                    @include pseudowh($width: 42px, $height: 42px);
                                    @include flex_common;
                                    margin: 0 auto;
                                    border-radius: 8px;

                                    &.fb {
                                        background-color: #3b5998;
                                    }

                                    &.twitter {
                                        background-color: #4099ff;
                                    }

                                    &.google {
                                        background-color: #db4a39;
                                    }

                                    &.link {
                                        background-color: #0077b5;
                                    }

                                    &.mail {
                                        background-color: #4a89dc;
                                    }

                                    &.more {
                                        background-color: #c92228;
                                    }

                                    &.whatsapp {
                                        background-color: #34af23;
                                    }

                                    &.pint {
                                        background-color: #c92228;
                                    }

                                    i {
                                        font-size: 18px;
                                        color: rgba(var(--white), 1);

                                        [class="dark"] & {
                                            color: #fff;
                                        }
                                    }
                                }

                                h6 {
                                    font-weight: 600;
                                    color: rgba(var(--content), 1);
                                    margin-top: 4px;
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.certificate-modal {
        .offcanvas-body {
            .product-detail-box {
                .certificate-box {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
                    align-items: center;

                    .certificate-image {
                        width: 160px;
                        border-radius: 8px;
                        overflow: hidden;
                    }

                    .certificate-content {
                        width: calc(100% - 160px - 8px);

                        p {
                            margin: 0;
                            color: rgba(var(--title), 1);
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }

    &.question-modal {
        .offcanvas-body {
            margin-top: 6px;

            .question-accordion {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);

                .accordion-item {
                    border: none;
                    width: 100%;

                    .accordion-header {
                        border-bottom: 1px solid rgba(var(--black), 0.078);

                        .accordion-button {
                            font-size: 15px;
                            padding: 0 0 11px;
                            font-weight: 500;
                            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 7px);
                            align-items: flex-start;
                            color: rgba(var(--black), 1);
                            background-color: transparent;

                            @include mq-max(md) {
                                font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (600 - 320)));
                            }

                            &:not(.collapsed) {
                                box-shadow: none;
                            }

                            &:after {
                                content: none;
                            }

                            &:focus {
                                border-color: transparent;
                                box-shadow: none;
                            }
                        }
                    }

                    .accordion-collapse {
                        border-bottom: 1px solid rgba(var(--black), 0.078);
                        padding-bottom: 8px;

                        .accordion-body {
                            padding: 0;
                            margin-top: 12px;

                            p {
                                margin: 0;
                                font-size: 13px;
                                color: rgba(var(--title), 1);
                                line-height: 1.5;

                                @include mq-max(md) {
                                    font-size: calc(12px + (13 - 12) * ((100vw - 320px) / (600 - 320)));
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.notes-modal {
        .offcanvas-body {
            .note-box {
                .form-label {
                    font-weight: 400;
                    font-size: 16px;
                    color: rgba(var(--title), 1);
                }

                .note-form {
                    position: relative;

                    .form-control {
                        background-color: rgba(245, 247, 247, 1);
                        box-shadow: 5px 6px 0px #efefef;
                        border: none;
                        padding: 13px 17px;

                        [class="dark"] & {
                            background-color: #121924;
                            box-shadow: 5px 6px 0px rgba(239, 239, 239, 0.08);
                            color: #fff;
                        }
                    }

                    .btn {
                        border: none;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        @include pseudowh($width: 28px, $height: 28px);
                        @include flex_common;
                        color: rgba(var(--title), 0.6);
                        font-size: 20px;
                        background-color: transparent;
                    }
                }
            }

            .note-review-box {
                margin-top: 26px;

                .note-pills {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                    align-items: center;
                    padding: 8px;
                    position: relative;
                    z-index: 0;
                    border-radius: 6px;
                    overflow: hidden;

                    &::before {
                        @include pos;
                        top: 0;
                        left: 0;
                        @include pseudowh;
                        background: var(--gradient);
                        opacity: 0.08;
                        z-index: -1;
                    }

                    .nav-item {
                        width: 100%;

                        .nav-link {
                            width: 100%;
                            color: rgba(var(--title), 1);
                            font-weight: 600;

                            &.active {
                                color: rgba(var(--white), 1);
                                background: var(--gradient);

                                [class="dark"] & {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

                .note-list-box {
                    .tab-pane {
                        .note-list {
                            margin-top: 10px;
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);
                            max-height: 325px;
                            overflow: auto;

                            li {
                                .note-review-box-list {
                                    padding: 12px;
                                    background-color: #f5f7f7;
                                    border-radius: 11px;
                                    position: relative;

                                    [class="dark"] & {
                                        background-color: #09111b;
                                    }

                                    &.favorite-box {
                                        background-color: rgba(var(--yellow), 0.08);

                                        .favorite-icon {
                                            display: block;
                                        }
                                    }

                                    .favorite-icon {
                                        position: absolute;
                                        top: 12px;
                                        right: 12px;
                                        color: rgba(var(--yellow), 1);
                                        line-height: 1;
                                        display: none;
                                    }

                                    .note-title {
                                        margin-bottom: 11px;
                                        color: rgba(var(--title), 1);

                                        h5 {
                                            font-size: 15px;
                                        }
                                    }

                                    .note-content {
                                        p {
                                            margin: 0;
                                            font-size: 13px;
                                            line-height: 1.5;
                                            color: rgba(var(--title), 0.7);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.chat-history-modal {
        .offcanvas-body {
            .chat-history-list {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 15px);

                li {
                    width: 100%;

                    a {
                        color: rgba(var(--title), 1);
                        align-items: center;
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);

                        h5 {
                            color: rgba(var(--title), 1);
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }

    &.cab-sidebar-offcanvas {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;

        [dir="rtl"] & {
            border-radius: 0;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }

        .offcanvas-header {
            padding-top: 23px;
            padding-bottom: 0;
            position: relative;

            .profile-box {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                align-items: center;

                .profile-image {
                    @include pseudowh($width: 68px, $height: 68px);
                    border-radius: 100%;
                    overflow: hidden;
                }

                .profile-name {
                    h4 {
                        margin-bottom: 5px;
                        color: rgba(var(--title), 1);
                    }

                    h5 {
                        color: rgba(var(--content), 1);
                        font-weight: 400;
                    }
                }
            }

            .close-button {
                position: absolute;
                top: 13px;
                right: 20px;
                @include pseudowh($width: 23px, $height: 23px);
                padding: 0;
                background: var(--gradient2);
                color: rgba(var(--white), 1);
                line-height: 1;
                @include flex_common;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .offcanvas-body {
            margin-top: 21px;
            padding: 0 15px;

            .profile-menu-list {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 14px);

                @include mq-max(md) {
                    gap: calc(9px + (14 - 9) * ((100vw - 320px) / (767 - 320)));
                }

                li {
                    width: 100%;

                    &.active {
                        a {
                            background: var(--gradient2);
                            border-color: transparent;

                            .menu-icon,
                            .menu-name {
                                color: rgba(var(--white), 1);
                            }
                        }
                    }

                    a {
                        border: 1px solid #f3f3f3;
                        border-radius: 18px;
                        padding: 14px 21px;
                        align-items: center;
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);

                        [class="dark"] & {
                            border-color: #272d34;
                        }

                        @include mq-max(md) {
                            border-radius: calc(11px + (18 - 11) * ((100vw - 320px) / (767 - 320)));
                            padding: calc(11px + (14 - 11) * ((100vw - 320px) / (767 - 320))) calc(17px + (21 - 17) * ((100vw - 320px) / (767 - 320)));
                        }

                        .menu-icon {
                            font-size: 20px;
                            line-height: 1;
                            color: rgba(var(--title), 1);
                            @include pseudowh($width: 20px, $height: 20px);

                            [class="dark"] & {
                                color: #efefef;
                            }
                        }

                        .menu-name {
                            @include flex_common($dis: flex, $align: center, $justify: space-between);
                            width: 100%;
                            color: rgba(var(--title), 1);

                            [class="dark"] & {
                                color: #efefef;
                            }

                            i {
                                font-size: 20px;
                                @include pseudowh($width: 20px, $height: 20px);
                                line-height: 1;
                            }
                        }
                    }
                }

                &.profile-list-2 {
                    li {
                        width: 100%;

                        &.active {
                            a {
                                background-color: rgba(var(--theme-color), 1);
                                border-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    &.food-menu-canvas {
        padding: 18px 15px;
        box-shadow: $drop-shadow;
        border-radius: 20px 20px 0px 0px;
        height: auto;
        overflow: hidden;
        border: none;

        .offcanvas-header {
            display: block;
            padding: 0;

            .offcanvas-title {
                color: rgba(var(--title), 1);
                margin-bottom: 8px;
                line-height: 1;
            }

            .btn-close {
                display: none;
            }
        }

        .offcanvas-body {
            padding: 14px 14px 10px;

            .description-content {
                p {
                    font-weight: 400;
                    font-size: 13px;
                    color: rgba(var(--content), 1);
                    margin-top: 12px;
                }
            }

            .menu-title {
                color: rgba(var(--title), 1);
                margin-bottom: 17px;

                h5 {
                    font-weight: 400;
                    margin-bottom: 4px;
                }

                h6 {
                    font-weight: 400;
                    color: rgba(var(--content), 1);
                }
            }

            .food-select {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 14px);
                padding-bottom: 19px;
                margin-bottom: 11px;
                border-bottom: 1px solid #f9f9f9;

                .form-check {
                    margin: 0;
                    padding: 0;
                    min-height: auto;
                    @include flex_common($dis: flex, $align: center, $justify: space-between);
                    width: 100%;

                    .form-check-input {
                        float: none;
                        border: none;
                        margin-top: 0;

                        [dir="rtl"] & {
                            margin-right: unset;
                        }

                        &:checked {
                            background-color: transparent;
                            border-color: transparent;
                        }

                        &:active {
                            filter: none;
                        }

                        &:focus {
                            box-shadow: none;
                        }

                        &::after {
                            top: 2px;
                            left: 10px;

                            [dir="rtl"] & {
                                left: 0;
                                right: unset;
                            }
                        }

                        &::before {
                            left: 14px;
                            top: 8px;

                            [dir="rtl"] & {
                                left: 4px;
                            }
                        }
                    }

                    .form-check-label {
                        font-weight: 400;
                        font-size: 14px;
                        color: rgba(var(--content), 1);
                    }
                }
            }

            .total-price {
                @include flex_common($dis: flex, $align: center, $justify: space-between);

                h5 {
                    font-weight: 400;
                    color: rgba(var(--content), 1);
                    margin-bottom: 3px;
                }

                h4 {
                    font-weight: 600;
                    color: rgba(var(--black), 1);
                }
            }
        }
    }

    &.food-description-offcanvas {
        padding: 18px 15px;
        box-shadow: $drop-shadow;
        border-radius: 20px 20px 0px 0px;
        height: auto;
        overflow: hidden;
        border: none;

        .offcanvas-header {
            display: block;
            padding: 0;

            img {
                @include pseudowh($width: 100%, $height: 196px);
                object-fit: cover;
            }

            .btn-close {
                display: none;
            }
        }

        .offcanvas-body {
            padding: 15px;

            .description-content {
                h4 {
                    color: rgba(var(--title), 1);
                    margin-bottom: 8px;
                }

                p {
                    font-weight: 400;
                    font-size: 13px;
                    color: rgba(var(--content), 1);
                    margin-top: 12px;
                    margin-bottom: 11px;
                    padding-bottom: 14px;
                    border-bottom: 1px solid #f9f9f9;
                }
            }

            .total-price {
                @include flex_common($dis: flex, $align: center, $justify: space-between);

                h5 {
                    font-weight: 400;
                    color: rgba(var(--content), 1);
                    margin-bottom: 3px;
                }

                h4 {
                    font-weight: 600;
                    color: rgba(var(--black), 1);
                }
            }
        }
    }

    &.hotel-sidebar-offcanvas {
        width: 100%;
        border: none;

        [class="dark"] & {
            background-color: #101214;
        }

        .offcanvas-header {
            background: var(--gradient-color);
            box-shadow: 0px 4px 9px rgba(var(--black), 0.1);
            border-radius: 0px 0px 22px 22px;
            height: 253px;
            display: block;
            padding: 0 15px;

            .sidemenu-header {
                margin-top: 31px;
                margin-bottom: 28px;
                @include flex_common($dis: flex, $align: center, $justify: space-between);

                img {
                    width: 107px;
                }

                .btn-close {
                    background-image: none;
                    @include pseudowh($width: 26px, $height: 26px);
                    background-color: rgba(var(--white), 0.2);
                    border-radius: 4px;
                    font-size: 14px;
                    color: rgba(var(--white), 1);
                    @include flex_common;
                    margin: 0;
                    padding: 0;
                    opacity: 1;

                    [class="dark"] & {
                        color: #fff;
                        background-color: rgba(255, 255, 255, 0.2);
                    }
                }
            }

            .sidebar-profile-box {
                text-align: center;

                .profile-image {
                    @include pseudowh($width: 80px, $height: 80px);
                    margin-inline: auto;
                    border-radius: 22px;
                    overflow: hidden;
                }

                .profile-name {
                    margin-top: 9px;
                }
            }
        }

        .offcanvas-body {
            margin-top: 23px;

            .sidebar-menu-list {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 21px);

                li {
                    width: 100%;

                    &:last-child {
                        a {
                            color: #0363da;
                        }
                    }

                    a {
                        color: rgba(var(--title), 1);
                        align-items: center;
                        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 12px);

                        i {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    &.price-filter-offcanvas {
        .offcanvas-header {
            @include flex_common($dis: flex, $align: center, $justify: space-between);

            h4 {
                font-weight: 600;
                font-size: 16px;
                color: rgba(var(--title), 1);
            }

            .btn-close {
                margin: 0;
                padding: 0;
                background-color: none;
                font-size: 20px;
                opacity: 1;
                @include pseudowh($width: auto, $height: auto);
                line-height: 1;
            }
        }

        .offcanvas-body {
            .button-group {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: rgba(var(--white), 1);
                display: flex;
                align-items: center;
                padding: 9px 16px;
                box-shadow: 0px 0px 4px rgba(var(--black), 0.05);

                [dir="rtl"] & {
                    left: unset;
                    right: 0;
                }
            }
        }
    }

    &.amenities-offcanvas {
        .offcanvas-body {
            padding-inline: 15px;

            .amenities-list-2 {
                display: grid;
                grid-template-columns: auto auto auto auto auto;
                gap: 23px;
                text-align: center;

                @media (max-width: 767px) {
                    gap: calc(5px + (23 - 5) * ((100vw - 320px) / (600 - 320)));
                }

                li {
                    .amenities-box {
                        color: rgba(var(--title), 1);

                        .amenities-icon {
                            background-color: rgba(var(--light-bg), 1);
                            border-radius: 10px;
                            @include pseudowh($width: 45px, $height: 45px);
                            @include flex_common;
                            font-size: 20px;
                            margin: 0 auto 8px;

                            svg {
                                @include pseudowh($width: 24px, $height: 24px);
                                fill: transparent;
                                stroke: rgba(var(--title), 1);
                            }

                            h3 {
                                font-weight: 400;
                            }
                        }

                        h5 {
                            font-weight: 400;
                            font-size: 13px;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }

    &.nationality-offcanvas {
        border: none;

        [class="dark"] & {
            background-color: #1f222b;
        }

        .search-country {
            display: flex;
            align-items: center;
            gap: 15px;

            i {
                font-size: 19px;
                color: rgba(var(--title), 0.9);
            }

            .form-floating {
                width: 100%;
            }
        }

        .location-content {
            background-color: #fff6ef;
            padding: 10px;
            border-radius: 7px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: 9px;
            margin-bottom: 20px;

            .location-icon {
                @include pseudowh($width: 24px, $height: 24px);
                background-color: #ffe9c0;
                border-radius: 100%;
                @include flex_common;
                color: #e0b764;
            }

            h6 {
                font-weight: 400;
                line-height: 1.5;
                width: calc(100% - 9px - 24px);

                [class="dark"] & {
                    color: #222;
                }
            }
        }

        .country-list {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding-left: 20px;

            [dir="rtl"] & {
                padding-left: unset;
                padding-right: 20px;
            }

            li {
                width: 100%;
                list-style: disc;

                a {
                    font-size: 14px;
                    color: rgba(var(--title), 1);
                }
            }
        }
    }

    &.category-filter-offcanvas {
        border-radius: 0;
        height: 100%;

        [class="dark"] & {
            background-color: #101214;
        }

        .offcanvas-header {
            @include flex_common ($dis: flex, $align: center, $justify: space-between);

            [class="dark"] & {
                background-color: #1f222b;
            }

            .btn-close {
                padding: 0;
                margin: 0;
                background: none;
                font-size: 22px;
                opacity: 1;
                position: relative;
                @include pseudowh($width: auto, $height: auto);
                line-height: 1;
                display: flex;
                color: rgba(var(--title), 1);

                i {
                    line-height: 1;
                }
            }
        }

        .category-group {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            background-color: rgba(var(--white), 1);
            box-shadow: 0px 0px 4px rgba(var(--black), 0.05);
            padding: 9px 18px;

            [class="dark"] & {
                background-color: #1f222b;
            }

            .reset-button {
                color: rgba(var(--title), 1);
            }
        }
    }

    &.ecommerce-sidebar {
        width: 320px;
        border: none;

        [class="dark"] & {
            background-color: #212325;
        }

        @include mq-max(md) {
            width: calc(280px + (320 - 280) * ((100vw - 320px) / (600 - 320)));
        }

        .btn-close {
            @include pseudowh($width: 38px, $height: 38px);
            padding: 0;
            margin: 0;
            background-color: rgba(var(--theme-color), 1);
            opacity: 1;
            border-radius: 0;
            background-image: unset;
            color: rgba(var(--white), 1);
            font-size: 26px;
            position: absolute;
            top: 0;
            left: 320px;
            line-height: 1;
            @include flex_common;

            [class="dark"] & {
                color: #fff;
            }

            [dir="rtl"] & {
                left: unset;
                right: 320px;
            }

            @include mq-max(md) {
                @include pseudowh($width: calc(33px + (38 - 33) * ((100vw - 320px) / (600 - 320))), $height: calc(33px + (38 - 33) * ((100vw - 320px) / (600 - 320))));
                left: calc(280px + (320 - 280) * ((100vw - 320px) / (600 - 320)));
                font-size: calc(21px + (26 - 21) * ((100vw - 320px) / (600 - 320)));

                [dir="rtl"] & {
                    left: unset;
                    right: calc(280px + (320 - 280) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }

        .offcanvas-header {
            padding: 0;

            .sidebar-profile {
                width: 100%;
                margin-top: 62px;
                margin-bottom: 30px;

                @include mq-max(md) {
                    margin-top: calc(37px + (62 - 37) * ((100vw - 320px) / (600 - 320)));
                }

                .profile-image {
                    @include pseudowh($width: 92px, $height: 92px);
                    border-radius: 100%;
                    overflow: hidden;
                    margin-inline: auto;
                    margin-bottom: 11px;
                }
            }

            .profile-name {
                text-align: center;
                color: rgba(var(--title), 1);

                h4 {
                    font-weight: 500;
                    font-size: 16px;
                    margin-bottom: 7px;

                    @include mq-max(md) {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                    }
                }

                h5 {
                    font-weight: 400;

                    @include mq-max(md) {
                        font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }
        }

        .offcanvas-body {
            padding: 0 15px;
            padding-bottom: 15px;

            .sidebar-list {
                li {
                    +li {
                        margin-top: 18px;
                        padding-top: 18px;
                        border-top: 1px solid rgba(237, 239, 244, 0.6);

                        [class="dark"] & {
                            border-top-color: rgba(237, 239, 244, 0.05);
                        }

                        @include mq-max(md) {
                            margin-top: calc(9px + (18 - 9) * ((100vw - 320px) / (600 - 320)));
                            padding-top: calc(9px + (18 - 9) * ((100vw - 320px) / (600 - 320)));
                        }
                    }

                    a {
                        color: rgba(var(--title), 1);
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;
                        gap: 14px;

                        @include mq-max(md) {
                            gap: calc(9px + (14 - 9) * ((100vw - 320px) / (600 - 320)));
                        }

                        i {
                            font-size: 20px;

                            @include mq-max(md) {
                                font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (600 - 320)));
                            }
                        }

                        h5 {
                            @include mq-max(md) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.grocery-sidemenu {
        background-color: rgba(var(--theme-color), 1);
        width: 311px;
        border: none;
        padding-bottom: 92px;

        .offcanvas-body {
            padding: 0 24px 24px;

            .profile-box {
                text-align: center;
                margin-top: 50px;

                .profile-img {
                    @include pseudowh($width: 92px, $height: 92px);
                    border-radius: 100%;
                    overflow: hidden;
                    margin: 0 auto 11px;
                    display: block;
                }

                .profile-content {
                    h4 {
                        text-transform: capitalize;
                        color: rgba(var(--white), 1);
                        margin-bottom: 8px;

                        [class="dark"] & {
                            color: #efefef;
                        }
                    }

                    h5 {
                        font-weight: 400;
                        color: rgba(var(--white), 0.7);

                        [class="dark"] & {
                            color: rgba(239, 239, 239, 0.7);
                        }
                    }
                }
            }

            .menu-list {
                margin-top: 31px;
                margin-bottom: 110px;
                display: flex;
                flex-wrap: wrap;
                gap: 25px;

                li {
                    width: 100%;

                    a {
                        color: rgba(var(--white), 1);
                        font-weight: 400;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        gap: 14px;

                        [class="dark"] & {
                            color: #efefef;
                        }

                        i {
                            font-size: 18px;
                        }
                    }
                }
            }

            .sidebar-btn {
                position: absolute;
                bottom: 30px;
                width: calc(100% - 48px);
            }
        }
    }

    &.send-offcanvas {
        background-color: rgba(var(--dark-gray), 1);
        color: rgba(var(--white), 1);
        max-height: unset;
        height: unset;
        border: none;
        border-radius: 20px 20px 0 0;

        [class="dark"] & {
            color: #efefef;
        }

        .offcanvas-header {
            padding-bottom: 0;
            border: none;

            .btn-close {
                padding: 0;
                margin: 0;
                background: none;
                color: rgba(var(--white), 1);
                opacity: 1;
                font-size: 21px;
                line-height: 1;
            }
        }

        .offcanvas-body {
            .offcanvas-form {
                position: relative;

                .form-control {
                    border-width: 1px;
                    border-style: solid;
                    border-color: rgba(var(--theme-light-gray), 0.5);
                    border-radius: 11px;
                    background-color: rgba(var(--title), 0.6);
                    backdrop-filter: blur(14px);
                    padding: 15px 59px 15px 12px;
                    font-weight: 400;
                    font-size: 18px;
                    color: rgba(var(--theme-light-gray), 1);
                }

                i {
                    position: absolute;
                    @include center(vertical);
                    right: 21px;
                    color: rgba(var(--white), 0.36);
                    font-size: 24px;
                }
            }

            .offcanvas-form-2 {
                .form-group-box {
                    position: relative;

                    .form-control {
                        border-width: 1px;
                        border-style: solid;
                        border-color: rgba(var(--theme-light-gray), 0.2);
                        border-radius: 11px;
                        background-color: rgba(var(--title), 0.6);
                        backdrop-filter: blur(14px);
                        padding: 15px 59px 15px 12px;
                        font-weight: 400;
                        font-size: 18px;
                        color: rgba(var(--theme-light-gray), 1);

                        @include mq-max(md) {
                            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
                        }
                    }

                    i {
                        position: absolute;
                        @include center(vertical);
                        right: 21px;
                        color: rgba(var(--white), 0.36);
                        font-size: 24px;

                        @include mq-max(md) {
                            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
                        }
                    }

                    &:first-child {
                        .form-control {
                            border-bottom: 0;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }

                    &:last-child {
                        .form-control {
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                        }
                    }
                }
            }

            .pay-button-group {
                display: flex;
                gap: 15px;
                margin-top: 19px;

                a {
                    &:first-child {
                        border-color: rgba(var(--theme-light-gray), 0.5);
                    }
                }
            }
        }
    }

    &.delete-modal-3 {
        .offcanvas-header {
            align-items: flex-start;

            .btn-close {
                @include pseudowh($width: unset, $height: unset);
                color: rgba(var(--title), 1);
                font-size: 25px;
                opacity: 1;
                background: unset;
            }
        }
    }

    &.edit-offcanvas {
        border: none;
        background-color: #FFF9EC;

        [class="dark"] & {
            background-color: #1b1b1b;
        }

        .offcanvas-header {
            background-image: url(../svg/header-shape.svg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: bottom center;
            margin-bottom: 23px;
            color: #fff;

            .btn-close {
                padding: 0;
                margin: 0;
                @include pseudowh($width: unset, $height: unset);
                background: unset;
                opacity: 1;
                font-size: 21px;
                color: #fff;
            }
        }
    }
}

.theme-modal {
    .modal-content {
        border: none;

        [class="dark"] & {
            background-color: #1f2937;
        }

        .modal-header {
            padding-bottom: 0;
            border: none;

            h3 {
                font-weight: 400;
            }

            .btn-close {
                background-image: none;
                @include pseudowh($width: unset, $height: unset);
                font-size: 19px;
                opacity: 1;
                color: rgba(var(--title), 1);
                line-height: 1;
                box-sizing: border-box;
                padding: 0;
                margin: 0;

                i {
                    line-height: 1;
                }
            }
        }

        .modal-body {
            h5 {
                color: rgba(var(--title), 1);
                font-weight: 400;
            }
        }

        .modal-footer {
            flex-wrap: nowrap;
            gap: 12px;
            border: none;
            padding-top: 0;

            button {
                margin: 0;
            }
        }
    }
}

.cancel-modal {
    .modal-dialog {
        max-width: calc(600px - 15px);

        .modal-content {
            border: none;
            text-align: center;
            border-radius: 15px;

            [class="dark"] & {
                background-color: #363636;
            }

            .modal-body {
                padding: 25px;

                h4 {
                    font-size: 17px;
                    margin-bottom: 9px;
                    font-weight: 600;
                    color: rgba(var(--title), 1);
                }

                p {
                    font-size: 13px;
                    line-height: 1.5;
                    color: rgba(var(--title), 0.7);
                }

                .cancel-button {
                    border: none;
                    padding: 9px;
                    border-radius: 9px;
                    margin: 0;

                    [class="dark"] & {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.delete-modal-2 {
    .modal-content {
        .modal-body {
            .delete-list {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 6px);

                li {
                    width: 100%;
                    font-size: 13px;
                    color: rgba(var(--title), 1);
                    font-weight: 400;
                    padding-left: 10px;
                    position: relative;

                    &::before {
                        @include pos;
                        @include center(vertical);
                        @include pseudowh($width: 3px, $height: 3px);
                        left: 0;
                        background-color: rgba(var(--title), 1);
                        border-radius: 100%;
                    }
                }
            }

            h5 {
                color: rgba(var(--title), 1);
                font-weight: 400;
            }
        }

        .modal-footer {
            flex-wrap: nowrap;
            gap: 12px;

            button {
                margin: 0;
            }
        }
    }
}

.alert-box {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;

    .alert {
        padding: 10px 14px;
        border-radius: 9px;
        border: none;

        .error-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
            display: flex;
            align-items: center;
            gap: 8px;

            i {
                font-size: 16px;
            }
        }
    }
}