/*===================== 
    3.7 Nav Tabs CSS
==========================*/
.custom-nav-tabs {
    @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 18px);
    align-items: center;
    border: none;
    margin-bottom: 18px;

    li {
        .nav-link {
            padding: 0;
            border: none;
            font-weight: 500;
            font-size: 14px;
            color: rgba(var(--content), 1);

            [class="dark"] & {
                color: rgba(var(--content), 0.7);
            }

            &.active {
                color: rgba(var(--title), 1);

                [class="dark"] & {
                    background-color: transparent;
                }
            }
        }
    }
}

.video-box-list {
    .video-list {
        @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 18px);

        li {
            width: 100%;

            .video-box {
                @include flex_wrap ($dis: flex, $wrap: nowrap, $gap: 13px);
                align-items: center;
                background-color: #F5F7F7;
                border-radius: 7px;
                padding: 17px 14px;
                position: relative;

                [class="dark"] & {
                    background-color: #121924;
                }

                &::before {
                    @include pos;
                    bottom: -5px;
                    right: -5px;
                    @include pseudowh;
                    background: var(--gradient);
                    opacity: 0.08;
                    border-radius: 7px;
                    z-index: -1;
                }

                .video-number {
                    h4 {
                        font-weight: 500;
                        font-size: 16px;
                        color: rgba(var(--title), 1);
                    }
                }

                .video-content {
                    h5 {
                        color: rgba(var(--title), 1);
                        margin-bottom: 5px;
                    }

                    h6 {
                        color: rgba(var(--content), 1);
                        font-weight: 400;
                        font-size: 13px;
                    }
                }

                .video-icon {
                    margin-left: auto;
                    @include pseudowh($width: 25px, $height: 25px);
                    background-color: rgba(var(--title), 1);
                    border-radius: 100%;
                    @include flex_common;
                    font-size: 16px;
                    color: rgba(var(--white), 1);

                    [class="dark"] & {
                        background-color: rgba(var(--title), 0.1);
                        color: #fff;
                    }

                    [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: auto;
                    }

                    i {
                        font-size: 14px;
                        display: none;
                    }
                }

                &.complete {
                    .video-icon {
                        i {
                            &.check-icon {
                                display: block;
                            }
                        }
                    }
                }

                &.watching-2 {
                    .video-icon {
                        i {
                            &.play-icon {
                                display: block;
                            }
                        }
                    }
                }

                &.watching {
                    background: var(--gradient);

                    .video-number {
                        h4 {
                            color: rgba(var(--white), 1);

                            [class="dark"] & {
                                color: #fff;
                            }
                        }
                    }

                    .video-content {
                        h5 {
                            color: rgba(var(--white), 1);

                            [class="dark"] & {
                                color: #fff;
                            }
                        }
                    }

                    .video-icon {
                        background-color: rgba(var(--white), 1);
                        color: rgba(var(--title), 1);

                        [class="dark"] & {
                            background-color: rgba(var(--title), 0.1);
                        }

                        i {
                            &.play-icon {
                                display: block;
                            }
                        }
                    }
                }

                &.download {
                    .video-icon {
                        i {
                            &.download-icon {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}

.more-option-box {
    .more-option-list {
        @include flex_wrap ($dis: flex, $wrap: wrap, $gap: 15px);

        li {
            width: 100%;

            .option-box {
                padding: 11px 17px;
                background-color: #F5F7F7;
                display: flex;
                align-items: center;
                gap: 8px;
                border-radius: 4px;
                color: rgba(var(--title), 1);
                position: relative;
                width: 100%;
                border: none;

                [class="dark"] & {
                    background-color: #121924;
                }

                &::before {
                    @include pos;
                    bottom: -5px;
                    right: -5px;
                    @include pseudowh;
                    background: var(--gradient);
                    opacity: 0.08;
                    border-radius: 7px;
                    z-index: -1;
                }

                .option-icon {
                    i {
                        font-size: 16px;
                    }
                }

                .option-content {
                    span {
                        font-weight: 400;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.media-nav-pills {
    background-color: rgba(var(--grey), 1);
    padding: 7px;
    border-radius: 7px;
    flex-wrap: nowrap;
    gap: 8px;

    .nav-item {
        width: 100%;

        .nav-link {
            width: 100%;
            color: rgba(var(--title), 1);
            font-weight: 500;

            &.active {
                background-color: rgba(var(--white), 1);
            }
        }
    }
}

.media-content {
    margin-top: 13px;

    .media-image {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 6px;

        img {
            border-radius: 5px;
            @include pseudowh;
            object-fit: cover;
        }
    }
}