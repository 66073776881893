/*=====================
    1.5 Variables CSS
==========================*/
:root {
    --theme-color: 42, 99, 84;
    --white: 255, 255, 255;
    --black: 0, 0, 0;
    --light-bg: 248, 248, 248;
    --yellow: 255, 206, 31;
    --title: 34, 34, 34;
    --grey: 244, 244, 244;
    --content: 157, 172, 177;
    --border-color: 238, 238, 238;
}

.learning-color {
    --theme-color: 35, 51, 83;
    --theme-color2: 52, 77, 84;
    --gradient: linear-gradient(147.23deg, rgba(var(--theme-color), 1) 10.7%, rgba(var(--theme-color2), 1) 85.56%);
    --title: 22, 65, 80;
    --orange: 245, 162, 0;
    --content: 157, 172, 177;
    --border-color: 238, 238, 238;
    --danger: 207, 0, 0;
}

.chatting-color {
    --theme-color: 171, 222, 218;
    --grey: 238, 238, 238;
    --title: 56, 72, 73;
    --content: 132, 143, 143;
    --body-bg: linear-gradient(135deg, rgb(245, 247, 250) 0%, rgb(195, 207, 226) 100%);
}

.cab-booking-color {
    --theme-color: 255, 187, 18;
    --dark: 28, 26, 26;
    --light-gray: 196, 196, 196;
    --title: 62, 62, 62;
    --content: 141, 141, 141;
    --border-color: 238, 238, 238;
    --gradient: linear-gradient(270deg, rgba(255, 160, 0, 1) -5.38%, rgba(255, 213, 79, 1) 100%);
    --gradient2: linear-gradient(180deg, rgba(35, 37, 38, 1) 0%, rgba(65, 67, 69, 1) 100%);
}

.food-color {
    --theme-color: 254, 175, 24;
    --yellow: 255, 187, 18;
    --title: 67, 52, 23;
    --content: 145, 145, 145;
    --primary: 254, 175, 24;
    --light-bg-color: 255, 249, 237;
}

.hotel-booking-color {
    --border-color: 234, 234, 234;
    --theme-color: 3, 99, 218;
    --purple-color: 85, 70, 203;
    --gradient-color: linear-gradient(180deg, rgb(var(--theme-color), 1) 0%, rgb(var(--purple-color), 1) 100%);
    --title: 74, 74, 74;
    --content: 163, 163, 163;
    --danger-color: 255, 0, 0;
}

.ecommerce-color {
    --dark-bg: 55, 55, 55;
    --theme-color: 255, 186, 70;
    --title: 84, 84, 84;
    --content: 134, 134, 134;
    --theme-gradient-color: linear-gradient(270deg, #ffa000 -5.38%, #ffd54f 100%);
    --success: 0, 142, 31;
}

.grocery-color {
    --gradient-bg: linear-gradient(180deg, rgba(245, 249, 250, 1) 61.46%, rgba(245, 249, 250, 0) 100%);
    --theme-color: 60, 133, 153;
    --content: 143, 143, 178;
    --title: 27, 27, 62;
    --border-color: 232, 232, 232;
    --facebook-color: 45, 73, 150;
    --google-color: 196, 50, 24;
    --primary: 254, 175, 24;
    --light-bg: 247, 247, 247;
    --rating-color: 255, 191, 19;
}

.financial-color {
    --theme-color: 155, 1, 68;
    --title: 34, 34, 34;
    --dark-bg: 3, 3, 3;
    --dark-gray: 21, 21, 21;
    --gradient: linear-gradient(180deg, #2a2a72 0%, #009ffd 99.99%, rgba(217, 217, 217, 0) 100%);
    --content: 105, 105, 105;
    --dark: 20, 20, 20;
    --theme-maroon: 155, 1, 68;
    --theme-blue: 55, 1, 179;
    --theme-light: 209, 245, 255;
    --theme-light-gray: 196, 196, 196;
    --theme-gray: 110, 110, 110;
    --border-color: 238, 238, 238;
}

.blog-color {
    --theme-color: 255, 199, 0;
    --title: 34, 34, 34;
    --content: 133, 133, 133;
    --content-dark: 120, 120, 120;
    --border-color: 217, 217, 217;
}

.fitness-color {
    --dark-gray: 119, 119, 119;
    --title: 34, 34, 34;
    --content: 160, 160, 160;
    --theme-color: 68, 102, 242;
    --gradient-color: linear-gradient(90.08deg, #1ea6ec 0.07%, rgba(var(--theme-color), 1) 91.07%);
}

.nft-color {
    --theme-color: 255, 156, 66;
    --main-dark: 69, 69, 69;
    --title: 70, 70, 70;
    --content: 119, 119, 119;
    --light-bg: 247, 247, 247;
}

/* Content Color */
$main-content: #919191;
$light-gray: #ececec;
$dark-green: #164150;
$border-color: #efefef;

/* Fonts */
$montserrat: "Montserrat",
sans-serif;
$inter: "Inter",
sans-serif;
$rubik: "Rubik",
sans-serif;
$roboto: "Roboto",
sans-serif;
$lato: "Lato",
sans-serif;
$public-san: "Public Sans",
sans-serif;
$oleo: "Oleo Script Swash Caps",
cursive;
$nunito: "Nunito",
sans-serif;
$rowdies: "Rowdies",
cursive;
$fontawesome: "Font Awesome 6 Free";

/* Drop shadow css */
$drop-shadow: 0px 0px 6px rgba(213, 213, 213, 0.19);

/* Shadows css */
$shadow2: 2px 3px 6px rgba(192, 192, 192, 0.19);
$shadow3: 2px 3px 6px rgba(213, 213, 213, 0.19);

/* Breakpoints */
$max-breakpoints: (md: 600px);