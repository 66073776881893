/*===================== 
    5.2 Cab Booking Application CSS
==========================*/
.sign-divider {
    margin-top: 35px;
    text-align: center;
    position: relative;

    &::before {
        @include pos;
        @include center(vertical);
        left: 0;
        @include pseudowh($width: 100%, $height: 1px);
        border: 1px dashed rgba(var(--content), 1);
        z-index: -1;

        [class="dark"] & {
            border-color: rgba(255, 255, 255, .11);
        }
    }

    span {
        background-color: rgba(var(--white), 1);
        padding-inline: 16px;
        font-weight: 500;
        font-size: 14px;
        color: rgba(var(--content), 1);
    }
}

/* ---------- GPS Css ---------- */
.gps-section {
    padding-top: 100px;

    .gps-box {
        .gps-image {
            margin-bottom: 33px;
            padding-inline: 17px;
        }
    }

    .gps-content {
        text-align: center;

        h2 {
            font-weight: 500;
            font-size: 22px;
            color: rgba(var(--title), 1);
            margin-bottom: 10px;
        }

        h4 {
            font-weight: 400;
            font-size: 16px;
            color: rgba(var(--content), 1);
            line-height: 1.5;
            margin-bottom: 30px;
        }
    }

    .gps-button {
        background: var(--gradient2);
        border-radius: 18px;
        color: rgba(var(--white), 1);
        padding: 17px;
        font-weight: 500;
        font-size: 18px;
        border: none;

        [class="dark"] & {
            color: #efefef;
        }
    }
}

/* ---------- Booking Css ---------- */
.booking-section {
    padding-top: 39px;
    padding-bottom: 63px;
    background-color: rgba(var(--white), 1);
    z-index: 1;
    border-radius: 25px 25px 0 0;
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    max-width: 600px;

    [class="dark"] & {
        background-color: #22272c;
    }

    @include mq-max(md) {
        padding-top: calc(30px + (39 - 30) * ((100vw - 320px) / (600 - 320)));
        padding-bottom: calc(43px + (63 - 43) * ((100vw - 320px) / (600 - 320)));
    }

    &:before {
        @include pos;
        @include pseudowh($width: 52px, $height: 4px);
        background-color: rgb(233, 233, 233);
        top: 14px;
        @include center(horizontal);

        [class="dark"] & {
            background-color: rgba(233, 233, 233, 0.11);
        }
    }

    .cab-drive-box {
        align-items: center;
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
        background-color: rgba(var(--white), 1);
        box-shadow: $shadow2;
        border-radius: 13px;
        padding: 17px;
        position: absolute;
        top: -88px;
        left: 0;
        width: -webkit-fill-available;
        margin-inline: 15px;

        .driver-image {
            @include pseudowh($width: 50px, $height: 45px);
            border-radius: 8px;
            overflow: hidden;

            img {
                @include pseudowh;
                object-fit: cover;
            }
        }

        .driver-name {
            width: calc(100% - 50px - 12px);
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            color: rgba(var(--title), 1);

            .name-box {
                h5 {
                    font-weight: 400;
                    color: rgba(var(--title), 1);
                }

                h6 {
                    font-weight: 400;
                    margin-top: 5px;
                    color: rgba(var(--content), 1);
                }
            }

            .driver-contact {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                color: rgba(var(--title), 1);

                li {
                    @include pseudowh($width: 35px, $height: 35px);
                    background-color: rgb(249, 249, 249);
                    border-radius: 4px;
                    @include flex_common;

                    &:last-child {
                        background-color: rgba(var(--theme-color), 1);
                        color: rgba(var(--white), 1);
                    }

                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .booking-box {
        .booking-title {
            color: rgba(var(--title), 1);
            margin-bottom: 16px;

            h3 {
                @include mq-max(md) {
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
                }
            }
        }

        .address-location {
            form {
                position: relative;

                .location-arrow {
                    position: absolute;
                    @include center(vertical);
                    left: 0;

                    [dir="rtl"] & {
                        right: 0;
                        transform: rotateY(-180deg) translateY(-50%);
                    }
                }

                .location-input {
                    margin-left: 25px;

                    [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: 25px;
                    }

                    +.location-input {
                        .form-control {
                            margin-top: 14px;
                        }
                    }

                    .form-control {
                        margin-bottom: 0;
                        padding-left: 42px;
                        padding-right: 53px;
                    }

                    .dots {
                        position: absolute;
                        @include center(vertical);
                        left: 22px;
                        @include pseudowh($width: 10px, $height: 10px);
                        background: var(--gradient);

                        [dir="rtl"] & {
                            left: unset;
                            right: 22px;
                        }

                        &-2 {
                            border-radius: 100%;
                            background: var(--gradient2);
                        }
                    }
                }
            }

            .time-passenger {
                @include flex_common($dis: flex, $align: center, $justify: space-between);
                margin-left: 25px;
                margin-top: 8px;

                [dir="rtl"] & {
                    margin-left: unset;
                    margin-right: 25px;
                }

                .time-passenger-box {
                    align-items: center;
                    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 7px);

                    i {
                        font-size: 16px;
                        color: rgba(var(--content), 1);
                    }

                    h5 {
                        font-weight: 400;
                        color: rgba(var(--title), 1);
                        font-size: 14px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}

/* ---------- Ride Css ---------- */
.ride-section {
    padding-top: 39px;
    padding-bottom: 63px;
    background-color: rgba(var(--white), 1);
    z-index: 1;
    border-radius: 25px 25px 0 0;
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    max-width: 600px;

    [class="dark"] & {
        background-color: #22272c;
    }

    @include mq-max(md) {
        padding-top: calc(30px + (39 - 30) * ((100vw - 320px) / (600 - 320)));
        padding-bottom: calc(43px + (63 - 43) * ((100vw - 320px) / (600 - 320)));
    }

    &:before {
        border-radius: 100px;
        @include pos;
        @include pseudowh($width: 52px, $height: 4px);
        background-color: rgb(233, 233, 233);
        top: 14px;
        @include center(horizontal);

        [class="dark"] & {
            background-color: rgba(233, 233, 233, 0.11);
        }
    }

    .choose-ride-box {
        .ride-title {
            color: rgba(var(--title), 1);
            margin-bottom: 16px;
        }

        .select-ride {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 14px);

            li {
                width: 100%;

                &.active {
                    .cab-ride-box {
                        box-shadow: 2px 3px 6px 0px rgba(192, 192, 192, 0.19);
                    }
                }

                .cab-ride-box {
                    padding: 12px;
                    align-items: center;
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
                    border-radius: 8px;

                    .cab-image {
                        @include pseudowh($width: 70px, $height: 30px);
                        position: relative;

                        .cab-image {
                            @include pseudowh;
                            object-fit: contain;
                        }

                        .shape-image {
                            position: absolute;
                            @include center(vertical);
                            left: 0;
                            z-index: -1;
                            @include pseudowh($width: 100%, $height: 45px);
                        }
                    }

                    .cab-detail {
                        @include flex_common($dis: flex, $align: center, $justify: space-between);
                        width: calc(100% - 70px - 12px);
                        color: rgba(var(--title), 1);

                        .cab-name {
                            h5 {
                                font-weight: 400;
                                color: rgba(var(--content), 1);
                                margin-top: 4px;
                            }
                        }

                        .cab-price {
                            h3 {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Ride Details Css ---------- */
.ride-details-section {
    padding-top: 39px;
    padding-bottom: 63px;
    background-color: rgba(var(--white), 1);
    z-index: 1;
    border-radius: 25px 25px 0 0;
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    max-width: 600px;

    [class="dark"] & {
        background-color: #22272c;
    }

    @include mq-max(md) {
        padding-top: calc(30px + (39 - 30) * ((100vw - 320px) / (600 - 320)));
        padding-bottom: calc(43px + (63 - 43) * ((100vw - 320px) / (600 - 320)));
    }

    &:before {
        @include pos;
        @include pseudowh($width: 52px, $height: 4px);
        background-color: rgb(233, 233, 233);
        top: 14px;
        @include center(horizontal);

        [class="dark"] & {
            background-color: #414a51;
        }
    }

    .ride-details-box {
        .ride-title-flex {
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            color: rgba(var(--title), 1);
            margin-bottom: 16px;

            .right-title {
                align-items: center;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 5px);

                i {
                    font-size: 15px;
                }

                h5 {
                    font-weight: 400;
                    font-size: 15px;
                }
            }
        }

        .select-ride {
            margin-bottom: 18px;

            .cab-box {
                padding: 12px;
                align-items: center;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
                border-radius: 8px;
                box-shadow: $shadow2;

                .cab-image {
                    @include pseudowh($width: 70px, $height: 30px);
                    position: relative;

                    .cab-image {
                        @include pseudowh;
                        object-fit: contain;
                    }

                    .shape-image {
                        position: absolute;
                        @include center(vertical);
                        left: 0;
                        z-index: -1;
                        @include pseudowh($width: 100%, $height: 45px);
                    }
                }

                .cab-detail {
                    @include flex_common($dis: flex, $align: center, $justify: space-between);
                    width: calc(100% - 70px - 12px);
                    color: rgba(var(--title), 1);

                    .cab-name {
                        h5 {
                            font-weight: 400;
                            color: rgba(var(--content), 1);
                            margin-top: 4px;
                            font-size: 14px;
                        }
                    }

                    .cab-time {
                        h4 {
                            color: rgba(var(--content), 1);
                            font-weight: 400;
                        }
                    }
                }
            }
        }

        .cab-price-detail {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 12px);

            li {
                width: 100%;

                &.last-box {
                    border-bottom: 1px dashed rgba(var(--border-color), 1);
                    padding-bottom: 14px;

                    [class="dark"] & {
                        border-color: rgba(233, 233, 233, 0.11);
                    }
                }

                .cab-price-box {
                    @include flex_common($dis: flex, $align: center, $justify: space-between);
                    color: rgba(var(--title), 1);

                    .name {
                        font-weight: 400;
                        color: rgba(var(--content), 1);
                    }

                    .coupon-link {
                        &:hover {
                            color: rgba(var(--theme-color), 1);
                        }
                    }
                }

                .total-price-box {
                    @include flex_common($dis: flex, $align: center, $justify: space-between);
                    color: rgba(var(--title), 1);
                }
            }
        }
    }
}

/* ---------- Payment Option Css ---------- */
.payment-option-section {
    padding-top: 20px;
    padding-bottom: 10px;
    height: calc(100vh - 240px);
    overflow: auto;

    .payment-title {
        margin-bottom: 16px;

        h3 {
            color: rgba(var(--title), 1);
        }
    }

    .payment-list-box {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 16px);

        @include mq-max(md) {
            gap: calc(10px + (16 - 10) * ((100vw - 320px) / (600 - 320)));
        }

        li {
            width: 100%;

            .wallet-box {
                border: 1px solid rgb(243, 243, 243);
                box-shadow: $shadow2;
                border-radius: 18px;
                padding: 16px 24px;

                [class="dark"] & {
                    border-color: #272d34;
                    background-color: #272d34;
                }

                @include mq-max(md) {
                    padding: calc(13px + (16 - 13) * ((100vw - 320px) / (600 - 320))) calc(17px + (24 - 17) * ((100vw - 320px) / (600 - 320)));
                }

                label {
                    position: relative;
                    padding-left: 37px;
                    align-items: center;
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 6px);

                    [dir="rtl"] & {
                        padding-left: unset;
                        padding-right: 37px;

                        @include mq-max(md) {
                            padding-right: calc(23px + (37 - 23) * ((100vw - 320px) / (600 - 320)));
                        }
                    }

                    img {
                        margin-right: 6px;
                    }

                    .wallet {
                        color: rgba(var(--title), 1);
                        line-height: 1;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .balance {
                        line-height: 1;
                        color: rgba(var(--content), 1);
                        font-weight: 400;
                        font-size: 15px;
                    }
                }

                /* ----------- THIS IS THE CUSTOM CODE ----------- */
                input[type="radio"] {
                    opacity: 0;
                    z-index: -1;
                    position: absolute;
                    width: auto;

                    &+label {
                        &:before {
                            @include pos;
                            display: block;
                            @include pseudowh($width: 20px, $height: 20px);
                            background-color: rgba(var(--grey), 1);
                            left: 0;
                            @include center(vertical);
                            border-radius: 10px;
                            transition: background-color 0.3s linear;

                            [dir="rtl"] & {
                                left: unset;
                                right: 0;
                            }

                            [class="dark"] & {
                                background-color: #22272c;
                            }
                        }

                        &:after {
                            @include pos;
                            @include pseudowh($width: 10px, $height: 10px);
                            background: var(--gradient);
                            position: absolute;
                            left: 5px;
                            top: 50%;
                            border-radius: 5px;
                            transform: translateY(-50%) scale(0);
                            transform-origin: center;

                            [dir="rtl"] & {
                                left: unset;
                                right: 5px;
                            }
                        }
                    }

                    &:checked+label {
                        &:after {
                            animation: radio-button 0.3s normal forwards;
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Cab Pay Css ---------- */
.cab-pay-section {
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    max-width: 600px;
    width: 100%;
    background-color: rgba(var(--white), 1);
    box-shadow: $shadow2;
    border-radius: 24px 24px 0px 0px;
    padding-bottom: 15px;
    padding-top: 15px;

    [class="dark"] & {
        background-color: #272d34;
    }

    .cab-pay-box {
        .cab-name {
            align-items: center;
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 23px);
            border-bottom: 1px dashed rgba(var(--content), 1);
            padding-bottom: 14px;
            margin-bottom: 20px;

            [class="dark"] & {
                border-color: rgba(233, 233, 233, 0.11);
            }

            @include mq-max(md) {
                padding-bottom: calc(8px + (14 - 8) * ((100vw - 320px) / (600 - 320)));
                margin-bottom: calc(12px + (20 - 12) * ((100vw - 320px) / (600 - 320)));
            }

            .cab-image {
                @include pseudowh($width: 50px, $height: 50px);
                @include flex_common;
                position: relative;

                .cab-img {
                    z-index: 1;
                }

                .shape-image {
                    position: absolute;
                    @include center(vertical);
                    z-index: 0;
                    width: 110%;
                }
            }

            .car-name {
                h3 {
                    color: rgba(var(--title), 1);
                    margin-bottom: 5px;
                }

                h4 {
                    font-weight: 400;
                    color: rgba(var(--content), 1);
                }
            }
        }

        .map-list {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;

            li {
                align-items: center;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
                color: rgba(var(--title), 1);
                width: 100%;

                i {
                    font-size: 16px;
                }

                h5 {
                    font-weight: 400;
                    font-size: 16px;
                }

                &:last-child {
                    justify-content: flex-end;
                }
            }
        }
    }
}

/* ---------- Booking Ride Css ---------- */
.booking-ride-section {
    padding-top: 39px;
    padding-bottom: 39px;
    background-color: rgba(var(--white), 1);
    z-index: 1;
    border-radius: 24px 24px 0px 0px;
    position: fixed;
    width: 100%;
    bottom: 0;
    max-width: 600px;

    [class="dark"] & {
        background-color: #22272c;
    }

    @include mq-max(md) {
        padding-top: calc(30px + (39 - 30) * ((100vw - 320px) / (600 - 320)));
        padding-bottom: calc(19px + (39 - 19) * ((100vw - 320px) / (600 - 320)));
    }

    &:before {
        @include pos;
        @include pseudowh($width: 52px, $height: 4px);
        @include center(horizontal);
        background-color: #e9e9e9;
        top: 14px;
    }

    .cab-drive-box {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
        align-items: center;
        background-color: rgba(var(--white), 1);
        box-shadow: $shadow2;
        border-radius: 13px;
        padding: 17px;
        position: absolute;
        top: -88px;
        left: 0;
        width: -webkit-fill-available;
        margin-inline: 15px;

        [class="dark"] & {
            background-color: #22272c;
        }

        @include mq-max(md) {
            padding: calc(10px + (17 - 10) * ((100vw - 320px) / (600 - 320)));
            top: calc(-73px + (-88 - -73) * ((100vw - 320px) / (600 - 320)));
            gap: calc(8px + (12 - 8) * ((100vw - 320px) / (600 - 320)));
        }

        .driver-image {
            @include pseudowh($width: 50px, $height: 45px);
            border-radius: 8px;
            overflow: hidden;

            img {
                @include pseudowh;
                object-fit: cover;
            }
        }

        .driver-name {
            width: calc(100% - 50px - 12px);
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            color: rgba(var(--title), 1);

            .name-box {
                h5 {
                    font-weight: 400;
                    color: rgba(var(--title), 1);
                }

                h6 {
                    font-weight: 400;
                    margin-top: 5px;
                    color: rgba(var(--content), 1);

                    [class="dark"] & {
                        color: #9b9b9b;
                    }
                }
            }

            .driver-contact {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                color: rgba(var(--title), 1);

                li {
                    &:last-child {
                        a {
                            background-color: rgba(var(--theme-color), 1);
                            color: rgba(var(--white), 1);

                            [class="dark"] & {
                                background-color: rgba(var(--theme-color), 1);
                            }
                        }
                    }

                    a {
                        @include pseudowh($width: 35px, $height: 35px);
                        @include flex_common;
                        background-color: rgb(249, 249, 249);
                        border-radius: 4px;
                        color: rgba(var(--title), 1);

                        [class="dark"] & {
                            background-color: #272d34;
                        }

                        @include mq-max(md) {
                            @include pseudowh($width: calc(30px + (35 - 30) * ((100vw - 320px) / (600 - 320))),
                                $height: calc(30px + (35 - 30) * ((100vw - 320px) / (600 - 320))));
                        }

                        i {
                            font-size: 20px;

                            @include mq-max(md) {
                                font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (600 - 320)));
                            }
                        }
                    }
                }
            }
        }
    }

    .cab-pay-box {
        .cab-name {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 23px);
            align-items: center;
            margin-bottom: 18px;

            @include mq-max(md) {
                gap: calc(15px + (23 - 15) * ((100vw - 320px) / (600 - 320)));
                margin-bottom: calc(11px + (18 - 11) * ((100vw - 320px) / (600 - 320)));
            }

            .cab-image {
                @include pseudowh($width: 50px, $height: 50px);
                @include flex_common;
                position: relative;

                @include mq-max(md) {
                    @include pseudowh($width: calc(42px + (50 - 42) * ((100vw - 320px) / (600 - 320))),
                        $height: calc(42px + (50 - 42) * ((100vw - 320px) / (600 - 320))));
                }

                .cab-img {
                    z-index: 1;
                }

                .shape-image {
                    position: absolute;
                    @include center(vertical);
                    z-index: 0;
                    width: 110%;
                }
            }

            .car-name {
                h3 {
                    color: rgba(var(--title), 1);
                    margin-bottom: 5px;

                    @include mq-max(md) {
                        font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (600 - 320)));
                    }
                }

                h4 {
                    font-weight: 400;
                    color: rgba(var(--content), 1);

                    @include mq-max(md) {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                    }

                    [class="dark"] & {
                        color: #9b9b9b;
                    }
                }
            }
        }

        .map-list {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
            align-items: center;

            li {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
                align-items: center;
                color: rgba(var(--title), 1);
                padding: 8px 20px;
                border-radius: 50px;
                background-color: rgba(var(--light-bg), 1);

                [class="dark"] & {
                    background-color: #272d34;
                }

                @include mq-max(md) {
                    padding: calc(6px + (8 - 6) * ((100vw - 320px) / (600 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (600 - 320)));
                }

                i {
                    font-size: 16px;
                }

                h5 {
                    font-weight: 400;

                    @include mq-max(md) {
                        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }
        }

        .button-group {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 15px);
            position: relative;
            margin: 18px 0 0;

            @include mq-max(md) {
                gap: calc(8px + (15 - 8) * ((100vw - 320px) / (600 - 320)));
                margin-top: calc(13px + (18 - 13) * ((100vw - 320px) / (600 - 320)));
            }

            .btn {
                border: none;
                font-weight: 500;
                font-size: 18px;
                padding: 13px;
                border-radius: 18px;
                color: rgba(var(--white), 1);

                @include mq-max(md) {
                    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (600 - 320)));
                    padding: calc(9px + (13 - 9) * ((100vw - 320px) / (600 - 320)));
                    border-radius: calc(13px + (18 - 13) * ((100vw - 320px) / (600 - 320)));
                }

                &.cancel-button {
                    background: var(--gradient2);

                    [class="dark"] & {
                        color: #efefef;
                    }
                }

                &.reschedule-button {
                    background: var(--gradient);
                }
            }
        }
    }
}

/* ---------- Setting Css ---------- */
.cab-profile-setting-section {
    padding-top: 25px;

    .setting-box {
        .profile-box {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
            align-items: center;

            .profile-image {
                @include pseudowh($width: 68px, $height: 68px);
                border-radius: 8px;
                overflow: hidden;
            }

            .profile-name {
                h4 {
                    margin-bottom: 5px;
                    color: rgba(var(--title), 1);
                }

                h5 {
                    color: rgba(var(--content), 1);
                    font-weight: 400;
                }
            }
        }

        .profile-menu-list {
            margin-top: 29px;
            display: flex;
            flex-wrap: wrap;

            li {
                width: 100%;

                &:last-child {
                    a {
                        border-bottom: none;
                        padding-bottom: unset;
                        margin-bottom: unset;
                    }
                }

                a {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
                    margin-bottom: 24px;
                    border-bottom: 1px solid rgba(var(--grey), 1);
                    align-items: center;
                    padding-bottom: 12px;

                    [class="dark"] & {
                        border-bottom-color: #272d34;
                    }

                    .menu-icon {
                        @include pseudowh($width: 20px, $height: 20px);
                        font-size: 20px;
                        line-height: 1;
                        color: rgba(var(--title), 1);
                    }

                    .menu-name {
                        @include flex_common($dis: flex, $align: center, $justify: space-between);
                        width: 100%;
                        color: rgba(var(--title), 1);

                        i {
                            @include pseudowh($width: 20px, $height: 20px);
                            font-size: 20px;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Your Ride Css ---------- */
.your-ride-section {
    .your-ride-list {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 18px);

        li {
            width: 100%;

            .your-ride-box {
                background-color: rgba(var(--white), 1);
                padding: 17px;
                border-radius: 9px;
                box-shadow: 2px 2px 6px rgba(192, 192, 192, 0.2);
                color: rgba(var(--title), 1);

                [class="dark"] & {
                    background-color: #272d33;
                    box-shadow: 2px 2px 6px rgba(192, 192, 192, 0.06);
                }

                .box-top {
                    @include flex_common($dis: flex, $align: flex-start, $justify: space-between);
                    border-bottom: 1px solid rgba(var(--border-color), 0.4);
                    padding-bottom: 9px;
                    margin-bottom: 9px;

                    [class="dark"] & {
                        border-color: rgba(233, 233, 233, 0.11);
                    }

                    .ride-from-to {
                        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 5px);

                        .cad-ride-from {
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 9px 0);
                            z-index: 0;

                            .to-from {
                                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 4px);
                                align-items: center;

                                @media (max-width: 576px) {
                                    width: 100%;
                                }

                                +.to-from {
                                    padding-left: 5px;
                                    margin-left: 6px;
                                    position: relative;

                                    @media (max-width: 576px) {
                                        padding-left: 0;
                                        margin-left: 0;
                                    }

                                    [dir="rtl"] & {
                                        padding-left: unset;
                                        padding-right: 5px;
                                        margin-left: unset;
                                        margin-right: 6px;

                                        @media (max-width: 576px) {
                                            margin-right: 0;
                                            padding-right: 0;
                                        }
                                    }

                                    &::before {
                                        @include pos;
                                        @include center(vertical);
                                        @include pseudowh($width: 1px, $height: 13px);
                                        left: 0;
                                        background-color: rgba(var(--content), 0.5);
                                        z-index: -1;

                                        @media (max-width: 576px) {
                                            display: none;
                                        }

                                        [dir="rtl"] & {
                                            left: unset;
                                            right: 0;
                                        }
                                    }
                                }

                                i {
                                    font-size: 15px;
                                    line-height: 1;
                                    margin-top: -1px;
                                }

                                h5 {
                                    font-weight: 400;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    display: -webkit-box;
                                    overflow: hidden;
                                    color: rgba(var(--title), 0.8);
                                    line-height: 1;
                                    font-size: 15px;
                                }
                            }
                        }

                        .cab-car-image {
                            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
                            padding-bottom: 12px;
                            align-items: center;

                            .cab-image {
                                @include pseudowh($width: 55px, $height: auto);
                                position: relative;
                                z-index: 0;

                                .cab-image {
                                    @include pseudowh;
                                    object-fit: contain;
                                }

                                .shape-image {
                                    @include center(vertical);
                                    @include pseudowh($width: 100%, $height: 45px);
                                    position: absolute;
                                    left: 0;
                                    z-index: -1;
                                }
                            }

                            .cab-detail {
                                @include flex_common($dis: flex, $align: center, $justify: space-between);
                                width: calc(100% - 70px - 12px);
                                color: rgba(var(--title), 1);

                                .cab-name {
                                    h5 {
                                        font-weight: 400;
                                        color: rgba(var(--content), 1);
                                        margin-top: 4px;
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        display: -webkit-box;
                                        overflow: hidden;
                                        font-size: 14px;
                                    }
                                }

                                .cab-price {
                                    h3 {
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }

                    .cab-price {
                        text-align: right;

                        [dir="rtl"] & {
                            text-align: left;
                        }

                        h5 {
                            white-space: nowrap;
                            font-weight: 400;
                            color: rgba(var(--title), 0.65);
                            margin-bottom: 3px;
                        }

                        h4 {
                            font-size: 17px;
                        }
                    }
                }

                .box-bottom {
                    @include flex_common($dis: flex, $align: flex-start, $justify: space-between);

                    .ride-price {
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 7px);
                        align-items: center;

                        i {
                            font-size: 16px;
                        }

                        h5 {
                            color: rgba(var(--title), 1);
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }

                    .ride {
                        padding: 4px 10px;
                        border-radius: 50px;

                        &-success {
                            background-color: #5cb85c;
                        }

                        &-cancel {
                            background-color: #cf142b;
                        }
                    }

                    h5 {
                        font-size: 13px;
                        font-weight: 400;
                        color: rgba(var(--white), 1);

                        [class="dark"] & {
                            color: #efefef;
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Notification Css ---------- */
.notification-section {
    .clear-button {
        display: block;
        text-align: right;
        margin-top: 17px;
        color: rgba(var(--theme-color), 1);
        text-decoration: underline;
        padding: 0;
        font-weight: 400;
        font-size: 14px;
    }

    .notification-list {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 22px);
        margin-top: 15px;

        li {
            width: 100%;

            .notification-box {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 15px);
                padding: 19px;
                border-radius: 9px;
                box-shadow: $shadow2;
                position: relative;
                background-color: rgba(var(--border-color), 0.15);
                color: rgba(var(--title), 1);

                [class="dark"] & {
                    background-color: #272d34;
                }

                @include mq-max(md) {
                    padding: calc(10px + (19 - 10) * ((100vw - 320px) / (600 - 320)));
                    gap: calc(10px + (15 - 10) * ((100vw - 320px) / (600 - 320)));
                }

                .notification-icon {
                    @include flex_common;
                    @include pseudowh($width: 42px, $height: 42px);
                    background-color: rgba(var(--content), 0.03);
                    border-radius: 5px;
                    font-size: 18px;
                    color: rgba(var(--theme-color), 1);

                    [class="dark"] & {
                        background-color: #22272c;
                    }

                    @include mq-max(md) {
                        @include pseudowh($width: calc(34px + (42 - 34) * ((100vw - 320px) / (600 - 320))), $height: calc(34px + (42 - 34) * ((100vw - 320px) / (600 - 320))));
                    }
                }

                .notification-content {
                    width: calc(100% - 42px - 15px);

                    @include mq-max(md) {
                        width: calc(100% - calc(34px + (42 - 34) * ((100vw - 320px) / (600 - 320))) - calc(10px + (15 - 10) * ((100vw - 320px) / (600 - 320))));
                    }

                    h3 {
                        margin-bottom: 3px;

                        @include mq-max(md) {
                            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
                        }
                    }

                    h6 {
                        font-weight: 400;
                        color: rgba(var(--content), 1);
                        margin-bottom: 7px;
                    }

                    p {
                        font-size: 13px;
                        line-height: 1.5;
                        color: rgba(var(--title), 0.8);
                        margin: 0;
                    }

                    .close-button {
                        @include pseudowh($width: 20px, $height: 20px);
                        @include flex_common;
                        background-color: rgba(var(--content), 0.4);
                        padding: 0;
                        border-radius: 100%;
                        font-size: 12px;
                        color: rgba(var(--title), 1);
                        position: absolute;
                        top: 19px;
                        right: 19px;
                        border-color: transparent;

                        @include mq-max(md) {
                            top: calc(10px + (19 - 10) * ((100vw - 320px) / (600 - 320)));
                            right: calc(10px + (19 - 10) * ((100vw - 320px) / (600 - 320)));
                        }

                        [dir="rtl"] & {
                            right: unset;
                            left: 19px;

                            @include mq-max(md) {
                                left: calc(10px + (19 - 10) * ((100vw - 320px) / (600 - 320)));
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Map Css ---------- */
.map-section {
    &-2 {
        #map {
            height: 765px !important;
        }
    }

    #map {
        @include pseudowh($width: 100%, $height: 100vh);
        z-index: 0;

        &::after {
            @include pos;
            @include pseudowh;
            left: 0;
            top: 0;
            background-color: rgba(var(--title), 0.22);
            z-index: 400;
        }

        .leaflet-left {
            .leaflet-control {
                display: none;
            }
        }
    }
}

/* ---------- Wallet Css ---------- */
.wallet-section {
    margin-top: 30px;

    .card-box {
        padding: 15px;
        border-radius: 20px;
        background: var(--gradient);
        color: rgba(var(--white), 1);

        .top-card {
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            margin-bottom: 11px;

            .more-button {
                padding: 0;
                width: auto;
                color: rgba(var(--white), 1);
                line-height: 1;
                border-color: transparent;
            }
        }

        .card-number {
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            margin-bottom: 25px;

            li {
                font-size: 18px;
            }
        }

        .card-holder-name {
            display: flex;
            align-items: center;

            .holder-expiry {
                width: 100%;

                h6 {
                    font-weight: 400;
                    color: rgba(var(--white), 0.9);
                    margin-bottom: 3px;
                }
            }
        }
    }
}

/* ---------- Payment Option Css ---------- */
.payment-option {
    a {
        background-color: rgba(var(--border-color), 0.5);
        padding: 10px 17px;
        color: rgba(var(--title), 1);
        font-weight: 500;
        border-radius: 50px;
    }
}

/* ---------- Profile Image Change Css ---------- */
.profile-image-change {
    .edit-image {
        .profile-pic {
            @include flex_common;
            color: transparent;
            transition: all 0.3s ease;
            position: relative;
            overflow: hidden;
            padding: 4px;

            input {
                display: none;
            }

            img {
                @include pseudowh($width: 115px, $height: 115px);
                position: absolute;
                object-fit: cover;
                border: 6px solid rgba(var(--white), 1);
                box-shadow: $shadow2;
                border-radius: 100px;
                z-index: 0;
            }

            .-label {
                @include pseudowh($width: 115px, $height: 115px);
                cursor: pointer;
            }

            span {
                font-size: 25px;
            }
        }
    }
}

/* ---------- Driver Detail Css ---------- */
.driver-detail-section {
    .driver-details {
        text-align: center;

        .driver-image {
            @include pseudowh($width: 83px, $height: 83px);
            border-radius: 7px;
            overflow: hidden;
            margin: 0 auto;
            box-shadow: $shadow2;

            img {
                @include pseudowh;
                object-fit: cover;
            }
        }

        .driver-content {
            margin-top: 8px;

            h4 {
                color: rgba(var(--title), 1);
                font-weight: 600;
                margin-bottom: 6px;
            }

            h5 {
                color: rgba(var(--title), 0.7);
                margin-top: 5px;
                font-weight: 400;
            }
        }
    }
}

.cancel-cab-section {
    .cancel-cab-form {
        .form-check {
            display: flex;
            padding: 0;

            .form-check-input {
                float: unset;
                margin: 5px 14px 0 0;

                [dir="rtl"] & {
                    margin: 5px 0 0 14px;
                }

                &:before {
                    [dir="rtl"] & {
                        left: -1px;
                        top: 1px;
                    }
                }
            }

            .form-check-label {
                font-size: 15px;
                font-weight: 500;
                margin-top: 0;
            }
        }
    }

    .submit-box {
        position: fixed;
        bottom: 0;
        @include center(horizontal);
        max-width: 600px;
        width: 100%;
        background-color: rgba(var(--white), 1);
        box-shadow: $shadow2;
        border-radius: 0;
        padding: 15px;

        [class="dark"] & {
            background-color: #272d34;
            border-color: #272d34;
        }

        .find-button {
            border: none;

            [class="dark"] & {
                color: #efefef !important;
            }
        }
    }
}

/* ---------- Invite Friends Css ---------- */
.invite-friend-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    li {
        width: 100%;

        .invite-friend-box {
            @include flex_common ($dis: flex, $align: center, $justify: space-between);

            a {
                display: flex;
                align-items: center;
                gap: 10px;

                .friend-image {
                    @include pseudowh($width: 53px, $height: 53px);
                    overflow: hidden;
                    border-radius: 100%;

                    img {
                        @include pseudowh;
                        object-fit: cover;
                    }
                }

                h5 {
                    color: rgba(var(--title), 1);
                    margin-bottom: 4px;
                    font-size: 14px;
                }

                h6 {
                    color: rgba(var(--content), 1);
                    font-weight: 400;
                }
            }

            .invite-btn {
                border: none;
                background: linear-gradient(180deg, #232526 0%, #414345 100%);
                border-radius: 100px;
                width: auto;
                color: rgba(var(--white), 1);
                padding: 7px 14px;
                line-height: 1;
                font-size: 13px;

                [class="dark"] & {
                    color: #fff;
                }
            }
        }
    }
}

.cab-booking-box {
    h4 {
        font-size: 18px;
        margin-bottom: 9px;
        line-height: 1.4;
    }

    p {
        font-size: 14px;
        color: rgba(var(--title), 0.6);
        margin: 0;
        padding-left: 1.4rem;

        +p {
            margin-top: 10px;
        }
    }

    .cab-ul {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 20px;

        li {
            width: 100%;
            text-align: center;
            padding: 10px;
            background-color: #f9f9f9;
            border-radius: 10px;
            font-size: 24px;

            [class="dark"] & {
                background-color: #272d34;
            }

            h5 {
                font-size: 16px;
                font-weight: 400;
                color: rgba(var(--content), 1);
            }

            a {
                color: rgba(var(--title-color), 1);
            }
        }
    }
}

.faq-section {
    .cab-accordion {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        .accordion-item {
            width: 100%;
            background-color: transparent;
            border: 1px solid rgba(var(--border-color), 1);
            font-weight: 400;
            font-size: 18px;
            padding: 18px;
            border-radius: 22px;
            color: rgba(var(--title), 1);

            [class="dark"] & {
                border-color: rgba(233, 233, 233, 0.11);
            }

            .accordion-button {
                background-color: transparent;
                padding: 0;
                color: rgba(var(--title), 1);
                border-bottom: rgba(var(--border-color), 1);
                font-weight: 500;
                @include flex_common ($dis: flex, $align: center, $justify: space-between);
                line-height: 1.5;

                &:not(.collapsed) {
                    background-color: transparent;
                    box-shadow: none;
                }

                &:focus {
                    z-index: 0;
                    border-color: transparent;
                    box-shadow: none;
                }

                &::after {
                    content: "\ea4e";
                    @include font;
                    @include pseudowh($width: unset, $height: unset);
                    background: unset;
                    font-size: 18px;
                    line-height: 1;
                    margin: 0;
                }
            }

            .accordion-collapse {
                .accordion-body {
                    border-top: 1px solid rgba(var(--border-color), 1);
                    margin-top: 11px;
                    padding: 14px 0 0;

                    [class="dark"] & {
                        border-color: rgba(233, 233, 233, 0.11);
                    }

                    p {
                        margin: 0;
                        font-size: 14px;
                        color: rgba(var(--title), 0.7);
                    }
                }
            }
        }
    }
}

.cab-account-section {
    .cab-account-form {
        .form-box {
            margin-bottom: 16px;

            .form-label {
                font-size: 16px;
                margin-top: 9px;
                color: rgba(var(--title), 0.8);
            }
        }

        .form-control {
            border-radius: 16px;
            border: none;
            padding: 13px 20px;
            color: rgba(var(--title), 1);
            font-weight: 400;
            font-size: 16px;
            background-color: rgba(var(--grey), 1);

            [dir="rtl"] & {
                text-align: right;
            }

            [class="dark"] & {
                background-color: #272d34;
            }
        }
    }
}

/* ---------- Personal Settings CSS ---------- */
.cab-profile-section {
    .edit-image {
        text-align: center;

        .profile-pic {
            position: relative;
            text-align: center;
            display: inline-block;

            &::before {
                @include pos;
                @include center(both);
                @include pseudowh($width: 110%, $height: 110%);
                background-color: #f4f4f4;
                border-radius: 100%;

                [class="dark"] & {
                    background-color: #272d34;
                }
            }

            img {
                @include pseudowh($width: 115px, $height: 115px);
                object-fit: cover;
                overflow: hidden;
                border-radius: 100%;
                z-index: 0;
                position: relative;

                [class="dark"] & {
                    border-color: #121924;
                }
            }

            .change-image-button {
                position: absolute;
                bottom: 0;
                right: 0;

                [dir="rtl"] & {
                    right: unset;
                    left: 0;
                }

                button {
                    background: #f4f4f4;
                    color: rgba(var(--title), 1);
                    @include pseudowh($width: 40px, $height: 40px);
                    padding: 0;
                    @include flex_common;
                    font-size: 20px;
                    border-radius: 100%;

                    [class="dark"] & {
                        background-color: #272d34;
                    }
                }

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include pseudowh;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }
    }
}