/*=====================
    2.1 Reset CSS
==========================*/
.custom-container {
    padding: 0 15px;
}

.mt-35 {
    margin-top: 35px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.px-15 {
    padding-inline: 15px !important;
}

.px-10 {
    padding-inline: 10px !important;
}

.pt-45 {
    padding-top: 45px;
}

.mt-42 {
    margin-top: 42px;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.pt-25 {
    padding-top: 25px;
}

.h-102 {
    height: 102px;
}

.h-136 {
    height: 136px;
}

.ps-15 {
    padding-left: 15px;

    [dir="rtl"] & {
        padding-left: unset;
        padding-right: 15px;
    }
}

.mt-200 {
    margin-top: calc(100px + (200 - 100) * ((100vw - 320px) / (600 - 320)));
}

.fw-500 {
    font-weight: 500 !important;
}

.section-t-space {
    padding-top: 25px;
}

.section-t-sm-space {
    padding-top: 18px;
}

.section-b-sm-space {
    padding-bottom: 18px;
}

.section-b-space {
    padding-bottom: 25px !important;
}

.section-t-space-2 {
    padding-top: 20px;
}

.section-b-space-2 {
    padding-bottom: 20px;
}

.section-t-space-3 {
    padding-top: 30px;
}

.section-b-space-3 {
    padding-bottom: 30px;
}

.section-t-space-4 {
    padding-top: 28px;
}

.section-b-space-4 {
    padding-bottom: 28px;
}

.section-t-space-5 {
    padding-top: 38px;
}

.section-b-space-5 {
    padding-bottom: 38px;
}

.p-center {
    @include flex_common;
}

.content-color {
    color: rgba(var(--content), 1) !important;
}

.text-dark-bg {
    color: rgba(var(--dark-bg), 1);
}

.title-color {
    color: rgba(var(--title), 1) !important;
}

.theme-color {
    color: rgba(var(--theme-color), 1) !important;
}

.theme-bg-color {
    background-color: rgba(var(--theme-color), 1) !important;
}

.primary-color {
    color: rgba(var(--primary), 1);
}

.bg-transparent {
    background-color: transparent !important;
}

.box-shadow-unset {
    box-shadow: unset !important;
}

.divider {
    background-color: rgb(245, 247, 247);
    box-shadow: inset 0px 4px 4px #f0f2f3;
    @include pseudowh($width: 100%, $height: 10px);

    [class="dark"] & {
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.2);
    }
}

.grocery-bottom-spacing {
    padding-bottom: 100px;
}

.bottom-space {
    padding-bottom: 100px;
}

.form-control {
    &[type="number"] {
        appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
    }
}

grammarly-extension {
    display: none;
}

/* Position Top */
.p-top-left {
    display: flex;
    justify-content: flex-start;

    [dir="rtl"] & {
        justify-content: flex-end;
    }
}

.p-top-center {
    display: flex;
    justify-content: center;
}

.p-top-right {
    display: flex;
    justify-content: flex-end;
}

/* Position Center */
.p-center-left {
    @include flex_common($dis: flex, $align: center, $justify: flex-start);

    [dir="rtl"] & {
        justify-content: flex-end;
    }
}

.p-center {
    @include flex_common;
}

.p-center-right {
    @include flex_common($dis: flex, $align: center, $justify: flex-end);

    [dir="rtl"] & {
        justify-content: flex-start;
    }
}

/* Position Bottom */
.p-bottom-left {
    @include flex_common($dis: flex, $align: flex-end, $justify: flex-start);

    [dir="rtl"] & {
        justify-content: flex-end;
    }
}

.p-bottom-center {
    @include flex_common($dis: flex, $align: flex-end, $justify: center);
}

.p-bottom-right {
    @include flex_common($dis: flex, $align: flex-end, $justify: flex-end);
}

.swiper {
    [dir="rtl"] & {
        direction: ltr;
    }
}