/*=====================
   3.11 Tab CSS
==========================*/
/* ---------- tab style 1 scss ---------- */
.tab-style-1 {
    .nav-pills {
        flex-wrap: nowrap;
        padding: 5px;
        background-color: rgba(244, 244, 244, 1);
        border-radius: 16px;
        margin-bottom: 25px;

        .nav-item {
            width: 100%;

            .nav-link {
                width: 100%;
                font-weight: 400;
                font-size: 18px;
                border-radius: 15px;
                padding: 11px;
                color: rgba(var(--title), 1);

                @include mq-max(md) {
                    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
                    padding: calc(9px + (11 - 9) * ((100vw - 320px) / (600 - 320)));
                }

                &.active {
                    font-weight: 500;
                    background: var(--gradient);
                    color: rgba(var(--white), 1);

                    [class="dark"] & {
                        color: #fff;
                    }
                }
            }
        }
    }
}

/* ---------- Tab Style 2 Scss ---------- */
.tab-style-2 {
    background-color: rgba(var(--white), 1);
    box-shadow: $shadow3;
    border-radius: 5px 0px 0px 5px;
    flex-wrap: nowrap;
    overflow: auto hidden;
    border: none;
    padding: 5px 10px 0;
    gap: 20px;

    [class="dark"] & {
        background-color: #171717;
        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
    }

    [dir="rtl"] & {
        padding: 5px 10px 0;
    }

    .nav-item {
        .nav-link {
            white-space: nowrap;
            font-size: 15px;
            font-weight: 500;
            border: none;
            border-bottom: 2px solid transparent;
            color: rgba(var(--content), 1);
            padding: 7px;
            padding-bottom: 14px;
            margin: 0;
            background-color: transparent;

            &.active {
                border-bottom: 2px solid rgba(var(--primary), 1);
                color: rgba(var(--title), 1);
            }
        }
    }
}

/* ---------- Tab Style 3 Scss ---------- */
.tab-style-3 {
    flex-wrap: nowrap;
    gap: 9px;
    overflow: auto;
    padding-bottom: 10px;

    .nav-item {
        .nav-link {
            font-weight: 500;
            border: 1px solid transparent;
            background-color: rgba(var(--content), 0.1);
            border-radius: 100px;
            color: rgba(var(--title), 0.6);
            font-size: 14px;
            padding: 7px 20px;

            [class="dark"] & {
                border-color: #2e3139;
                background-color: #1f222b;
            }

            &.active {
                background-color: rgba(var(--theme-color), 0.1);
                border-color: rgba(var(--theme-color), 0.6);
                color: rgba(var(--theme-color), 1);
            }
        }
    }
}

/* ---------- Tab Style 4 Scss ---------- */
.tab-style-4 {
    flex-wrap: nowrap;
    overflow: auto;
    gap: 10px;
    padding-bottom: 16px;
    margin-bottom: 22px;
    border-bottom: 1px solid rgb(244, 244, 244);

    @include mq-max(md) {
        padding-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (600 - 320)));
        margin-bottom: calc(16px + (22 - 16) * ((100vw - 320px) / (600 - 320)));
    }

    [class="dark"] & {
        border-bottom-color: #212325;
    }

    .nav-item {
        .nav-link {
            white-space: nowrap;
            background-color: rgba(var(--light-bg), 1);
            border-radius: 5px;
            padding: 9px 25px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(var(--title), 1);

            [class="dark"] & {
                background-color: #212325;
            }

            &.active {
                background-color: rgba(var(--theme-color), 1);
                font-weight: 600;
                color: rgba(var(--white), 1);

                [class="dark"] & {
                    color: #fafafa;
                }
            }
        }
    }
}

.tab-content-4 {
    .tab-pane {
        p {
            font-weight: 400;
            font-size: 14px;
            margin: 0;
            color: #919191;
        }
    }
}

/* ---------- Tab Style 5 Scss ---------- */
.tab-style-5 {
    flex-wrap: nowrap;
    gap: 7px;
    overflow: auto;
    padding-bottom: 8px;

    .nav-item {
        .nav-link {
            display: flex;
            align-items: center;
            padding: 11px 21px;
            border-radius: 100px;
            width: max-content;
            gap: 5px;
            background-color: rgba(var(--theme-color), 0.05);
            color: rgba(var(--title), 1);
            font-size: 14px;
            line-height: 1;

            &.active {
                color: rgba(var(--white), 1);
                background-color: rgba(var(--theme-color), 1);

                [class="dark"] & {
                    color: #fff;
                }
            }

            img {
                width: 25px;
            }
        }
    }
}

/* ---------- Tab Style 6 Scss ---------- */
.tab-style-6 {
    flex-wrap: nowrap;
    gap: 9px;
    overflow: auto;
    padding-bottom: 10px;

    .nav-item {
        .nav-link {
            font-weight: 500;
            border: 1px solid transparent;
            background-color: rgba(var(--title), 0.6);
            border-radius: 100px;
            color: rgba(var(--title), 0.6);
            font-size: 13px;
            padding: 7px 20px;

            &.active {
                color: rgba(var(--white), 1);
                background: rgba(147, 3, 84, 0.3);
                border-color: rgba(147, 3, 84, 0.6);
            }
        }
    }
}

/* ---------- Tab Style 7 Scss ---------- */
.tab-style-7 {
    flex-wrap: nowrap;
    gap: 10px;
    overflow: auto;
    margin-top: 14px;

    .nav-item {
        .nav-link {
            display: flex;
            align-items: center;
            padding: 9px 26px;
            border-radius: 100px;
            width: max-content;
            gap: 5px;
            background-color: #f4f4f4;
            color: rgba(var(--title), 1);
            font-size: 14px;
            line-height: 1;
            font-weight: 400;

            &.active {
                color: rgba(var(--white), 1);
                background-color: rgba(var(--title), 1);

                [class="dark"] & {
                    color: #fff;
                }
            }

            img {
                width: 25px;
            }
        }
    }
}

/* ---------- Tab Style 8 Scss ---------- */
.tab-style-8 {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: nowrap;
    border-bottom: 1px solid #ededed;
    padding-bottom: 3px;
    margin-bottom: 18px;

    [class="dark"] & {
        border-bottom-color: #212325;
    }

    .nav-item {
        width: 100%;

        .nav-link {
            width: 100%;
            border-radius: 0;
            font-weight: 500;
            font-size: 16px;
            padding: 9px;
            position: relative;
            color: rgba(var(--title), 1);

            &:before {
                @include pos;
                bottom: -4px;
                left: 0;
                @include pseudowh($width: 100%, $height: 1px);
                border-top: 2px solid #ff9c42;
                opacity: 0;
                transition:
                    color 0.15s ease-in-out,
                    background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out;
            }

            &.active {
                color: rgba(var(--white), 1);
                background-color: rgba(var(--theme-color), 1);

                &:before {
                    opacity: 1;
                }
            }
        }
    }
}

/* ---------- Tab Style 9 Scss ---------- */
.tab-style-9 {
    background-color: rgb(250, 250, 250);
    border-radius: 9px;
    padding: 12px 14px 0;
    flex-wrap: nowrap;
    gap: 25px;
    margin-bottom: 20px;

    [dir="rtl"] & {
        padding: 12px 14px 0;
    }

    [class="dark"] & {
        background-color: #212325;
    }

    .nav-item {
        .nav-link {
            padding: 0;
            font-weight: 500;
            font-size: 16px;
            color: rgba(var(--title), 1);
            padding-bottom: 12px;
            border-bottom: 1px solid transparent;
            border-radius: 0;

            &.active {
                border-bottom-color: rgba(var(--theme-color), 1);
                color: rgba(var(--theme-color), 1);
                background-color: transparent;
            }
        }
    }
}