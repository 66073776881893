/*===================== 
   3.12 Title CSS
==========================*/
.title {
    color: rgba(var(--title), 1);
    margin-bottom: 15px;

    &.mb-10 {
        margin-bottom: 10px;
    }

    &-flex {
        @include flex_common($dis: flex, $align: center, $justify: space-between);

        h6 {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 3px);
            align-items: center;
            font-weight: 400;

            i {
                font-size: 13px;
            }
        }
    }

    h4 {
        font-weight: 600;
    }
}

.cab-title {
    h5 {
        font-weight: 400;
        color: rgba(var(--title), 0.7);
    }
}

.tile-sm {
    color: rgba(var(--content), 1);
    margin-bottom: 15px;

    h6 {
        font-weight: 400;
    }
}

.title-2 {
    @include flex_common($dis: flex, $align: center, $justify: space-between);
    margin-bottom: 18px;

    h4 {
        font-weight: 600;
        font-size: 16px;
        color: rgba(var(--title), 1);
    }

    h5 {
        font-weight: 600;
        color: rgba(var(--title), 1);

        &.title-content {
            margin-top: 5px;
            color: rgba(var(--content), 1);
            font-weight: 400;
        }
    }

    a {
        font-weight: 600;
        color: rgba(var(--primary), 1);

        &.theme-color {
            color: rgba(var(--theme-color), 1);
        }
    }

    &-sm {
        h4 {
            font-size: 15px;
            color: rgba(var(--content), 1);
        }
    }
}

.title-3 {
    @include flex_common($dis: flex, $align: center, $justify: space-between);
    margin-bottom: 13px;

    h5 {
        font-weight: 500;
        color: rgba(var(--white), 1);
        font-size: 16px;
    }

    a {
        color: rgba(var(--theme-maroon), 1);
        font-weight: 400;
        font-size: 13px;
    }
}

.title-4 {
    padding-bottom: 10px;

    h4 {
        font-size: 18px;

        @include mq-max(md) {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
        }
    }

    &.title-flex {
        justify-content: unset;

        img {
            @include pseudowh($width: 16px, $height: 16px);
            margin-right: 3px;
            margin-top: -11px;
        }
    }
}

.landing-title {
    margin-bottom: 32px;

    &-2 {
        margin-bottom: 20px;
    }

    h4 {
        border-left: 3px solid rgba(var(--theme-color), 1);
        padding-left: 6px;
        line-height: 140.4%;

        [dir="rtl"] & {
            padding-left: unset;
            padding-right: 6px;
            border-left: unset;
            border-right: 3px solid rgba(var(--theme-color), 1);
        }
    }
}