/*===================== 
   6.3 Theme Setting CSS
==========================*/
.theme-setting-box {
    position: fixed;
    @include center(vertical);
    right: 11px;
    z-index: 9;

    .theme-box {
        position: relative;

        .theme-icon {
            background-color: rgba(var(--theme-color), 1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            box-shadow: inset 0 0 3px 2px rgba(var(--black), 0.06);
            @include pseudowh($width: 44px, $height: 44px);
            border-radius: 10px;
            color: rgba(var(--title), 1);
            z-index: 1;
            position: relative;
            border: none;

            [class="dark"] & {
                background-color: #212325;
                box-shadow: 0 0 7px 2px rgba(42, 42, 42, 0.48);
            }

            &.show {
                ~.theme-option {
                    li {
                        &:first-child {
                            .theme-setting-button {
                                top: -57px;
                                left: -10px;
                                transform: scale(1);
                                transition-delay: 0.1s;
                            }
                        }

                        &:nth-child(2) {
                            .theme-setting-button {
                                top: 0;
                                left: -60px;
                                transition-delay: 0.2s;
                                transform: scale(1);
                            }
                        }

                        &:last-child {
                            .theme-setting-button {
                                top: 56px;
                                left: -10px;
                                transition-delay: 0.3s;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }

        .theme-option {
            li {
                .theme-setting-button {
                    border: none;
                    padding: 0;
                    font-size: 20px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(0.5);
                    box-shadow: inset 0 0 3px 2px rgba(var(--black), 0.06);
                    @include pseudowh($width: 44px, $height: 44px);
                    border-radius: 10px;
                    background-color: rgb(250, 250, 250);
                    @include flex_common;
                    color: rgba(var(--content), 1);
                    transition: all 0.3s;

                    [class="dark"] & {
                        background-color: #212325;
                        box-shadow: 0 0 7px 2px rgba(42, 42, 42, 0.48);
                    }
                }
            }
        }
    }

    &.financial-color {
        .theme-box {
            .theme-option {
                li {
                    .theme-setting-button {
                        background-color: #64002d;
                        color: rgba(var(--white), 1);
                    }
                }
            }
        }
    }
}