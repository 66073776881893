/*===================== 
   5.9 Hotel Booking Application CSS
==========================*/
/* ---------- Hotel Search ---------- */
.hotel-name-section {
    .hotel-search {
        .hotel-box {
            text-align: center;

            .hotel-image {
                @include pseudowh($width: 32px, $height: 32px);
                position: relative;
                margin: 0 auto;
                display: block;

                img {
                    [class="dark"] & {
                        filter: invert(1) brightness(100);
                    }
                }

                &:after {
                    @include pos;
                    top: 0;
                    left: -3px;
                    @include pseudowh($width: 28px, $height: 28px);
                    background-color: #ebebeb;
                    border-radius: 50%;
                    z-index: -1;

                    [class="dark"] & {
                        background-color: #1f222b;
                    }
                }
            }

            h5 {
                margin-top: 10px;
                color: rgba(var(--title), 1);
            }
        }
    }
}

/* ---------- Destinations Hotel ---------- */
.destinations-hotel-section {
    .destinations-box {
        .hotel-view-box {
            .view-image {
                display: block;
                border-radius: 9px;
                overflow: hidden;
                margin-bottom: 7px;
            }

            .view-content {
                .view-name {
                    color: rgba(var(--title), 1);
                    margin-bottom: 4px;
                    font-size: 15px;
                    font-weight: 600;
                    margin-top: 10px;
                }

                .view-properties {
                    color: rgba(var(--content), 1);
                }
            }
        }
    }
}

/* ---------- Banner Section ---------- */
.banner-section-2 {
    margin-top: 18px;

    .banner-box {
        height: 154px;
        position: relative;
        background: var(--gradient-color);
        border-radius: 9px;

        .banner-image {
            position: absolute;
            bottom: 0;
            right: 0;
            @include pseudowh($width: 180px, $height: auto);
            object-fit: contain;

            @include mq-max(md) {
                width: calc(145px + (180 - 145) * ((100vw - 320px) / (600 - 320)));
            }
        }

        .banner-content {
            position: absolute;
            top: 0;
            left: 0;
            @include pseudowh($width: 50%, $height: 100%);
            padding: 20px 14px;
            display: flex;
            align-items: center;

            @include mq-max(md) {
                padding: calc(10px + (20 - 10) * ((100vw - 320px) / (600 - 320))) calc(8px + (14 - 8) * ((100vw - 320px) / (600 - 320)));
                width: calc(55% + (50 - 55) * ((100vw - 320px) / (600 - 320)));
            }

            [dir="rtl"] & {
                text-align: left;
            }

            h4 {
                font-size: 15px;
                line-height: 1.4;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }

            p {
                margin-block: 4px 14px;
                font-weight: 400;
                font-size: 12px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                opacity: 0.8;
            }

            h6 {
                font-weight: 400;
                font-size: 13px;
            }
        }
    }
}

/* ---------- Hotel Place ---------- */
.hotel-place-section {
    .hotel-place-list {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 14px);

        li {
            width: 100%;

            .hotel-place-box {
                background-color: rgba(var(--light-bg), 1);
                border-radius: 9px;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                align-items: center;
                overflow: hidden;
                position: relative;

                .hotel-image {
                    @include pseudowh($width: 126px, $height: 102px);

                    img {
                        object-fit: cover;
                        @include pseudowh;
                    }
                }

                .hotel-details {
                    width: calc(100% - 126px - 9px);
                    padding: 14px 11px 14px 0;

                    h5 {
                        font-weight: 500;
                        font-size: 14px;
                        color: rgba(var(--title), 1);
                    }

                    h6 {
                        margin-block: 7px;
                        color: rgba(var(--content), 1);
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 4px);
                        align-items: center;
                        font-size: 14px;
                    }

                    .rating {
                        h6 {
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }

                    .price-button {
                        position: absolute;
                        bottom: 13px;
                        right: 11px;
                        display: inline-block;
                        width: auto;
                        font-weight: 400;
                        font-size: 12px;
                        background: var(--gradient-color);
                        border-radius: 6px;
                        color: rgba(var(--white), 1);
                        padding: 3px 5px;

                        [dir="rtl"] & {
                            right: unset;
                            left: 11px;
                        }

                        [class="dark"] & {
                            color: #fff;
                        }

                        span {
                            font-size: 8px;
                            margin-left: 1px;

                            [dir="rtl"] & {
                                margin-left: unset;
                                margin-right: 1px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Hotel Type ---------- */
.hotel-type-section {
    .hotel-type-list {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
        align-items: center;
        overflow-x: auto;

        li {
            a {
                padding: 13px 26px;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
                align-items: center;
                color: rgba(var(--title), 1);
                background-color: rgba(var(--light-bg), 1);
                width: max-content;
                border-radius: 5px;
                transition: all 0.3s ease-in-out;

                [class="dark"] & {
                    background-color: #1f222b;
                }

                &.active {
                    background: var(--gradient-color);
                    color: rgba(var(--white), 1);

                    [class="dark"] & {
                        color: #fff;
                    }

                    img {
                        filter: invert(1) brightness(100);

                        [class="dark"] & {
                            opacity: 1;
                        }
                    }
                }

                img {
                    @include pseudowh($width: 28px, $height: 28px);
                    transition: all 0.3s ease-in-out;

                    [class="dark"] & {
                        filter: invert(1) brightness(100);
                        opacity: 0.4;
                    }
                }
            }
        }
    }
}

/* ---------- Hotel Type ---------- */
.hotel-type-section {
    .hotel-type-pills {
        gap: 8px;

        .nav-item {
            .nav-link {
                font-weight: 500;
                font-size: 12px;
                border-radius: 4px;
                position: relative;
                background-color: rgba(var(--white), 1);
                background-clip: padding-box;
                border: solid 1px transparent;
                color: rgba(var(--title), 1);

                [class="dark"] & {
                    background-color: #1f222b;
                }

                &::before {
                    @include pos;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    margin: -1px;
                    border-radius: inherit;
                    background: var(--gradient-color);
                }

                &.active {
                    color: rgba(var(--white), 1);
                    background: var(--gradient-color);

                    [class="dark"] & {
                        color: #fff;
                    }
                }

                &-2 {
                    border-color: rgba(var(--theme-color), 1);

                    &.active {
                        background-color: rgba(var(--theme-color), 1);
                    }
                }
            }
        }
    }
}

/* ---------- Hotel Type ---------- */
.price-range-section {
    .range-slider {
        position: relative;
        height: 4px;
        background-color: #f8f8f8;

        [class="dark"] & {
            background-color: #1f222b;
        }

        .progress {
            position: absolute;
            left: 25%;
            right: 0%;
            height: 100%;
            border-radius: 15px;
            background-color: rgba(var(--theme-color), 1);
        }

        input {
            &[type="range"] {
                position: absolute;
                @include pseudowh($width: 100%, $height: 10px);
                appearance: none;
                pointer-events: none;
                background: none;
                outline: none;
                top: 117%;
            }
        }

        .range-min {
            &::-webkit-slider-thumb {
                pointer-events: auto;
                appearance: none;
                @include pseudowh($width: 17px, $height: 17px);
                @include center(vertical);
                background-color: rgba(var(--theme-color), 1);
                border-radius: 20px;
                opacity: 1;
            }
        }

        .range-max {
            &::-webkit-slider-thumb {
                pointer-events: auto;
                appearance: none;
                @include pseudowh($width: 17px, $height: 17px);
                @include center(vertical);
                background: rgba(var(--theme-color), 1);
                border-radius: 20px;
                opacity: 1;
            }
        }
    }

    .value-box {
        @include flex_common ($dis: flex, $align: center, $justify: space-between);
        margin-top: 12px;

        .numberVal {
            input {
                text-align: center;
                padding: 5px 16px;
                font-weight: 400;
                font-size: 14px;
                border: none;
                background: #f8f8f8;
                border-radius: 3px;
                color: rgba(var(--title), 1);

                [class="dark"] & {
                    background-color: #1f222b;
                }
            }
        }
    }
}

/* ---------- Hotel Category ---------- */
.hotel-category-list {
    display: grid;
    grid-gap: 23px 15px;
    grid-template-columns: auto auto;

    @include mq-max(md) {
        grid-gap: calc(16px + (23 - 16) * ((100vw - 320px) / (600 - 320))) calc(12px + (15 - 12) * ((100vw - 320px) / (600 - 320)));
    }

    li {
        .category-box {
            display: block;
            padding: 0;
            overflow: visible;

            .category-image {
                border-radius: 5px;
                overflow: hidden;
                margin-bottom: 8px;
                position: relative;

                .rating-hotel {
                    position: absolute;
                    bottom: 8px;
                    right: 8px;
                    display: flex;
                    align-items: center;
                    background-color: rgba(var(--white), 1);
                    color: rgba(var(--title), 1);
                    padding: 4px 7px;
                    border-radius: 4px;
                    gap: 2px;

                    @include mq-max(md) {
                        bottom: calc(5px + (8 - 5) * ((100vw - 320px) / (600 - 320)));
                        right: calc(5px + (8 - 5) * ((100vw - 320px) / (600 - 320)));
                    }

                    [dir="rtl"] & {
                        right: unset;
                        left: 8px;
                    }

                    [class="dark"] & {
                        background-color: #1f222b;
                    }

                    i {
                        color: rgba(var(--yellow), 1);
                        line-height: 1;
                    }
                }
            }

            .category-content {
                .name {
                    font-weight: 600;
                    color: rgba(var(--title), 1);
                }

                h6 {
                    font-weight: 400;
                    align-items: center;
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 4px);
                    color: rgba(var(--content), 1);
                    margin: 6px 0 8px;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    font-size: 13px;

                    @include mq-max(md) {
                        margin-top: calc(2px + (6 - 2) * ((100vw - 320px) / (600 - 320)));
                    }
                }

                .price {
                    color: rgba(var(--title), 1);
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }
}

/* ---------- Hotel search find ---------- */
.hotel-find-section {
    position: absolute;
    bottom: 18px;
    left: 0;
    width: 100%;

    .category-box {
        background-color: rgba(var(--white), 1);
        border-radius: 9px;
        align-items: center;
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
        overflow: hidden;
        padding: 13px 10px;
        position: relative;

        [class="dark"] & {
            background-color: #1f2229;
        }

        .hotel-image {
            @include pseudowh($width: 88px, $height: 88px);
            border-radius: 10px;
            overflow: hidden;

            img {
                @include pseudowh;
                object-fit: cover;
            }
        }

        .hotel-details {
            width: calc(100% - 88px - 9px);

            h5 {
                font-weight: 500;
                font-size: 14px;
                color: rgba(var(--title), 1);
            }

            h6 {
                margin-top: 7px;
                margin-bottom: 7px;
                color: rgba(var(--content), 1);
                align-items: center;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 4px);
                font-size: 14px;
            }

            .rating {
                i {
                    color: rgba(var(--yellow), 1);
                    font-size: 14px;
                }

                h6 {
                    margin: 0;

                    span {
                        color: rgba(var(--content), 1);
                        margin-left: 4px;
                    }
                }
            }

            .price-button {
                position: absolute;
                bottom: 13px;
                right: 11px;
                display: inline-block;
                width: auto;
                font-weight: 400;
                font-size: 13px;
                background: var(--gradient-color);
                border-radius: 6px;
                color: rgba(var(--white), 1);
                padding: 3px 5px;

                [class="dark"] & {
                    color: #fff;
                }

                span {
                    font-size: 10px;
                    margin-left: 2px;
                }
            }
        }
    }
}

/* ---------- Hotel Search Map ---------- */
.hotel-search-map-section {
    iframe {
        max-width: 600px;
        @include pseudowh($width: 100vw, $height: 100vh);
    }
}

/* ---------- Room view slider ---------- */
.room-view-image {
    border-radius: 5px;
    overflow: hidden;

    .swiper-pagination {
        left: unset;
        right: 10px;
        width: auto;
        background-color: rgba(var(--black), 0.6);
        border-radius: 4px;
        padding: 3px 10px;
        font-weight: 400;
        font-size: 12px;
        color: rgba(var(--white), 1);
    }
}

/* ---------- Room View ---------- */
.room-view-section {
    .room-category-list {
        align-items: center;
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);
        margin-bottom: 20px;

        @include mq-max(md) {
            gap: calc(6px + (8 - 6) * ((100vw - 320px) / (600 - 320)));
        }

        li {
            &.active {
                a {
                    color: rgba(var(--white), 1);
                    background-color: transparent;
                }
            }

            a {
                display: block;
                font-weight: 500;
                font-size: 13px;
                position: relative;
                padding: 8px 19px;
                color: rgba(var(--title), 1);
                background-color: rgba(var(--white), 1);
                background-clip: padding-box;
                border: solid 1px transparent;
                border-radius: 4px;

                @include mq-max(md) {
                    padding: calc(6px + (8 - 6) * ((100vw - 320px) / (600 - 320))) calc(13px + (19 - 13) * ((100vw - 320px) / (600 - 320)));
                }

                [class="dark"] & {
                    background-color: #1f222b;
                }

                &:before {
                    @include pos;
                    inset: 0;
                    z-index: -1;
                    margin: -1px;
                    border-radius: inherit;
                    background: var(--gradient-color);
                    opacity: 0.7;
                }
            }
        }
    }

    .hotel-price-button {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        padding-bottom: 30px;

        li {
            font-weight: 500;
            font-size: 16px;

            .btn {
                font-weight: 400;
                font-size: 16px;
                padding: 8px 15px;
            }
        }
    }
}

/* ---------- Hotel Name ---------- */
.hotel-name-review-section {
    position: relative;
    margin-bottom: 28px;

    .hotel-name-rate {
        width: -webkit-fill-available;
        margin-inline: 10px;
        position: absolute;
        top: -22px;
        left: 0;

        .hotel-name-box {
            z-index: 1;
            position: relative;
            padding: 13px 19px;
            background: var(--gradient-color);
            box-shadow: 0px 4px 9px rgba(var(--black), 0.1);
            border-radius: 10px;
            color: rgba(var(--white), 1);

            [class="dark"] & {
                color: #fff;
            }

            .hotel-name {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 4px;

                h4 {
                    font-weight: 600;
                }

                .rating {
                    i {
                        color: rgba(var(--yellow), 1);
                    }

                    span {
                        font-weight: 400;
                        font-size: 12px;
                    }
                }
            }

            .hotel-location {
                p {
                    font-weight: 400;
                    margin: 0;
                }
            }
        }
    }

    .hotel-review-tab {
        background-color: rgba(var(--white), 1);
        box-shadow: 0px 2px 7px rgba(var(--black), 0.04);
        padding: 60px 15px 15px;

        [class="dark"] & {
            background-color: #1f222a;
        }

        .custom-nav-pills {
            justify-content: space-between;

            .vertical-line {
                background-color: rgba(var(--content), 1);
                @include pseudowh($width: 1px, $height: 14px);
                position: relative;
                margin-top: 4px;

                [class="dark"] & {
                    background-color: rgba(var(--content), 0.1);
                }
            }

            .nav-item {
                .nav-link {
                    padding: 0;
                    background-color: transparent;
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(var(--title), 1);
                    position: relative;

                    &.active {
                        color: rgba(var(--theme-color), 1);
                    }
                }
            }
        }
    }
}

/* ---------- Hotel Content ---------- */
.booking-details-box {
    align-items: center;
    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 15px);
    position: relative;
    z-index: 0;

    &::after {
        @include pos;
        @include center(both);
        @include pseudowh($width: 80%, $height: 1px);
        background-color: #d5d5d5;
        z-index: -1;

        [class="dark"] & {
            background-color: rgba(213, 213, 213, 0.2);
        }
    }

    h6 {
        font-size: 13px;
    }

    li {
        width: 100%;

        .check-time-box {
            background-color: rgba(var(--light-bg), 1);
            border-radius: 10px;
            display: block;
            padding: 9px;
            color: rgba(var(--title), 1);

            .date {
                color: rgba(var(--theme-color), 1);
                margin: 4px 0;
            }

            .time {
                color: rgba(var(--content), 1);
            }

            .date,
            .time {
                font-size: 13px;
            }
        }
    }
}

.amenities-list {
    @include flex_common($dis: flex, $align: center, $justify: space-between);
    text-align: center;

    &-2 {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        gap: 23px;
    }

    li {
        .amenities-box {
            color: rgba(var(--title), 1);

            .amenities-icon {
                background-color: rgba(var(--light-bg), 1);
                border-radius: 10px;
                @include pseudowh($width: 45px, $height: 45px);
                @include flex_common;
                font-size: 20px;
                margin: 0 auto 8px;

                svg {
                    @include pseudowh($width: 24px, $height: 24px);
                    fill: transparent;
                    stroke: rgba(var(--title), 1);
                }

                h3 {
                    font-weight: 400;
                }
            }

            h5 {
                font-weight: 400;
                font-size: 13px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }
        }
    }
}

.hotel-content-section {
    .overview-tab {
        .about-detail {
            position: relative;

            h5 {
                font-size: 14px;
                margin-bottom: 6px;
                color: rgba(var(--title), 1);
            }

            p {
                font-weight: 400;
                font-size: 14px;
                margin: 0;
                color: rgba(var(--content), 1);
                line-height: 1.5;

                &.more {
                    display: none;
                }
            }

            .hide-show-button {
                font-size: 24px;
                position: absolute;
                bottom: 0;
                left: 0;
                color: rgba(var(--content), 1);
                border: none;
                padding: 0;
                background: linear-gradient(180deg, rgba(var(--white), 0.61) 0%, rgba(var(--white), 1) 100%);
                height: 64px;
                position: relative;
                height: auto;

                [class="dark"] & {
                    background: transparent;
                }
            }
        }

        hr {
            border: 1px dashed rgb(213, 213, 213);
            margin: 22px 0;
        }
    }

    .location-tab {
        .map-box {
            height: 140px;
            border-radius: 8px;
            overflow: hidden;
            margin-bottom: 15px;

            #hotel {
                @include pseudowh;

                .gmnoprint {
                    display: none;
                }

                .gm-fullscreen-control {
                    background-color: rgba(var(--white), 1);
                    margin: 5px !important;
                    border-radius: 4px !important;
                    @include pseudowh($width: 30px !important, $height: 30px !important);
                    box-shadow: none !important;

                    >img {
                        @include pseudowh($width: 12px !important, $height: 12px !important);
                    }
                }
            }
        }

        .location-nav-pills {
            justify-content: space-between;
            padding: 13px 28px;
            border-radius: 100px;
            background-color: rgba(var(--white), 1);
            box-shadow: 0px 2px 5px rgba(var(--black), 0.03);
            margin-bottom: 20px;

            [class="dark"] & {
                background-color: #1f2229;
            }

            .vertical-line {
                background-color: rgba(var(--content), 1);
                @include pseudowh($width: 1px, $height: 14px);
                position: relative;
                margin-top: 4px;

                [class="dark"] & {
                    background-color: rgba(var(--content), 0.1);
                }
            }

            .nav-item {
                .nav-link {
                    padding: 0;
                    background-color: transparent;
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(var(--title), 1);
                    position: relative;

                    &.active {
                        color: rgba(var(--theme-color), 1);
                    }
                }
            }
        }

        .transport-tab {
            .transport-box-list {
                @include flex_wrap($dis: flex, $wrap: wrap, $gap: 21px);
                align-items: center;

                li {
                    width: 100%;

                    .transport-box {
                        .transport-title {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            margin-bottom: 12px;
                            color: rgba(var(--title), 1);

                            i {
                                font-size: 20px;
                            }
                        }

                        .transport-detail {
                            color: rgba(var(--content), 1);

                            h5 {
                                @include flex_common($dis: flex, $align: center, $justify: space-between);

                                +h5 {
                                    margin-top: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .attraction-tab {
            .attraction-list {
                padding-left: 22px;

                [dir="rtl"] & {
                    padding-left: unset;
                    padding-right: 22px;
                }

                li {
                    list-style: disc;
                    font-size: 13px;
                    font-weight: 400;
                    text-align: justify;
                    color: rgba(var(--title), 0.8);
                    line-height: 1.5;

                    +li {
                        margin-top: 9px;
                    }
                }
            }
        }
    }

    .review-tab {
        .rating-review {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);
            align-items: center;
            margin-bottom: 14px;

            .rating-box {
                @include flex_common;
                background: var(--gradient-color);
                border-radius: 5px;
                @include pseudowh($width: 46px, $height: 46px);
                color: rgba(var(--white), 1);

                [class="dark"] & {
                    color: #fff;
                }

                h3 {
                    font-weight: 600;
                }
            }

            .customer-review {
                width: calc(100% - 46px - 10px);

                h5 {
                    +h5 {
                        margin-top: 4px;
                        font-weight: 400;
                    }
                }
            }
        }

        .progress-bar-review {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 15px);
            margin-bottom: 24px;

            li {
                width: 100%;

                .hotel-review-progress {
                    h5 {
                        @include flex_common($dis: flex, $align: center, $justify: space-between);
                        margin-bottom: 5px;
                        color: rgba(var(--content), 1);
                    }

                    .progress {
                        height: 6px;
                        border-radius: 0;

                        [class="dark"] & {
                            background-color: #1f2229;
                        }

                        .progress-bar {
                            background: var(--gradient-color);
                            border-radius: 100px;
                        }
                    }
                }
            }
        }

        .top-review-list {
            .top-review-box {
                padding: 12px;
                background-color: rgba(var(--light-bg), 1);
                border-radius: 6px;

                .review-title {
                    position: relative;

                    h6 {
                        color: rgba(var(--title), 1);
                        font-size: 13px;

                        +h6 {
                            color: rgba(var(--content), 1);
                            margin-top: 4px;
                        }
                    }

                    .reviewer-rating {
                        @include flex_common;
                        background: var(--gradient-color);
                        border-radius: 5px;
                        @include pseudowh($width: 30px, $height: 30px);
                        position: absolute;
                        top: 0;
                        right: 0;

                        span {
                            font-weight: 600;
                            font-size: 13px;
                            color: rgba(var(--white), 1);

                            [class="dark"] & {
                                color: #fff;
                            }
                        }
                    }
                }

                .review-content {
                    margin-top: 8px;
                    font-size: 13px;
                    line-height: 1.5;
                    color: rgba(var(--content), 1);

                    p {
                        margin: 0;
                        font-weight: 400;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

/* ---------- Review Hotel ---------- */
.review-hotel-section {
    .review-hotel-box {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 9px);

        .review-image {
            @include pseudowh($width: 126px, $height: 102px);
            border-radius: 8px;
            overflow: hidden;

            img {
                @include pseudowh;
                object-fit: cover;
            }
        }

        .review-content {
            width: calc(100% - 126px - 9px);
            color: rgba(var(--title), 1);

            h5 {
                font-weight: 600;
            }

            h6 {
                font-size: 14px;

                +h6 {
                    display: flex;
                    align-items: center;
                    gap: 3px;
                }

                i {
                    color: rgba(var(--yellow), 1);
                }

                span {
                    color: rgba(var(--content), 1);
                }

                &.location-name {
                    font-weight: 400;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin: 7px 0;
                    color: rgba(var(--content), 1);

                    i {
                        color: rgba(var(--content), 1);
                    }
                }
            }
        }
    }
}

/* ---------- Guest Detail ---------- */
.guest-detail-section {
    .guest-detail-box {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        flex-wrap: wrap;
        gap: 6px;
        color: rgba(var(--content), 1);

        li {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 4px;

            i {
                font-size: 18px;
                line-height: 1;
            }

            h5 {
                color: rgba(var(--content), 1);
                font-weight: 400;
            }
        }
    }
}

/* ---------- Payment Details ---------- */
.payment-detail-section {
    .payment-details-list {
        li {
            color: rgba(var(--content), 1);
            @include flex_common($dis: flex, $align: center, $justify: space-between);
            font-weight: 400;
            font-size: 14px;

            +li {
                margin-top: 3px;
            }

            hr {
                border: 1px dashed #eaeaea;
                margin: 12px 0;
            }

            &:last-child {
                margin-top: 0;
            }

            span {
                color: rgba(var(--title), 1);
                font-weight: 400;
                font-size: 14px;

                &.success {
                    color: #008e1f;
                }

                &.danger {
                    color: #bc0101;
                }
            }

            &.total-price {
                font-weight: 500;
                color: rgba(var(--title), 1);

                span {
                    font-weight: 500;
                }
            }
        }
    }
}

/* ---------- Important Information ---------- */
.important-info-section {
    .important-info-box {
        position: relative;
        color: rgba(var(--title), 1);
        background-color: rgba(var(--white), 1);
        background-clip: padding-box;
        border: 1px solid transparent;
        border-radius: 10px;

        &:after {
            @include pos;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: inherit;
            background: var(--gradient-color);
        }

        .important-title {
            padding: 15px 13px;
            background: var(--gradient-color);
            color: rgba(var(--white), 1);
            border-radius: 8px 8px 0px 0px;

            [class="dark"] & {
                color: #fff;
            }
        }

        .important-list {
            padding: 14px;
            padding-left: 2rem;
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 9px);
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;

            [class="dark"] & {
                background-color: rgba(var(--light-bg), 1);
            }

            [dir="rtl"] & {
                padding-left: unset;
                padding-right: 2rem;
            }

            li {
                list-style-type: disc;
                font-weight: 400;
                font-size: 13px;
                color: rgba(var(--content), 1);
            }
        }
    }
}

/* ---------- Review Bottom ---------- */
.review-bottom-box {
    background-color: rgba(var(--white), 1);
    box-shadow: 0px -6px 7px rgba(var(--black), 0.02);
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    max-width: 600px;
    padding: 15px;
    display: flex;
    align-items: center;

    [class="dark"] & {
        background-color: #282d30;
    }

    .review-price {
        width: 100%;
        color: rgba(var(--title), 1);

        h5 {
            margin-bottom: 5px;
            font-size: 14px;

            span {
                color: rgba(var(--content), 1);
            }
        }

        h6 {
            font-size: 13px;
        }
    }

    .continue-button {
        @include mq-max(md) {
            font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (600 - 320)));
        }
    }
}

/* ---------- Payment Method ---------- */
.payment-method-section {
    .payment-method-list {
        li {
            +li {
                border-top: 1px dashed #eaeaea;
                margin-top: 16px;
                padding-top: 16px;

                [class="dark"] & {
                    border-top-color: #1f222b;
                }
            }

            .payment-box {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
                align-items: center;
                color: rgba(var(--title), 1);

                .payment-image {
                    @include pseudowh($width: 66px, $height: 43px);
                    background-color: #f8f8f8;
                    border-radius: 6px;
                    @include flex_common;
                    padding: 12px;

                    img {
                        @include pseudowh;
                        object-fit: contain;
                    }
                }

                .payment-contact {
                    width: calc(100% - 66px - 12px);
                    @include flex_common($dis: flex, $align: center, $justify: space-between);

                    h5 {
                        margin-bottom: 6px;
                    }

                    h6 {
                        font-weight: 400;
                        font-size: 13px;
                        color: rgba(var(--title), 0.8);
                    }

                    i {
                        line-height: 1;
                        font-size: 24px;
                    }
                }
            }
        }
    }
}

/* ---------- Success Icon ---------- */
.success-icon-section {
    text-align: center;
    padding-top: 85px;

    img {
        @include pseudowh($width: 264px, $height: 264px);
    }
}

/* ---------- Payment Successful ---------- */
.payment-success-section {
    .title-2 {
        h3 {
            line-height: 1.4;
        }
    }

    .success-payment-list {
        display: grid;
        grid-template-columns: auto auto;
        gap: 27px;
        margin-top: 24px;

        li {
            width: 100%;

            h5 {
                color: rgba(var(--title), 1);
                font-weight: 500;
                margin-bottom: 5px;
                font-size: 14px;
            }

            h6 {
                color: rgba(var(--title), 0.8);
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
            }
        }
    }
}

/* ---------- Setting ---------- */
.setting-section {
    .setting-header {
        background: var(--gradient-color);
        box-shadow: 0px 4px 9px rgba(var(--black), 0.1);
        border-radius: 0px 0px 22px 22px;
        height: 253px;
        display: block;
        padding: 0 15px;

        .sidemenu-header {
            padding-top: 21px;
            padding-bottom: 31px;
            @include flex_common($dis: flex, $align: center, $justify: space-between);

            img {
                width: 107px;
            }

            .btn-close {
                @include pseudowh($width: 26px, $height: 26px);
                background-color: rgba(var(--white), 0.2);
                border-radius: 4px;
                font-size: 14px;
                color: rgba(var(--white), 1);
                @include flex_common;
                margin: 0;
                padding: 0;
                opacity: 1;
            }
        }

        .sidebar-profile-box {
            text-align: center;

            .profile-image {
                @include pseudowh($width: 80px, $height: 80px);
                margin: 0 auto;
                border-radius: 22px;
                overflow: hidden;
            }

            .profile-name {
                margin-top: 9px;
            }
        }
    }

    .setting-list {
        margin-top: 23px;

        .sidebar-menu-list {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 21px);

            li {
                width: 100%;

                &:last-child {
                    a {
                        color: #0363da;
                    }
                }

                a {
                    color: rgba(var(--title), 1);
                    align-items: center;
                    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 12px);

                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

/* ---------- Wishlist ---------- */
.wishlist-list {
    li {
        +li {
            margin-top: 17px;
            padding-top: 17px;
            border-top: 1px solid rgba(248, 248, 248, 1);

            [class="dark"] & {
                border-top-color: #1f222b;
            }
        }

        .wishlist-box {
            display: flex;
            flex-wrap: nowrap;
            gap: 12px;
            padding: 0 15px;

            @include mq-max(md) {
                gap: calc(8px + (12 - 8) * ((100vw - 320px) / (600 - 320)));
            }

            .wishlist-image {
                width: 130px;
                border-radius: 5px;
                overflow: hidden;
            }

            .wishlist-content {
                width: calc(100% - 130px - 12px);
                position: relative;
                display: flex;
                align-items: center;

                label {
                    font-size: 12px;
                    letter-spacing: 2.3px;
                    text-transform: uppercase;
                    margin-bottom: 4px;
                }

                a {
                    color: rgba(var(--title), 1);

                    h5 {
                        font-size: 15px;
                        font-weight: 500;
                    }
                }

                h6 {
                    color: rgba(var(--content), 1);
                    margin-top: 5px;
                    font-size: 13px;

                    i {
                        color: #f25f65;
                    }
                }

                h4 {
                    margin-top: 16px;
                    font-weight: 600;
                    font-size: 15px;
                    color: rgba(var(--theme-color), 1);
                }

                button {
                    width: auto;
                    padding: 8px 16px;
                    line-height: 1;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                .delete-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: rgba(var(--content), 1);
                    font-size: 16px;

                    [class="dark"] & {
                        color: #777;
                    }

                    [dir="rtl"] & {
                        right: unset;
                        left: 0;
                    }
                }
            }
        }
    }
}

/* ---------- Hotel history list ---------- */
.hotel-history-tab {
    .hotel-history-list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;

        li {
            width: 100%;

            .hotel-history-box {
                background-color: rgba(var(--light-bg), 1);
                border-radius: 9px;
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                overflow: hidden;
                position: relative;

                [class="dark"] & {
                    background-color: #1f222b;
                }

                .hotel-image {
                    @include pseudowh($width: 126px, $height: 102px);

                    @include mq-max(md) {
                        @include pseudowh($width: calc(96px + (126 - 96) * ((100vw - 320px) / (600 - 320))), $height: 102px);
                    }

                    img {
                        @include pseudowh;
                        object-fit: cover;
                    }
                }

                .hotel-details {
                    width: calc(100% - 126px - 9px);
                    padding: 14px 11px 14px 0;

                    @include mq-max(md) {
                        padding: calc(6px + (14 - 6) * ((100vw - 320px) / (600 - 320))) calc(5px + (11 - 5) * ((100vw - 320px) / (600 - 320))) calc(6px + (14 - 6) * ((100vw - 320px) / (600 - 320))) 0;
                        width: calc(100% - calc(96px + (126 - 96) * ((100vw - 320px) / (600 - 320))) - 9px);
                    }

                    h5 {
                        font-weight: 500;
                        font-size: 15px;
                        color: rgba(var(--title), 1);
                    }

                    h6 {
                        font-size: 15px;
                        margin-block: 7px;
                        color: rgba(var(--content), 1);
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 4px);
                        align-items: center;
                    }

                    .price-button {
                        position: absolute;
                        bottom: 13px;
                        right: 11px;
                        display: inline-block;
                        width: auto;
                        font-weight: 400;
                        font-size: 13px;
                        background: var(--gradient-color);
                        border-radius: 6px;
                        color: rgba(var(--white), 1);
                        padding: 3px 5px;

                        @include mq-max(md) {
                            bottom: calc(6px + (14 - 6) * ((100vw - 320px) / (600 - 320)));
                            right: calc(5px + (11 - 5) * ((100vw - 320px) / (600 - 320)));
                        }

                        [dir="rtl"] & {
                            right: unset;
                            left: 11px;
                        }

                        [class="dark"] & {
                            color: #fff;
                        }

                        span {
                            font-size: 8px;
                            margin-left: 1px;
                        }
                    }
                }
            }
        }
    }
}

/* ---------- Notification List ---------- */
.hotel-notification-list {
    li {
        &:nth-child(even) {
            margin: 8px 0;
        }

        .notification-box {
            .date {
                color: rgba(var(--content), 1);
                margin-bottom: 5px;
                text-transform: capitalize;
                font-size: 13px;
            }

            h5 {
                margin-bottom: 7px;
                font-weight: 600;
                color: rgba(var(--title), 1);
                display: flex;
                align-items: center;
                gap: 4px;
                text-transform: capitalize;
                font-size: 14px;

                span {
                    font-size: 12px;
                }
            }

            p {
                line-height: 1.5;
                color: rgba(var(--content), 1);
                margin: 0;
                font-size: 13px;
            }
        }

        .divider {
            height: 18px;

            [class="dark"] & {
                background-color: #1f222b;
                box-shadow: inset 0px 4px 4px #1f222b;
            }
        }
    }
}