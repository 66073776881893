/*===================== 
   5.7 Food Application CSS
==========================*/
.new-card {
    &::after {
        content: none;
    }

    .add-new-card {
        border: 1px dashed rgba(var(--primary), 1);
        border-radius: 10px;
        font-weight: 500;
        font-size: 15px;
        color: rgba(var(--primary), 1);
        @include flex_common;
        padding: 11px;
        flex-wrap: nowrap;
        gap: 3px;
        width: 100%;
        background-color: transparent;

        i {
            line-height: 1;
        }
    }
}

.time-list {
    display: flex;
    align-items: center;
    color: rgba(var(--title), 1);
    gap: 8px;
    flex-wrap: wrap;

    @include mq-max(md) {
        gap: calc(5px + (8 - 5) * ((100vw - 320px) / (600 - 320)));
    }

    li {
        display: flex;
        align-items: center;
        gap: 4px;

        &.dots {
            @include pseudowh($width: 4px, $height: 4px);
            border-radius: 100%;
            background-color: rgba(var(--content), 1);
        }

        i {
            line-height: 1;
        }

        h6 {
            font-weight: 400;
            font-size: 13px;
            margin: 0;
            color: rgba(var(--title), 1);

            margin-left: 15px;

            [dir="rtl"] & {
                margin-left: unset;
                margin-right: 15px;
            }
        }
    }
}

/* ---------- Banner Section ---------- */
.banner-section {
    .banner-box {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        height: 170px;

        .banner-image {
            @include pseudowh;
            object-fit: cover;
        }

        .banner-content {
            position: absolute;
            top: 0;
            left: 0;
            @include pseudowh($width: 100%, $height: 100%);
            padding: 27px 0 27px 21px;

            @include mq-max(md) {
                padding-left: calc(15px + (21 - 15) * ((100vw - 320px) / (600 - 320)));
            }

            .title {
                font-weight: 600;
                margin-bottom: 6px;
                line-height: 1.3;
                color: rgba(var(--title), 1);

                [dir="rtl"] & {
                    text-align: left;
                }
            }

            .offer-box {
                position: relative;
                margin-bottom: 12px;
                @include pseudowh($width: 141px, $height: 38px);

                [dir="rtl"] & {
                    margin-right: auto;
                }

                @include mq-max(md) {
                    @include pseudowh($width: calc(110px + (141 - 110) * ((100vw - 320px) / (600 - 320))), $height: 100%);
                }

                h4 {
                    position: absolute;
                    @include center(both);
                    white-space: nowrap;
                    font-weight: 600;
                    color: rgba(var(--white), 1);
                    font-size: 16px;

                    @include mq-max(md) {
                        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (600 - 320)));
                    }
                }
            }

            h6 {
                color: rgba(var(--content), 1);

                [dir="rtl"] & {
                    text-align: left;
                }
            }
        }
    }
}

.food-category-section {
    .swiper-slide {
        width: 33%;

        &:nth-child(2) {
            width: 35%;
        }
    }

    .food-category-box {
        text-align: center;

        .category-image {
            height: 79px;
            text-align: center;

            img {
                object-fit: contain;
                height: 80px;
            }
        }

        h5 {
            font-size: 15px;
            color: rgba(var(--title), 1);
            margin-top: 5px;
        }
    }
}

.option-section {
    .option-list {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
        align-items: center;
        overflow: auto;

        li {
            .option-box {
                background-color: rgba(var(--white), 1);
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
                align-items: center;
                padding: 10px 12px;
                box-shadow: $shadow3;
                border-radius: 8px;

                [class="dark"] & {
                    background-color: #171717;
                    box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
                }

                .option-image {
                    @include pseudowh($width: 28px, $height: 20px);

                    img {
                        @include pseudowh;
                        object-fit: contain;
                    }
                }

                h5 {
                    color: rgba(var(--title), 1);
                    white-space: nowrap;
                    font-size: 14px;
                }
            }
        }
    }
}

.recommended-section {
    &.js-section {
        &.is-active {
            position: sticky;
            top: 72px;
            left: 0;
            z-index: 1;
            background-color: rgba(var(--white), 1);
            padding: 0;

            .nav-tabs {
                box-shadow: none;
            }
        }
    }
}

.restaurant-section {
    .restaurant-list {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 12px);

        .name-list {
            width: 100%;

            .restaurant-name {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                align-items: center;
                box-shadow: $shadow3;
                border-radius: 13px;
                background-color: rgba(var(--white), 1);
                padding: 12px;

                [class="dark"] & {
                    background-color: #171717;
                    box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
                }

                .food-image {
                    @include pseudowh($width: 73px, $height: 73px);
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        @include pseudowh;
                        object-fit: cover;
                    }
                }

                .food-name {
                    width: calc(100% - 73px - 9px);
                    position: relative;

                    .restaurant-rating {
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 2px);
                        align-items: center;
                        margin: 7px 0;

                        span {
                            font-weight: 400;
                            font-size: 12px;
                            color: rgba(var(--title), 1);
                        }

                        ul {
                            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 2px);
                            align-items: center;

                            li {
                                i {
                                    color: #e0e0e0;

                                    [class="dark"] & {
                                        color: rgba(57, 44, 44, 0.19);
                                    }

                                    &.fill {
                                        color: rgba(var(--primary), 1);
                                    }
                                }
                            }
                        }
                    }

                    h4 {
                        font-weight: 500;
                        font-size: 15px;
                        color: rgba(var(--title), 1);
                    }

                    h5 {
                        font-weight: 400;
                        font-size: 14px;
                        color: rgba(var(--content), 1);
                        margin: 5px 0 10px;
                    }

                    .rating-box {
                        position: absolute;
                        top: 0;
                        right: 0;
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 4px);
                        align-items: center;
                        padding: 3px 8px;
                        background-color: #fff9ec;
                        border-radius: 4px;

                        [class="dark"] & {
                            background-color: #181818;
                        }

                        i {
                            font-size: 10px;
                            color: rgba(var(--theme-color), 1);
                        }

                        h6 {
                            font-weight: 400;
                            color: rgba(var(--title), 1);
                        }
                    }
                }
            }
        }
    }
}

.feature-section {
    .feature-box {
        box-shadow: $shadow3;
        border-radius: 10px;
        background-color: rgba(var(--white), 1);
        overflow: hidden;

        [class="dark"] & {
            background-color: #171717;
            box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
        }

        .feature-head {
            position: relative;
            @include pseudowh($width: 100%, $height: 102px);
            overflow: hidden;
            display: block;

            img {
                @include pseudowh;
                object-fit: cover;
            }

            .sale-rate {
                position: absolute;
                top: 6px;
                right: 7px;
                text-align: right;

                [dir="rtl"] & {
                    right: unset;
                    left: 7px;
                    text-align: left;
                }

                h6 {
                    +h6 {
                        margin-top: 5px;
                    }

                    &.sale {
                        background-color: rgba(var(--white), 1);
                        border-radius: 4px;
                        padding: 4px 6px;
                        color: rgba(var(--title), 1);
                        font-weight: 400;
                        font-size: 11px;

                        [class="dark"] & {
                            background-color: #171717;
                        }
                    }

                    &.rate {
                        background-color: rgba(var(--white), 1);
                        border-radius: 4px;
                        padding: 4px 6px;
                        color: rgba(var(--title), 1);
                        display: inline-block;

                        [class="dark"] & {
                            background-color: #171717;
                        }

                        i {
                            color: rgba(var(--theme-color), 1);
                            line-height: 1;
                        }

                        h6 {
                            font-weight: 400;
                            font-size: 11px;
                            margin-top: -1px;
                        }
                    }
                }
            }
        }

        .feature-content {
            padding: 8px 11px;

            h5 {
                font-size: 14px;
                color: rgba(var(--title), 1);
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
            }

            h6 {
                font-weight: 400;
                font-size: 13px;
                margin: 3px 0 8px;
                color: rgba(var(--content), 1);
            }

            .time-list {
                li {
                    h6 {
                        margin: 0;
                        color: rgba(var(--title), 1);
                    }
                }
            }

            .offer-box {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 4px);
                align-items: center;
                margin-top: 11px;

                img {
                    @include pseudowh($width: 17px, $height: 17px);
                }

                h6 {
                    font-weight: 400;
                    font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
                    color: rgba(var(--theme-color), 1);
                    margin: 0;
                }
            }
        }
    }
}

.recent-search-section {
    .recent-search-list {
        @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);

        li {
            width: 100%;

            .recent-box {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                align-items: center;
                color: rgba(var(--content), 1);

                .recent-icon {
                    @include pseudowh($width: 18px, $height: 18px);
                    font-size: 18px;
                    @include flex_common;

                    i {
                        line-height: 1;
                    }
                }

                .recent-name {
                    width: calc(100% - 18px - 9px);
                    @include flex_common($dis: flex, $align: center, $justify: space-between);

                    h5 {
                        font-weight: 400;
                        font-size: 15px;
                    }

                    i {
                        font-size: 16px;
                        line-height: 1;
                    }
                }
            }
        }
    }
}

.address-section {
    .address-list-box {
        .address-list {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 15px);

            li {
                width: 100%;

                .address-box {
                    background-color: rgba(var(--white), 1);
                    box-shadow: $shadow3;
                    border-radius: 12px;
                    padding: 13px;

                    [class="dark"] & {
                        background-color: #171717;
                        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
                    }

                    .address-header {
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
                        align-items: flex-start;
                        border-bottom: 1px solid #f9f9f9;
                        padding-bottom: 9px;
                        margin-bottom: 12px;

                        [class="dark"] & {
                            border-bottom-color: rgba(249, 249, 249, 0.08);
                        }

                        .address-icon {
                            @include pseudowh($width: 40px, $height: 40px);
                            background-color: #f9f9f9;
                            border-radius: 6px;
                            @include flex_common;
                            font-size: 20px;
                            color: rgba(var(--title), 1);

                            [class="dark"] & {
                                background-color: #181818;
                            }
                        }

                        .address-name {
                            width: calc(100% - 40px - 11px);

                            h5 {
                                font-weight: 600;
                                font-size: 16px;
                                color: rgba(var(--title), 1);
                                margin-bottom: 3px;
                            }

                            p {
                                font-weight: 400;
                                font-size: 14px;
                                color: rgba(var(--content), 1);
                                margin: 0;
                                line-height: 1.6;
                            }
                        }
                    }

                    .address-footer {
                        .button-group {
                            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
                            align-items: center;

                            .edit-button {
                                width: auto;
                                padding: 8px 20px;
                                background-color: rgba(var(--theme-color), 1);
                                border-radius: 3px;
                                color: rgba(var(--white), 1);

                                [class="dark"] & {
                                    color: #fff;
                                }
                            }

                            .delete-button {
                                width: auto;
                                padding: 8px 20px;
                                background-color: #acacac;
                                border-radius: 3px;
                                color: rgba(var(--white), 1);

                                [class="dark"] & {
                                    background-color: #181818;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.edit-profile-section {
    .profile-box {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 15px);
        align-items: center;

        .profile-image {
            @include pseudowh($width: 80px, $height: 80px);
            border-radius: 22px;
            overflow: hidden;
        }

        .profile-name {
            width: calc(100% - 80px - 15px);

            h3 {
                color: rgba(var(--title), 1);
                margin-bottom: 4px;
                line-height: 1;
            }

            h5 {
                font-weight: 400;
                font-size: 15px;
                color: rgba(var(--content), 1);
                margin-bottom: 9px;
            }

            h6 {
                a {
                    color: rgba(var(--primary), 1);
                    font-weight: 500;
                    font-size: 14px;
                }
            }
        }
    }
}

.account-section {
    .account-setting-list {
        .setting-list {
            background-color: rgba(var(--white), 1);
            border-radius: 6px;
            padding: 18px 12px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            [class="dark"] & {
                background-color: #171717;
                box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
            }

            li {
                width: 100%;
                padding-inline: 15px;
                position: relative;

                @include mq-max(md) {
                    padding-inline: calc(5px + (15 - 5) * ((100vw - 320px) / (600 - 320)));
                }

                +li {
                    margin-top: 14px;
                    padding-top: 14px;
                    border-top: 1px solid rgba(var(--content), 0.2);

                    @include mq-max(md) {
                        padding-top: calc(8px + (14 - 8) * ((100vw - 320px) / (600 - 320)));
                        margin-top: calc(10px + (14 - 10) * ((100vw - 320px) / (600 - 320)));
                    }

                    [class="dark"] & {
                        border-color: rgba(249, 249, 249, 0.11);
                    }
                }

                .setting-box {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 16px);
                    align-items: center;

                    .setting-image {
                        @include pseudowh($width: 26px, $height: 26px);

                        @include mq-max(md) {
                            @include pseudowh($width: calc(22px + (26 - 22) * ((100vw - 320px) / (600 - 320))), $height: calc(22px + (26 - 22) * ((100vw - 320px) / (600 - 320))));
                        }

                        img {
                            @include pseudowh;
                            object-fit: contain;
                        }
                    }

                    .setting-name {
                        @include flex_common($dis: flex, $align: center, $justify: space-between);
                        width: calc(100% - calc(22px + (26 - 22) * ((100vw - 320px) / (600 - 320))) - 16px);

                        h4 {
                            font-weight: 500;
                            font-size: 16px;
                            color: rgba(var(--title), 1);
                        }

                        i {
                            font-size: 18px;
                            line-height: 1;
                            color: rgba(var(--title), 1);
                        }
                    }
                }
            }
        }
    }
}

.order-section {
    .order-list {
        .delivered-list {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 20px);

            @include mq-max(md) {
                gap: calc(11px + (20 - 11) * ((100vw - 320px) / (600 - 320)));
            }

            >li {
                width: 100%;

                .order-box {
                    box-shadow: $shadow3;
                    border-radius: 12px;
                    background-color: rgba(var(--white), 1);
                    padding: 12px;

                    [class="dark"] & {
                        background-color: #171717;
                        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
                    }

                    .order-header {
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
                        align-items: center;
                        position: relative;
                        border-bottom: 1px solid #ebebeb;
                        padding-bottom: 13px;
                        margin-bottom: 10px;

                        [class="dark"] & {
                            border-bottom-color: rgba(249, 249, 249, 0.08);
                        }

                        .order-image {
                            @include pseudowh($width: 63px, $height: 63px);
                            overflow: hidden;
                            border-radius: 6px;

                            img {
                                @include pseudowh;
                                object-fit: cover;
                            }
                        }

                        .order-name {
                            width: calc(100% - 63px - 8px);

                            a {
                                @include flex_common ($dis: flex, $align: center, $justify: space-between);
                                gap: 4px;
                                margin-bottom: 2px;

                                h5 {
                                    color: rgba(var(--title), 1);
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    font-size: 14px;
                                }

                                .delivered-box {
                                    position: relative;
                                    inset: unset;
                                    align-items: center;
                                    background-color: #f9f9f9;
                                    border-radius: 4px;
                                    padding: 6px 10px;
                                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 6px);

                                    [class="dark"] & {
                                        background-color: #2b2b2b;
                                    }

                                    [dir="rtl"] & {
                                        right: unset;
                                        left: 0;
                                    }

                                    img {
                                        @include pseudowh($width: 14px, $height: 14px);
                                    }

                                    h6 {
                                        font-weight: 500;
                                        font-size: 12px;
                                        margin: 0;
                                        line-height: 1;
                                        color: rgba(var(--title), 1);
                                    }
                                }
                            }

                            h6 {
                                font-weight: 400;
                                color: rgba(var(--content), 1);
                                margin-bottom: 10px;
                                font-size: 14px;
                            }

                            .order-paid {
                                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 7px);
                                align-items: center;

                                li {
                                    width: auto;
                                    font-weight: 400;
                                    font-size: 14px;
                                    color: rgba(var(--title), 1);

                                    @include mq-max(md) {
                                        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (600 - 320)));
                                    }
                                }
                            }
                        }
                    }

                    .order-quantity {
                        border-bottom: 1px solid #ebebeb;
                        padding-bottom: 12px;
                        margin-bottom: 7px;

                        [class="dark"] & {
                            border-bottom-color: rgba(249, 249, 249, 0.08);
                        }

                        .quantity-list {
                            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 8px);

                            li {
                                width: 100%;
                                font-weight: 400;
                                font-size: 14px;
                                color: rgba(var(--content), 1);
                                line-height: 1;
                            }
                        }
                    }

                    .order-rate {
                        @include flex_common($dis: flex, $align: center, $justify: space-between);

                        .rate-food {
                            display: flex;
                            align-items: center;

                            h6 {
                                font-weight: 400;
                                font-size: 14px;
                            }

                            ul {
                                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 3px);
                                align-items: center;
                                margin-left: 5px;

                                [dir="rtl"] & {
                                    margin-left: unset;
                                    margin-right: 5px;
                                }

                                li {
                                    i {
                                        font-size: 15px;
                                        color: #e0e0e0;

                                        [class="dark"] & {
                                            color: rgba(57, 44, 44, 0.19);
                                        }

                                        &.fill {
                                            color: #ffbb12;
                                        }
                                    }
                                }
                            }
                        }

                        .record-button {
                            width: auto;
                            padding: 6px 13px;
                            background-color: #f9f9f9;
                            border-radius: 4px;
                            font-weight: 600;
                            font-size: 12px;
                            color: rgba(var(--primary), 1);

                            [class="dark"] & {
                                background-color: #181818;
                            }
                        }
                    }
                }
            }
        }
    }
}

.order-place-section {
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: rgba(var(--white), 1);
    margin-top: -35px;
    z-index: 1;
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    max-width: 600px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    [class="dark"] & {
        background-color: #171717;
        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
    }

    .arriving-order-box {
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 14px);
        align-items: center;
        background-color: rgba(var(--white), 1);
        box-shadow: $shadow2;
        border-radius: 13px;
        padding: 15px;
        position: absolute;
        top: -92px;
        left: 0;
        width: -webkit-fill-available;
        margin-inline: 15px;

        [class="dark"] & {
            background-color: #171717;
            box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
        }

        .order-image {
            @include pseudowh($width: 52px, $height: 52px);
            border-radius: 8px;
            overflow: hidden;

            [class="dark"] & {
                filter: invert(1) brightness(100);
            }

            img {
                @include pseudowh;
                object-fit: cover;
            }
        }

        .order-content {
            width: calc(100% - 52px - 14px);
            color: rgba(var(--title), 1);

            h5 {
                margin-bottom: 5px;

                span {
                    font-weight: 600;
                    color: rgba(var(--primary), 1);
                }
            }

            h6 {
                font-weight: 400;
                font-size: 14px;
                color: rgba(var(--content), 1);
            }
        }
    }

    .order-place-box {
        .order-drive-box {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 12px);
            align-items: center;
            border-bottom: 1px solid #f9f9f9;
            padding-bottom: 16px;
            margin-bottom: 21px;

            [class="dark"] & {
                border-bottom-color: rgba(249, 249, 249, 0.05);
            }

            .driver-image {
                @include pseudowh($width: 50px, $height: 45px);
                border-radius: 8px;
                overflow: hidden;

                img {
                    @include pseudowh;
                    object-fit: cover;
                }
            }

            .driver-name {
                @include flex_common($dis: flex, $align: center, $justify: space-between);
                width: calc(100% - 50px - 12px);
                color: rgba(var(--title), 1);

                .name-box {
                    h5 {
                        font-weight: 400;
                        color: rgba(var(--title), 1);
                    }

                    h6 {
                        font-weight: 400;
                        margin-top: 5px;
                        color: rgba(var(--content), 1);
                    }
                }

                .driver-contact {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                    color: rgba(var(--title), 1);

                    li {
                        a {
                            @include flex_common;
                            @include pseudowh($width: 35px, $height: 35px);
                            background-color: #f9f9f9;
                            border-radius: 4px;
                            color: rgba(var(--title), 1);

                            [class="dark"] & {
                                background-color: #181818;
                            }

                            i {
                                font-size: 20px;
                            }
                        }

                        &:last-child {
                            a {
                                background-color: rgba(var(--primary), 1);
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        .map-list {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
            align-items: center;

            li {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
                align-items: center;
                color: rgba(var(--title), 1);
                padding: 8px 20px;
                border-radius: 50px;
                background-color: #f8f8f8;

                i {
                    font-size: 16px;
                }

                h5 {
                    font-weight: 400;
                }
            }
        }

        .button-group {
            margin-top: 18px;
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 15px);

            .btn {
                font-weight: 500;
                font-size: 18px;
                padding: 13px;
                border-radius: 18px;
                color: rgba(var(--white), 1);

                &.cancel-button {
                    background: var(--gradient2);
                }

                &.reschedule-button {
                    background: var(--gradient);
                }
            }
        }
    }

    .address-list-box {
        .address-list {
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 25px);
            position: relative;

            &::before {
                @include pos;
                @include center(vertical);
                left: 19px;
                @include pseudowh($width: 0, $height: 80%);
                border-left: 1px dashed #f1f1f1;
                z-index: -1;

                [dir="rtl"] & {
                    left: unset;
                    right: 19px;
                }
            }

            li {
                width: 100%;

                .address-box {
                    color: rgba(var(--title), 1);
                    align-items: center;
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);

                    .address-icon {
                        @include pseudowh($width: 39px, $height: 39px);
                        background-color: #f9f9f9;
                        border-radius: 4px;
                        font-size: 21px;
                        @include flex_common;

                        [class="dark"] & {
                            background-color: #181818;
                        }
                    }

                    .address-name {
                        width: calc(100% - 39px - 9px);

                        h5 {
                            margin-bottom: 4px;
                        }

                        h6 {
                            color: rgba(var(--content), 1);
                        }
                    }
                }
            }
        }
    }
}

.payment-section {
    .payment-setting-list {
        .payment-list {
            background-color: rgba(var(--white), 1);
            border-radius: 6px;
            padding: 18px 12px;
            align-items: center;
            @include flex_wrap($dis: flex, $wrap: wrap, $gap: 28px);

            [class="dark"] & {
                background-color: #171717;
                box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
            }

            li {
                width: 100%;
                position: relative;

                +li {
                    &::after {
                        @include pos;
                        top: -14px;
                        left: 0;
                        @include pseudowh($width: 100%, $height: 1px);
                        background-color: #f9f9f9;

                        [class="dark"] & {
                            background-color: rgba(249, 249, 249, 0.08);
                        }
                    }
                }

                .payment-box {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 16px);
                    align-items: center;

                    .payment-image {
                        @include pseudowh($width: 66px, $height: 43px);
                        background-color: #f9f9f9;
                        border-radius: 6px;
                        padding: 12px;
                        @include flex_common;

                        img {
                            @include pseudowh;
                            object-fit: contain;
                        }
                    }

                    .payment-name {
                        @include flex_common($dis: flex, $align: center, $justify: space-between);
                        width: calc(100% - 26px - 16px);
                        color: rgba(var(--title), 1);

                        h5 {
                            margin-bottom: 6px;
                        }

                        h6 {
                            font-weight: 400;
                            font-size: 14px;
                            color: rgba(var(--content), 1);
                        }

                        i {
                            line-height: 1;
                            color: rgba(var(--title), 1);
                            font-size: 20px;
                        }
                    }
                }

                &.new-card {
                    &::after {
                        content: none;
                    }

                    .add-new-card {
                        border: 1px dashed rgba(var(--primary), 1);
                        border-radius: 10px;
                        font-weight: 500;
                        font-size: 15px;
                        color: rgba(var(--primary), 1);
                        @include flex_common;
                        padding: 11px;
                        flex-wrap: nowrap;
                        gap: 3px;
                        width: 100%;
                        background-color: transparent;

                        i {
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
}

.cart-section {
    .cart-items-list {
        .items-list {
            background-color: rgba(var(--white), 1);
            box-shadow: $shadow3;
            border-radius: 10px;
            padding: 10px;

            [class="dark"] & {
                background-color: #171717;
                box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
            }

            li {
                +li {
                    border-top: 1px solid #f9f9f9;
                    margin-top: 10px;
                    padding-top: 10px;

                    [class="dark"] & {
                        border-top-color: rgba(249, 249, 249, 0.08);
                    }
                }

                .cart-box {
                    @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 9px);
                    align-items: center;

                    .items-image {
                        @include pseudowh($width: 78px, $height: 78px);
                        border-radius: 6px;
                        overflow: hidden;

                        img {
                            @include pseudowh;
                            object-fit: cover;
                        }
                    }

                    .items-name {
                        width: calc(100% - 9px - 78px);
                        @include flex_common($dis: flex, $align: flex-end, $justify: space-between);
                        color: rgba(var(--title), 1);

                        h5 {
                            color: rgba(var(--title), 1);
                            font-size: 14px;
                            font-weight: 600;
                        }

                        h6 {
                            font-weight: 400;
                            font-size: 14px;
                            margin-top: 5px;
                            margin-bottom: 5px;
                            color: rgba(var(--content), 1);
                        }

                        h5 {
                            a {
                                font-weight: 400;
                                color: rgba(var(--primary), 1);
                            }
                        }
                    }

                    .quantity-box {
                        display: flex;
                        align-items: center;
                        background-color: rgba(var(--primary), 1);
                        border-radius: 5px;
                        width: 76px;
                        padding: 4px;
                        color: rgba(var(--white), 1);

                        button {
                            padding: 0;
                            line-height: 1;
                            color: rgba(var(--white), 1);
                            font-size: 20px;
                            font-weight: bold;
                            @include flex_common;
                            border: none;

                            [class="dark"] & {
                                color: #fff;
                            }

                            &:active {
                                border-color: transparent;
                            }
                        }

                        input {
                            width: 100%;
                            background-color: transparent;
                            border: none;
                            text-align: center;
                            color: rgba(var(--white), 1);
                            font-weight: 600;
                            font-size: 14px;

                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }

                            [class="dark"] & {
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.instructions-list {
    background-color: rgba(var(--white), 1);
    box-shadow: $shadow2;
    border-radius: 10px;
    padding: 14px 16px;
    @include flex_wrap($dis: flex, $wrap: wrap, $gap: 10px);

    [class="dark"] & {
        background-color: #171717;
        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
    }

    [dir="rtl"] & {
        padding: 14px 16px;
    }

    li {
        width: 100%;

        .instructions-box {
            align-items: center;
            flex-wrap: nowrap;
            gap: 10px;

            .form-check-label {
                @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 4px);
                align-items: center;

                img {
                    @include pseudowh($width: 18px, $height: 18px);
                }

                span {
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(var(--title), 1);

                    [class="dark"] & {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.bill-list {
    background-color: rgba(var(--white), 1);
    box-shadow: $shadow3;
    border-radius: 10px;
    padding: 14px 16px;
    display: flex;
    flex-wrap: wrap;

    [class="dark"] & {
        background-color: #171717;
        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
    }

    [dir="rtl"] & {
        padding: 14px 16px;
    }

    li {
        width: 100%;
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        font-weight: 400;
        color: rgba(var(--content), 1);

        h5 {
            font-size: 15px;
        }

        +li {
            margin-top: 12px;
        }

        &.total-bill {
            border-top: 1px solid rgba(237, 239, 244, 0.6);
            padding-top: 10px;
            color: rgba(var(--title), 1);
            font-size: 15px;

            [class="dark"] & {
                border-top-color: rgba(237, 239, 244, 0.08);
            }
        }
    }
}

.proceed-pay-section {
    z-index: 1;
    position: fixed;
    bottom: 0;
    @include center(horizontal);
    width: 100%;
    max-width: 600px;
    background-color: rgba(var(--white), 1);
    box-shadow: $shadow3;
    border-radius: 15px 15px 0px 0px;
    padding-top: 16px;
    padding-bottom: 16px;

    [class="dark"] & {
        background-color: #171717;
        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
    }

    .proceed-address-box {
        color: rgba(var(--title), 1);
        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 8px);
        align-items: center;
        padding-bottom: 11px;
        margin-bottom: 11px;
        border-bottom: 1px solid rgba(237, 239, 244, 0.6);

        [class="dark"] & {
            border-bottom-color: rgba(237, 239, 244, 0.08);
        }

        .address-icon {
            @include pseudowh($width: 40px, $height: 40px);
            background-color: #fff9ec;
            border-radius: 6px;
            @include flex_common;
            font-size: 20px;

            [class="dark"] & {
                background-color: #181818;
            }
        }

        .address-box {
            @include flex_common($dis: flex, $align: flex-start, $justify: space-between);
            width: calc(100% - 40px - 8px);

            h5 {
                font-weight: 600;
                margin-bottom: 3px;
                font-size: 16px;
            }

            h6 {
                font-weight: 400;
                color: #bcbcbc;
                font-size: 14px;
                margin-top: 6px;
                display: block;
            }

            a {
                font-weight: 600;
                font-size: 14px;
                color: #feaf18;
            }
        }
    }

    .amount-box {
        @include flex_common($dis: flex, $align: center, $justify: space-between);
        margin-bottom: 10px;
    }

    .pay-button {
        background-color: rgba(var(--primary), 1);
        border-radius: 6px;
        color: rgba(var(--white), 1);
        font-weight: 600;
        font-size: 18px;

        @include mq-max(md) {
            font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (600 - 320)));
        }

        &:active {
            background-color: rgba(var(--primary), 1);
            border-color: transparent;
            color: rgba(var(--white), 1);
        }
    }
}

.offer-section {
    .offer-list-box {
        .offer-list {
            @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 11px);
            overflow: auto hidden;

            li {
                .offer-box {
                    background-color: rgba(var(--white), 1);
                    box-shadow: $shadow3;
                    border-radius: 6px;
                    padding: 10px;
                    color: rgba(var(--title), 1);
                    display: inline-block;

                    [class="dark"] & {
                        background-color: #171717;
                        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
                    }

                    >div {
                        @include flex_wrap($dis: flex, $wrap: nowrap, $gap: 6px);
                        align-items: center;
                    }

                    .offer-icon {
                        @include pseudowh($width: 26px, $height: 26px);
                    }

                    .offer-contain {
                        h5 {
                            margin-bottom: 4px;
                            white-space: nowrap;
                        }

                        h6 {
                            font-weight: 400;
                            color: rgba(var(--content), 1);
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}

.add-to-cart-box {
    background-color: rgba(var(--primary), 1);
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    @include pseudowh($width: 63px, $height: 25px);

    &.hide {
        background-color: transparent;
    }

    .btn-add-cart {
        padding: 0;
        color: #fff;
        @include flex_common;
        height: 100%;
        flex-wrap: nowrap;
        gap: 6px;
        font-weight: 600;
        font-size: 14px;

        &.hide {
            opacity: 0;
        }

        &:first-child {
            &:active {
                background-color: rgba(var(--primary), 1);
                border-color: transparent;
                color: rgba(var(--white), 1);
            }
        }

        .add-icon {
            font-size: 18px;
            font-weight: 500;
            position: relative;
            inset: unset;
            @include pseudowh($width: unset, $height: unset);
            background-color: transparent;

            i {
                font-size: 13px;
            }
        }
    }

    .qty-box {
        @include pseudowh($width: 76px, $height: 25px);
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0;
        display: none;

        &.open {
            display: block;
        }

        .input-group {
            background-color: rgba(var(--primary), 1);
            border-radius: 5px;
            padding: 4px;
            text-align: center;
            z-index: 0;
            height: 100%;

            button {
                @include pseudowh($width: 24px, $height: 100%);
                @include flex_common;
                border: none;
                padding: 0;
                background-color: transparent;
                z-index: 0;
                color: rgba(var(--white), 1);
                font-size: 18px;
                font-weight: 500;

                i {
                    font-size: 13px;
                    color: rgba(var(--theme-color), 1);
                    margin-top: 1px;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            input {
                height: 100%;
                background-color: transparent;
                border: none;
                padding: 0;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                color: rgba(var(--white), 1);
            }
        }
    }
}

.food-price-section {
    position: fixed;
    bottom: 0;
    max-width: 600px;
    @include center(horizontal);
    width: 100%;
    background-color: rgba(var(--white), 1);
    box-shadow: $drop-shadow;
    padding-top: 11px;
    padding-bottom: 11px;

    [class="dark"] & {
        background-color: #171717;
        box-shadow: 2px 3px 6px rgba(57, 44, 44, 0.19);
    }

    .view-price {
        @include flex_common($dis: flex, $align: flex-end, $justify: space-between);

        h5 {
            font-weight: 400;
            color: rgba(var(--content), 1);
            margin-bottom: 3px;
        }

        h4 {
            font-weight: 600;
            color: rgba(var(--black), 1);
        }
    }
}

.wallet-section {
    .wallet-box {
        background-color: rgba(var(--white), 1);
        border-radius: 6px;
        padding: 18px 12px;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 2px 3px 6px rgba(213, 213, 213, 0.19);
        justify-content: space-between;

        .wallets-balance {
            h5 {
                font-weight: 400;
                color: rgba(var(--content), 1);
            }

            h4 {
                font-weight: 600;
                margin-top: 4px;
            }
        }

        .wallets-icon {
            i {
                font-size: 23px;
            }
        }

        .other-upi {
            background-color: rgba(var(--white), 1);
            border-radius: 6px;
            padding: 18px 12px;
            box-shadow: 2px 3px 6px rgba(213, 213, 213, 0.19);
        }
    }
}