/**=====================
     2.2 typography CSS
==========================**/
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    & {
        scrollbar-width: none;
        scrollbar-color: transparent;
    }

    &::-webkit-scrollbar {
        width: 0px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0px;
        border: unset;
    }
}

body {
    font-family: $montserrat;
    position: relative;
    font-size: 14px;
    color: rgba(var(--title), 1);
    background-color: rgba(var(--white), 1);
    transition: all 0.3s ease-in-out;
    max-width: 600px;
    min-height: 100vh;
    margin-inline: auto;
    box-shadow: 0 0 8px 3px rgba(var(--title), 0.1);
    padding-right: 0 !important;

    &.inter-body {
        font-family: $inter;
    }

    &.rubik-body {
        font-family: $rubik;
    }

    &.roboto-body {
        font-family: $roboto;
    }

    &.lato-body {
        font-family: $lato;
    }

    &.food-auth-bg {
        background-color: #FFF9EC;
    }

    &.ecommerce-bg-color {
        background-color: #373737;
    }

    &.public-san-body {
        font-family: $public-san;
    }

    &.nunito-body {
        font-family: $nunito;
    }

    &.rowdies-body {
        font-family: $rowdies;
    }

    &.dark-body {
        background-color: rgba(var(--dark-bg), 1);
        color: rgba(var(--content), 1);
    }

    .background-image {
        position: fixed;
        bottom: 84px;
        @include pseudowh($width: 182px, $height: 182px);
        z-index: -1;
    }
}

a {
    color: rgba(var(--theme-color), 1);
    transition: 0.5s ease;
    text-decoration: none;

    &:hover {
        text-decoration: none;
        transition: 0.5s ease;
    }

    &:focus {
        outline: none;
    }
}

button {
    &:focus {
        outline: none;
    }
}

.btn-close {
    &:focus {
        box-shadow: none;
    }
}

:focus {
    outline: none;
}

.form-control {
    background-color: rgba(var(--white), 1);

    &:focus {
        box-shadow: none;
        border-color: rgba(var(--theme-color), 1);
    }
}

span {
    display: inline-block;
}

h1,
.h1 {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 0;
}

h2,
.h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

h3,
.h3 {
    font-size: 18px;
    margin-bottom: 0;
}

h4,
.h4 {
    font-size: 16px;
    margin-bottom: 0;
}

h5,
.h5 {
    font-size: 14px;
    margin-bottom: 0;

    @include mq-max(md) {
        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (600 - 320)));
    }
}

h6,
.h6 {
    font-size: 12px;
    margin-bottom: 0;
}

p,
.p {
    font-size: 12px;
}

a {
    text-decoration: none;

    &:hover {
        color: unset;
    }
}

ul {
    padding-left: 0;
    margin-bottom: 0;
}

li {
    list-style: none;
}